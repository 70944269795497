// Standard
.tabs {
  &__controls {}

  &__button {}

  &__content {
    position: relative;
  }

  &__pane {
    position: absolute;
    top: 0;
    visibility: hidden;
    transition: none;
    opacity: 0;
    
    &.is-active {
      transition: opacity 0.3s $easeOutQuart 0.1s;
      position: relative;
      opacity: 1;
      visibility: visible;
    }
  }
}


// Fancy
.tabs.-buttons .tabs {
  &__button {
    padding: rem(8px) rem(24px);
    border-radius: rem(8px);
    transition: all 0.15s $easeOutQuart;
    background-color: var(--bg-light-4);

    &.is-active {
      color: white !important;
      background-color: var(--accent-color) !important;
    }

    &:not(:first-child) {
      margin-left: rem(12px);
    }
  }

  &__pane {
    display: none;
    opacity: 0;
    transition: all 0.15s $easeOutQuart;
    
    &.is-active {
      display: block;
      opacity: 1;
    }
  }
}

.tabs.-active-1 .tabs {
  &__controls button {
    &.is-active {
      color: black !important;
      background-color: white !important;
    }
  }
}

.tabs.-active-2 .tabs {
  &__controls .tabs__button {
    &.is-active {
      background-color: var(--bg-dark-5) !important;
    }
  }
}

.tabs.-active-3 .tabs {
  &__controls .tabs__button {
    &.is-active .-tab-active {
      background-color: var(--dark-blue-color) !important;
      color: white !important;
    }
  }
}

.tabs.-active-purple-1 .tabs {
  &__controls .tabs__button {
    &.is-active {
      color: var(--color-purple-1) !important;
    }
  }
}

.tabs.-active-purple-2 .tabs {
  justify-content: unset;

  &__controls {
    width: 100%;
    border-bottom: 2px solid var(--color-light-5);
    margin: 0;
    
    .tabs__button {
      padding-bottom: 12px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--color-purple-1);
        opacity: 0;
      }

      &.is-active {
        &::after {
          opacity: 1;
        }
        
        color: var(--color-purple-1) !important;
      }
    }
  }

  &__content {
    width: 100%;
  }
}

.tabs.-pills .tabs {
  &__controls .tabs__button {
    &.is-active {
      color: var(--color-purple-1) !important;
      font-weight: 500;
      background-color: var(--color-purple-3);
    }
  }
}

.tabs.-pills-2 .tabs {
  &__controls .tabs__button {
    &.is-active {
      color: var(--color-purple-1) !important;
      background-color: white !important;
    }
  }
}

.tabs.-side .tabs {
  &__controls {
    border-left: 1px solid var(--color-light-5);
    
    .tabs__button {
      position: relative;
      padding-left: 22px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -1px;
        height: 100%;
        width: 2px;
        background-color: var(--color-purple-1);
        transition: all 0.2s $easeOutQuart;
        opacity: 0;
      }
      
      &.is-active {
        color: var(--color-purple-1) !important;

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.tabs.-lines .tabs {
  &__controls {
    border-bottom: 1px solid #EEEEEE;
    display: flex;
  }
  
  &__button {
    margin-bottom: -1px;
    padding: rem(10px) rem(12px);
    padding-bottom: rem(34px);
    font-weight: 600;
    transition: all 0.15s $easeOutQuart;
    width: 100%;

    &.is-active {
      color: var(--accent-color);
      border-bottom: 1px solid var(--accent-color);
    }

    + .tabs__button {
      margin-left: rem(8px);
    }
  }

  // &__pane {
  //   display: none;
  //   opacity: 0;
  //   transition: all 0.15s $easeOutQuart;
    
  //   &.is-active {
  //     display: block;
  //     opacity: 1;
  //   }
  // }
}

.tabs.-lines-small .tabs {
  &__controls {
    border-bottom: 1px solid #EEEEEE;
    display: flex;
  }
  
  &__button {
    margin-bottom: -1px;
    padding: rem(10px) rem(12px);
    font-weight: 600;
    transition: all 0.15s $easeOutQuart;
    border-bottom: 2px solid transparent;

    &.is-active {
      color: var(--accent-color) !important;
      border-bottom: 2px solid var(--accent-color);
    }

    + .tabs__button {
      margin-left: rem(8px);
    }
  }

  &__pane {
    display: none;
    opacity: 0;
    transition: all 0.15s $easeOutQuart;
    
    &.is-active {
      display: block;
      opacity: 1;
    }
  }
}

.tabs.-button-bg-white {
  .tabs__button {
    &.is-active {
      background-color: white !important;
      box-shadow: 0px 5px 70px rgba(1, 33, 58, 0.03);
    }
  }

  .tabs__pane {
    display: none;
    opacity: 0;
    transition: all 0.15s $easeOutQuart;
    
    &.is-active {
      display: block;
      opacity: 1;
    }
  }
}
