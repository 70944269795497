[data-anim-wrap] {
  pointer-events: none;
  &.animated { pointer-events: auto; }
}

[data-anim*='cover-white']::after,
[data-anim-child*='cover-white']::after {
  background-color: white;
}

[data-anim*='cover-black']::after,
[data-anim-child*='cover-black']::after {
  background-color: black;
}

[data-anim*='cover-light-1']::after,
[data-anim-child*='cover-light-1']::after {
  background-color: var(--bg-light-1);
}

[data-anim*='cover-light-2']::after,
[data-anim-child*='cover-light-2']::after {
  background-color: var(--bg-light-2);
}

[data-anim*='cover-dark-1']::after,
[data-anim-child*='cover-dark-1']::after {
  background-color: var(--bg-dark-1);
}

[data-anim*='cover-dark-2']::after,
[data-anim-child*='cover-dark-2']::after {
  background-color: var(--bg-dark-2);
}

[data-anim*='cover-accent']::after,
[data-anim-child*='cover-accent']::after {
  background-color: var(--accent-color);
}

@keyframes reveal {
  100% {
    opacity: 1;
  }
}


// Cover anim
$cover-anim-duration: 1.2s;

[data-anim^='cover-right'],
[data-anim-child^='cover-right'] {
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    transform: scaleX(0);
  
    animation-duration: $cover-anim-duration;
    animation-timing-function: $easeInOutQuart;
  }

  > * {
    opacity: 0;
    pointer-events: none;
  
    animation-delay: $cover-anim-duration / 2;
    animation-duration: 20ms;
    animation-timing-function: $linear-easing;
    animation-fill-mode: both;
  }
}

[data-anim^='cover-right'].is-in-view,
[data-anim-child^='cover-right'].is-in-view {
  &::after {
    animation-name: coverRight;
  }

  > * {
    animation-name: reveal;
    pointer-events: auto;
  }
}

[data-anim^='cover-up'],
[data-anim-child^='cover-up'] {
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    transform: scaleY(0);
  
    animation-duration: $cover-anim-duration;
    animation-timing-function: $easeInOutQuart;
  }

  > * {
    opacity: 0;
    pointer-events: none;
  
    animation-delay: $cover-anim-duration / 2;
    animation-duration: 20ms;
    animation-timing-function: $linear-easing;
    animation-fill-mode: both;
  }
}

[data-anim^='cover-up'].is-in-view,
[data-anim-child^='cover-up'].is-in-view {
  &::after {
    animation-name: coverUp;
  }

  > * {
    animation-name: reveal;
    pointer-events: auto;
  }
}

@keyframes coverRight {
  0% {
    transform-origin: 0 50%;
    transform: scaleX(0);
  }
  42% {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  58% {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scaleX(0);
  }
}

@keyframes coverUp {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  42% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  58% {
    transform-origin: 50% 0;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0;
    transform: scaleY(0);
  }
}



[data-anim^='img-fade'],
[data-anim-child^='img-fade'] {
  overflow: hidden;
  display: block;
  position: relative;

  > * {
    pointer-events: none;
    opacity: 0;
    transform: scale(1.5);
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: $easeInOutQuart;
  }

  &.is-in-view > * {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}



// Img anim

[data-anim^='img-right'],
[data-anim-child^='img-right'] {
  overflow: hidden;
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: rem(-1px);
    left: rem(-1px);
    right: 0;
    z-index: 20;

    height: calc(100% + 0.125rem);
    width: calc(100% + 0.125rem);

    transform-origin: right;
    animation-duration: 0.8s;
    animation-timing-function: $easeInOutQuart;
    animation-fill-mode: both;
  }

  > * {
    transform-origin: center;
    animation-duration: 0.8s;
    animation-timing-function: $easeInOutQuart;
    animation-fill-mode: both;
  }
}

[data-anim^='img-right'].is-in-view,
[data-anim-child^='img-right'].is-in-view {
  &::after { animation-name: imgRight; }
  > * { animation-name: imgReveal; }
}

@keyframes imgRight {
  0% { transform: scaleX(1); }
  100% { transform: scaleX(0); }
}

@keyframes imgReveal {
  0% { transform: scale(1.4); }
  100% { transform: scale(1.01); }
}


// Slide anim
$slide-distance: 40px;
$slide-duration: 1.0s;

[data-anim^='slide-'],
[data-anim-child^='slide-'] {
  opacity: 0;
  transition-property: opacity, transform;
  pointer-events: none;

  transition-duration: $slide-duration;
  transition-timing-function: $easeOutCubic;

  &.is-in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }
}

[data-anim^='slide-up'],
[data-anim-child^='slide-up'] {
  transform: translate3d(0, $slide-distance, 0);
}

[data-anim^='slide-down'],
[data-anim-child^='slide-down'] {
  transform: translate3d(0, -$slide-distance, 0);
}

[data-anim^='slide-right'],
[data-anim-child^='slide-right'] {
  transform: translate3d(-$slide-distance, 0, 0);
}

[data-anim^='slide-left'],
[data-anim-child^='slide-left'] {
  transform: translate3d($slide-distance, 0, 0);
}


// Fade animation

[data-anim^='fade'],
[data-anim-child^='fade'] {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;

  transition-duration: 0.8s;
  transition-timing-function: $easeInOutCubic;

  &.is-in-view {
    opacity: 1;
    pointer-events: auto;
  }
}


// Scale animation

[data-anim^='scale'],
[data-anim-child^='scale'] {
  opacity: 0;
  transform: scale(0.9) translateY(60px);
  transition-property: opacity, transform;
  pointer-events: none;
  
  transition-duration: 1.2s;
  transition-timing-function: $easeOutCubic;
  
  &.is-in-view {
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }
}


[data-anim^='fancy-1'],
[data-anim-child^='fancy-1'] {
  opacity: 0;
  transform:  translateY(120px) rotateY(20deg);
  transform-origin: left;
  transition-property: opacity, transform;
  pointer-events: none;
  
  transition-duration: 1.2s;
  transition-timing-function: $easeOutCubic;
  
  &.is-in-view {
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }
}


[data-anim^='fancy-2'],
[data-anim-child^='fancy-2'] {
  opacity: 0;
  transform:  translateY(120px) rotateY(40deg) scaleX(1.3);
  transform-origin: left;
  transition-property: opacity, transform;
  pointer-events: none;
  
  transition-duration: 1.2s;
  transition-timing-function: $easeOutCubic;
  
  &.is-in-view {
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }
}

.float-animation {
  animation: floatOne 1s ease-out infinite alternate;
}

@keyframes floatOne {
  0% { 
    transform:translateY(0)
  } 
  100% {
    transform:translateY(-3%)
  }
}

.float-animation-sm {
  animation: floatTwo 1s ease-out infinite alternate;
}

@keyframes floatTwo {
  0% { 
    transform:translateY(0)
  } 
  100% {
    transform:translateY(-15px)
  }
}

.pulse-animation {
  position: relative;
  z-index: 1;

  &::after {
    z-index: -1;
    content: "";
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    animation: video_btn_pulse 3.0s infinite;
    animation-timing-function: $easeOutCubic;
  }
}

@keyframes video_btn_pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }

  75% {
    opacity: 0;
    transform: scale(1.6);
  }

  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}
