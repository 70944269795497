.pu-icon {
  display: inline-flex;
  font-size: 1rem;
  vertical-align: middle;
}

.pu-icon svg {
  height: 1em;
  width: 1em;
}

.pu-icon svg * {
  fill: currentColor;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #f85a3e;
  --green: #28a745;
  --teal: #00798c;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #f85a3e;
  --secondary: #4587ad;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #fec751;
  --danger: #dc3545;
  --light: #f0f3f7;
  --dark: #0f1319;
  --facebook: #3b5998;
  --google: #f2f2f2;
  --twitter: #1da1f2;
  --youtube: red;
  --rmi: #53b97e;
  --yellow: #fec751;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: sans-serif;
  line-height: 1.15;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  background-color: #fff;
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
}

abbr[data-original-title],
abbr[title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

dl,
ol,
ul {
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  color: #f85a3e;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #f74525;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
}

img,
svg {
  vertical-align: middle;
}

svg {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  caption-side: bottom;
  color: #6c757d;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  color: inherit;
  display: block;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: none;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.h5,
h5 {
  font-size: 1.1875rem;
}

.h6,
h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
}

.display-1,
.display-2 {
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
}

.display-3 {
  font-size: 4.5rem;
}

.display-3,
.display-4 {
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.mark,
mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}

.list-inline,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.blockquote-footer {
  color: #6c757d;
  display: block;
  font-size: 80%;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
}

.img-thumbnail {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  word-wrap: break-word;
  color: #e83e8c;
  font-size: 87.5%;
}

a>code {
  color: inherit;
}

kbd {
  background-color: #212529;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
}

kbd kbd {
  font-size: 100%;
  font-weight: 700;
  padding: 0;
}

pre {
  color: #212529;
  display: block;
  font-size: 87.5%;
}

pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  max-width: 100%;
  width: auto;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

.table {
  color: #212529;
  margin-bottom: 1rem;
  width: 100%;
}

.table td,
.table th {
  border-top: 1px solid #dee2e6;
  padding: 0.75rem;
  vertical-align: top;
}

.table thead th {
  border-bottom: 2px solid #dee2e6;
  vertical-align: bottom;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
  color: #212529;
}

.table-primary,
.table-primary>td,
.table-primary>th {
  background-color: #fdd1c9;
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #fba99b;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #fcbcb0;
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
  background-color: #cbdde8;
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #9ec1d4;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #b9d1e0;
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: #c3e6cb;
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb;
}

.table-info,
.table-info>td,
.table-info>th {
  background-color: #bee5eb;
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>td,
.table-warning>th {
  background-color: #ffefce;
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #fee2a5;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe7b5;
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: #f5c6cb;
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7;
}

.table-light,
.table-light>td,
.table-light>th {
  background-color: #fbfcfd;
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #f7f9fb;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #eaeff5;
}

.table-dark,
.table-dark>td,
.table-dark>th {
  background-color: #bcbdbf;
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #828487;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #afb0b3;
}

.table-facebook,
.table-facebook>td,
.table-facebook>th {
  background-color: #c8d1e2;
}

.table-facebook tbody+tbody,
.table-facebook td,
.table-facebook th,
.table-facebook thead th {
  border-color: #99a9c9;
}

.table-hover .table-facebook:hover,
.table-hover .table-facebook:hover>td,
.table-hover .table-facebook:hover>th {
  background-color: #b7c3d9;
}

.table-google,
.table-google>td,
.table-google>th {
  background-color: #fbfbfb;
}

.table-google tbody+tbody,
.table-google td,
.table-google th,
.table-google thead th {
  border-color: #f8f8f8;
}

.table-hover .table-google:hover,
.table-hover .table-google:hover>td,
.table-hover .table-google:hover>th {
  background-color: #eee;
}

.table-twitter,
.table-twitter>td,
.table-twitter>th {
  background-color: #c0e5fb;
}

.table-twitter tbody+tbody,
.table-twitter td,
.table-twitter th,
.table-twitter thead th {
  border-color: #89cef8;
}

.table-hover .table-twitter:hover,
.table-hover .table-twitter:hover>td,
.table-hover .table-twitter:hover>th {
  background-color: #a8dbf9;
}

.table-youtube,
.table-youtube>td,
.table-youtube>th {
  background-color: #ffb8b8;
}

.table-youtube tbody+tbody,
.table-youtube td,
.table-youtube th,
.table-youtube thead th {
  border-color: #ff7a7a;
}

.table-hover .table-youtube:hover,
.table-hover .table-youtube:hover>td,
.table-hover .table-youtube:hover>th {
  background-color: #ff9f9f;
}

.table-rmi,
.table-rmi>td,
.table-rmi>th {
  background-color: #cfebdb;
}

.table-rmi tbody+tbody,
.table-rmi td,
.table-rmi th,
.table-rmi thead th {
  border-color: #a6dbbc;
}

.table-hover .table-rmi:hover,
.table-hover .table-rmi:hover>td,
.table-hover .table-rmi:hover>th {
  background-color: #bde4ce;
}

.table-yellow,
.table-yellow>td,
.table-yellow>th {
  background-color: #ffefce;
}

.table-yellow tbody+tbody,
.table-yellow td,
.table-yellow th,
.table-yellow thead th {
  border-color: #fee2a5;
}

.table-hover .table-yellow:hover,
.table-hover .table-yellow:hover>td,
.table-hover .table-yellow:hover>th {
  background-color: #ffe7b5;
}

.table-active,
.table-active>td,
.table-active>th,
.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  background-color: #343a40;
  border-color: #454d55;
  color: #fff;
}

.table .thead-light th {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}

.table-dark {
  background-color: #343a40;
  color: #fff;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: hsla(0, 0%, 100%, 0.075);
  color: #fff;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
  width: 100%;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  background-color: #fff;
  border-color: #fcc3b9;
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
  color: #495057;
  outline: 0;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  background-color: #fff;
  color: #495057;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: calc(0.375rem + 1px);
  padding-top: calc(0.375rem + 1px);
}

.col-form-label-lg {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-bottom: calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
}

.col-form-label-sm {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-bottom: calc(0.25rem + 1px);
  padding-top: calc(0.25rem + 1px);
}

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #212529;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.375rem 0;
  width: 100%;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px);
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}

.form-control-lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 2px);
  line-height: 1.5;
  padding: 0.5rem 1rem;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  display: block;
  padding-left: 1.25rem;
  position: relative;
}

.form-check-input {
  margin-left: -1.25rem;
  margin-top: 0.3rem;
  position: absolute;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  display: inline-flex;
  margin-right: 0.75rem;
  padding-left: 0;
}

.form-check-inline .form-check-input {
  margin-left: 0;
  margin-right: 0.3125rem;
  margin-top: 0;
  position: static;
}

.valid-feedback {
  color: #28a745;
  display: none;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}

.valid-tooltip {
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.75rem;
  left: 0;
  line-height: 1.5;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.form-row>.col>.valid-tooltip,
.form-row>[class*="col-"]>.valid-tooltip {
  left: 5px;
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
  display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  padding-right: calc(1.5em + 0.75rem);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat,
    #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #28a745;
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
  display: block;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
  border-color: #28a745;
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before {
  border-color: #28a745;
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  color: #dc3545;
  display: none;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}

.invalid-tooltip {
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.75rem;
  left: 0;
  line-height: 1.5;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.form-row>.col>.invalid-tooltip,
.form-row>[class*="col-"]>.invalid-tooltip {
  left: 5px;
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  background-position: right 1.5rem center;
  padding-right: 3rem !important;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  padding-right: calc(1.5em + 0.75rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat,
    #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E") center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
  border-color: #dc3545;
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before {
  border-color: #dc3545;
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
  }

  .form-inline .form-group,
  .form-inline label {
    align-items: center;
    display: flex;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    flex: 0 0 auto;
    flex-flow: row wrap;
  }

  .form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 0;
    width: auto;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-left: 0;
    margin-right: 0.25rem;
    margin-top: 0;
    position: relative;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #212529;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.4375rem 1.3125rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
  outline: 0;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  background-color: #f85a3e;
  border-color: #f85a3e;
  color: #fff;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #f73a19;
  border-color: #f6300d;
  color: #fff;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem hsla(9, 93%, 67%, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #f85a3e;
  border-color: #f85a3e;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #f6300d;
  border-color: #ee2b09;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(9, 93%, 67%, 0.5);
}

.btn-secondary {
  background-color: #4587ad;
  border-color: #4587ad;
  color: #fff;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #3a7292;
  border-color: #366b89;
  color: #fff;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 153, 185, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #4587ad;
  border-color: #4587ad;
  color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  background-color: #366b89;
  border-color: #33637f;
  color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 153, 185, 0.5);
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
  color: #fff;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  background-color: #1e7e34;
  border-color: #1c7430;
  color: #fff;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}

.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
  background-color: #138496;
  border-color: #117a8b;
  color: #fff;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  background-color: #117a8b;
  border-color: #10707f;
  color: #fff;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
  background-color: #febb2b;
  border-color: #feb71e;
  color: #212529;
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 75, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  background-color: #feb71e;
  border-color: #feb312;
  color: #212529;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 75, 0.5);
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
  color: #fff;
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  background-color: #bd2130;
  border-color: #b21f2d;
  color: #fff;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  background-color: #f0f3f7;
  border-color: #f0f3f7;
  color: #212529;
}

.btn-light.focus,
.btn-light:focus,
.btn-light:hover {
  background-color: #d7dfea;
  border-color: #cfd8e5;
  color: #212529;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem hsla(214, 8%, 83%, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #f0f3f7;
  border-color: #f0f3f7;
  color: #212529;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  background-color: #cfd8e5;
  border-color: #c6d2e1;
  color: #212529;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(214, 8%, 83%, 0.5);
}

.btn-dark {
  background-color: #0f1319;
  border-color: #0f1319;
  color: #fff;
}

.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover {
  background-color: #010101;
  border-color: #000;
  color: #fff;
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 54, 60, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #0f1319;
  border-color: #0f1319;
  color: #fff;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 54, 60, 0.5);
}

.btn-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook.focus,
.btn-facebook:focus,
.btn-facebook:hover {
  background-color: #30497c;
  border-color: #2d4373;
  color: #fff;
}

.btn-facebook.focus,
.btn-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active,
.show>.btn-facebook.dropdown-toggle {
  background-color: #2d4373;
  border-color: #293e6a;
  color: #fff;
}

.btn-facebook:not(:disabled):not(.disabled).active:focus,
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-google {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #212529;
}

.btn-google.focus,
.btn-google:focus,
.btn-google:hover {
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  color: #212529;
}

.btn-google.focus,
.btn-google:focus {
  box-shadow: 0 0 0 0.2rem hsla(240, 1%, 83%, 0.5);
}

.btn-google.disabled,
.btn-google:disabled {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #212529;
}

.btn-google:not(:disabled):not(.disabled).active,
.btn-google:not(:disabled):not(.disabled):active,
.show>.btn-google.dropdown-toggle {
  background-color: #d9d9d9;
  border-color: #d2d2d2;
  color: #212529;
}

.btn-google:not(:disabled):not(.disabled).active:focus,
.btn-google:not(:disabled):not(.disabled):active:focus,
.show>.btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(240, 1%, 83%, 0.5);
}

.btn-twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter.focus,
.btn-twitter:focus,
.btn-twitter:hover {
  background-color: #0d8ddc;
  border-color: #0c85d0;
  color: #fff;
}

.btn-twitter.focus,
.btn-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:not(:disabled):not(.disabled).active,
.btn-twitter:not(:disabled):not(.disabled):active,
.show>.btn-twitter.dropdown-toggle {
  background-color: #0c85d0;
  border-color: #0b7ec4;
  color: #fff;
}

.btn-twitter:not(:disabled):not(.disabled).active:focus,
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.show>.btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-youtube {
  background-color: red;
  border-color: red;
  color: #fff;
}

.btn-youtube.focus,
.btn-youtube:focus,
.btn-youtube:hover {
  background-color: #d90000;
  border-color: #c00;
  color: #fff;
}

.btn-youtube.focus,
.btn-youtube:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}

.btn-youtube.disabled,
.btn-youtube:disabled {
  background-color: red;
  border-color: red;
  color: #fff;
}

.btn-youtube:not(:disabled):not(.disabled).active,
.btn-youtube:not(:disabled):not(.disabled):active,
.show>.btn-youtube.dropdown-toggle {
  background-color: #c00;
  border-color: #bf0000;
  color: #fff;
}

.btn-youtube:not(:disabled):not(.disabled).active:focus,
.btn-youtube:not(:disabled):not(.disabled):active:focus,
.show>.btn-youtube.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}

.btn-rmi {
  background-color: #53b97e;
  border-color: #53b97e;
  color: #fff;
}

.btn-rmi.focus,
.btn-rmi:focus,
.btn-rmi:hover {
  background-color: #42a36b;
  border-color: #3f9a65;
  color: #fff;
}

.btn-rmi.focus,
.btn-rmi:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 196, 145, 0.5);
}

.btn-rmi.disabled,
.btn-rmi:disabled {
  background-color: #53b97e;
  border-color: #53b97e;
  color: #fff;
}

.btn-rmi:not(:disabled):not(.disabled).active,
.btn-rmi:not(:disabled):not(.disabled):active,
.show>.btn-rmi.dropdown-toggle {
  background-color: #3f9a65;
  border-color: #3b915f;
  color: #fff;
}

.btn-rmi:not(:disabled):not(.disabled).active:focus,
.btn-rmi:not(:disabled):not(.disabled):active:focus,
.show>.btn-rmi.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 196, 145, 0.5);
}

.btn-yellow {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-yellow.focus,
.btn-yellow:focus,
.btn-yellow:hover {
  background-color: #febb2b;
  border-color: #feb71e;
  color: #212529;
}

.btn-yellow.focus,
.btn-yellow:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 75, 0.5);
}

.btn-yellow.disabled,
.btn-yellow:disabled {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-yellow:not(:disabled):not(.disabled).active,
.btn-yellow:not(:disabled):not(.disabled):active,
.show>.btn-yellow.dropdown-toggle {
  background-color: #feb71e;
  border-color: #feb312;
  color: #212529;
}

.btn-yellow:not(:disabled):not(.disabled).active:focus,
.btn-yellow:not(:disabled):not(.disabled):active:focus,
.show>.btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 175, 75, 0.5);
}

.btn-outline-primary {
  border-color: #f85a3e;
  color: #f85a3e;
}

.btn-outline-primary:hover {
  background-color: #f85a3e;
  border-color: #f85a3e;
  color: #fff;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: transparent;
  color: #f85a3e;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: #f85a3e;
  border-color: #f85a3e;
  color: #fff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.5);
}

.btn-outline-secondary {
  border-color: #4587ad;
  color: #4587ad;
}

.btn-outline-secondary:hover {
  background-color: #4587ad;
  border-color: #4587ad;
  color: #fff;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 135, 173, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  background-color: transparent;
  color: #4587ad;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  background-color: #4587ad;
  border-color: #4587ad;
  color: #fff;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary .dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 135, 173, 0.5);
}

.btn-outline-success {
  border-color: #28a745;
  color: #28a745;
}

.btn-outline-success:hover {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  background-color: transparent;
  color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success .dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  border-color: #17a2b8;
  color: #17a2b8;
}

.btn-outline-info:hover {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  border-color: #fec751;
  color: #fec751;
}

.btn-outline-warning:hover {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 81, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #fec751;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-outline-warning:not(:disabled):not(.disabled) .active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 81, 0.5);
}

.btn-outline-danger {
  border-color: #dc3545;
  color: #dc3545;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  border-color: #f0f3f7;
  color: #f0f3f7;
}

.btn-outline-light:hover {
  background-color: #f0f3f7;
  border-color: #f0f3f7;
  color: #212529;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 243, 247, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  background-color: transparent;
  color: #f0f3f7;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  background-color: #f0f3f7;
  border-color: #f0f3f7;
  color: #212529;
}

.btn-outline-light:not(:disabled):not(.disabled) .active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 243, 247, 0.5);
}

.btn-outline-dark {
  border-color: #0f1319;
  color: #0f1319;
}

.btn-outline-dark:hover {
  background-color: #0f1319;
  border-color: #0f1319;
  color: #fff;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 19, 25, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  background-color: transparent;
  color: #0f1319;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  background-color: #0f1319;
  border-color: #0f1319;
  color: #fff;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 19, 25, 0.5);
}

.btn-outline-facebook {
  border-color: #3b5998;
  color: #3b5998;
}

.btn-outline-facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-outline-facebook.focus,
.btn-outline-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.btn-outline-facebook.disabled,
.btn-outline-facebook:disabled {
  background-color: transparent;
  color: #3b5998;
}

.btn-outline-facebook:not(:disabled):not(.disabled) .active,
.btn-outline-facebook:not(:disabled):not(.disabled):active,
.show>.btn-outline-facebook.dropdown-toggle {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
.btn-outline-facebook:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-facebook .dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.btn-outline-google {
  border-color: #f2f2f2;
  color: #f2f2f2;
}

.btn-outline-google:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #212529;
}

.btn-outline-google.focus,
.btn-outline-google:focus {
  box-shadow: 0 0 0 0.2rem hsla(0, 0%, 95%, 0.5);
}

.btn-outline-google.disabled,
.btn-outline-google:disabled {
  background-color: transparent;
  color: #f2f2f2;
}

.btn-outline-google:not(:disabled):not(.disabled).active,
.btn-outline-google:not(:disabled):not(.disabled):active,
.show>.btn-outline-google.dropdown-toggle {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #212529;
}

.btn-outline-google:not(:disabled):not(.disabled).active:focus,
.btn-outline-google:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(0, 0%, 95%, 0.5);
}

.btn-outline-twitter {
  border-color: #1da1f2;
  color: #1da1f2;
}

.btn-outline-twitter:hover {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-outline-twitter.focus,
.btn-outline-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}

.btn-outline-twitter.disabled,
.btn-outline-twitter:disabled {
  background-color: transparent;
  color: #1da1f2;
}

.btn-outline-twitter:not(:disabled):not(.disabled).active,
.btn-outline-twitter:not(:disabled):not(.disabled):active,
.show>.btn-outline-twitter.dropdown-toggle {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-outline-twitter:not(:disabled):not(.disabled) .active:focus,
.btn-outline-twitter:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}

.btn-outline-youtube {
  border-color: red;
  color: red;
}

.btn-outline-youtube:hover {
  background-color: red;
  border-color: red;
  color: #fff;
}

.btn-outline-youtube.focus,
.btn-outline-youtube:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}

.btn-outline-youtube.disabled,
.btn-outline-youtube:disabled {
  background-color: transparent;
  color: red;
}

.btn-outline-youtube:not(:disabled):not(.disabled).active,
.btn-outline-youtube:not(:disabled):not(.disabled):active,
.show>.btn-outline-youtube.dropdown-toggle {
  background-color: red;
  border-color: red;
  color: #fff;
}

.btn-outline-youtube:not(:disabled):not(.disabled).active:focus,
.btn-outline-youtube:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-youtube.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}

.btn-outline-rmi {
  border-color: #53b97e;
  color: #53b97e;
}

.btn-outline-rmi:hover {
  background-color: #53b97e;
  border-color: #53b97e;
  color: #fff;
}

.btn-outline-rmi.focus,
.btn-outline-rmi:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 185, 126, 0.5);
}

.btn-outline-rmi.disabled,
.btn-outline-rmi:disabled {
  background-color: transparent;
  color: #53b97e;
}

.btn-outline-rmi:not(:disabled):not(.disabled).active,
.btn-outline-rmi:not(:disabled):not(.disabled):active,
.show>.btn-outline-rmi.dropdown-toggle {
  background-color: #53b97e;
  border-color: #53b97e;
  color: #fff;
}

.btn-outline-rmi:not(:disabled):not(.disabled).active:focus,
.btn-outline-rmi:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-rmi.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 185, 126, 0.5);
}

.btn-outline-yellow {
  border-color: #fec751;
  color: #fec751;
}

.btn-outline-yellow:hover {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-outline-yellow.focus,
.btn-outline-yellow:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 81, 0.5);
}

.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
  background-color: transparent;
  color: #fec751;
}

.btn-outline-yellow:not(:disabled):not(.disabled).active,
.btn-outline-yellow:not(:disabled):not(.disabled):active,
.show>.btn-outline-yellow.dropdown-toggle {
  background-color: #fec751;
  border-color: #fec751;
  color: #212529;
}

.btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
.btn-outline-yellow:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 81, 0.5);
}

.btn-link {
  color: #f85a3e;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #f74525;
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  text-decoration: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg>.btn,
.btn-lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.75rem 3rem;
}

.btn-group-sm>.btn,
.btn-sm {
  border-radius: 0.2rem;
  line-height: 1.5;
  padding: 0.3125rem 1 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #212529;
  display: none;
  float: left;
  font-size: 1rem;
  left: 0;
  list-style: none;
  margin: 0.125rem 0 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  bottom: 100%;
  margin-bottom: 0.125rem;
  margin-top: 0;
  top: auto;
}

.dropup .dropdown-toggle:after {
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto;
  top: 0;
}

.dropright .dropdown-toggle:after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-right: 0;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  left: auto;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100%;
  top: 0;
}

.dropleft .dropdown-toggle:after {
  content: "";
  display: inline-block;
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropleft .dropdown-toggle:before {
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-top: 0.3em solid transparent;
  content: "";
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  border-top: 1px solid #e9ecef;
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
}

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #212529;
  display: block;
  font-weight: 400;
  padding: 0.25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #e9ecef;
  color: #16181b;
  text-decoration: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f85a3e;
  color: #fff;
  text-decoration: none;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: #adb5bd;
  pointer-events: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
}

.dropdown-item-text {
  color: #212529;
  display: block;
  padding: 0.25rem 1.5rem;
}

.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  flex: 1 1 auto;
  position: relative;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: 0.984375rem;
  padding-right: 0.984375rem;
}

.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn-group>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
  flex: 1 1 auto;
  margin-bottom: 0;
  min-width: 0;
  position: relative;
  width: 1%;
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
  margin-left: -1px;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group>.custom-file {
  align-items: center;
  display: flex;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label:after {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group.has-validation>.custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation>.custom-file:nth-last-child(n + 3) .custom-file-label:after,
.input-group.has-validation>.custom-select:nth-last-child(n + 3),
.input-group.has-validation>.form-control:nth-last-child(n + 3),
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label:after,
.input-group:not(.has-validation)>.custom-select:not(:last-child),
.input-group:not(.has-validation)>.form-control:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  align-items: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
}

.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.75rem;
}

.input-group.has-validation>.input-group-append:nth-last-child(n + 3)>.btn,
.input-group.has-validation>.input-group-append:nth-last-child(n + 3)>.input-group-text,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.custom-control {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
  z-index: 1;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  width: 1rem;
  z-index: -1;
}

.custom-control-input:checked~.custom-control-label:before {
  background-color: #f85a3e;
  border-color: #f85a3e;
  color: #fff;
}

.custom-control-input:focus~.custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
  border-color: #fcc3b9;
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
  background-color: #feedea;
  border-color: #feedea;
  color: #fff;
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label:before,
.custom-control-input[disabled]~.custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
  position: relative;
  vertical-align: top;
}

.custom-control-label:before {
  background-color: #fff;
  border: 1px solid #adb5bd;
  pointer-events: none;
}

.custom-control-label:after,
.custom-control-label:before {
  content: "";
  display: block;
  height: 1rem;
  left: -1.5rem;
  position: absolute;
  top: 0.25rem;
  width: 1rem;
}

.custom-control-label:after {
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='m6.564.75-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
  background-color: #f85a3e;
  border-color: #f85a3e;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
  background-color: rgba(248, 90, 62, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
  background-color: rgba(248, 90, 62, 0.5);
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
  background-color: rgba(248, 90, 62, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  border-radius: 0.5rem;
  left: -2.25rem;
  pointer-events: all;
  width: 1.75rem;
}

.custom-switch .custom-control-label:after {
  background-color: #adb5bd;
  border-radius: 0.5rem;
  height: calc(1rem - 4px);
  left: calc(-2.25rem + 2px);
  top: calc(0.25rem + 2px);
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: calc(1rem - 4px);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
  background-color: rgba(248, 90, 62, 0.5);
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  vertical-align: middle;
  width: 100%;
}

.custom-select:focus {
  border-color: #fcc3b9;
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
  outline: 0;
}

.custom-select:focus::-ms-value {
  background-color: #fff;
  color: #495057;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  background-image: none;
  height: auto;
  padding-right: 0.75rem;
}

.custom-select:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px);
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
}

.custom-select-lg {
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 2px);
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.custom-file {
  display: inline-block;
  margin-bottom: 0;
}

.custom-file,
.custom-file-input {
  height: calc(1.5em + 0.75rem + 2px);
  position: relative;
  width: 100%;
}

.custom-file-input {
  margin: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 2;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #fcc3b9;
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
}

.custom-file-input:disabled~.custom-file-label,
.custom-file-input[disabled]~.custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label:after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.custom-file-label,
.custom-file-label:after {
  color: #495057;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
}

.custom-file-label:after {
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  bottom: 0;
  content: "Browse";
  display: block;
  height: calc(1.5em + 0.75rem);
  z-index: 3;
}

.custom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.4rem;
  padding: 0;
  width: 100%;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #f85a3e;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #feedea;
}

.custom-range::-webkit-slider-runnable-track {
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}

.custom-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #f85a3e;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #feedea;
}

.custom-range::-moz-range-track {
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}

.custom-range::-ms-thumb {
  appearance: none;
  background-color: #f85a3e;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #feedea;
}

.custom-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}

.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .custom-control-label:before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  cursor: default;
  pointer-events: none;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6c757d;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  color: #495057;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #f85a3e;
  color: #fff;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  padding: 0.9375rem 3.125rem;
  position: relative;
}

.navbar,
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  font-size: 1.5rem;
  line-height: inherit;
  margin-right: 3.125rem;
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  display: inline-block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

.navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  background: 50%/100% 100% no-repeat;
  content: "";
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #0f1319;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(15, 19, 25, 0.75);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #0f1319;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #0f1319;
}

.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(15, 19, 25, 0.75);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(15, 19, 25, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(15, 19, 25, 0.75);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: #0f1319;
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  border-color: hsla(0, 0%, 100%, 0.1);
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0.4375rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

.card>hr {
  margin-left: 0;
  margin-right: 0;
}

.card>.list-group {
  border-bottom: inherit;
  border-top: inherit;
}

.card>.list-group:first-child {
  border-top-left-radius: calc(0.4375rem - 1px);
  border-top-right-radius: calc(0.4375rem - 1px);
  border-top-width: 0;
}

.card>.list-group:last-child {
  border-bottom-left-radius: calc(0.4375rem - 1px);
  border-bottom-right-radius: calc(0.4375rem - 1px);
  border-bottom-width: 0;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid transparent;
  margin-bottom: 0;
  padding: 0.75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(0.4375rem - 1px) calc(0.4375rem - 1px) 0 0;
}

.card-footer {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid transparent;
  padding: 0.75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.4375rem - 1px) calc(0.4375rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -0.75rem;
}

.card-header-pills,
.card-header-tabs {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.card-img-overlay {
  border-radius: calc(0.4375rem - 1px);
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.card-img,
.card-img-bottom,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.4375rem - 1px);
  border-top-right-radius: calc(0.4375rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-left-radius: calc(0.4375rem - 1px);
  border-bottom-right-radius: calc(0.4375rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-header,
  .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-footer,
  .card-group>.card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-header,
  .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-footer,
  .card-group>.card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion>.card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  float: left;
  padding-right: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item:hover:before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: 0.25rem;
  display: flex;
  list-style: none;
  padding-left: 0;
}

.page-link {
  background-color: #fff;
  border: 1px solid #dee2e6;
  color: #f85a3e;
  display: block;
  line-height: 1.25;
  margin-left: -1px;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.page-link:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #f74525;
  text-decoration: none;
  z-index: 2;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.25);
  outline: 0;
  z-index: 3;
}

.page-item:first-child .page-link {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.page-item.active .page-link {
  background-color: #f85a3e;
  border-color: #f85a3e;
  color: #fff;
  z-index: 3;
}

.page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6;
  color: #6c757d;
  cursor: auto;
  pointer-events: none;
}

.pagination-lg .page-link {
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.badge {
  border-radius: 0.25rem;
  display: inline-block;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  padding: 0.25em 0.4em;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: baseline;
  white-space: nowrap;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: 0.6em;
  padding-right: 0.6em;
}

.badge-primary {
  background-color: #f85a3e;
  color: #fff;
}

a.badge-primary:focus,
a.badge-primary:hover {
  background-color: #f6300d;
  color: #fff;
}

a.badge-primary.focus,
a.badge-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 90, 62, 0.5);
  outline: 0;
}

.badge-secondary {
  background-color: #4587ad;
  color: #fff;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  background-color: #366b89;
  color: #fff;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 135, 173, 0.5);
  outline: 0;
}

.badge-success {
  background-color: #28a745;
  color: #fff;
}

a.badge-success:focus,
a.badge-success:hover {
  background-color: #1e7e34;
  color: #fff;
}

a.badge-success.focus,
a.badge-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  outline: 0;
}

.badge-info {
  background-color: #17a2b8;
  color: #fff;
}

a.badge-info:focus,
a.badge-info:hover {
  background-color: #117a8b;
  color: #fff;
}

a.badge-info.focus,
a.badge-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  outline: 0;
}

.badge-warning {
  background-color: #fec751;
  color: #212529;
}

a.badge-warning:focus,
a.badge-warning:hover {
  background-color: #feb71e;
  color: #212529;
}

a.badge-warning.focus,
a.badge-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 81, 0.5);
  outline: 0;
}

.badge-danger {
  background-color: #dc3545;
  color: #fff;
}

a.badge-danger:focus,
a.badge-danger:hover {
  background-color: #bd2130;
  color: #fff;
}

a.badge-danger.focus,
a.badge-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  outline: 0;
}

.badge-light {
  background-color: #f0f3f7;
  color: #212529;
}

a.badge-light:focus,
a.badge-light:hover {
  background-color: #cfd8e5;
  color: #212529;
}

a.badge-light.focus,
a.badge-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 243, 247, 0.5);
  outline: 0;
}

.badge-dark {
  background-color: #0f1319;
  color: #fff;
}

a.badge-dark:focus,
a.badge-dark:hover {
  background-color: #000;
  color: #fff;
}

a.badge-dark.focus,
a.badge-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 19, 25, 0.5);
  outline: 0;
}

.badge-facebook {
  background-color: #3b5998;
  color: #fff;
}

a.badge-facebook:focus,
a.badge-facebook:hover {
  background-color: #2d4373;
  color: #fff;
}

a.badge-facebook.focus,
a.badge-facebook:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
  outline: 0;
}

.badge-google {
  background-color: #f2f2f2;
  color: #212529;
}

a.badge-google:focus,
a.badge-google:hover {
  background-color: #d9d9d9;
  color: #212529;
}

a.badge-google.focus,
a.badge-google:focus {
  box-shadow: 0 0 0 0.2rem hsla(0, 0%, 95%, 0.5);
  outline: 0;
}

.badge-twitter {
  background-color: #1da1f2;
  color: #fff;
}

a.badge-twitter:focus,
a.badge-twitter:hover {
  background-color: #0c85d0;
  color: #fff;
}

a.badge-twitter.focus,
a.badge-twitter:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
  outline: 0;
}

.badge-youtube {
  background-color: red;
  color: #fff;
}

a.badge-youtube:focus,
a.badge-youtube:hover {
  background-color: #c00;
  color: #fff;
}

a.badge-youtube.focus,
a.badge-youtube:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
  outline: 0;
}

.badge-rmi {
  background-color: #53b97e;
  color: #fff;
}

a.badge-rmi:focus,
a.badge-rmi:hover {
  background-color: #3f9a65;
  color: #fff;
}

a.badge-rmi.focus,
a.badge-rmi:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 185, 126, 0.5);
  outline: 0;
}

.badge-yellow {
  background-color: #fec751;
  color: #212529;
}

a.badge-yellow:focus,
a.badge-yellow:hover {
  background-color: #feb71e;
  color: #212529;
}

a.badge-yellow.focus,
a.badge-yellow:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 199, 81, 0.5);
  outline: 0;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: 0.3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: 0.75rem 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.alert-primary {
  background-color: #feded8;
  border-color: #fdd1c9;
  color: #812f20;
}

.alert-primary hr {
  border-top-color: #fcbcb0;
}

.alert-primary .alert-link {
  color: #582016;
}

.alert-secondary {
  background-color: #dae7ef;
  border-color: #cbdde8;
  color: #24465a;
}

.alert-secondary hr {
  border-top-color: #b9d1e0;
}

.alert-secondary .alert-link {
  color: #152a36;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  background-color: #fff4dc;
  border-color: #ffefce;
  color: #84672a;
}

.alert-warning hr {
  border-top-color: #ffe7b5;
}

.alert-warning .alert-link {
  color: #5d491e;
}

.alert-danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  background-color: #fcfdfd;
  border-color: #fbfcfd;
  color: #7d7e80;
}

.alert-light hr {
  border-top-color: #eaeff5;
}

.alert-light .alert-link {
  color: #646566;
}

.alert-dark {
  background-color: #cfd0d1;
  border-color: #bcbdbf;
  color: #080a0d;
}

.alert-dark hr {
  border-top-color: #afb0b3;
}

.alert-dark .alert-link {
  color: #000;
}

.alert-facebook {
  background-color: #d8deea;
  border-color: #c8d1e2;
  color: #1f2e4f;
}

.alert-facebook hr {
  border-top-color: #b7c3d9;
}

.alert-facebook .alert-link {
  color: #11192a;
}

.alert-google {
  background-color: #fcfcfc;
  border-color: #fbfbfb;
  color: #7e7e7e;
}

.alert-google hr {
  border-top-color: #eee;
}

.alert-google .alert-link {
  color: #656565;
}

.alert-twitter {
  background-color: #d2ecfc;
  border-color: #c0e5fb;
  color: #0f547e;
}

.alert-twitter hr {
  border-top-color: #a8dbf9;
}

.alert-twitter .alert-link {
  color: #0a3650;
}

.alert-youtube {
  background-color: #fcc;
  border-color: #ffb8b8;
  color: #850000;
}

.alert-youtube hr {
  border-top-color: #ff9f9f;
}

.alert-youtube .alert-link {
  color: #520000;
}

.alert-rmi {
  background-color: #ddf1e5;
  border-color: #cfebdb;
  color: #2b6042;
}

.alert-rmi hr {
  border-top-color: #bde4ce;
}

.alert-rmi .alert-link {
  color: #1b3d2a;
}

.alert-yellow {
  background-color: #fff4dc;
  border-color: #ffefce;
  color: #84672a;
}

.alert-yellow hr {
  border-top-color: #ffe7b5;
}

.alert-yellow .alert-link {
  color: #5d491e;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position: 0.625rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 0.625rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  background-color: #e9ecef;
  border-radius: 0.625rem;
  font-size: 0.75rem;
  height: 0.625rem;
  line-height: 0;
}

.progress,
.progress-bar {
  display: flex;
  overflow: hidden;
}

.progress-bar {
  background-color: #f85a3e;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 0.6s ease;
  white-space: nowrap;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      hsla(0, 0%, 100%, 0.15) 25%,
      transparent 0,
      transparent 50%,
      hsla(0, 0%, 100%, 0.15) 0,
      hsla(0, 0%, 100%, 0.15) 75%,
      transparent 0,
      transparent);
  background-size: 0.625rem 0.625rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}

.list-group-item-action {
  color: #495057;
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: #f8f9fa;
  color: #495057;
  text-decoration: none;
  z-index: 1;
}

.list-group-item-action:active {
  background-color: #e9ecef;
  color: #212529;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: block;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  background-color: #fff;
  color: #6c757d;
  pointer-events: none;
}

.list-group-item.active {
  background-color: #f85a3e;
  border-color: #f85a3e;
  color: #fff;
  z-index: 2;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-left-width: 0;
  border-top-width: 1px;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  background-color: #fdd1c9;
  color: #812f20;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  background-color: #fcbcb0;
  color: #812f20;
}

.list-group-item-primary.list-group-item-action.active {
  background-color: #812f20;
  border-color: #812f20;
  color: #fff;
}

.list-group-item-secondary {
  background-color: #cbdde8;
  color: #24465a;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  background-color: #b9d1e0;
  color: #24465a;
}

.list-group-item-secondary.list-group-item-action.active {
  background-color: #24465a;
  border-color: #24465a;
  color: #fff;
}

.list-group-item-success {
  background-color: #c3e6cb;
  color: #155724;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  background-color: #b1dfbb;
  color: #155724;
}

.list-group-item-success.list-group-item-action.active {
  background-color: #155724;
  border-color: #155724;
  color: #fff;
}

.list-group-item-info {
  background-color: #bee5eb;
  color: #0c5460;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: #abdde5;
  color: #0c5460;
}

.list-group-item-info.list-group-item-action.active {
  background-color: #0c5460;
  border-color: #0c5460;
  color: #fff;
}

.list-group-item-warning {
  background-color: #ffefce;
  color: #84672a;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  background-color: #ffe7b5;
  color: #84672a;
}

.list-group-item-warning.list-group-item-action.active {
  background-color: #84672a;
  border-color: #84672a;
  color: #fff;
}

.list-group-item-danger {
  background-color: #f5c6cb;
  color: #721c24;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  background-color: #f1b0b7;
  color: #721c24;
}

.list-group-item-danger.list-group-item-action.active {
  background-color: #721c24;
  border-color: #721c24;
  color: #fff;
}

.list-group-item-light {
  background-color: #fbfcfd;
  color: #7d7e80;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  background-color: #eaeff5;
  color: #7d7e80;
}

.list-group-item-light.list-group-item-action.active {
  background-color: #7d7e80;
  border-color: #7d7e80;
  color: #fff;
}

.list-group-item-dark {
  background-color: #bcbdbf;
  color: #080a0d;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  background-color: #afb0b3;
  color: #080a0d;
}

.list-group-item-dark.list-group-item-action.active {
  background-color: #080a0d;
  border-color: #080a0d;
  color: #fff;
}

.list-group-item-facebook {
  background-color: #c8d1e2;
  color: #1f2e4f;
}

.list-group-item-facebook.list-group-item-action:focus,
.list-group-item-facebook.list-group-item-action:hover {
  background-color: #b7c3d9;
  color: #1f2e4f;
}

.list-group-item-facebook.list-group-item-action.active {
  background-color: #1f2e4f;
  border-color: #1f2e4f;
  color: #fff;
}

.list-group-item-google {
  background-color: #fbfbfb;
  color: #7e7e7e;
}

.list-group-item-google.list-group-item-action:focus,
.list-group-item-google.list-group-item-action:hover {
  background-color: #eee;
  color: #7e7e7e;
}

.list-group-item-google.list-group-item-action.active {
  background-color: #7e7e7e;
  border-color: #7e7e7e;
  color: #fff;
}

.list-group-item-twitter {
  background-color: #c0e5fb;
  color: #0f547e;
}

.list-group-item-twitter.list-group-item-action:focus,
.list-group-item-twitter.list-group-item-action:hover {
  background-color: #a8dbf9;
  color: #0f547e;
}

.list-group-item-twitter.list-group-item-action.active {
  background-color: #0f547e;
  border-color: #0f547e;
  color: #fff;
}

.list-group-item-youtube {
  background-color: #ffb8b8;
  color: #850000;
}

.list-group-item-youtube.list-group-item-action:focus,
.list-group-item-youtube.list-group-item-action:hover {
  background-color: #ff9f9f;
  color: #850000;
}

.list-group-item-youtube.list-group-item-action.active {
  background-color: #850000;
  border-color: #850000;
  color: #fff;
}

.list-group-item-rmi {
  background-color: #cfebdb;
  color: #2b6042;
}

.list-group-item-rmi.list-group-item-action:focus,
.list-group-item-rmi.list-group-item-action:hover {
  background-color: #bde4ce;
  color: #2b6042;
}

.list-group-item-rmi.list-group-item-action.active {
  background-color: #2b6042;
  border-color: #2b6042;
  color: #fff;
}

.list-group-item-yellow {
  background-color: #ffefce;
  color: #84672a;
}

.list-group-item-yellow.list-group-item-action:focus,
.list-group-item-yellow.list-group-item-action:hover {
  background-color: #ffe7b5;
  color: #84672a;
}

.list-group-item-yellow.list-group-item-action.active {
  background-color: #84672a;
  border-color: #84672a;
  color: #fff;
}

.close {
  color: #000;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  text-shadow: 0 1px 0 #fff;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  background-color: transparent;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  background-clip: padding-box;
  background-color: hsla(0, 0%, 100%, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  flex-basis: 350px;
  font-size: 0.875rem;
  max-width: 350px;
  opacity: 0;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: hsla(0, 0%, 100%, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  color: #6c757d;
  display: flex;
  padding: 0.25rem 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}

.modal-dialog {
  margin: 0.5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}

.modal.fade .modal-dialog {
  transform: translateY(-50px);
  transition: transform 0.3s ease-out;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered:before {
  content: "";
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  align-items: flex-start;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  line-height: 1.5;
  margin-bottom: 0;
}

.modal-body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  align-items: center;
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-top: 1px solid #dee2e6;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.75rem;
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 500px;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  word-wrap: break-word;
  display: block;
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  margin: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1070;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  display: block;
  height: 0.4rem;
  position: absolute;
  width: 0.8rem;
}

.tooltip .arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before,
.bs-tooltip-top .arrow:before {
  border-top-color: #3f5069;
  border-width: 0.4rem 0.4rem 0;
  top: 0;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  height: 0.8rem;
  left: 0;
  width: 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-right .arrow:before {
  border-right-color: #3f5069;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: 0;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #3f5069;
  border-width: 0 0.4rem 0.4rem;
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  height: 0.8rem;
  right: 0;
  width: 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before,
.bs-tooltip-left .arrow:before {
  border-left-color: #3f5069;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  left: 0;
}

.tooltip-inner {
  background-color: #3f5069;
  border-radius: 0.25rem;
  color: #fff;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

.popover {
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  left: 0;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1060;
}

.popover,
.popover .arrow {
  display: block;
  position: absolute;
}

.popover .arrow {
  height: 0.5rem;
  margin: 0 0.3rem;
  width: 1rem;
}

.popover .arrow:after,
.popover .arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^="top"]>.arrow,
.bs-popover-top>.arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"]>.arrow:before,
.bs-popover-top>.arrow:before {
  border-top-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0;
  bottom: 0;
}

.bs-popover-auto[x-placement^="top"]>.arrow:after,
.bs-popover-top>.arrow:after {
  border-top-color: #fff;
  border-width: 0.5rem 0.5rem 0;
  bottom: 1px;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^="right"]>.arrow,
.bs-popover-right>.arrow {
  height: 1rem;
  left: calc(-0.5rem - 1px);
  margin: 0.3rem 0;
  width: 0.5rem;
}

.bs-popover-auto[x-placement^="right"]>.arrow:before,
.bs-popover-right>.arrow:before {
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0;
}

.bs-popover-auto[x-placement^="right"]>.arrow:after,
.bs-popover-right>.arrow:after {
  border-right-color: #fff;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 1px;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"]>.arrow,
.bs-popover-bottom>.arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"]>.arrow:before,
.bs-popover-bottom>.arrow:before {
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0 0.5rem 0.5rem;
  top: 0;
}

.bs-popover-auto[x-placement^="bottom"]>.arrow:after,
.bs-popover-bottom>.arrow:after {
  border-bottom-color: #fff;
  border-width: 0 0.5rem 0.5rem;
  top: 1px;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  border-bottom: 1px solid #f7f7f7;
  content: "";
  display: block;
  left: 50%;
  margin-left: -0.5rem;
  position: absolute;
  top: 0;
  width: 1rem;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^="left"]>.arrow,
.bs-popover-left>.arrow {
  height: 1rem;
  margin: 0.3rem 0;
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
}

.bs-popover-auto[x-placement^="left"]>.arrow:before,
.bs-popover-left>.arrow:before {
  border-left-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0;
}

.bs-popover-auto[x-placement^="left"]>.arrow:after,
.bs-popover-left>.arrow:after {
  border-left-color: #fff;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 1px;
}

.popover-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: 0.5rem 0.75rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  opacity: 1;
  z-index: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
  transition: opacity 0s 0.6s;
  z-index: 0;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background: none;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.15s ease;
  width: 15%;
  z-index: 1;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background: 50%/100% 100% no-repeat;
  display: inline-block;
  height: 20px;
  width: 20px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='m5.25 0-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='m2.75 0-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  position: absolute;
  right: 0;
  z-index: 15;
}

.carousel-indicators li {
  background-clip: padding-box;
  background-color: #fff;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 1 auto;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.5;
  text-indent: -999px;
  transition: opacity 0.6s ease;
  width: 30px;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  bottom: 20px;
  color: #fff;
  left: 15%;
  padding-bottom: 20px;
  padding-top: 20px;
  position: absolute;
  right: 15%;
  text-align: center;
  z-index: 10;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.spinner-border {
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  border: 0.25em solid;
  border-radius: 50%;
  border-right: 0.25em solid transparent;
  display: inline-block;
  height: 2rem;
  vertical-align: -0.125em;
  width: 2rem;
}

.spinner-border-sm {
  border-width: 0.2em;
  height: 1rem;
  width: 1rem;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  opacity: 0;
  vertical-align: -0.125em;
  width: 2rem;
}

.spinner-grow-sm {
  height: 1rem;
  width: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary,
.btn-primary.loading:active,
.btn-primary.loading:focus {
  background-color: #f85a3e !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
a.btn-primary.loading:focus,
a.btn-primary.loading:hover:active,
button.bg-primary:focus,
button.bg-primary:hover,
button.btn-primary.loading:focus,
button.btn-primary.loading:hover:active {
  background-color: #f6300d !important;
}

.bg-secondary {
  background-color: #4587ad !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #366b89 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #fec751 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #feb71e !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f0f3f7 !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #cfd8e5 !important;
}

.bg-dark {
  background-color: #0f1319 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #000 !important;
}

.bg-facebook {
  background-color: #3b5998 !important;
}

a.bg-facebook:focus,
a.bg-facebook:hover,
button.bg-facebook:focus,
button.bg-facebook:hover {
  background-color: #2d4373 !important;
}

.bg-google {
  background-color: #f2f2f2 !important;
}

a.bg-google:focus,
a.bg-google:hover,
button.bg-google:focus,
button.bg-google:hover {
  background-color: #d9d9d9 !important;
}

.bg-twitter {
  background-color: #1da1f2 !important;
}

a.bg-twitter:focus,
a.bg-twitter:hover,
button.bg-twitter:focus,
button.bg-twitter:hover {
  background-color: #0c85d0 !important;
}

.bg-youtube {
  background-color: red !important;
}

a.bg-youtube:focus,
a.bg-youtube:hover,
button.bg-youtube:focus,
button.bg-youtube:hover {
  background-color: #c00 !important;
}

.bg-rmi {
  background-color: #53b97e !important;
}

a.bg-rmi:focus,
a.bg-rmi:hover,
button.bg-rmi:focus,
button.bg-rmi:hover {
  background-color: #3f9a65 !important;
}

.bg-yellow {
  background-color: #fec751 !important;
}

a.bg-yellow:focus,
a.bg-yellow:hover,
button.bg-yellow:focus,
button.bg-yellow:hover {
  background-color: #feb71e !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0,
.dashboard .profile--main #schools>div .row:last-of-type,
.featured .school:last-of-type,
.popover.more-info,
.popover.more-info .popover-header,
.profile--main canvas {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #f85a3e !important;
}

.border-secondary {
  border-color: #4587ad !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #fec751 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f0f3f7 !important;
}

.border-dark {
  border-color: #0f1319 !important;
}

.border-facebook {
  border-color: #3b5998 !important;
}

.border-google {
  border-color: #f2f2f2 !important;
}

.border-twitter {
  border-color: #1da1f2 !important;
}

.border-youtube {
  border-color: red !important;
}

.border-rmi {
  border-color: #53b97e !important;
}

.border-yellow {
  border-color: #fec751 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.dashboard .profile--main .dashboard-nav-top:before,
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.profile .key>div>span,
.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block,
.footnote,
.profile .header-facts--label,
.site-footer .share {
  display: inline-block !important;
}

.d-block,
.profile--main--fact-label,
.user-dropdown-menu a {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex,
.info-box-wrapper .info-box,
.pagination .page-item .page-link,
.profile--main ul li,
.profile .header-facts .calendar--day,
.profile .header-facts .calendar--label,
.profile .key>div,
.profile .related-test-prep .card .key>div>span,
.profile.scholarship .header-facts--more-info ul li,
.profile header .profile-title--logo,
.related-test-prep .card .profile .key>div>span,
.related-test-prep .card .rounded-circle {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  border: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.embed-responsive-21by9:before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center,
.pagination .page-item .page-link,
.profile .header-facts .calendar--day,
.profile .header-facts .calendar--label,
.profile .related-test-prep .card .key>div>span,
.profile header .profile-title--logo,
.related-test-prep .card .profile .key>div>span,
.related-test-prep .card .rounded-circle {
  justify-content: center !important;
}

.info-box-wrapper .info-box,
.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center,
.info-box-wrapper .info-box,
.pagination .page-item .page-link,
.profile .header-facts .calendar--day,
.profile .header-facts .calendar--label,
.profile .key>div,
.profile .related-test-prep .card .key>div>span,
.profile.scholarship .header-facts--more-info ul li,
.profile header .profile-title--logo,
.related-test-prep .card .profile .key>div>span,
.related-test-prep .card .rounded-circle {
  align-items: center !important;
}

.align-items-baseline,
.profile--main ul li {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.hero-bg,
.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky !important;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1020;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1,
.profile .header-facts--label {
  margin-top: 0.25rem !important;
}

.badge.grad-school,
.badge.online-school,
.badge.scholarship,
.badge.ug-school,
.mr-1,
.mx-1,
.pagination .page-item {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1,
.pagination .page-item {
  margin-left: 0.25rem !important;
}

.m-2,
.profile .key>div {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2,
.profile .key>div>span,
.site-footer .share {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3,
.site-footer .share {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4,
.profile--main #overview p {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.footnote,
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 5rem !important;
}

.m-7 {
  margin: 7rem !important;
}

.mt-7,
.my-7 {
  margin-top: 7rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 7rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 7rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 7rem !important;
}

.p-0,
.pagination .page-item .page-link {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.dashboard .profile--main .form-control:disabled,
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.profile--main ul li,
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.profile--main ul li,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2,
.user-dropdown-menu a {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2,
.user-dropdown-menu a {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.checkbox-dropdown .dropdown-menu,
.p-3,
.popover.more-info {
  padding: 1rem !important;
}

.info-box-wrapper .info-box,
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.info-box-wrapper .info-box,
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

.p-7 {
  padding: 7rem !important;
}

.pt-7,
.py-7 {
  padding-top: 7rem !important;
}

.pr-7,
.px-7 {
  padding-right: 7rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 7rem !important;
}

.pl-7,
.px-7 {
  padding-left: 7rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -5rem !important;
}

.m-n7 {
  margin: -7rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -7rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-6 {
    margin: 5rem !important;
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 5rem !important;
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 5rem !important;
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 5rem !important;
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 5rem !important;
  }

  .m-sm-7 {
    margin: 7rem !important;
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important;
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important;
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important;
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 5rem !important;
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 5rem !important;
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 5rem !important;
  }

  .p-sm-7 {
    padding: 7rem !important;
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important;
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important;
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important;
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-n6 {
    margin: -5rem !important;
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -5rem !important;
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -5rem !important;
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -5rem !important;
  }

  .m-sm-n7 {
    margin: -7rem !important;
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important;
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important;
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important;
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-6 {
    margin: 5rem !important;
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 5rem !important;
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 5rem !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 5rem !important;
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 5rem !important;
  }

  .m-md-7 {
    margin: 7rem !important;
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important;
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important;
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important;
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }

  .p-md-7 {
    padding: 7rem !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-n6 {
    margin: -5rem !important;
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -5rem !important;
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -5rem !important;
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -5rem !important;
  }

  .m-md-n7 {
    margin: -7rem !important;
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important;
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important;
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important;
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-6 {
    margin: 5rem !important;
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 5rem !important;
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 5rem !important;
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 5rem !important;
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 5rem !important;
  }

  .m-lg-7 {
    margin: 7rem !important;
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important;
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important;
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important;
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 5rem !important;
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 5rem !important;
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 5rem !important;
  }

  .p-lg-7 {
    padding: 7rem !important;
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important;
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important;
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important;
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-n6 {
    margin: -5rem !important;
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -5rem !important;
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -5rem !important;
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -5rem !important;
  }

  .m-lg-n7 {
    margin: -7rem !important;
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important;
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important;
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important;
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-6 {
    margin: 5rem !important;
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 5rem !important;
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 5rem !important;
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 5rem !important;
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 5rem !important;
  }

  .m-xl-7 {
    margin: 7rem !important;
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important;
  }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important;
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important;
  }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 5rem !important;
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 5rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 5rem !important;
  }

  .p-xl-7 {
    padding: 7rem !important;
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important;
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important;
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important;
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-n6 {
    margin: -5rem !important;
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -5rem !important;
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -5rem !important;
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -5rem !important;
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -5rem !important;
  }

  .m-xl-n7 {
    margin: -7rem !important;
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important;
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important;
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important;
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  background-color: transparent;
  bottom: 0;
  content: "";
  left: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal,
.profile table.has-active-highlighting tbody tr td {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

p.sectionTitle__text.text-white {
  font-size: 20px;
  font-family: monospace;
}

.container.animated {
  max-width: fit-content;
}

.badge.grad-school,
.badge.online-school,
.badge.scholarship,
.badge.ug-school,
.btn-primary,
.btn-rmi,
.btn-secondary,
.text-white {
  color: #fff !important;
  /* font-size: 20px; */
}

.text-primary {
  color: #f85a3e !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #e12908 !important;
}

.text-secondary {
  color: #4587ad !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #2f5c76 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #fec751 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #feaf05 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f0f3f7 !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #becbdc !important;
}

.btn-white,
.text-dark {
  color: #0f1319 !important;
}

a.btn-white:focus,
a.btn-white:hover,
a.text-dark:focus,
a.text-dark:hover {
  color: #000 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

a.text-facebook:focus,
a.text-facebook:hover {
  color: #263961 !important;
}

.text-google {
  color: #f2f2f2 !important;
}

a.text-google:focus,
a.text-google:hover {
  color: #ccc !important;
}

.text-twitter {
  color: #1da1f2 !important;
}

a.text-twitter:focus,
a.text-twitter:hover {
  color: #0b76b8 !important;
}

.text-youtube {
  color: red !important;
}

a.text-youtube:focus,
a.text-youtube:hover {
  color: #b30000 !important;
}

.text-rmi {
  color: #53b97e !important;
}

a.text-rmi:focus,
a.text-rmi:hover {
  color: #378859 !important;
}

.text-yellow {
  color: #fec751 !important;
}

a.text-yellow:focus,
a.text-yellow:hover {
  color: #feaf05 !important;
}

.text-body {
  color: #212529 !important;
}

.sib-input input::-moz-placeholder {
  color: #6c757d !important;
}

.sib-input input:-ms-input-placeholder {
  color: #6c757d !important;
}

.footnote,
.sib-input input::placeholder,
.site-footer .footer-links a,
.strikethrough,
.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {

  *,
  :after,
  :before {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
  }

  blockquote,
  img,
  pre,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  .container,
  body {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody+tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    border-color: #dee2e6;
    color: inherit;
  }
}

:root {
  --body-bg: #eaeef5;
  --h-color: #1e2024;
  --body-color: #787e86;
  --form-bg: #fff;
  --form-border: #d0d6de;
  --input-color: #1e2024;
  --placeholder-color: #c0c4cc;
  --footer-bg: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-bg: #1e2024;
    --h-color: #fff;
    --body-color: #acb2ba;
    --form-bg: #292a2e;
    --form-border: #3c424a;
    --input-color: #fff;
    --placeholder-color: #525860;
    --footer-bg: #1e2024;
  }
}

.dtcApp #footer,
.dtcApp #header,
.dtcApp .promo-container {
  display: none !important;
}

.body--auth {
  background-color: #f0f3f7;
}

.body--auth #content {
  margin-bottom: 3rem;
}

.body--auth .site-footer,
.body--auth header.navbar {
  display: none;
}

.body--auth .brand,
.body--auth .brand:active,
.body--auth .brand:focus,
.body--auth .brand:hover {
  color: #0f1319;
  text-decoration: none;
}

.body--auth .brand .logo {
  fill: #f85a3e;
  height: auto;
  width: 2.5rem;
}

@media (min-width: 768px) {
  .body--auth .brand .logo {
    width: 2.25rem;
  }
}

.body--auth .brand span {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .body--auth .app-footer {
    border-top: 1px solid #cfd8e5;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
  }

  .body--auth .app-footer a[aria-selected="true"] {
    display: none;
  }
}

.body--auth .tab-content {
  border-radius: 0.625rem;
}

@media (max-width: 767px) {
  .body--auth .tab-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.body--auth .nav-item:not(.active) {
  color: #6c757d;
}

@media (max-width: 767px) {
  .body--auth .nav-tabs .nav-item:not(.active) {
    display: flex;
    width: 100%;
  }

  .body--auth .nav-tabs .nav-item:not(.active) span:last-of-type {
    color: #0f1319;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.body--auth .form-control.is-invalid,
.body--auth .form-control.is-valid,
.body--auth .was-validated .form-control:invalid,
.body--auth .was-validated .form-control:valid {
  background-image: none;
}

.body--auth .form-control.is-valid,
.body--auth .was-validated .form-control:valid {
  border: none;
}

.body--auth #passwordHelp {
  line-height: 1.25;
}

.body--auth .agree,
.body--auth .footer {
  font-size: 0.875rem;
}

.body--auth.app {
  background-color: var(--body-bg);
}

.body--auth.app * {
  font-family: Inter, sans-serif;
}

.body--auth.app .h1 {
  color: var(--h-color);
}

.body--auth.app p {
  color: var(--body-color);
}

.body--auth.app hr {
  border-top: 1px solid var(--form-border);
}

.body--auth.app u {
  text-decoration: none;
}

.body--auth.app .login-box {
  margin-bottom: 56px;
}

.body--auth.app .app-form-wrapper {
  background-color: var(--form-bg);
}

.body--auth.app .app-form-wrapper .form-group:last-of-type .form-control {
  border-bottom: none;
}

.body--auth.app .app-form-wrapper label {
  color: var(--body-color);
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.body--auth.app .app-form-wrapper .form-control {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--form-border);
  border-radius: 0;
  color: var(--input-color);
  font-size: 18px;
  padding: 0 1rem;
}

.body--auth.app .app-form-wrapper .form-control::-moz-placeholder {
  color: var(--placeholder-color);
}

.body--auth.app .app-form-wrapper .form-control:-ms-input-placeholder {
  color: var(--placeholder-color);
}

.body--auth.app .app-form-wrapper .form-control::placeholder {
  color: var(--placeholder-color);
}

.body--auth.app .app-form-wrapper .form-control:active,
.body--auth.app .app-form-wrapper .form-control:focus,
.body--auth.app .app-form-wrapper .form-control:hover {
  box-shadow: none;
  outline: none;
}

.body--auth.app .app-form-wrapper #switchVisibility {
  color: var(--body-color);
  opacity: 1;
}

.body--auth.app .app-form-wrapper button[type="submit"] {
  font-size: 18px;
}

.body--auth.app .app-footer {
  border-top: 1px solid var(--form-border);
}

.body--auth.app .nav-tabs .nav-item:not(.active) span:last-of-type {
  color: var(--h-color);
}

.body--auth .btn,
.body--auth .btn:active,
.body--auth .btn:focus,
.body--auth .btn:hover {
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .body--auth .btn-social.btn-white {
    margin-top: 40px;
  }
}

.body--auth .btn-link,
.body--auth .btn-link:active,
.body--auth .btn-link:focus,
.body--auth .btn-link:hover {
  color: #0f1319 !important;
}

.body--auth .btn-facebook,
.body--auth .btn-facebook:active,
.body--auth .btn-facebook:focus,
.body--auth .btn-facebook:hover {
  background-color: #2c88ff !important;
  border-color: #2c88ff !important;
  color: #fff !important;
}

.body--auth .btn-apple,
.body--auth .btn-apple:active,
.body--auth .btn-apple:focus,
.body--auth .btn-apple:hover {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
}

.body--auth #apple,
.body--auth #facebook,
.body--auth #google {
  width: 1.25rem;
}

.body--auth #google .st0 {
  fill: #4285f4;
}

.body--auth #google .st1 {
  fill: #34a853;
}

.body--auth #google .st2 {
  fill: #fbbc04;
}

.body--auth #google .st3 {
  fill: #ea4335;
}

.body--auth #apple .st0,
.body--auth #facebook .st0 {
  fill: #fff;
}

@media (max-width: 991px) {
  #blog {
    overflow-x: scroll;
  }

  #blog .related-blog-posts .post img {
    width: 100%;
  }
}

.custom-select.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control {
  background-image: none !important;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-control,
.form-control-lg {
  height: auto;
}

.accordion>.card {
  overflow: visible;
}

.btn {
  cursor: pointer;
  white-space: nowrap;
}

body {
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body.rmi-processing,
body.rmi-processing section.list {
  position: relative;
}

body.rmi-processing:before,
body.rmi-processing section.list:before {
  background-color: #0f1319;
  content: "";
  height: 100%;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  z-index: 9;
}

body.rmi-processing .filter-wrapper,
body.rmi-processing section.list .filter-wrapper {
  z-index: 0;
}

body.rmi-processing .btn-rmi,
body.rmi-processing section.list .btn-rmi {
  opacity: 0.5;
  pointer-events: none;
}

body.rmi-processing .btn-rmi.loading,
body.rmi-processing section.list .btn-rmi.loading {
  opacity: 1;
  position: relative;
  z-index: 99999;
}

body.profile-search-wrapper.rmi-processingbefore {
  display: none;
}

body.notification-show .vue-back-to-top {
  display: none !important;
}

@media (max-width: 767px) {
  body.notification-show .promo-container {
    bottom: 132px;
  }
}

@media (min-width: 768px) {
  body.notification-show .promo-container {
    bottom: 90px;
  }
}

body.body-search .btn-rmi {
  height: 38px;
}

body.body-search .school .save {
  font-size: 12px;
}

@media (min-width: 768px) {
  body.body-search .school .save {
    font-size: 13px;
  }
}

body.body-search .featured-wrapper .btn-rmi~.success {
  left: 0;
  width: 100%;
}

body.body-search .featured-wrapper .btn-rmi~.success:before {
  left: 50%;
}

#content,
#main-content {
  margin-top: 90px;
}

@media (max-width: 1050px) {

  #content,
  #main-content {
    margin-top: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .col-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.text-12 {
  font-size: 12px;
}

.navbar {
  /* background-color: #fff; */
  border-bottom: none;
  /* box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1); */
  height: 50px;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999999;
}

.navbar .dropdown-menu {
  border: 0 !important;
  display: none;
}

.navbar .dropdown-menu.show {
  display: block;
}

.navbar .dropdown-menu.multi-column h5 {
  font-weight: 400;
  left: 1.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  white-space: nowrap;
}

.navbar .navbar-brand {
  color: #0f1319;
  font-size: 1.5rem;
  line-height: inherit;
  text-decoration: none;
  white-space: nowrap;
}

.navbar .navbar-brand .logo {
  fill: #f85a3e;
  height: auto;
  width: 1.875rem;
}

.navbar .navbar-brand span {
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.navbar-nav .nav-item .nav-link {
  color: rgba(15, 19, 25, 0.75);
  display: block;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

@media (max-width: 991px) {
  body.navbar-shown {
    overflow: hidden;
    position: fixed;
  }

  .navbar {
    padding: 0;
  }

  .navbar .navbar-brand {
    height: 50px;
  }

  .navbar .navbar-toggler {
    border: none;
    height: 50px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .navbar .navbar-toggler:active,
  .navbar .navbar-toggler:focus,
  .navbar .navbar-toggler:hover {
    box-shadow: none;
    outline: none;
  }

  .navbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 17.7 13.8' style='enable-background:new 0 0 17.7 13.8' xml:space='preserve'%3E%3Cstyle%3E.st0{fill:%230f1319}%3C/style%3E%3Cpath className='st0' d='M16.8 2H1c-.6 0-1-.5-1-1s.4-1 1-1h15.8c.5 0 1 .4 1 1-.1.5-.5 1-1 1M16.8 7.9H1c-.5 0-1-.4-1-1 0-.5.4-1 1-1h15.8c.5 0 1 .4 1 1-.1.5-.5 1-1 1M16.8 13.8H1c-.5 0-1-.4-1-1 0-.5.4-1 1-1h15.8c.5 0 1 .4 1 1-.1.5-.5 1-1 1'/%3E%3C/svg%3E");
    height: 22px;
    width: 22px;
  }

  .navbar .close-navbar-toggler {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 12px 12px;
    padding: 20px;
    right: -1px;
    top: 1px;
  }

  .navbar .nav-item {
    border-bottom: 1px solid #dfe6ee;
    padding: 0 0.8125rem;
    text-align: left;
  }

  .navbar .nav-item .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar .nav-item .dropdown-toggle {
    position: relative;
  }

  .navbar .nav-item .dropdown-toggle:after {
    position: absolute;
    right: 9px;
    top: 18px;
  }

  .navbar .nav-item .dropdown-submenu {
    left: -15px;
    padding-left: 1.41575em;
    position: relative;
    width: calc(100% + 30px);
  }

  .navbar .nav-item .dropdown-submenu:after {
    position: absolute;
    right: 6px;
    top: 0.8em;
    transform: rotate(-90deg);
  }

  .navbar .nav-item .dropdown-submenu>a:focus,
  .navbar .nav-item .dropdown-submenu>a:hover {
    background-color: transparent;
  }

  .navbar .nav-item .dropdown-submenu a:after {
    right: 9px !important;
    top: 11px !important;
  }

  .navbar .nav-item .dropdown-submenu .dropdown-menu {
    left: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    top: 0;
  }

  .navbar .nav-item.search {
    display: none;
  }

  .navbar .nav-item .dropdown.show {
    color: #f85a3e;
  }

  .navbar .nav-item .dropdown-menu .dropdown-item {
    padding: 0.5rem 1.5rem;
  }

  .navbar .nav-item .dropdown-menu .dropdown-item.border-bottom {
    border-bottom: none !important;
  }

  .navbar-collapse {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.7);
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 58px;
    position: fixed !important;
    text-align: center;
    transition: left 0.2s ease-in;
    width: 90vw;
    z-index: 999;
  }

  .navbar-collapse.collapsing {
    left: 100%;
    transition: left 0.2s ease;
  }

  .navbar-collapse.show {
    left: 10vw;
    z-index: 20;
  }

  .collapse:not(.show) {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar {
    height: 70px;
    z-index: 999;
  }

  .navbar .navbar-toggler {
    height: 100%;
    padding-right: 20px;
  }

  .navbar .navbar-toggler .navbar-toggler-icon {
    margin-top: -1px;
  }

  .navbar .count {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1481px) {
  .navbar {
    padding: 0 15px !important;
  }

  .navbar .navbar-brand {
    font-size: 1.3125rem;
    margin-right: 1rem !important;
  }

  .navbar .navbar-brand .logo {
    width: 1.625rem;
  }

  .navbar .count {
    display: none;
  }

  .navbar-nav .nav-item .nav-link {
    font-size: 0.875rem;
    padding: 0.5rem;
  }

  .site-header-cart {
    margin-left: 50px !important;
  }

  .site-header-cart .cart-contents .amount {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .navbar {
    height: 70px;
    z-index: 999;
  }

  .navbar .dropdown-menu {
    box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
    margin-top: 20px;
    min-width: 265px;
  }

  .navbar .dropdown-menu.multi-column {
    margin-left: 5px;
    margin-top: 5px;
    width: calc(100% - 10px);
  }

  .navbar .dropdown-submenu>a {
    color: #f85a3e;
  }

  .navbar .dropdown-submenu a {
    padding: 0.25em 0.5rem;
  }

  .navbar .dropdown-submenu a:after {
    display: none;
  }

  .navbar .dropdown-submenu .dropdown-menu {
    box-shadow: none !important;
    display: block !important;
    margin: 0 !important;
    min-width: auto !important;
    padding: 0 !important;
    position: relative !important;
  }
}

@media (min-width: 992px) and (max-width: 1111px) {
  .navbar .navbar-brand span {
    display: none !important;
  }
}

.breadcrumb {
  background: #f85a3e;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.5);
  color: #fff;
  font-size: 0.9375rem;
  position: relative;
  z-index: 9;
}

@media (max-width: 767px) {
  .breadcrumb {
    font-size: 14px;
    overflow-x: auto;
  }
}

@media (min-width: 768px) {
  .breadcrumb {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.breadcrumb-item {
  white-space: nowrap;
}

.breadcrumb-item a:active,
.breadcrumb-item a:focus,
.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:before,
.breadcrumb-item.active {
  color: hsla(0, 0%, 100%, 0.7);
}

.profile-search-wrapper {
  background: #f85a3e;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.5);
  position: relative;
  z-index: 9;
}

.profile-search-wrapper form {
  width: 100%;
}

.profile-search-wrapper .input-group {
  z-index: 99;
}

.profile-search-wrapper .input-group input,
.profile-search-wrapper .input-group input:focus {
  box-shadow: none !important;
  font-size: 15px;
  outline: none !important;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .profile-search-wrapper {
    background: #f85a3e;
  }
}

@media (min-width: 1200px) and (max-width: 1481px) {
  .profile-search-wrapper form {
    padding-right: 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .profile-search-wrapper form {
    min-width: 50%;
    width: 50%;
  }
}

@media (min-width: 992px) {
  .profile-search-wrapper form {
    min-width: 500px;
    width: 500px;
  }
}

@media (max-width: 767px) {

  .profile-search-wrapper .input-group-append,
  .profile-search-wrapper input {
    border-radius: 0 !important;
  }
}

@media (max-width: 991.98px) {

  .h1,
  h1 {
    font-size: 2.25rem;
  }

  .h2,
  h2 {
    font-size: 1.875rem;
  }

  .h3,
  h3 {
    font-size: 1.5rem;
  }

  .h4,
  h4 {
    font-size: 1.25rem;
  }

  .h5,
  h5 {
    font-size: 1.125rem;
  }

  .h6,
  h6 {
    font-size: 1rem;
  }
}

@media (max-width: 767.98px) {

  .h1,
  h1 {
    font-size: 1.875rem;
  }

  .h2,
  h2 {
    font-size: 1.5rem;
  }

  .h3,
  h3 {
    font-size: 1.25rem;
  }

  .h4,
  h4 {
    font-size: 1.125rem;
  }

  .h5,
  h5 {
    font-size: 1rem;
  }

  .h6,
  h6 {
    font-size: 0.875rem;
  }
}

.text-underline {
  text-decoration: underline;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.icon-sm {
  height: 1.25rem;
}

.tooltip .tooltip-inner {
  font-weight: 500;
}

.tooltip.yellow .tooltip-inner {
  background-color: #edae48;
}

.tooltip.bs-tooltip-top.yellow .arrow:before,
.tooltip.yellow.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #edae48;
}

.tooltip.orange .tooltip-inner {
  background-color: #f85a3e;
}

.tooltip.bs-tooltip-top.orange .arrow:before,
.tooltip.orange.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #f85a3e;
}

.mobile-stick {
  display: none;
}

.fixed-bottom .btn-rmi~.success,
.mobile-save-share .btn-rmi~.success {
  bottom: 72px;
  width: 100%;
}

.fixed-bottom .btn-rmi~.success:before,
.mobile-save-share .btn-rmi~.success:before {
  left: 50%;
}

.modal button.close {
  opacity: 1;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  z-index: 999;
}

.modal button.close:active,
.modal button.close:focus,
.modal button.close:hover {
  box-shadow: none;
  outline: 0;
}

.modal button.close span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 352' style='enable-background:new 0 0 352 352' xml:space='preserve'%3E%3Cpath d='M242.7 176 342.8 75.9c12.3-12.3 12.3-32.2 0-44.5L320.6 9.2c-12.3-12.3-32.2-12.3-44.5 0L176 109.3 75.9 9.2C63.7-3.1 43.7-3.1 31.5 9.2L9.2 31.4c-12.3 12.3-12.3 32.2 0 44.5L109.3 176 9.2 276.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 242.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 176z' style='fill:%23333'/%3E%3C/svg%3E");
  display: block;
  height: 12px;
  margin-top: 1px;
  width: 12px;
}

.popover.more-info .arrow:before {
  border-top-color: rgba(15, 19, 25, 0.3);
  filter: blur(15px);
}

.popover.more-info .popover-header {
  background-color: transparent;
  text-align: center;
}

.success-message {
  height: calc(100% - 47px);
  top: 0;
  z-index: 9;
}

.custom-control-input {
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.custom-checkbox .custom-control-label:before {
  top: 0.313em;
}

.pagination .page-item .page-link {
  border: none;
  color: #3f5069;
  height: 2rem;
  width: 2rem;
}

.pagination .page-item.disabled {
  opacity: 0.5;
}

.badge-yellow {
  color: #fff;
}

.select-extend {
  display: none;
}

.select-last-element .btn {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.select-extended-element {
  position: relative;
  text-overflow: ellipsis;
}

.select-extended-element.select-checkbox .active {
  background: none;
  color: #212529;
  position: relative;
}

.select-extended-element.select-checkbox .active:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTYiIHdpZHRoPSIxNiIvPg==);
  content: "";
  height: 16px;
  left: 4px;
  position: absolute;
  top: 8px;
  width: 16px;
}

.select-extended-element.select-checkbox .dropdown-menu .dropdown-item:active {
  background: #fff;
  color: #212529;
}

.select-extended-element.input-group-prepend {
  margin-bottom: 0;
}

.select-extended-element .btn {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-extended-element .dropdown-menu .select-search {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
}

.select-extended-element .dropdown-menu .select-extend-alert {
  bottom: 0;
  display: none;
  left: 0;
  margin-bottom: 0;
  position: absolute;
  right: 0;
}

.select-extended-element .dropdown-menu .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control-mega {
  font-size: 60px !important;
}

.graduation-cap-corner {
  height: 144px;
  position: absolute;
  right: -49px;
  top: -60px;
  width: 144px;
}

.form-control-lg {
  font-size: 1rem;
  padding: 0.75rem 1.125em;
}

.btn {
  font-size: 0.9375rem;
}

.btn-white {
  background: #fff;
}

.btn-outline-light {
  border-color: #acb5bd !important;
  color: #354359;
}

.btn-group-sm>.btn,
.btn-sm {
  font-size: 0.875rem;
  font-weight: 400;
}

.btn-link {
  color: #0f1319;
  font-weight: 500;
}

.btn-link:hover {
  color: #4587ad;
}

.btn-outline-white {
  border: 1px solid #ced4da;
}

@media (max-width: 576px) {
  .btn-block-sm-only {
    display: block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .btn-block-md-down {
    display: block;
    width: 100%;
  }
}

.card {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  text-decoration: none;
}

.card .card-body {
  padding: 1.875rem 1.5625rem;
}

.card h5.card-title {
  font-weight: 400;
}

h2 {
  font-weight: 600;
}

.sib-input h2 input::-moz-placeholder,
h2 .sib-input input::-moz-placeholder {
  display: block;
  font-size: 70%;
  font-weight: 300;
}

.sib-input h2 input:-ms-input-placeholder,
h2 .sib-input input:-ms-input-placeholder {
  display: block;
  font-size: 70%;
  font-weight: 300;
}

.sib-input h2 input::placeholder,
.site-footer .footer-links h2 a,
h2 .footnote,
h2 .sib-input input::placeholder,
h2 .site-footer .footer-links a,
h2 .strikethrough,
h2 .text-muted {
  display: block;
  font-size: 70%;
  font-weight: 300;
}

.input-group-lg {
  width: auto;
}

.input-group-lg,
.input-group-lg> :first-child {
  border-radius: 0.3rem;
}

@media (min-width: 768px) {
  .input-group-lg> :first-child {
    border-radius: 0.3rem 0 0 0.3rem;
  }
}

.input-group-lg>.input-group-append>.btn {
  border-radius: 0.3rem;
}

@media (min-width: 768px) {
  .input-group-lg>.input-group-append>.btn {
    padding: 0.75rem 3rem;
  }
}

input::-webkit-input-placeholder {
  color: #495057;
}

input:-ms-input-placeholder {
  color: #495057;
}

input:-moz-placeholder,
input::-moz-placeholder {
  color: #495057;
  opacity: 1;
}

.text-underline:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  html {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }
}

[placeholder] {
  text-overflow: ellipsis;
}

::-webkit-input-placeholder {
  text-overflow: ellipsis;
}

::-moz-placeholder {
  text-overflow: ellipsis;
}

:-ms-input-placeholder {
  text-overflow: ellipsis;
}

:-moz-placeholder {
  text-overflow: ellipsis;
}

.text-transparent {
  color: transparent;
}

.ad {
  overflow: hidden;
}

.ad img {
  height: auto !important;
  width: auto !important;
}

.btn-rmi.loading {
  pointer-events: none;
}

.btn-rmi.loading .btn-text {
  visibility: hidden;
}

.btn-rmi.loading .spinner {
  -webkit-animation: spin 0.5s linear 0s infinite normal;
  animation: spin 0.5s linear 0s infinite normal;
  background: #53b97e;
  border-radius: 80%;
  display: block;
  height: 20px;
  width: 20px;
}

.btn-rmi.loading .spinner:after,
.btn-rmi.loading .spinner:before {
  content: "";
  display: block;
  position: absolute;
}

.btn-rmi.loading .spinner:before {
  background: #fff;
  background-image: linear-gradient(#53b97e, #fff);
  border-radius: 0 90px 90px 0;
  height: 20px;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 1;
}

.btn-rmi.loading .spinner:after {
  background: #53b97e;
  border-radius: 80%;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  z-index: 1;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.btn-rmi.loaded,
.btn-rmi.submitted {
  opacity: 0.5;
  pointer-events: none;
}

.btn-rmi.submitted:after {
  content: "Submitted";
}

.btn-rmi.submitted+div:not(.d-flex),
.btn-rmi.submitted .btn-text {
  display: none !important;
}

.btn-rmi.submitted i {
  display: inline !important;
}

.btn-rmi.btn-white {
  border: none !important;
}

.btn-rmi~.success {
  bottom: 45px;
  box-shadow: 0 0 10px 10px rgba(0, 5, 10, 0.1);
  font-size: 13px;
  line-height: 1.3;
  opacity: 0;
  right: 0;
  transition: visibility 0.5s, opacity 0.5s ease;
  visibility: hidden;
  width: calc(100% + 60px);
}

@media (min-width: 768px) {
  .btn-rmi~.success {
    left: -30px;
    right: auto;
  }
}

.btn-rmi~.success:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  bottom: -10px;
  content: "";
  left: calc(50% + 30px);
  margin-left: -10px;
  position: absolute;
  width: 0;
}

@media (min-width: 768px) {
  .btn-rmi~.success:before {
    left: 50%;
  }
}

.btn-rmi.loaded~.success {
  opacity: 1;
  visibility: visible;
}

.btn-rmi.btn-white~.success {
  border-color: #fff;
}

@media (max-width: 767px) {
  .modal-dialog {
    margin: 0;
    max-width: unset;
  }

  .modal-dialog,
  .modal-dialog .modal-content,
  .modal-dialog .modal-footer,
  .modal-dialog .modal-header {
    border-radius: 0;
  }

  .modal-dialog .modal-content {
    -webkit-overflow-scrolling: touch;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .modal-dialog {
    margin: auto;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #fff;
}

p {
  color: #6c757d;
}

.no-fast-facts {
  height: 109px;
  width: 100%;
}

.USD {
  display: inline;
}

.USD:before {
  content: "₹";
}

.CAD {
  display: inline;
}

.CAD:before {
  content: "C$";
}

.GBP {
  display: inline;
}

.GBP:before {
  content: "₤";
}

.EUR {
  display: inline;
}

.EUR:before {
  content: "€";
}

.KES {
  display: inline;
}

.KES:before {
  content: "KSh";
}

.CHF {
  display: inline;
}

.CHF:before {
  content: "Fr.";
}

.AED {
  display: inline;
}

.AED:before {
  content: "Dh";
}

.badge.ug-school {
  background-color: #fecf6a;
  font-size: 14px;
  padding: 5px 12px 6px;
}

.badge.grad-school,
.badge.online-school,
.badge.scholarship {
  background-color: rgba(0, 61, 91, 0.8);
  font-size: 14px;
  padding: 5px 12px 6px;
}

.z-index-9 {
  z-index: 9;
}

.back-to-search.btn,
.flakh,
.popover.more-info,
.site-footer .share {
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
}

.text-14 {
  font-size: 14px;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-7 {
  opacity: 0.7;
}

.line-height-sm {
  line-height: 1.2;
}

.separator {
  align-items: center;
  display: flex;
  text-align: center;
}

.separator:after,
.separator:before {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  content: "";
  flex: 1;
}

.separator:before {
  margin-right: 0.5rem;
}

.separator:after {
  margin-left: 0.5rem;
}

.bg-secondary-gradient {
  background: linear-gradient(180deg, #4c90b7 0, #407ea1);
}

.btn-amazon {
  background: linear-gradient(180deg, #fec970 0, #fdae3e);
  border: none;
  color: #0f1319;
}

.btn-arrow:after {
  content: "→";
  margin-left: 5px;
  margin-top: 2px;
}

.font-weight-600 {
  font-weight: 600;
}

.btn .spinner {
  display: none;
}

.btn .spinner svg path,
.btn .spinner svg rect {
  fill: #f85a3e;
}

.btn.loading .spinner {
  display: block;
}

.btn.send-share .spinner {
  left: 0;
  top: 4px;
}

.btn.send-share .spinner svg path,
.btn.send-share .spinner svg rect {
  fill: #fff;
}

.info-box-wrapper [class*="col-"] .check {
  width: 1.25rem;
}

.info-box-wrapper .info-box {
  border-bottom: 1px solid #f0f3f7;
  border-radius: 0.1875rem;
  color: #cfd8e5;
  font-weight: 400;
  line-height: 1.2;
}

.info-box-wrapper .info-box img {
  display: none;
}

.info-box-wrapper .info-box.yes {
  color: #0f1319;
}

.info-box-wrapper .info-box.yes img {
  display: inline-block;
}

.site-header-cart {
  display: none;
}

@media (min-width: 768px) {
  .site-header-cart {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 60px;
  }

  .site-header-cart:hover .widget_shopping_cart {
    left: auto;
    right: 50px;
    top: 70px;
  }

  .site-header-cart .widget_shopping_cart {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
    display: block;
    font-size: 0.875em;
    left: -999em;
    position: absolute;
    top: 100%;
    width: 300px;
    z-index: 999999;
  }

  .site-header-cart .widget_shopping_cart a {
    color: #0f1319;
  }

  .site-header-cart .widget_shopping_cart p {
    text-align: center;
  }

  .site-header-cart .widget_shopping_cart .woocommerce-mini-cart__empty-message {
    margin: 20px 0;
  }

  .site-header-cart .widget_shopping_cart ul {
    list-style: none;
    padding-left: 0;
  }

  .site-header-cart .widget_shopping_cart li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1em 1.41575em;
  }

  .site-header-cart .widget_shopping_cart li a.remove {
    display: none;
  }

  .site-header-cart .widget_shopping_cart li img {
    float: right;
    height: 100%;
    margin-left: 1em;
    max-width: 2.617924em;
    width: 100%;
  }

  .site-header-cart .widget_shopping_cart li>a {
    display: block;
  }

  .site-header-cart .widget_shopping_cart strong {
    color: #212529;
  }

  .site-header-cart .widget_shopping_cart a {
    color: #333;
  }

  .site-header-cart .widget_shopping_cart .total {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 0;
  }

  .site-header-cart .widget_shopping_cart .buttons,
  .site-header-cart .widget_shopping_cart .total {
    padding: 1em 1.41575em;
  }

  .site-header-cart .widget_shopping_cart .buttons {
    padding-bottom: 0;
  }

  .site-header-cart .widget_shopping_cart .buttons .button {
    -webkit-appearance: none;
    background-color: #f85a3e;
    border: 1px solid #f85a3e;
    border-radius: 0.4rem !important;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.6180469716em;
    outline: 0;
    padding: 0.6180469716em 1.41575em;
    text-decoration: none;
    text-shadow: none;
  }

  .site-header-cart .cart-contents {
    align-items: center;
    color: #333;
    display: flex;
    height: 70px;
    padding: 0;
    position: relative;
  }

  .site-header-cart .cart-contents .amount {
    margin-right: 10px;
  }

  .site-header-cart .cart-contents .count {
    font-size: 0.875em;
    font-weight: 400;
    opacity: 0.5;
  }

  .site-header-cart .cart-contents:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' style='enable-background:new 0 0 576 512' xml:space='preserve'%3E%3Cpath d='M576 216v16c0 13.3-10.7 24-24 24h-8l-26.1 182.8c-3.4 23.6-23.6 41.2-47.5 41.2H105.6c-23.9 0-44.1-17.6-47.5-41.2L32 256h-8c-13.3 0-24-10.7-24-24v-16c0-13.3 10.7-24 24-24h67.3L198.1 45.2c10.4-14.3 30.4-17.5 44.7-7.1 14.3 10.4 17.5 30.4 7.1 44.7L170.5 192h235L326.1 82.8c-10.4-14.3-7.2-34.3 7.1-44.7 14.3-10.4 34.3-7.2 44.7 7.1L484.7 192H552c13.3 0 24 10.7 24 24zM312 392V280c0-13.3-10.7-24-24-24s-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24zm112 0V280c0-13.3-10.7-24-24-24s-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24zm-224 0V280c0-13.3-10.7-24-24-24s-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24z' style='fill:%23333'/%3E%3C/svg%3E");
    content: "";
    display: inline-block;
    float: right;
    height: 16px;
    left: -25px;
    position: absolute;
    width: 18px;
  }
}

@media (min-width: 1200px) {
  .site-header-cart:hover .widget_shopping_cart {
    right: 100px;
  }
}

.user-dropdown-menu {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
  display: none;
  left: auto;
  position: absolute;
  right: 55px;
  top: 70px;
  z-index: 999999;
}

@media (max-width: 767px) {
  .user-dropdown-menu {
    right: 44px;
    top: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1481px) {
  .user-dropdown-menu {
    right: 15px;
  }
}

.user-dropdown-menu:hover {
  display: block;
}

.user-dropdown-menu a {
  border-bottom: 1px solid #f0f3f7;
  color: #0f1319;
  font-size: 15px;
  padding-left: 1.41575rem;
  padding-right: 1.41575rem;
}

.user-dropdown-menu a:last-of-type {
  border-bottom: none;
}

.user-dropdown-menu a:hover {
  background-color: #feedea;
  color: #0f1319;
}

@media (min-width: 768px) {
  .user-dropdown-toggle {
    height: 70px;
  }
}

.user-dropdown-toggle:hover~.user-dropdown-menu {
  display: block;
}

.user-dropdown-toggle .logged-in {
  color: #fe5a46;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .user-dropdown-toggle .logged-in {
    height: 50px;
    position: relative;
    right: 45px;
  }
}

@media (min-width: 768px) {
  .user-dropdown-toggle .logged-in {
    border-left: 1px solid #e1e5e8;
    margin-left: 20px;
    padding-left: 20px;
  }
}

.user-dropdown-toggle .logged-in span {
  background-color: #f85a3e;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 89.7 89.7' style='enable-background:new 0 0 89.7 89.7' xml:space='preserve'%3E%3Cpath d='M9.5 89.7h70.8c3.2 0 5.7-2.7 5.7-5.9v-5.3c0-6.9-3.3-13.1-8.8-16.7-9.6-6.1-15-9.3-18.1-11.1 5.5-6.1 9-15.5 9-24.9C68 10.9 58.5 0 45.5 0S23 10.7 23 25.7c0 9.1 3.4 18.4 8.7 24.5-4.1 2.2-10.5 6-19.3 11.6-5.4 3.6-8.6 9.8-8.6 16.6v5.4c0 3.2 2.6 5.9 5.7 5.9z' style='fill:%23fff'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 0.2rem;
  height: 28px;
  padding: 5px;
  width: 28px;
}

@media (min-width: 768px) {
  .user-dropdown-toggle .logged-in span {
    background-size: 14px;
    height: 24px;
    margin-right: 7px;
    width: 24px;
  }
}

@media (max-width: 767px) {
  .storefront-handheld-footer-bar {
    bottom: 1rem;
    display: none;
    left: 1rem;
    position: fixed;
    z-index: 99;
  }

  .storefront-handheld-footer-bar.show {
    display: block;
  }

  .storefront-handheld-footer-bar .cart a {
    align-items: center;
    background-color: #fe5a46;
    border-radius: 3px;
    color: #fff;
    display: flex;
    height: 50px;
    justify-content: center;
    position: relative;
    width: 75px;
    z-index: 999;
  }

  .storefront-handheld-footer-bar .cart a:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' style='enable-background:new 0 0 576 512' xml:space='preserve'%3E%3Cpath d='M576 216v16c0 13.3-10.7 24-24 24h-8l-26.1 182.8c-3.4 23.6-23.6 41.2-47.5 41.2H105.6c-23.9 0-44.1-17.6-47.5-41.2L32 256h-8c-13.3 0-24-10.7-24-24v-16c0-13.3 10.7-24 24-24h67.3L198.1 45.2c10.4-14.3 30.4-17.5 44.7-7.1 14.3 10.4 17.5 30.4 7.1 44.7L170.5 192h235L326.1 82.8c-10.4-14.3-7.2-34.3 7.1-44.7 14.3-10.4 34.3-7.2 44.7 7.1L484.7 192H552c13.3 0 24 10.7 24 24zM312 392V280c0-13.3-10.7-24-24-24s-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24zm112 0V280c0-13.3-10.7-24-24-24s-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24zm-224 0V280c0-13.3-10.7-24-24-24s-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24z' style='fill:%23fff'/%3E%3C/svg%3E");
    content: "";
    height: 16px;
    margin-right: 7px;
    width: 18px;
  }

  .storefront-handheld-footer-bar .cart .count {
    color: #fff;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .storefront-handheld-footer-bar {
    display: none;
  }
}

.site-footer {
  display: block;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .site-footer {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

.site-footer .navbar-brand {
  color: #0f1319;
}

.site-footer .navbar-brand .logo {
  fill: #f85a3e;
  height: auto;
  width: 2.25rem;
}

.site-footer .navbar-brand span {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.site-footer h5 {
  font-size: 1.0625rem;
  font-weight: 500;
}

.site-footer .footer-links {
  font-size: 0.9375rem;
  padding-left: 0;
}

.site-footer .footer-links li {
  display: block;
}

.site-footer .svg--source {
  display: none;
}

.site-footer .svg--icon {
  fill: currentColor;
  display: block;
  height: 100%;
  margin: 0 auto;
  max-height: 2.25rem;
  max-width: 2.25rem;
  width: 100%;
}

.site-footer .share {
  border-radius: 50%;
  color: #0f1319;
  height: 2.25rem;
  transition: 0.25s;
  width: 2.25rem;
}

.site-footer .share:last-child {
  margin-right: 0;
}

.site-footer .share:focus {
  outline-color: inherit;
}

.site-footer .twitter:focus,
.site-footer .twitter:hover {
  color: #00aced;
}

.site-footer .pinterest:focus,
.site-footer .pinterest:hover {
  color: #bd081c;
}

.site-footer .youtube:focus,
.site-footer .youtube:hover {
  color: #cd201f;
}

.site-footer .facebook:focus,
.site-footer .facebook:hover {
  color: #3b5998;
}

.site-footer .footer-copyright {
  font-size: 0.9375rem;
}

@media (max-width: 767px) {
  .site-footer .footer-copyright {
    bottom: -15px;
    height: 60px;
    left: -35px;
    width: calc(100% + 100px);
    z-index: 9;
  }
}

.body-profile .save.saved:after {
  content: "d";
}

.body-profile .btn-rmi {
  height: 38px;
}

@media (max-width: 767px) {
  .body-profile #content {
    overflow-x: visible;
  }

  .body-profile .storefront-handheld-footer-bar {
    display: none !important;
  }
}

.body-profile .popover.more-info.show {
  min-width: 307px;
}

@media (max-width: 991px) {
  .body-profile .site-footer .footer-copyright {
    bottom: 23px;
  }

  .body-profile .site-footer {
    padding-bottom: 50px !important;
  }

  .body-profile .mobile-stick {
    height: 50px;
  }

  .body-profile .mobile-stick.fixed-top {
    z-index: 9999;
  }

  .body-profile .mobile-stick.mobile-info-top {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    overflow: hidden;
    padding-left: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 132px);
    z-index: 999999;
  }

  .body-profile .mobile-stick.mobile-info-bottom {
    height: auto;
    z-index: 99;
  }

  .body-profile .mobile-stick.mobile-info-bottom .btn {
    border-radius: 0;
    padding: 10px;
  }

  .body-profile .mobile-stick .save,
  .body-profile .mobile-stick .share {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .body-profile .mobile-stick.mobile-info-top {
    height: 70px;
    padding-top: 23px;
    width: calc(100% - 349px);
  }
}

.body-profile .profile--main section {
  margin-top: -100px;
  padding-top: 100px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .body-profile .profile--main section {
    box-shadow: none !important;
  }
}

@media (min-width: 992px) {
  .body-profile .profile--main section {
    margin-top: -120px;
    padding-top: 120px;
  }
}

.profile .claim-wrapper i {
  font-size: 18px;
}

.profile .claim-wrapper span {
  font-size: 14px;
}

.profile .claim-wrapper .btn {
  border-radius: 99px;
}

.profile .save:hover i {
  color: #edae49 !important;
}

.profile .share:hover i {
  color: #f85a3e !important;
}

.profile .breadcrumb {
  background: none;
  box-shadow: none;
}

@media (min-width: 1200px) and (max-width: 1481px) {
  .profile .breadcrumb {
    padding-left: 15px !important;
  }
}

@media (max-width: 767px) {
  .profile nav[aria-label="breadcrumb"] {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .profile nav[aria-label="breadcrumb"] {
    overflow: hidden;
  }
}

/* @media (min-width: 992px) and (max-height: 588px) {
  .profile #profileNavWrapper {
    position: relative;
    top: 0;
  }
}
@media (max-width: 991px) {
  .profile #profileNavWrapper {
    background: #f85a3e !important;
    box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.2);
    display: none;
    left: 0 !important;
    overflow-x: auto;
    position: fixed !important;
    top: 60px !important;
    width: 100% !important;
    z-index: 9;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .profile #profileNavWrapper {
    top: 70px !important;
  }
}
@media (min-width: 992px) {
  .profile #profileNavWrapper {
    display: block !important;
    opacity: 1 !important;
    top: 150px;
    z-index: 0;
  }
} */
.profile .desktop-stick {
  background: #f85a3e;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.5);
  display: none;
  top: 104px !important;
  z-index: 19;
}

@media (max-width: 991px) {
  .profile .desktop-stick {
    display: none !important;
  }
}

.profile .desktop-stick ol {
  font-size: 0.9375rem;
}

@media (min-width: 992px) {
  .profile .desktop-stick ol {
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.profile .desktop-stick .btn-rmi {
  background-color: #fff !important;
}

.profile .desktop-stick .btn-rmi.loading .spinner {
  background: #fff;
  opacity: 0.5;
}

.profile .desktop-stick .btn-rmi.loading .spinner:before {
  background-image: linear-gradient(#fff, #0f131b);
}

.profile .desktop-stick .btn-rmi.loading .spinner:after {
  background: #fff;
}

.profile .desktop-stick .save:hover,
.profile .desktop-stick .share:hover {
  background-color: transparent;
}

.profile .invalid-feedback {
  color: #fff !important;
  font-size: 15px;
  z-index: 9;
}

@media (min-width: 768px) {
  .profile .invalid-feedback {
    color: #e4606d !important;
    position: absolute;
    right: 50px;
    top: 13px;
    width: auto;
  }
}

.profile header .d-lg-none.school-actions .btn-light:active,
.profile header .d-lg-none.school-actions .btn-light:focus,
.profile header .d-lg-none.school-actions .btn-light:hover {
  background-color: #dfe6ee !important;
  border-color: #dfe6ee !important;
}

@media (min-width: 768px) {
  .profile.online .header-facts .col-md-auto {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 992px) {
  .profile.online .header-facts .col-md-auto {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.profile .profile-content {
  padding: 0;
}

@media (min-width: 768px) {
  .profile .profile-content {
    padding: 0 15px;
  }
}

.profile header .overlay {
  box-shadow: inset 0 -250px 250px -150px rgba(0, 0, 0, 0.75);
  height: 100%;
  left: 0;
  overflow: visible;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.profile header .map,
.profile header .owl-carousel,
.profile header .slide-item,
.profile header .static-hero,
.profile header .youvisit {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  min-height: 200px;
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) {

  .profile header .map,
  .profile header .owl-carousel,
  .profile header .slide-item,
  .profile header .static-hero,
  .profile header .youvisit {
    height: 300px;
    min-height: 300px;
  }
}

@media (min-width: 1200px) {

  .profile header .map,
  .profile header .owl-carousel,
  .profile header .slide-item,
  .profile header .static-hero,
  .profile header .youvisit {
    height: 375px;
    min-height: 375px;
  }
}

@media (min-width: 992px) and (max-height: 684px) {

  .profile header .map,
  .profile header .owl-carousel,
  .profile header .slide-item,
  .profile header .static-hero,
  .profile header .youvisit {
    height: 325px;
    min-height: 325px;
  }
}

.profile header .static-hero {
  max-width: 1200px;
}

.profile header .static-hero:after,
.profile header .static-hero:before {
  background-color: #fff;
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.profile header .static-hero:before {
  left: -100%;
}

.profile header .static-hero:after {
  right: -100%;
}

.profile header .owl-carousel {
  background-color: #fff;
  display: block;
}

@media (min-width: 768px) {
  .profile header .owl-carousel:before {
    align-items: center;
    content: "Image Gallery Loading...";
    display: flex;
    font-size: 20px;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.profile header .owl-carousel .owl-nav button {
  display: none;
}

@media (min-width: 768px) {
  .profile header .owl-carousel .owl-nav button {
    color: #fff;
    display: block;
    font-size: 72px;
    height: 300px;
    padding: 0 15px !important;
    position: absolute;
    top: 0;
  }
}

@media (min-width: 1200px) {
  .profile header .owl-carousel .owl-nav button {
    height: 375px;
  }
}

.profile header .owl-carousel .owl-nav button.owl-prev {
  left: 0;
}

.profile header .owl-carousel .owl-nav button.owl-next {
  right: 0;
}

.profile header .youvisit {
  background-color: #fff;
}

@media (min-width: 768px) {
  .profile header .youvisit:before {
    align-items: center;
    content: "YouVisit Virtual Tour Loading...";
    display: flex;
    font-size: 20px;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.profile header .map {
  cursor: default;
  z-index: 1;
}

.profile header .map .btn {
  border-radius: 4px;
  bottom: 10px;
  box-shadow: 0 4px 12px 0 rgba(19, 40, 54, 0.2);
  color: #000;
  font-size: 14px;
  left: 10px;
  padding: 5px 10px;
  position: absolute;
  transition: all 0.2s;
  z-index: 9999;
}

.profile header .map .leaflet-bottom.leaflet-right {
  top: 0;
  z-index: 9;
}

.profile header .map .leaflet-control-attribution {
  background: none;
  color: #fff;
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
  font-size: 14px !important;
}

@media (max-width: 767px) {
  .profile header .map .leaflet-control-attribution {
    display: none;
  }
}

.profile header .map .leaflet-control-attribution a {
  color: #fff;
}

.profile header .map:empty {
  background: #949d81;
  background-position: 50%;
  background-size: 50%;
}

.profile header .map:empty:after {
  display: none;
}

.profile header .profile-title {
  bottom: 1.5rem;
  z-index: 9;
}

@media (min-width: 768px) {
  .profile header .profile-title {
    bottom: 3.125rem;
  }
}

@media (min-width: 992px) {
  .profile header .profile-title {
    bottom: 3.125rem;
  }
}

.profile header .profile-title--logo {
  height: 3.125rem;
  margin-top: 2px;
  min-height: 3.125rem;
  min-width: 3.125rem;
  overflow: hidden;
  width: 3.125rem;
}

@media (min-width: 768px) {
  .profile header .profile-title--logo {
    height: 5rem;
    min-height: 5rem;
    min-width: 5rem;
    width: 5rem;
  }
}

.profile header .profile-title--logo.default {
  opacity: 0.9;
}

.profile header .profile-title--logo.default img {
  opacity: 0.5;
}

.profile header .profile-title p {
  font-weight: 300;
}

.profile .circle-chart__circle {
  transform: rotate(-90deg);
  transform-origin: center;
}

.profile .key>div>span {
  display: inline-block;
  height: 1rem;
  width: 1rem;
}

@media (max-width: 374px) {
  .profile .key small {
    font-size: 10px;
  }
}

.profile .info-tip-icon {
  opacity: 0.2;
  width: 0.875rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  .profile .header-facts .col-md-20 {
    transform: scale(0.9);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .profile .header-facts .col-lg-9>.row {
    margin-left: -10%;
    transform: scale(0.86);
    width: 114%;
  }
}

.profile .header-facts--value {
  font-weight: 300;
}

@media (min-width: 768px) and (max-width: 990px) {
  .profile .header-facts--value {
    font-size: 24px;
  }
}

.profile .header-facts--value sub {
  bottom: 0;
}

.profile .header-facts--value sub>span {
  margin-right: 5px;
}

.profile .header-facts--label {
  color: #6c757d;
  font-size: 90%;
  line-height: 1.2;
}

.profile .header-facts--more-info {
  bottom: 0;
  display: none;
  z-index: 9;
}

@media (min-width: 992px) {
  .profile .header-facts--more-info {
    display: inline-block;
    max-width: 240px;
    min-width: 240px;
    right: 15px;
    width: 240px;
  }
}

@media (min-width: 1200px) {
  .profile .header-facts--more-info {
    max-width: 18rem;
    min-width: 18rem;
    width: 18rem;
  }
}

.profile .header-facts--more-info form small {
  font-size: 70%;
  line-height: 1.3;
  opacity: 0.7;
}

@media (min-width: 768px) {
  .profile .header-facts .circle-fact {
    background-color: #efefef;
    border-radius: 50%;
    height: 55px;
    width: 55px;
  }
}

.profile .header-facts .calendar {
  height: 60px;
  position: relative;
  text-align: center;
  width: 60px;
}

.profile .header-facts .calendar:before {
  background: linear-gradient(0deg,
      rgba(15, 19, 25, 0.05) 0,
      rgba(15, 19, 25, 0.05));
  border-radius: 10px 10px 0 0;
  content: "";
  height: calc(50% - 10px);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.profile .header-facts .calendar--label {
  background: #4587ad;
  border-radius: 0 0 10px 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  position: relative;
  text-transform: uppercase;
}

.profile .header-facts .calendar--day {
  background: #f9f9f9;
  border-radius: 10px 10px 0 0;
  font-size: 20px;
  font-weight: 400;
  height: 40px;
}

.profile .header-facts .student-rating {
  height: 1.25rem;
  position: relative;
  width: 100px;
}

.profile .header-facts .student-rating:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39.1 80'%3E%3Cstyle%3E.st0{fill:%23e9ecef}%3C/style%3E%3Ccircle className='st0' cx='15.4' cy='9.2' r='9.2'/%3E%3Cpath className='st0' d='M24.9 21h-19C2.7 21 0 23.6 0 26.9v16.4c0 3.3 2.7 5.9 5.9 5.9h1.4v26.2c0 2.5 2 4.6 4.6 4.6h6.9c2.5 0 4.6-2 4.6-4.6V49.2h1.4c3.3 0 5.9-2.7 5.9-5.9V26.9c.1-3.3-2.6-5.9-5.8-5.9z'/%3E%3C/svg%3E");
  background-size: 0.375rem 1rem;
  background-size: 0.625rem 1.25rem;
  bottom: 0;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.profile .header-facts .student-rating>span {
  display: block;
  height: 1.25rem;
  overflow: hidden;
  position: absolute;
  text-indent: -10000px;
}

.profile .header-facts .student-rating>span:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39.1 80'%3E%3Cstyle%3E.st0{fill:%23f85a3e}%3C/style%3E%3Ccircle className='st0' cx='15.4' cy='9.2' r='9.2'/%3E%3Cpath className='st0' d='M24.9 21h-19C2.7 21 0 23.6 0 26.9v16.4c0 3.3 2.7 5.9 5.9 5.9h1.4v26.2c0 2.5 2 4.6 4.6 4.6h6.9c2.5 0 4.6-2 4.6-4.6V49.2h1.4c3.3 0 5.9-2.7 5.9-5.9V26.9c.1-3.3-2.6-5.9-5.8-5.9z'/%3E%3C/svg%3E");
  background-size: 0.375rem 1rem;
  background-size: 0.625rem 1.25rem;
  bottom: 0;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0;
  position: absolute;
  right: 0;
  text-indent: 10000px;
  top: 0;
}

.profile .header-facts .financial-aid-thermometer {
  height: 5rem;
  width: 18px;
}

.profile .header-facts .setting {
  width: 60px;
}

.profile .header-facts .progress-bar-vertical {
  background: transparent;
  height: calc(100% - 16px);
  left: 19px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 10px;
}

.profile .header-facts .progress-bar-vertical .progress-bar {
  background-color: #53b97e;
  height: 0;
  width: 100%;
}

.profile .header-facts .progress-bar-difficulty {
  border-radius: 99px;
  height: 10px;
  position: relative;
}

.profile .header-facts .progress-bar-difficulty.bar-most-difficult {
  background-color: #ff4e50;
}

.profile .header-facts .progress-bar-difficulty.bar-very-difficult {
  background-color: #fc913a;
}

.profile .header-facts .progress-bar-difficulty.bar-moderately-difficult {
  background-color: #ffc669;
}

.profile .header-facts .progress-bar-difficulty.bar-min-difficult {
  background-color: #53b97e;
}

.profile .header-facts .circle-chart {
  max-height: 85px;
  max-width: 85px;
}

.profile .header-facts .circle-chart .circle-chart__percent {
  font-size: 10px;
  font-weight: 300;
}

.profile--main {
  position: relative;
}

.profile--main nav {
  opacity: 1;
  transition: opacity 0.3s;
}

.profile--main nav.nav-hide {
  opacity: 0;
}

.profile--main nav a {
  color: hsla(0, 0%, 100%, 0.5);
  white-space: nowrap;
}

@media (min-width: 992px) and (max-height: 684px) {
  .profile--main nav a {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
  }
}

@media (min-width: 992px) {
  .profile--main nav a {
    color: #6c757d;
  }
}

.profile--main nav a.active {
  background-color: transparent;
  color: #fff;
}

@media (min-width: 992px) {
  .profile--main nav a.active {
    background-color: #f85a3e;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    position: relative;
  }

  .profile--main nav a.active:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82.31 172.53' style='enable-background:new 0 0 82.31 172.53' xml:space='preserve'%3E%3Cpath d='M79.41 79.38 45.49 45.3.39 0 .37 10 .2 86.27 0 172.53l53-52.76 26.38-26.26c3.89-3.87 3.91-10.23.03-14.13z' style='fill:%23f85a3e'/%3E%3C/svg%3E");
    background-size: cover;
    content: "";
    height: 40px;
    position: absolute;
    right: -20px;
    top: 0;
    width: 20px;
  }
}

@media (min-width: 992px) and (max-height: 684px) {
  .profile--main nav a.active:after {
    height: 32px;
    right: -16px;
    width: 16px;
  }
}

.profile--main h2 {
  font-weight: 300;
}

.profile--main ul {
  list-style: none;
}

.profile--main ul .check {
  width: 16px;
}

.profile--main table td,
.profile--main table th,
.profile--main table tr {
  line-height: 1.2;
  padding-top: 14px;
}

@media (max-width: 767px) {

  .profile--main table td,
  .profile--main table th,
  .profile--main table tr {
    font-size: 14px;
  }
}

.profile--main--fact-value {
  font-weight: 300;
}

.profile--main--fact-label {
  color: #6c757d;
  font-size: 90%;
  line-height: 1.3;
}

.profile--main .icon-heading img {
  max-width: 2.25rem;
}

.profile--main .circle-chart.acceptance {
  max-width: 6.25rem;
}

@media (min-width: 768px) {
  .profile--main .circle-chart.acceptance {
    max-width: 9.375rem;
  }
}

.profile--main .circle-chart.acceptance--gender {
  max-width: 2.25rem;
}

@media (max-width: 767px) {
  .profile--main #overview {
    font-size: 14px;
  }
}

.profile--main #overview h3 {
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .profile--main #overview h3 {
    font-size: 1.5rem;
  }
}

.profile--main #overview h4 {
  font-size: 1.1rem;
}

@media (min-width: 768px) {
  .profile--main #overview h4 {
    font-size: 1.25rem;
  }
}

.profile--main #overview h5 {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .profile--main #overview h5 {
    font-size: 1.25rem;
  }
}

.profile--main #overview h6 {
  font-size: 1rem;
}

@media (max-width: 767px) {
  .profile--main #overview h6 {
    font-size: 0.9rem;
  }
}

.profile--main #overview p strong {
  color: #212529;
  font-weight: 600;
}

.profile--main #overview p a {
  text-decoration: underline;
}

.profile--main #gallery .row {
  margin-left: -15px;
  width: calc(100% + 15px);
}

.profile--main #gallery a[data-type="image"] {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  overflow: hidden;
  padding: 15px;
}

@media (min-width: 768px) {
  .profile--main #gallery a[data-type="image"] {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 992px) {
  .profile--main #gallery a[data-type="image"] {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.profile--main #gallery .thumbnail {
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.profile--main #gallery .thumbnail:hover img {
  transform: scale(1.1);
}

.profile--main #gallery .thumbnail img {
  position: absolute;
  transition: all 0.5s ease;
}

.profile--main #gallery .thumbnail img,
.profile--main #gallery .thumbnail img.portrait {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.profile--main #location .map-wrapper {
  z-index: 0;
}

.profile--main #location .map-wrapper:after {
  background: transparent;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}

.profile--main #location .map {
  height: 360px;
  width: 100%;
}

@media (min-width: 992px) {
  .profile--main #location .map {
    height: 100%;
  }
}

.profile--main #location .map .btn {
  border-radius: 4px;
  bottom: 10px;
  box-shadow: 0 4px 12px 0 rgba(19, 40, 54, 0.2);
  color: #000;
  font-size: 14px;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  transition: all 0.2s;
  z-index: 999;
}

.profile--main #location .map .leaflet-bottom.leaflet-right {
  display: none !important;
}

@media (max-width: 767px) {
  .profile--main #degrees a {
    font-size: 14px;
  }
}

.profile--main #degrees>div>div:last-of-type {
  border-bottom: 0 !important;
}

.profile--main #degrees a:hover {
  color: #f85a3e !important;
  text-decoration: underline !important;
}

.profile--main #degrees .collapse>div:hover {
  background-color: #fff;
}

.profile--main #degrees tbody tr th[scope="row"].top-level {
  background: #fff !important;
  font-weight: 600;
}

.profile--main #degrees tbody tr th[scope="row"].top-level+td,
.profile--main #degrees tbody tr th[scope="row"].top-level+td+td {
  background: #fff;
}

.profile--main #degrees .expand-degrees {
  height: 36px;
  order: 1;
  width: 36px;
}

.profile--main #degrees .expand-degrees:hover {
  cursor: pointer;
  opacity: 1;
}

.profile--main #degrees .expand-degrees:active,
.profile--main #degrees .expand-degrees:focus {
  box-shadow: none;
  outline: none;
}

.profile--main #degrees .expand-degrees:before {
  content: "";
  display: inline-block;
  height: 16px;
  left: 0;
  top: 0;
  width: 16px;
}

.profile--main #degrees .expand-degrees .info-box-wrapper .profile--main #degrees.grad>.info-box,
.pagination .page-item .profile--main #degrees.grad>.page-link,
.profile--main #degrees.grad>.d-flex,
.profile--main .info-box-wrapper #degrees.grad>.info-box,
.profile--main .pagination .page-item #degrees.grad>.page-link,
.profile--main .profile .header-facts #degrees.grad>.calendar--day,
.profile--main .profile .header-facts #degrees.grad>.calendar--label,
.profile--main .profile .key#degrees.grad>div,
.profile--main .profile .related-test-prep .card .key>div#degrees.grad>span,
.profile--main .profile header #degrees.grad>.profile-title--logo,
.profile--main .related-test-prep .card #degrees.grad>.rounded-circle,
.profile--main .related-test-prep .card .profile .key>div#degrees.grad>span,
.profile--main ul #degrees.grad>li,
.profile .header-facts .profile--main #degrees.grad>.calendar--day,
.profile .header-facts .profile--main #degrees.grad>.calendar--label,
.profile .profile--main .key#degrees.grad>div,
.profile .related-test-prep .card .profile--main .key>div#degrees.grad>span,
.profile.scholarship .header-facts--more-info ul .profile--main #degrees.grad>li,
.profile header .profile--main #degrees.grad>.profile-title--logo,
.related-test-prep .card .profile--main #degrees.grad>.rounded-circle,
.related-test-prep .card .profile .profile--main .key>div#degrees.grad>span {
  background-color: #fff !important;
}

.info-box-wrapper .profile--main #degrees.grad>.info-box a,
.pagination .page-item .profile--main #degrees.grad>.page-link a,
.profile--main #degrees.grad>.d-flex a,
.profile--main .info-box-wrapper #degrees.grad>.info-box a,
.profile--main .pagination .page-item #degrees.grad>.page-link a,
.profile--main .profile .header-facts #degrees.grad>.calendar--day a,
.profile--main .profile .header-facts #degrees.grad>.calendar--label a,
.profile--main .profile .key#degrees.grad>div a,
.profile--main .profile .related-test-prep .card .key>div#degrees.grad>span a,
.profile--main .profile header #degrees.grad>.profile-title--logo a,
.profile--main .related-test-prep .card #degrees.grad>.rounded-circle a,
.profile--main .related-test-prep .card .profile .key>div#degrees.grad>span a,
.profile--main ul #degrees.grad>li a,
.profile .header-facts .profile--main #degrees.grad>.calendar--day a,
.profile .header-facts .profile--main #degrees.grad>.calendar--label a,
.profile .profile--main .key#degrees.grad>div a,
.profile .related-test-prep .card .profile--main .key>div#degrees.grad>span a,
.profile.scholarship .header-facts--more-info ul .profile--main #degrees.grad>li a,
.profile header .profile--main #degrees.grad>.profile-title--logo a,
.related-test-prep .card .profile--main #degrees.grad>.rounded-circle a,
.related-test-prep .card .profile .profile--main .key>div#degrees.grad>span a {
  order: 2;
}

.profile--main #campus-life2 tr td,
.profile--main #campus-life2 tr th,
.profile--main #campus-life tr td,
.profile--main #campus-life tr th,
.profile--main #tuition tr td,
.profile--main #tuition tr th {
  white-space: normal;
}

.profile--main .read-more {
  margin-bottom: 0 !important;
  overflow: hidden;
  padding-bottom: 10px;
}

.profile--main .read-more.table-responsive {
  overflow-x: auto;
}

.profile--main .bigtext {
  color: #787878;
  display: block;
  overflow: hidden;
}

.profile--main .contract,
.profile--main .expand {
  color: #555;
  cursor: pointer;
  font-weight: 700;
  padding: 15px 0;
  text-align: center;
}

.profile--main .contract:hover,
.profile--main .expand:hover {
  color: #121212;
}

.profile--main .hide {
  display: none;
}

.profile--main .see-link {
  bottom: 0;
  font-weight: 500;
  left: 0;
  line-height: 1;
}

.profile--main .see-link:hover {
  text-decoration: underline;
}

.profile--main .see-link.more {
  box-shadow: 0 -20px 80px 40px #fff;
}

.profile table .bg-light {
  background-color: #fff !important;
}

.profile table tbody tr th {
  font-weight: 400;
}

.profile table tbody tr td {
  color: #6c757d;
}

.profile table.has-active-highlighting tbody tr th:not(.top-level) {
  color: #0f1319;
  font-weight: 600;
}

.profile table.has-active-highlighting tbody tr td {
  color: #adb5bd;
}

.profile table.has-active-highlighting tbody tr td.active {
  color: rgba(0, 121, 140, 0.9);
}

.profile table.has-active-highlighting tbody tr td.active .check {
  color: rgba(0, 121, 140, 0.9);
  width: 1.0625rem;
}

.profile.scholarship .breadcrumb {
  background: #f85a3e;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.5);
}

.profile.scholarship .carousel.static {
  background: #00798c;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='105'%3E%3Cpath d='M20 10a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm15 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zM20 75a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zm30-65a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm0 65a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zM35 10a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zM5 45a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zm60 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10z' fill='%23fff' fill-opacity='.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: 50%;
  background-size: 50%;
  height: 15rem;
}

@media (min-width: 768px) {
  .profile.scholarship .carousel.static {
    height: 20rem;
  }
}

.profile.scholarship .header-facts--more-info ul {
  list-style: none;
}

.profile.scholarship .header-facts--more-info ul li {
  font-size: 14px;
  padding: 1px 0;
}

.profile.scholarship .header-facts--more-info ul .check {
  width: 14px;
}

.body-dashboard #content {
  overflow-x: visible;
}

.dashboard .profile--main .dashboard-nav-wrapper {
  margin-top: 30px !important;
}

@media (max-width: 767px) {
  .dashboard .profile--main .dashboard-nav-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative !important;
    top: 0 !important;
  }

  .dashboard .profile--main .dashboard-nav-wrapper .rounded {
    border-radius: none !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .dashboard .profile--main .dashboard-nav-wrapper {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .dashboard .profile--main .dashboard-nav-wrapper {
    left: 0;
    margin-top: 30px !important;
    position: absolute;
  }
}

.dashboard .profile--main .form-control:disabled {
  background-color: transparent;
  border-color: #fff;
  opacity: 0.5;
}

.dashboard .profile--main .dashboard-nav-top:before {
  content: "";
  height: 50%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -9;
}

.dashboard .profile--main .dashboard-nav-top img {
  background: linear-gradient(180deg, #fff 0, #f0f3f7);
  border: 5px solid #fff;
  height: 100px;
  width: 100px;
}

.dashboard .profile--main #dashboardNav .nav-link {
  color: rgba(15, 19, 25, 0.75);
}

.dashboard .profile--main .saved-school .logo {
  height: 6.25rem;
  width: 6.25rem;
}

@media (max-width: 767px) {
  .dashboard .profile--main .saved-school .text-right {
    margin-top: -10px;
  }
}

.dashboard .profile--main #schools .btn-rmi {
  min-width: 162px;
}

.dashboard .profile--main .no-results img {
  max-width: 500px;
}

.dashboard .profile--main .no-results h5 {
  margin-top: -1rem;
}

@media (max-width: 991px) {
  .dashboard .profile--main {
    padding-top: 15px;
  }
}

.hero-bg {
  background-size: 100% 100%;
  height: 100%;
  opacity: 0.4;
  right: 0;
  width: 100%;
  z-index: -9;
}

@media (min-width: 768px) {
  .hero-bg {
    height: 375px;
    opacity: 0.8;
    width: 375px;
  }
}

@media (min-width: 992px) {
  .hero-bg {
    height: 480px;
    width: 480px;
  }
}

@media (min-width: 1200px) {
  .hero-bg {
    height: 560px;
    width: 560px;
  }
}

.featured-programs .top-10 {
  max-width: 125px;
  opacity: 0.9;
}

.featured-programs .card {
  overflow: hidden;
}

.featured-programs .card .card-bg-image {
  background-position: 50%;
  background-size: cover;
  height: 100px;
}

.featured-programs .card p {
  min-height: 48px;
}

.body--landing {
  overflow-x: hidden;
}

.body--landing .landing-hero {
  background-position: 50%;
  background-size: cover;
}

@media (max-width: 767px) {
  .body--landing .landing-hero h1 {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .body--landing .landing-hero h1 {
    font-size: 36px;
  }

  .body--landing .landing-hero input[name="q"],
  .body--landing .landing-hero select {
    font-size: 18px;
    height: 77px !important;
  }

  .body--landing .landing-hero input[name="q"]:active,
  .body--landing .landing-hero input[name="q"]:focus,
  .body--landing .landing-hero select:active,
  .body--landing .landing-hero select:focus {
    box-shadow: none;
    outline: none;
  }
}

@media (max-width: 767px) {

  .body--landing .landing-hero.international .input-group .custom-select,
  .body--landing .landing-hero.international .input-group .form-control {
    border-radius: 0.3rem !important;
    width: 100%;
  }

  .body--landing .landing-hero.international .input-group .custom-select {
    border-right: none !important;
    min-width: 100%;
  }
}

.body--landing .landing-hero .invalid-feedback {
  bottom: -22px;
}

.body--sweeps {
  background-color: #e8f6fe;
}

.body--sweeps .storefront-handheld-footer-bar {
  display: none !important;
}

@media (min-width: 768px) {
  .body--sweeps #content {
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
  }
}

.body--sweeps .site-footer {
  border-top: none !important;
  margin-top: 0 !important;
}

.body--sweeps h1 {
  font-weight: 500;
}

@media (max-width: 767px) {
  .body--sweeps h1 {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .body--sweeps h1 {
    font-size: 36px;
  }
}

.body--sweeps h2 {
  font-weight: 400;
}

@media (max-width: 767px) {
  .body--sweeps h2 {
    font-size: 17px;
  }
}

.body--sweeps .step {
  background: #fbce81;
  background: -webkit-gradient(left top,
      right bottom,
      color-stop(0, #fbce81),
      color-stop(100%, #fab968));
  background: linear-gradient(135deg, #fbce81, #fab968);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbce81", endColorstr="#fab968", GradientType=1);
  font-weight: 500;
  height: 36px;
  width: 36px;
}

.back-to-search {
  display: none;
}

.back-to-search span {
  cursor: pointer;
}

.back-to-search.btn {
  background-color: hsla(0, 0%, 100%, 0.7);
  font-size: 12px;
  font-weight: 500;
  left: 0.5rem;
  top: 0.5rem;
  z-index: 5;
}

@media (min-width: 768px) {
  .back-to-search.btn {
    font-size: 14px;
    left: 15px;
    top: 15px;
  }
}

@media (min-width: 1200px) {
  .back-to-search.btn {
    left: 3rem;
    top: 1rem;
  }
}

.back-to-search.btn:hover {
  background-color: #fff;
}

.back-to-search.btn span {
  font-size: 18px;
  left: -2px;
  line-height: 0;
  top: 2px;
}

@media (max-width: 767px) {
  #shareModal .modal-content {
    border: 0;
  }

  #shareModal .modal-content .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #shareModal .modal-content .modal-footer .btn {
    width: 50%;
  }
}

.related-blog-posts ul li:nth-of-type(9) {
  display: none !important;
}

.related-blog-posts .post img {
  width: 125px;
}

@media (min-width: 768px) {
  .related-blog-posts .post img {
    min-width: 175px;
    width: 175px;
  }
}

.best-schools h3 span,
.related-schools h3 span {
  border-bottom: 2px solid #f85a3e;
  color: #f85a3e;
}

.best-schools h3 img,
.related-schools h3 img {
  height: auto;
  opacity: 0.5;
  width: 1.25rem;
}

.related-schools .row>div {
  flex-basis: 100%;
  width: 100%;
}

@media (min-width: 992px) {
  .related-schools .row>div {
    flex-basis: calc(50% - 2rem);
    max-width: calc(50% - 2rem);
  }

  .related-schools .row>div:nth-of-type(odd) {
    margin-right: 1rem;
  }

  .related-schools .row>div:nth-of-type(2n) {
    margin-left: 1rem;
  }

  .related-schools .row>div:nth-last-of-type(2) {
    border-bottom: none !important;
  }
}

.related-schools .row>div:last-of-type {
  border-bottom: none !important;
}

.related-schools .row>div>div:not(.school-actions):nth-of-type(2) {
  white-space: nowrap;
}

.related-schools .row .logo {
  height: 50px;
  width: 50px;
}

@media (min-width: 768px) {
  .related-schools .row .logo {
    height: 70px;
    width: 70px;
  }
}

.related-schools .row .logo.default {
  height: 60px;
  opacity: 0.5;
  width: 60px;
}

@media (max-width: 767px) {
  .related-schools .btn-rmi~.success {
    bottom: 65px;
  }
}

.related-test-prep .card {
  position: relative;
  z-index: 0;
}

.related-test-prep .card:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='105'%3E%3Cpath d='M20 10a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm15 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zM20 75a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zm30-65a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm0 65a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zM35 10a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zM5 45a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zm60 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10z' fill='%23fff' fill-opacity='.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: 50%;
  background-size: 125%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.related-test-prep .card .card-body {
  position: relative;
  z-index: 9;
}

@media (max-width: 991px) {
  .related-test-prep .card .card-body {
    margin-bottom: 53px;
    padding: 20px 10px;
  }
}

.related-test-prep .card.sat {
  background: linear-gradient(180deg, #00b6fc 0, #00a4e2);
}

.related-test-prep .card.act {
  background: linear-gradient(180deg, #05346c 0, #032854);
}

.related-test-prep .card.psat {
  background: linear-gradient(180deg, #00a5eb 0, #0093d1);
}

.related-test-prep .card.gre {
  background: linear-gradient(180deg, #85366c 0, #732e5e);
}

.related-test-prep .card.gmat {
  background: linear-gradient(180deg, #f7cc41 0, #f7c629);
}

.related-test-prep .card.lsat {
  background: linear-gradient(180deg, #008694 0, #006e7a);
}

.related-test-prep .card.toefl {
  background: linear-gradient(180deg, #00879f 0, #007185);
}

.profile .related-test-prep .card .key>div>span,
.related-test-prep .card .profile .key>div>span,
.related-test-prep .card .rounded-circle {
  border: 2px solid hsla(0, 0%, 100%, 0.6);
  font-size: 18px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  width: 60px;
}

@media (min-width: 768px) {

  .profile .related-test-prep .card .key>div>span,
  .related-test-prep .card .profile .key>div>span,
  .related-test-prep .card .rounded-circle {
    font-size: 20px;
    height: 75px;
    min-height: 75px;
    min-width: 75px;
    width: 75px;
  }
}

.related-test-prep .card .card-text {
  opacity: 0.7;
}

@media (max-width: 991px) {
  .related-test-prep .card .btn {
    bottom: -33px;
    position: absolute;
  }
}

#social {
  overflow: visible;
  padding-left: 15px;
  padding-right: 15px;
}

#social .card-columns {
  -moz-column-count: 1;
  column-count: 1;
  -moz-column-gap: 0;
  column-gap: 0;
}

@media (min-width: 768px) {
  #social .card-columns {
    -moz-column-count: 2;
    column-count: 2;
    margin-left: -1rem;
    width: calc(100% + 1rem) !important;
  }

  #social .card-columns .card {
    padding-left: 1rem;
    padding-right: 1rem;
    width: calc(100% + 1rem);
  }
}

@media (min-width: 992px) {
  #social .card-columns {
    width: calc(100% + 2rem) !important;
  }
}

#social .card-columns .card:hover {
  cursor: default;
}

#social .card-header {
  position: relative;
}

#social .card.twitter .card-body {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
}

#social .card.facebook .widget-facebook {
  height: 500px;
}

#social .card.facebook .widget-facebook .facebook_iframe {
  border: none;
}

#social .card.links a {
  color: #0f1319;
}

#social .card.instagram .card-header {
  background: linear-gradient(135deg, #1e5799, #d321a4 63%, #ffef63);
}

.video-container {
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}

.video-container embed,
.video-container iframe,
.video-container object {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.promo-container {
  background-color: rgba(116, 203, 208, 0.875);
  bottom: 10px;
  display: none;
  left: 10px;
  max-width: 100%;
  position: fixed;
  right: 10px;
  z-index: 99999;
}

.promo-container.show {
  display: block;
}

@media (min-width: 768px) {
  .promo-container {
    bottom: 20px;
    left: 15px;
    max-width: 420px;
    right: auto;
  }
}

.promo-container .promo-close {
  color: #fff;
  font-size: 30px;
  height: 30px;
  right: 12px;
  top: 12px;
  width: 30px;
}

.promo-container .promo-close:hover {
  cursor: pointer;
}

@media (max-width: 767px) {
  .promo-container p {
    font-size: 14px;
  }
}

.promo-container .btn:hover {
  cursor: pointer;
}

.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #feedea;
  color: #16181b !important;
}

.ekko-lightbox {
  align-items: center;
  display: flex !important;
  justify-content: center;
  padding-right: 0 !important;
}

.ekko-lightbox-container {
  position: relative;
}

.ekko-lightbox-container>div.ekko-lightbox-item {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.ekko-lightbox iframe {
  height: 100%;
  width: 100%;
}

.ekko-lightbox-nav-overlay {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.ekko-lightbox-nav-overlay a {
  align-items: center;
  color: #fff;
  display: flex;
  flex: 1;
  font-size: 30px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1;
}

.ekko-lightbox-nav-overlay a>* {
  flex-grow: 1;
}

.ekko-lightbox-nav-overlay a> :focus {
  outline: none;
}

.ekko-lightbox-nav-overlay a span {
  padding: 0 30px;
}

.ekko-lightbox-nav-overlay a:last-child span {
  text-align: right;
}

.ekko-lightbox-nav-overlay a:hover {
  text-decoration: none;
}

.ekko-lightbox-nav-overlay a:focus {
  outline: none;
}

.ekko-lightbox-nav-overlay a.disabled {
  cursor: default;
  visibility: hidden;
}

.ekko-lightbox a:hover {
  opacity: 1;
  text-decoration: none;
}

.ekko-lightbox .modal-dialog {
  display: none;
}

.ekko-lightbox .modal-body {
  padding: 0 !important;
}

.ekko-lightbox .modal-footer {
  text-align: left;
}

.ekko-lightbox-loader {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.ekko-lightbox-loader>div {
  height: 40px;
  position: relative;
  text-align: center;
  width: 40px;
}

.ekko-lightbox-loader>div>div {
  -webkit-animation: a 2s ease-in-out infinite;
  animation: a 2s ease-in-out infinite;
  background-color: #fff;
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

.ekko-lightbox-loader>div>div:last-child {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.modal-dialog .ekko-lightbox-loader>div>div {
  background-color: #333;
}

@-webkit-keyframes a {

  0%,
  to {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes a {

  0%,
  to {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.sitemap-col-wrap {
  line-height: 1.2;
}

@media (min-width: 768px) {
  .sitemap-col-wrap {
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (min-width: 992px) {
  .sitemap-col-wrap {
    -moz-column-count: 4;
    column-count: 4;
  }

  .sitemap-col-wrap-sm {
    -moz-column-count: 2;
    column-count: 2;
  }
}

.sitemap-col-wrap-sm li,
.sitemap-col-wrap li {
  padding: 5px 0;
}

@media (min-width: 767px) and (max-width: 769px) {
  div.search--header--filters {
    width: 600px;
  }
}

.saved-school-size {
  width: 68px !important;
}

@media (-ms-high-contrast: none) {
  #eligibility ul li {
    display: block !important;
  }

  #eligibility ul li,
  ::-ms-backdrop {
    display: block !important;
  }

  .invalid-feedback {
    text-align: center;
    width: auto;
  }
}

html body {
  -webkit-overflow-scrolling: touch;
}

html.user-modal-open body {
  -webkit-overflow-scrolling: auto;
  overflow: hidden;
}

@media (max-width: 767px) {
  html.user-modal-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

.purchase-table {
  background-color: transparent !important;
  font-size: 14px;
}

.purchase-table thead tr th {
  background-color: #eef0f1;
  font-weight: 500;
}

@media (max-width: 767px) {
  .purchase-table thead {
    display: none;
  }

  .purchase-table td,
  .purchase-table th,
  .purchase-table tr {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .purchase-table thead tr th:first-of-type {
    width: 40%;
  }

  .purchase-table thead tr th:last-of-type,
  .purchase-table thead tr th:nth-of-type(2),
  .purchase-table thead tr th:nth-of-type(3),
  .purchase-table thead tr th:nth-of-type(4) {
    width: 15%;
  }
}

.purchase-table thead tr th:first-of-type {
  border-top-left-radius: 5px;
}

.purchase-table thead tr th:last-of-type {
  border-top-right-radius: 5px;
}

.purchase-table tbody tr th {
  font-weight: 500;
  line-height: 1.1;
}

.purchase-table tbody tr th>div {
  line-height: 0.9;
  text-decoration: underline;
}

.purchase-table tbody tr td,
.purchase-table tbody tr th {
  background-color: #f6f8fa;
}

.purchase-table tbody tr:last-of-type th {
  border-bottom-left-radius: 5px;
}

.purchase-table tbody tr:last-of-type td.d-md-flex {
  border-bottom-right-radius: 5px;
}

.purchase-table .status-indicator {
  height: 15px;
  min-height: 15px;
  min-width: 15px;
  width: 15px;
}

.purchase-table .status-indicator.active {
  background-color: #c1d7ae;
}

.purchase-table .status-indicator.expired {
  background-color: #fabcbf;
}

.purchase-table .status-indicator.refunded {
  background-color: #fce09d;
}

.body--resource-landing .hero {
  background-position: 50%;
  background-size: cover;
  overflow-x: hidden;
}

@media (max-width: 991.98px) {
  .body--resource-landing .hero h1 {
    font-size: 24px;
  }

  .body--resource-landing .hero .lead {
    font-size: 16px;
    line-height: 1.2;
  }
}

.body--resource-landing .hero .lead {
  line-height: 1.4;
}

@media (min-width: 768px) {
  .body--resource-landing .hero .bg-image {
    right: -50%;
    width: 650px;
  }
}

@media (min-width: 992px) {
  .body--resource-landing .hero .bg-image {
    right: -25%;
  }
}

@media (min-width: 1200px) {
  .body--resource-landing .hero .bg-image {
    right: -15%;
  }
}

@media (min-width: 1400px) {
  .body--resource-landing .hero .bg-image {
    right: -5%;
  }
}

@media (max-width: 767.98px) {

  .body--resource-landing .resource-test-prep-schools .bg-white.back-to-search.btn,
  .body--resource-landing .resource-test-prep-schools .bg-white.flakh,
  .body--resource-landing .resource-test-prep-schools .bg-white.popover.more-info,
  .body--resource-landing .resource-test-prep-schools .site-footer .bg-white.share,
  .site-footer .body--resource-landing .resource-test-prep-schools .bg-white.share {
    background: transparent;
    box-shadow: none;
  }
}

.body--resource-landing .resource-test-prep-schools .btn-group-lg>.btn,
.body--resource-landing .resource-test-prep-schools .btn-lg {
  font-size: 18px;
}

@media (min-width: 768px) {
  .body--resource-landing .school-list-links {
    font-size: 18px;
  }
}

.body--resource-landing .school-list-links a:hover {
  color: #f85a3e !important;
}

.body--resource-landing .school-list-links img {
  max-width: 50px;
}

@media (min-width: 768px) {
  .body--resource-landing .school-list-links img {
    max-width: 100px;
  }
}

.body--resource-landing .resource-downloads {
  background: #fff;
}

@media (max-width: 991.98px) {
  .body--resource-landing .resource-downloads .lead {
    font-size: 16px;
    line-height: 1.2;
  }
}

.body--resource-landing .resource-downloads a:hover {
  cursor: pointer;
}

.body--resource-landing .resource-downloads img.icon {
  width: 100px;
}

@media (min-width: 768px) {
  .body--resource-landing .resource-downloads .d-lg-flex.align-items-lg-center {
    height: 100%;
  }
}

.body--resource-landing .resource-search {
  background-size: cover;
}

.body--resource-landing .resource-search:after {
  background: rgba(10, 15, 30, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.body--resource-landing .resource-search input#EMAIL {
  border: 1px solid #cfd8e5;
  border-radius: 4px;
  height: 50px;
}

.body--resource-landing .resource-search .btn {
  height: 50px;
}

.body--resource-landing .resource-scholarship-video {
  background: #fff;
}

.body--resource-landing .resource-scholarship-video img {
  width: 75%;
}

.body--resource-landing .resource-scholarship-video img:hover {
  cursor: pointer;
}

.body--resource-landing.military .hero {
  background-color: #e5dccc;
}

.body--resource-landing.adult .hero {
  background-color: #3bb6d5;
}

.body--resource-landing.adult .hero h1 {
  color: #fff !important;
}

.body--resource-landing.adult .hero .lead {
  color: hsla(0, 0%, 100%, 0.7) !important;
}

.body--resource-landing.transfer .hero {
  background-color: #b6eaff;
}

.body--resource-landing.transfer .resource-search {
  background-color: #40a9dd;
  background-size: 50%;
}

.body--resource-landing.transfer .resource-search:after {
  display: none;
}

@media (max-width: 767px) {

  .body--resource-landing.transfer .resource-search input,
  .body--resource-landing.transfer .resource-search select {
    border-radius: 0.3rem !important;
  }
}

.body--category-landing .hero {
  background-position: 50%;
  background-size: cover;
}

.body--category-landing .hero h1 {
  font-size: 24px;
}

@media (min-width: 768px) {
  .body--category-landing .hero h1 {
    font-size: 36px;
  }

  .body--category-landing .hero {
    min-height: 375px;
  }
}

.body--category-landing .hero .radial-gradient {
  background: radial-gradient(ellipse at bottom,
      rgba(10, 15, 30, 0.8) 0,
      transparent 100%);
  left: 0;
  top: 0;
  z-index: 4;
}

.body--category-landing .hero .container {
  z-index: 5;
}

@media (min-width: 768px) {
  .body--category-landing .hero .container {
    padding-bottom: 150px;
  }
}

.body--category-landing .hero:after {
  background-color: rgba(10, 15, 30, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.body--category-landing .hero img {
  max-width: 75px;
  opacity: 0.9;
}

@media (min-width: 768px) {
  .body--category-landing .hero img {
    max-width: 125px;
  }
}

.body--category-landing .list {
  z-index: 5;
}

@media (min-width: 768px) {
  .body--category-landing .list {
    margin-top: -100px;
  }
}

.body--category-landing .list .col-lg-6.border-bottom:last-of-type {
  border-bottom: none !important;
}

@media (min-width: 768px) {
  .body--category-landing .list .col-lg-6.border-bottom {
    border-bottom: none !important;
  }
}

@media (min-width: 991px) {
  .body--category-landing .list #schoolList>div:nth-of-type(odd) {
    padding-right: 20px !important;
  }

  .body--category-landing .list #schoolList>div:nth-of-type(2n) {
    padding-left: 20px !important;
  }
}

.body--category-landing .list .school .logo-wrapper {
  height: calc(50px + 1rem);
}

.body--category-landing .list .school img.logo {
  width: calc(50px + 1rem);
}

.body--category-landing .list .school p {
  font-size: 14px;
  font-weight: 500;
}

.body--category-landing .list .noteworthy img {
  max-width: 24px;
}

.body--category-landing .list .noteworthy span {
  font-size: 13px;
  font-style: italic;
  line-height: 1.2;
}

.body--category-landing .list .stats {
  font-size: 12px;
}

.body--category-landing .list .stats .line-height-adj {
  line-height: 1.2;
  margin-top: 2px;
}

.body--category-landing .list .stats strong {
  font-weight: 600;
  line-height: 1.2;
}

.body--category-landing .testprep-ad .row>div:first-of-type {
  background-color: #e1f2fc;
}

@media (max-width: 767px) {
  .body--category-landing .testprep-ad .row>div:first-of-type {
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .body--category-landing .testprep-ad .row>div:first-of-type .laptop {
    transform: scale(1.2);
  }
}

.body--category-landing .testprep-ad ul li {
  line-height: 1.2;
}

.body--category-landing .testprep-ad ul li:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.7 45.3' style='enable-background:new 0 0 48.7 45.3' xml:space='preserve'%3E%3Cpath d='M16.9 45.3c-1.2 0-2.4-.5-3.3-1.4L1.3 31.3c-1.8-1.8-1.8-4.8.1-6.5 1.8-1.8 4.7-1.8 6.5 0l8.6 8.8L40.4 1.8c1.5-2 4.4-2.5 6.5-.9 2 1.5 2.4 4.4.9 6.5l-27.1 36c-.8 1.1-2 1.7-3.4 1.8-.2.1-.3.1-.4.1z' style='fill:%23a9c68b'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  content: "";
  height: 18px;
  left: 0;
  position: absolute;
  width: 18px;
}

.body--category-landing .testprep-ad .starting-at {
  line-height: 1.2;
}

.body--category-landing .testprep-ad .starting-at strong {
  font-size: 20px;
  font-weight: 500;
}

.body--category-landing .testprep-ad .starting-at small {
  font-size: 18px;
  font-weight: 400;
}

.body--category-landing .btn-rmi {
  min-width: 129px;
}

.body--category-landing .client-rmi:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 99.9 99.9' style='enable-background:new 0 0 99.9 99.9' xml:space='preserve'%3E%3Cstyle%3E.st0{fill:%23fff}%3C/style%3E%3Cpath className='st0' d='M80.9 51.2c-3.5 0-6.4 2.8-6.4 6.4v27c0 1.5-1.2 2.7-2.7 2.7H15.4c-1.5 0-2.7-1.2-2.7-2.7V28.1c0-1.5 1.2-2.7 2.7-2.7h27c3.5 0 6.4-2.8 6.4-6.4s-2.8-6.4-6.4-6.4h-27C6.9 12.7 0 19.6 0 28.1v56.5C0 93 6.9 99.9 15.4 99.9h56.5c8.5 0 15.4-6.9 15.4-15.4v-27c-.1-3.4-2.9-6.3-6.4-6.3z'/%3E%3Cpath className='st0' d='M84.6 0H58.2c-3.5 0-6.4 2.8-6.4 6.4s2.8 6.4 6.4 6.4h20L36.1 54.9c-2.5 2.5-2.5 6.5 0 9 1.2 1.2 2.9 1.9 4.5 1.9s3.3-.6 4.5-1.9l42.2-42.2v20c0 3.5 2.8 6.4 6.4 6.4 3.5 0 6.4-2.8 6.4-6.4V15.4C99.9 6.9 93 0 84.6 0z'/%3E%3C/svg%3E");
  content: "";
  display: inline-block;
  height: 15px;
  margin-left: 7px;
  position: relative;
  top: 2px;
  width: 15px;
}

.btn.loading {
  pointer-events: none;
}

.strikethrough {
  font-size: 18px;
  position: relative;
}

.strikethrough:before {
  border-top: 2px solid #7c7c7c;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: calc(50% - 3px);
}

.save.saved {
  pointer-events: none;
}

.save.saved i {
  color: #edae49 !important;
}

#saveSchoolButtonMobileTop .spinner {
  left: 0;
  top: 0;
}

#sib-container {
  background: transparent;
}

.sib-form {
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.sib-form .entry__label {
  color: #212529;
}

.sib-form .entry__field {
  height: 40px;
}

.disableScroll {
  overflow-y: hidden;
}

@media screen and (min-width: 750px) {
  .disableScroll {
    overflow-y: inherit;
  }
}

.body-search:not(.new) .navbar {
  border-bottom-color: #fff;
}

.body-search:not(.new) #content {
  display: block;
  overflow: visible;
}

.body-search:not(.new).filters-active {
  position: fixed;
}

@media (max-width: 767px) {
  .body-search:not(.new).filters-active {
    overflow-y: hidden;
    position: fixed;
  }

  .body-search:not(.new) #searchHeader input[type="search"] {
    width: 100px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .body-search:not(.new) {
    min-width: 500px;
  }
}

.body-search:not(.new) .search .loading-wrapper {
  background-color: #fff !important;
  height: calc(100% - 174px);
  z-index: 9;
}

.body-search:not(.new) .search .loading-wrapper #load {
  -webkit-animation: loading 3s linear infinite;
  animation: loading 3s linear infinite;
  width: 100px;
}

.body-search:not(.new) .search .loading-wrapper #load #loading-inner {
  stroke-dashoffset: 0;
  stroke-dasharray: 300;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke: #f85a3e;
  fill: transparent;
  -webkit-animation: loading-circle 1s linear infinite;
  animation: loading-circle 1s linear infinite;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes loading-circle {
  0% {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -600;
  }
}

@keyframes loading-circle {
  0% {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -600;
  }
}

.body-search:not(.new) .search .search--header .bootstrap-select {
  background-color: transparent !important;
}

@media (max-width: 991px) {

  .body-search:not(.new) .search .search--header .bootstrap-select.online-top-filters>.dropdown-toggle .filter-option:after,
  .body-search:not(.new) .search .search--header .bootstrap-select.scholarship-top-filters:nth-of-type(2) .filter-option:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 501 301.6' style='enable-background:new 0 0 501 301.6' xml:space='preserve'%3E%3Cstyle%3E.st0{fill:%23fff}%3C/style%3E%3Cpath className='st0' d='M497.2 99.6c5.1-2 5.1-5.2 0-7.2L259.8 1.5c-5.1-2-13.5-2-18.7 0L3.9 92.4c-5.1 2-5.1 5.2 0 7.2l237.3 90.9c5.1 2 13.5 2 18.7 0l237.3-90.9z'/%3E%3Cpath className='st0' d='M259.8 222.5c-5.1 2-13.5 2-18.7 0l-138.3-53c-5.1-2-9.3.9-9.3 6.4v56.9c0 5.5 3.5 12.9 7.7 16.4 0 0 62.6 52.4 149.3 52.4 86.7 0 149.3-52.4 149.3-52.4 4.2-3.5 7.7-10.9 7.7-16.4v-56.9c0-5.5-4.2-8.4-9.3-6.4l-138.4 53z'/%3E%3C/svg%3E");
    height: 16px;
  }

  .body-search:not(.new) .search .search--header .bootstrap-select.scholarship-top-filters:first-of-type .filter-option:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 76.8 94.9' style='enable-background:new 0 0 76.8 94.9' xml:space='preserve'%3E%3Cstyle%3E.st0{fill:%23fff}%3C/style%3E%3Cg id='Layer_1'%3E%3Cpath className='st0' d='M25.9 69.7c-2.4 0-4.7-1.4-5.8-3.5l-5-9.9-1.9-.9L.1 83.2c-.4.8.3 1.7 1.2 1.5l13.1-2.5c.5-.1.9.1 1.1.5l6.4 11.6c.4.8 1.5.7 1.9-.1l13.2-28-9.1 3c-.7.3-1.3.5-2 .5zM76.7 83.2 63.6 55.3l-1.9.9-5 9.9c-1.1 2.2-3.3 3.5-5.8 3.5-.7 0-1.4-.1-2-.3l-9.1-3 13.2 28c.4.8 1.5.8 1.9.1l6.4-11.6c.2-.4.7-.6 1.1-.5l13.1 2.5c.9.1 1.6-.8 1.2-1.6z'/%3E%3Cpath className='st0' d='m53.3 64.4 5.2-10.2c.3-.5.7-.9 1.2-1.2l10.2-5.2c1.2-.6 1.7-2 1.3-3.2l-3.5-10.9c-.2-.5-.2-1.1 0-1.7l3.5-10.9c.4-1.3-.2-2.6-1.3-3.2l-10.2-5.2c-.5-.3-.9-.7-1.2-1.2l-5.2-10c-.6-1.2-2-1.7-3.2-1.3L39.2 3.7c-.5.2-1.1.2-1.7 0L26.7.1c-1.3-.4-2.6.2-3.2 1.4l-5.2 10.2c-.3.5-.7.9-1.2 1.2L6.9 18.1c-1.2.6-1.7 2-1.3 3.2l3.5 10.9c.2.5.2 1.1 0 1.7L5.6 44.8c-.4 1.3.2 2.6 1.3 3.2l10.2 5.2c.5.3.9.7 1.2 1.2l5.2 10.2c.6 1.2 2 1.7 3.2 1.3l10.9-3.5c.5-.2 1.1-.2 1.7 0l10.9 3.5c1.2.2 2.5-.3 3.1-1.5z'/%3E%3C/g%3E%3C/svg%3E");
    height: 20px;
  }
}

.body-search:not(.new) .search .search--header .dropdown-menu {
  border: 0 !important;
}

.body-search:not(.new) .search .search--header .dropdown-menu label {
  background-color: transparent !important;
}

.body-search:not(.new) .search .dropdown.location-with-radius .bs-donebutton .btn,
.body-search:not(.new) .search .dropdown.location-with-radius .bs-searchbox input,
.body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu .bs-searchbox input,
.body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle {
  border-radius: 0.25rem !important;
}

.body-search:not(.new) .search .profile .key>div>span,
.body-search:not(.new) .rounded-circle,
.profile .body-search:not(.new) .search .key>div>span {
  border-radius: 50% !important;
}

.body-search:not(.new) .search .search--header .bootstrap-select.open .dropdown-menu.inner,
.body-search:not(.new) .search .search--header .bootstrap-select.open>.dropdown-menu {
  display: block !important;
}

.body-search:not(.new) .search .d-flex,
.body-search:not(.new) .search .info-box-wrapper .info-box,
.body-search:not(.new) .search .pagination .page-item .page-link,
.body-search:not(.new) .search .profile--main ul li,
.body-search:not(.new) .search .profile .header-facts .calendar--day,
.body-search:not(.new) .search .profile .header-facts .calendar--label,
.body-search:not(.new) .search .profile .key>div,
.body-search:not(.new) .search .profile .related-test-prep .card .key>div>span,
.body-search:not(.new) .search .profile.scholarship .header-facts--more-info ul li,
.body-search:not(.new) .search .profile header .profile-title--logo,
.body-search:not(.new) .search .related-test-prep .card .profile .key>div>span,
.body-search:not(.new) .search .related-test-prep .card .rounded-circle,
.body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle .filter-option,
.info-box-wrapper .body-search:not(.new) .search .info-box,
.pagination .page-item .body-search:not(.new) .search .page-link,
.profile--main ul .body-search:not(.new) .search li,
.profile .body-search:not(.new) .search .key>div,
.profile .header-facts .body-search:not(.new) .search .calendar--day,
.profile .header-facts .body-search:not(.new) .search .calendar--label,
.profile .related-test-prep .card .body-search:not(.new) .search .key>div>span,
.profile.scholarship .header-facts--more-info ul .body-search:not(.new) .search li,
.profile header .body-search:not(.new) .search .profile-title--logo,
.related-test-prep .card .body-search:not(.new) .search .rounded-circle,
.related-test-prep .card .profile .body-search:not(.new) .search .key>div>span {
  display: flex !important;
}

.body-search:not(.new) .search .justify-content-center,
.body-search:not(.new) .search .pagination .page-item .page-link,
.body-search:not(.new) .search .profile .header-facts .calendar--day,
.body-search:not(.new) .search .profile .header-facts .calendar--label,
.body-search:not(.new) .search .profile .related-test-prep .card .key>div>span,
.body-search:not(.new) .search .profile header .profile-title--logo,
.body-search:not(.new) .search .related-test-prep .card .profile .key>div>span,
.body-search:not(.new) .search .related-test-prep .card .rounded-circle,
.pagination .page-item .body-search:not(.new) .search .page-link,
.profile .header-facts .body-search:not(.new) .search .calendar--day,
.profile .header-facts .body-search:not(.new) .search .calendar--label,
.profile .related-test-prep .card .body-search:not(.new) .search .key>div>span,
.profile header .body-search:not(.new) .search .profile-title--logo,
.related-test-prep .card .body-search:not(.new) .search .rounded-circle,
.related-test-prep .card .profile .body-search:not(.new) .search .key>div>span {
  justify-content: center !important;
}

.body-search:not(.new) .search .align-items-center,
.body-search:not(.new) .search .info-box-wrapper .info-box,
.body-search:not(.new) .search .pagination .page-item .page-link,
.body-search:not(.new) .search .profile .header-facts .calendar--day,
.body-search:not(.new) .search .profile .header-facts .calendar--label,
.body-search:not(.new) .search .profile .key>div,
.body-search:not(.new) .search .profile .related-test-prep .card .key>div>span,
.body-search:not(.new) .search .profile.scholarship .header-facts--more-info ul li,
.body-search:not(.new) .search .profile header .profile-title--logo,
.body-search:not(.new) .search .related-test-prep .card .profile .key>div>span,
.body-search:not(.new) .search .related-test-prep .card .rounded-circle,
.body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle .filter-option,
.info-box-wrapper .body-search:not(.new) .search .info-box,
.pagination .page-item .body-search:not(.new) .search .page-link,
.profile .body-search:not(.new) .search .key>div,
.profile .header-facts .body-search:not(.new) .search .calendar--day,
.profile .header-facts .body-search:not(.new) .search .calendar--label,
.profile .related-test-prep .card .body-search:not(.new) .search .key>div>span,
.profile.scholarship .header-facts--more-info ul .body-search:not(.new) .search li,
.profile header .body-search:not(.new) .search .profile-title--logo,
.related-test-prep .card .body-search:not(.new) .search .rounded-circle,
.related-test-prep .card .profile .body-search:not(.new) .search .key>div>span {
  align-items: center !important;
}

.body-search:not(.new) .search .search--header .dropdown-menu label {
  margin-bottom: 0 !important;
}

.body-search:not(.new) .search .search--header .bootstrap-select {
  margin-right: 0.5rem !important;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu,
.body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu,
.body-search:not(.new) .search .search--header .dropdown-menu {
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
}

.body-search:not(.new) .search .search--header .bootstrap-select.dropdown.location-with-radius .bs-donebutton {
  bottom: 20px;
  padding: 0;
  position: fixed;
}

.body-search:not(.new) .search .search--header .bootstrap-select.open>.dropdown-menu {
  overflow: hidden;
}

.body-search:not(.new) .search .search--header .bootstrap-select div[role="listbox"] {
  overflow-x: hidden;
}

.body-search:not(.new) .search .search--header .bootstrap-select .bs-searchbox {
  padding: 0 !important;
}

.body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle .filter-option {
  overflow: hidden;
}

.body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle .filter-option .filter-option-inner {
  width: 100%;
}

.body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle .filter-option .filter-option-inner-inner {
  display: none;
}

.body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu {
  border: none !important;
  margin-top: 5px !important;
  z-index: 99;
}

.body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu .bs-searchbox input {
  border: 1px solid #ced9e5;
}

.body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu .bs-searchbox input:focus {
  border: 1px solid #ced9e5;
  box-shadow: none;
}

.body-search:not(.new) .search .search--header .bootstrap-select .dropdown-item:active {
  background-color: transparent;
  color: #0f1319;
}

.body-search:not(.new) .search .search--header .bootstrap-select .dropdown-item:active:focus {
  outline: 0;
}

.body-search:not(.new) .search .search--header .dropdown-menu {
  min-width: 250px;
}

.body-search:not(.new) .search .search--header .dropdown-menu label:after,
.body-search:not(.new) .search .search--header .dropdown-menu label:before {
  margin-top: 0.063em;
}

.body-search:not(.new) .search .search--header button.dropdown-toggle {
  background: #fff;
  box-shadow: 0 3px 5px 0 rgba(232, 56, 27, 0.2);
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
}

.body-search:not(.new) .search .search--header button.dropdown-toggle:active,
.body-search:not(.new) .search .search--header button.dropdown-toggle:focus {
  box-shadow: 0 3px 5px 0 rgba(232, 56, 27, 0.3);
  color: #0f1319;
}

@media (min-width: 768px) {

  .body-search:not(.new) .search .search--header .dropdown.location-with-radius .bs-searchbox,
  .body-search:not(.new) .search .search--header .dropdown.location-with-radius .popover-header,
  .body-search:not(.new) .search .search--header .dropdown.location-with-radius div[role="listbox"] {
    padding-left: 25px !important;
  }

  .body-search:not(.new) .search .search--header .dropdown.location-with-radius .inner {
    max-height: 364px !important;
  }

  .body-search:not(.new) .search .search--header .dropdown.location-with-radius .radius-wrapper {
    padding-right: 25px !important;
  }

  .body-search:not(.new) .search .search--header .bootstrap-select.dropdown.location-with-radius .bs-donebutton {
    bottom: 0;
    margin-left: 23px;
    margin-top: 15px;
    position: static;
  }
}

@media (max-width: 767px) {
  .body-search:not(.new) .search .search--header .bootstrap-select.dropdown.location-with-radius.open>.dropdown-menu {
    height: 100%;
    margin: 0 !important;
    overflow: hidden;
    padding-top: 0 !important;
    position: fixed;
    top: 0 !important;
    width: 100%;
    z-index: 999;
  }

  .body-search:not(.new) .search .search--header .bootstrap-select.dropdown.location-with-radius.open>.dropdown-menu .radius-wrapper {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  .body-search:not(.new) .search .search--header .bootstrap-select.open>.dropdown-menu {
    max-height: 500px;
    padding: 0;
  }

  .body-search:not(.new) .search .search--header .bootstrap-select.open>.dropdown-menu ul {
    margin-top: 0 !important;
  }

  .body-search:not(.new) .search .search--header .bootstrap-select.open>.dropdown-menu ul li a.opt {
    padding-left: 1.5rem;
  }
}

@media (max-width: 767px) {
  .body-search:not(.new) .search .search--header .bootstrap-select div[role="listbox"] {
    height: calc(100% - 270px);
  }
}

@media (max-width: 991px) {
  .body-search:not(.new) .search .search--header .bootstrap-select {
    flex: none;
    width: 42px;
  }

  .body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle {
    background-color: transparent !important;
    height: 42px;
    width: 42px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle {
    height: 56px;
  }
}

@media (max-width: 991px) {
  .body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle .filter-option:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 90' style='enable-background:new 0 0 60 90' xml:space='preserve'%3E%3Cpath d='M30 0C13.4 0 0 13.4 0 30c0 30 30 60 30 60s30-30 30-60C60 13.4 46.6 0 30 0zm0 45c-8.3 0-15-6.7-15-15s6.7-15 15-15 15 6.7 15 15-6.7 15-15 15z' style='fill:%23fff'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    content: "";
    height: 24px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle .filter-option .filter-option-inner-inner {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .body-search:not(.new) .search .search--header .bootstrap-select>.dropdown-toggle:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu {
    border-radius: 0 !important;
    margin-left: -15px;
    overflow: visible !important;
    top: 45px !important;
    width: 100vw;
  }

  .body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu:after {
    border-bottom: 6px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: "";
    left: 31px;
    position: absolute;
    top: -6px;
    z-index: 1;
  }
}

@media (min-width: 768px) {
  .body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .body-search:not(.new) .search .search--header .bootstrap-select .dropdown-menu .bs-searchbox {
    padding: 0 15px;
  }
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu {
  border: none !important;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-item:active {
  background-color: inherit !important;
  color: inherit !important;
}

.body-search:not(.new) .search .dropdown.location-with-radius .or {
  background-color: #fff !important;
  border: 1px solid #dfe6ee;
  bottom: -45px;
  color: #3f5069;
  font-size: 13px;
  height: 40px;
  position: absolute;
  right: calc(50% - 20px);
  width: 40px;
  z-index: 9;
}

.body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper {
  border-bottom: 1px solid #dfe6ee;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper input[type="text"] {
  padding-left: 8px;
}

.body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper input[type="text"]::-moz-placeholder {
  color: #bfc7cf;
}

.body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper input[type="text"]:-ms-input-placeholder {
  color: #bfc7cf;
}

.body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper input[type="text"]::placeholder {
  color: #bfc7cf;
}

.body-search:not(.new) .search .dropdown.location-with-radius>.dropdown-menu {
  margin-top: 5px !important;
  padding: 20px;
}

.body-search:not(.new) .search .dropdown.location-with-radius .bs-searchbox input {
  border: 1px solid #ced9e5;
}

.body-search:not(.new) .search .dropdown.location-with-radius .bs-searchbox input:focus {
  border: 1px solid #ced9e5;
  box-shadow: none;
}

.body-search:not(.new) .search .dropdown.location-with-radius .popover-header {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 !important;
}

.body-search:not(.new) .search .dropdown.location-with-radius .popover-header .close {
  pointer-events: visible !important;
  position: absolute;
  right: 15px;
  top: 10px;
}

.body-search:not(.new) .search .dropdown.location-with-radius .bs-donebutton {
  bottom: 18px;
  display: block;
  position: fixed;
  width: calc(100% - 50px);
}

.body-search:not(.new) .search .dropdown.location-with-radius .bs-donebutton .btn {
  background-color: #f85a3e;
  border: none;
  color: #fff;
  font-size: 15px;
  padding: 10px !important;
}

@media (min-width: 768px) {
  .body-search:not(.new) .search .dropdown.location-with-radius .or {
    bottom: calc(50% - 20px);
  }
}

@media (min-width: 992px) {
  .body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper {
    height: 9999px;
  }
}

@media (max-width: 767px) {
  .body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper {
    position: relative;
  }
}

@media (min-width: 768px) {
  .body-search:not(.new) .search .dropdown.location-with-radius .radius-wrapper {
    border-bottom: none;
    border-left: 1px solid #dfe6ee;
    float: right;
    margin-bottom: 0;
    min-height: 500px !important;
    padding-bottom: 0;
    padding-left: 25px;
    width: 50% !important;
  }

  .body-search:not(.new) .search .dropdown.location-with-radius>.dropdown-menu {
    max-height: 500px !important;
    width: 700px !important;
  }

  .body-search:not(.new) .search .dropdown.location-with-radius .bs-searchbox,
  .body-search:not(.new) .search .dropdown.location-with-radius .inner {
    float: left;
    padding: 0 !important;
  }

  .body-search:not(.new) .search .dropdown.location-with-radius .bs-searchbox {
    margin-bottom: 15px !important;
    padding-right: 25px !important;
    width: 50% !important;
  }

  .body-search:not(.new) .search .dropdown.location-with-radius .inner {
    max-height: 355px !important;
    width: calc(50% - 25px) !important;
  }

  .body-search:not(.new) .search .dropdown.location-with-radius .popover-header {
    padding: 20px 0 0 !important;
  }

  .body-search:not(.new) .search .dropdown.location-with-radius .bs-donebutton {
    width: 305px;
  }
}

.body-search:not(.new) .search .bootstrap-select>.dropdown-toggle {
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: 100%;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.body-search:not(.new) .search .bootstrap-select.form-control {
  border: none;
  margin-bottom: 0;
  padding: 0;
}

.body-search:not(.new) .search .bootstrap-select:not(.input-group-btn) {
  display: inline-block;
  float: none;
  margin-left: 0;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-toggle:before {
  content: "";
  display: inline-block;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-toggle .filter-option {
  height: 100%;
  left: 0;
  padding: inherit;
  position: absolute;
  text-align: left;
  top: 0;
  width: 100%;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-toggle .caret {
  margin-top: -2px;
  position: absolute;
  right: 12px;
  top: 50%;
  vertical-align: middle;
}

.body-search:not(.new) .search .input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu {
  box-sizing: border-box;
  min-width: 100%;
  z-index: 9;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu>.inner:focus {
  outline: 0 !important;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu.inner {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  float: none;
  margin: 0;
  padding: 0;
  position: static;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu li {
  position: relative;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu li.selected a span.check-mark {
  display: inline-block;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu li a.opt {
  align-items: center !important;
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 2.25em;
  position: relative;
  width: 100% !important;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu li a span.check-mark {
  display: none;
  order: 1;
}

.body-search:not(.new) .search .bootstrap-select .dropdown-menu li a span.text {
  display: inline-block;
}

.body-search:not(.new) .search .bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.body-search:not(.new) .search .bootstrap-select .bs-ok-default:after {
  border-color: #f85a3e;
  border-style: solid;
  border-width: 0 0.13em 0.13em 0;
  content: "";
  display: block;
  height: 1em;
  transform: rotate(45deg);
  width: 0.5em;
}

.body-search:not(.new) .search .bs-searchbox {
  display: none;
}

.body-search:not(.new) .search .bs-donebutton {
  box-sizing: border-box;
  display: none;
  float: left;
  width: 100%;
}

.body-search:not(.new) .search .bs-donebutton .btn-group button {
  width: 100%;
}

@media (max-width: 991px) {

  .body-search:not(.new) .search .bs-donebutton,
  .body-search:not(.new) .search .bs-searchbox {
    display: none;
    padding: 0 8px 8px;
  }
}

.body-search:not(.new) .search .bs-searchbox .form-control {
  float: none;
  margin-bottom: 0;
  width: 100%;
}

.body-search:not(.new) .search .dropdown-item:active,
.body-search:not(.new) .search .dropdown-item:focus,
.body-search:not(.new) .search .dropdown-item:hover {
  background-color: #feedea;
}

.body-search:not(.new) .search .more-info {
  bottom: 48px;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
  left: 50px;
}

.body-search:not(.new) .search .more-info:after {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 10px 10px 0;
  bottom: -10px;
  content: "";
  display: block;
  left: 50%;
  margin-left: -10px;
  position: absolute;
}

.body-search:not(.new) .search .more-info .invalid-feedback {
  display: block;
  margin-top: -5px;
}

.body-search:not(.new) .search .their-form {
  color: #46ac71;
}

.body-search:not(.new) .search .their-form:active,
.body-search:not(.new) .search .their-form:focus,
.body-search:not(.new) .search .their-form:hover {
  box-shadow: none;
  outline: 0;
}

.body-search:not(.new) .search .their-form:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 99.9 99.9' style='enable-background:new 0 0 99.9 99.9' xml:space='preserve'%3E%3Cstyle%3E.st0{fill:%2395bd4d}%3C/style%3E%3Cpath className='st0' d='M80.9 51.2c-3.5 0-6.4 2.8-6.4 6.4v27c0 1.5-1.2 2.7-2.7 2.7H15.4c-1.5 0-2.7-1.2-2.7-2.7V28.1c0-1.5 1.2-2.7 2.7-2.7h27c3.5 0 6.4-2.8 6.4-6.4s-2.8-6.4-6.4-6.4h-27C6.9 12.7 0 19.6 0 28.1v56.5C0 93 6.9 99.9 15.4 99.9h56.5c8.5 0 15.4-6.9 15.4-15.4v-27c-.1-3.4-2.9-6.3-6.4-6.3z'/%3E%3Cpath className='st0' d='M84.6 0H58.2c-3.5 0-6.4 2.8-6.4 6.4s2.8 6.4 6.4 6.4h20L36.1 54.9c-2.5 2.5-2.5 6.5 0 9 1.2 1.2 2.9 1.9 4.5 1.9s3.3-.6 4.5-1.9l42.2-42.2v20c0 3.5 2.8 6.4 6.4 6.4 3.5 0 6.4-2.8 6.4-6.4V15.4C99.9 6.9 93 0 84.6 0z'/%3E%3C/svg%3E");
  content: "";
  height: 18px;
  margin-left: 7px;
  margin-top: 1px;
  position: absolute;
  width: 18px;
}

.body-search:not(.new) .search .success-message {
  height: 100%;
  left: 0;
}

.body-search:not(.new) .search--header {
  background: #f85a3e;
  z-index: 9;
}

@media (max-width: 767px) {
  .body-search:not(.new) .search--header {
    height: 58px;
  }
}

.body-search:not(.new) .search--header input::-moz-placeholder {
  color: hsla(0, 0%, 100%, 0.6);
  opacity: 1;
}

.body-search:not(.new) .search--header input:-ms-input-placeholder {
  opacity: 1;
}

.body-search:not(.new) .search--header input::placeholder {
  color: hsla(0, 0%, 100%, 0.6);
  opacity: 1;
}

.body-search:not(.new) .search--header input:-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.6);
}

.body-search:not(.new) .search--header input:focus::-moz-placeholder {
  color: hsla(0, 0%, 100%, 0.6);
  opacity: 1;
}

.body-search:not(.new) .search--header input:focus:-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.6);
  opacity: 1;
}

.body-search:not(.new) .search--header input:focus::placeholder {
  color: hsla(0, 0%, 100%, 0.6);
  opacity: 1;
}

.body-search:not(.new) .search--header input:focus:-ms-input-placeholder {
  color: #0f1319;
}

.body-search:not(.new) .search--header input:focus::-ms-input-placeholder {
  color: #0f1319;
}

.body-search:not(.new) .search--header #searchHeader input[type="search"],
.body-search:not(.new) .search--header .more-filters,
.body-search:not(.new) .search--header button.dropdown-toggle {
  background: #fff;
  box-shadow: 0 3px 5px 0 rgba(232, 56, 27, 0.2);
  font-family: Nunito Sans, sans-serif, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
}

.body-search:not(.new) .search--header #searchHeader input[type="search"]:active,
.body-search:not(.new) .search--header #searchHeader input[type="search"]:focus,
.body-search:not(.new) .search--header .more-filters:active,
.body-search:not(.new) .search--header .more-filters:focus,
.body-search:not(.new) .search--header button.dropdown-toggle:active,
.body-search:not(.new) .search--header button.dropdown-toggle:focus {
  box-shadow: 0 3px 5px 0 rgba(232, 56, 27, 0.3);
  color: #0f1319;
}

@media (min-width: 768px) {

  .body-search:not(.new) .search--header #searchHeader input[type="search"],
  .body-search:not(.new) .search--header .more-filters,
  .body-search:not(.new) .search--header button.dropdown-toggle {
    height: 56px;
  }
}

.body-search:not(.new) .search--header .more-filters {
  background: 0 0;
}

.body-search:not(.new) .search--header .search-submit img {
  width: 1rem;
}

.body-search:not(.new) .search--header--filters:empty {
  display: none !important;
}

.body-search:not(.new) .search--header--filters>span a {
  display: none;
}

.body-search:not(.new) .search--header--filters>span:first-of-type a {
  display: none !important;
}

.body-search:not(.new) .search--header--filters>span:last-of-type a {
  display: inline-block;
}

.body-search:not(.new) .search--header--filters .badge {
  align-items: center !important;
  background-color: #fff;
  color: #0f1319;
  display: inline-flex !important;
  font-size: 0.875rem;
  font-weight: 400;
  margin-right: 0.25rem !important;
  margin-top: 0.25rem !important;
  opacity: 0.9;
  padding: 0.5rem 1rem !important;
}

.body-search:not(.new) .search--header--filters .badge:hover {
  cursor: pointer;
  opacity: 1;
}

.body-search:not(.new) .search--header--filters .badge .close {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70.6 70.6' style='enable-background:new 0 0 70.6 70.6' xml:space='preserve'%3E%3Cpath d='M42.4 35.3 69.1 8.6c2-2 2-5.2 0-7.1-2-2-5.2-2-7.1 0L35.3 28.2 8.6 1.5c-2-2-5.2-2-7.1 0-2 2-2 5.2 0 7.1l26.7 26.7L1.5 62c-2 2-2 5.2 0 7.1 1 1 2.3 1.5 3.6 1.5 1.3 0 2.6-.5 3.6-1.5l26.7-26.7L62 69.1c1 1 2.3 1.5 3.6 1.5 1.3 0 2.6-.5 3.6-1.5 2-2 2-5.2 0-7.1L42.4 35.3z' style='fill:%230f1319'/%3E%3C/svg%3E");
  height: 9px;
  margin-left: 10px;
  opacity: 1;
  width: 9px;
}

.body-search:not(.new) .search .openSubMenu {
  background-color: #fff !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px solid #dee2e6 !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.3s;
  width: 100%;
  z-index: -9;
}

.body-search:not(.new) .search .openSubMenu.popped {
  left: -100%;
  opacity: 1;
}

.body-search:not(.new) .search .openSubMenu .custom-control-label:after {
  height: 20px;
  left: -26px;
  right: 15px;
  top: 3px;
  width: 20px;
}

.body-search:not(.new) .search .openSubMenu .h5 {
  padding-top: 7px;
}

.body-search:not(.new) .search .dropdownMenuFilters.left-sharp {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.body-search:not(new) .search .dropdownMenuFilters li {
  cursor: pointer;
  padding-top: 0.3125rem !important;
  transition: 0.15s;
}

.body-search:not(.new) .search .dropdownMenuFilters li.active,
.body-search:not(.new) .search .dropdownMenuFilters li:hover {
  background: #f85a3e;
  border-radius: 3px;
  color: #fff;
  padding-left: 10px;
}

.body-search:not(.new) .search .more-filters-wrapper .custom-radio .active+.custom-control-label:before {
  background-color: #f85a3e;
  border-radius: 50%;
}

.body-search:not(.new) .search .more-filters-wrapper .custom-radio .active+.custom-control-label:after {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  height: 6px;
  margin-left: 7px;
  margin-top: 7px;
  width: 6px;
}

@media (max-width: 767px) {
  .body-search:not(.new) .search .more-filters-wrapper {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 9;
  }

  .body-search:not(.new) .search .more-filters-wrapper .more-filters {
    background: 0 0;
    box-shadow: none;
    color: #0f1319;
  }

  .body-search:not(.new) .search .more-filters-wrapper .more-filters:before {
    background-size: 20px 20px;
    content: "";
    height: 20px;
    left: 0;
    margin-right: 10px;
    top: 0;
    width: 20px;
  }

  .body-search:not(.new) .search .more-filters-wrapper .more-filters:after {
    display: none;
  }

  .body-search:not(.new) .search .more-filters-wrapper .more-filters[aria-expanded="true"]:after {
    border-bottom: 9px solid #fff;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: transparent;
    bottom: -1px;
    content: "";
    display: inline-block;
    position: absolute;
    right: 20px;
    z-index: 1;
  }

  .body-search:not(.new) .search .more-filters-wrapper .dropdown-menu {
    border-radius: 0;
    bottom: 0;
    box-shadow: none;
    left: 0 !important;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed !important;
    top: 165px !important;
    transform: none !important;
    width: 100vw;
  }

  .body-search:not(.new) .search .more-filters-wrapper .dropdown-menu>ul,
  .body-search:not(.new) .search .more-filters-wrapper .toggle {
    display: none;
  }

  .body-search:not(.new) .search .more-filters-wrapper .content,
  .body-search:not(.new) .search .more-filters-wrapper .title {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: all 0.2s;
  }

  .body-search:not(.new) .search .more-filters-wrapper .title:after,
  .body-search:not(.new) .search .more-filters-wrapper .title:before {
    background-color: #0f1319;
    content: "";
    height: 0.75em;
    position: absolute;
    right: 1.25em;
    top: 22px;
    transition: all 0.2s;
    width: 2px;
  }

  .body-search:not(.new) .search .more-filters-wrapper .title:after {
    transform: rotate(90deg);
  }

  .body-search:not(.new) .search .more-filters-wrapper .content {
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
  }

  .body-search:not(.new) .search .more-filters-wrapper .content p {
    font-size: 0.9em;
    line-height: 1.5;
    margin: 0;
    padding: 0.5em 1em 1em;
  }

  .body-search:not(.new) .search .more-filters-wrapper .toggle:checked+.title,
  .body-search:not(.new) .search .more-filters-wrapper .toggle:checked+.title+.content {
    max-height: 500px;
    overflow-y: auto;
  }

  .body-search:not(.new) .search .more-filters-wrapper .toggle:checked+.title:before {
    transform: rotate(90deg) !important;
  }

  .body-search:not(.new) .search .more-filters-wrapper .custom-control {
    padding-left: 36px;
  }

  .body-search:not(.new) .search .more-filters-wrapper .custom-control-label:after,
  .body-search:not(.new) .search .more-filters-wrapper .custom-control-label:before {
    background-color: #fff;
    border: 1px solid #feedea;
    border-radius: 0;
    height: 24px;
    left: -36px;
    margin-top: 0;
    top: 0;
    width: 24px;
  }

  .body-search:not(.new) .search .more-filters-wrapper .custom-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23F85A3E' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }

  .body-search:not(.new) .search .more-filters-wrapper .custom-radio .custom-control-label:after {
    border-radius: 50%;
    content: "";
  }

  .body-search:not(.new) .search .more-filters-wrapper .custom-radio .active+.custom-control-label:before {
    background-color: #fff;
  }

  .body-search:not(.new) .search .more-filters-wrapper .custom-radio .active+.custom-control-label:after {
    background-color: #f85a3e;
    height: 8px;
    margin-left: 8px;
    margin-top: 9px;
    width: 8px;
  }

  .body-search:not(.new) .search .more-filters-wrapper .openSubMenu {
    border-right: none !important;
    box-shadow: none;
    height: auto;
    opacity: 1;
    position: relative;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .body-search:not(.new) .search .more-filters-wrapper .more-filters {
    box-shadow: none;
    color: #0f1319;
  }

  .body-search:not(.new) .search .more-filters-wrapper .more-filters[aria-expanded="true"] {
    background-color: transparent;
  }

  .body-search:not(.new) .search .more-filters-wrapper .dropdown-menu {
    margin-top: 5px !important;
  }
}

.body-search:not(.new) .search .more-filters .filter-count {
  align-items: center;
  background-color: #f85a3e;
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 24px;
  justify-content: center;
  left: -2px;
  margin-right: 10px;
  position: absolute;
  width: 24px;
}

@media (min-width: 992px) {
  .body-search:not(.new) .search .more-filters .filter-count {
    left: 22px;
  }
}

@media (min-width: 768px) {
  .body-search:not(.new) .search .more-filters {
    color: #fff;
    height: 56px;
  }

  .body-search:not(.new) .search .more-filters:active,
  .body-search:not(.new) .search .more-filters:focus,
  .body-search:not(.new) .search .more-filters:hover {
    color: #fff;
  }

  .body-search:not(.new) .search .more-filters:before {
    background-size: 20px 20px;
    content: "";
    height: 20px;
    left: 0;
    margin-right: 10px;
    top: 0;
    width: 20px;
  }

  .body-search:not(.new) .search .more-filters[aria-expanded="true"] {
    background-color: #fff;
    color: #0f1319;
  }
}

.body-search:not(.new) .search .sorting-options .per-page-wrapper {
  display: inline-block;
  position: relative;
}

.body-search:not(.new) .search .sorting-options .per-page-wrapper:before {
  background: #fff;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  content: "";
  display: block;
  height: 30px;
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 3px;
  width: 22px;
}

.body-search:not(.new) .search .sorting-options .per-page-wrapper:after {
  border-color: #0f1319 transparent transparent;
  border-style: solid;
  border-width: 6.9px 4px 0;
  content: " ";
  height: 0;
  margin-top: -3px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  width: 0;
  z-index: 2;
}

.body-search:not(.new) .search .sorting-options .per-page-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #dfe6ee;
  border-radius: 3px;
  height: 36px;
  padding: 0 30px 0 15px;
}

@media (min-width: 768px) {
  .body-search:not(.new) .search .result-wrapper {
    width: calc(100% - 350px);
  }
}

@media (max-width: 767px) {
  .body-search:not(.new) .search .result-wrapper .h4 {
    font-size: 15px;
  }
}

.body-search:not(.new) .search .featured {
  background-color: #fdfdfe;
}

.body-search:not(.new) .search .featured .featured-text {
  color: #526889;
  font-size: 14px;
  left: 15px;
  top: -12px;
}

.body-search:not(.new) .search .featured .search--results--card .logo {
  border: none;
  height: 75px;
  min-height: 75px;
  min-width: 75px;
  width: 75px;
}

@media (max-width: 991px) {
  .body-search:not(.new) .search .result-count {
    background-color: #f0f3f7;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .body-search:not(.new) .search .result-count:after,
  .body-search:not(.new) .search .result-count:before {
    background-color: #f0f3f7;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .body-search:not(.new) .search .result-count:before {
    left: -100%;
  }

  .body-search:not(.new) .search .result-count:after {
    right: -100%;
  }

  .body-search:not(.new) .search .result-count>div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75%;
  }
}

.body-search:not(.new) .search .result-count {
  position: fixed;
  top: 118px;
  z-index: 9;
}

@media (min-width: 768px) and (max-width: 991px) {
  .body-search:not(.new) .search .result-count {
    top: 72px;
  }
}

.body-search:not(.new) .search--results {
  margin: 0 15px;
}

.body-search:not(.new) .search--results--card {
  align-items: center !important;
  display: flex !important;
  transition: all 0.2s;
}

.body-search:not(.new) .search--results--card [class*="col-"] {
  padding: 0 !important;
}

@media (max-width: 767px) {
  .body-search:not(.new) .search--results--card [class*="col-"] {
    height: 31px;
    margin-left: calc(60px + 0.5rem);
  }

  .body-search:not(.new) .search--results--card [class*="col-"] .body-search:not(.new) .search--results--card [class*="col-"] .share {
    order: 1;
    position: absolute;
    right: 0;
  }

  .body-search:not(.new) search--results--card [class*="col-"] a.btn-rmi,
  .body-search:not(.new) .search--results--card [class*="col-"] a.their-form {
    font-size: 13px;
    order: 0;
    padding: 5px 10px;
  }

  .body-search:not(.new) .search--results--card [class*="col-"] a.btn-rmi.featured-info-btn,
  .body-search:not(.new) .search--results--card [class*="col-"] a.their-form.featured-info-btn {
    margin-left: 0 !important;
  }

  .body-search:not(.new) .search--results--card [class*="col-"] a.their-form {
    padding-left: 0;
  }

  .body-search:not(.new) .search--results--card [class*="col-"] a.their-form:after {
    height: 15px;
    margin-top: 2px;
    width: 15px;
  }

  .body-search:not(.new) .search--results--card .card-body {
    padding: 0;
  }

  .body-search:not(.new) .search--results--card .card-body h5,
  .body-search:not(.new) .search--results--card .card-body p {
    font-size: 15px;
  }
}

.body-search:not(.new) .search--results--card .state:before {
  content: ",";
  margin-left: -3px;
  margin-right: 3px;
}

.body-search:not(.new) .search--results--card .badge {
  font-size: 11px;
  padding: 4px 8px;
}

@media (min-width: 768px) {
  .body-search:not(.new) .search--results--card .badge {
    font-size: 14px;
    padding: 5px 12px 6px;
  }
}

.body-search:not(.new) .search--results--card .logo {
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  width: 60px;
}

@media (min-width: 768px) {
  .body-search:not(.new) .search--results--card .logo {
    height: 80px;
    min-height: 80px;
    min-width: 80px;
    width: 80px;
  }
}

@media (min-width: 992px) {
  .body-search:not(.new) .search--results--card .logo {
    height: 100px;
    min-height: 100px;
    min-width: 100px;
    width: 100px;
  }
}

.body-search:not(.new) .search--results--card .logo:not(.default) {
  border: 1px solid #dfe6ee;
}

.body-search:not(.new) .search--results--card .logo.default {
  background: #f0f3f7;
}

.body-search:not(.new) .search--results--card .logo.default img {
  height: 40px;
  opacity: 0.2;
  width: 40px;
}

@media (min-width: 768px) {
  .body-search:not(.new) .search--results--card .logo.default img {
    height: 56px;
    width: 60px;
  }
}

@media (min-width: 992px) {
  .body-search:not(.new) .search--results--card .logo.default img {
    height: 75px;
    width: 75px;
  }
}

.body-search:not(.new) .search--results--card .custom-control-label {
  color: #fff;
}

.body-search:not(.new) .search--results--card .custom-control-label:after,
.body-search:not(.new) .search--results--card .custom-control-label:before {
  display: none;
}

.body-search:not(.new) .search--results--card .shown-filters {
  font-size: 14px;
}

.body-search:not(.new) .search.ug #areaofstudyFilterSub {
  overflow-y: scroll;
}

@media (min-width: 768px) {

  .body-search:not(.new) .search.grad .search--header .dropdown-menu,
  .body-search:not(.new) .search.grad .search--header .dropdown-menu .list-unstyled {
    min-height: 345px !important;
  }
}

.body-search:not(.new) .search.grad #areaofstudyFilterSub {
  overflow-y: scroll;
}

@media (min-width: 768px) {

  .body-search:not(.new) .search.online .search--header .dropdownMenuFilters,
  .body-search:not(.new) .search.online .search--header .dropdownMenuFilters .list-unstyled {
    min-height: 345px !important;
  }
}

.body-search:not(.new) .search.online #areaofstudySub {
  overflow-y: scroll;
}

@media (min-width: 992px) {
  .body-search:not(.new) .search.scholarship .search--header .bootstrap-select {
    min-width: 165px;
  }

  .body-search:not(.new) .search.scholarship .search--header .bootstrap-select:nth-last-of-type(2) {
    min-width: 175px;
  }

  .body-search:not(.new) .search.scholarship .search--header .bootstrap-select>.dropdown-menu {
    padding: 0 !important;
  }

  .body-search:not(.new) .search.scholarship .search--header .bootstrap-select .inner {
    margin-top: 0 !important;
  }

  .body-search:not(.new) .search.scholarship .search--header .dropdownMenuFilters,
  .body-search:not(.new) .search.scholarship .search--header .dropdownMenuFilters .list-unstyled {
    min-height: 345px !important;
  }
}

.body-search:not(.new) .search.scholarship #areaofstudyFilterSub,
.body-search:not(.new) .search.scholarship #awardAmountFilterSub,
.body-search:not(.new) .search.scholarship #ethnicityFilterSub,
.body-search:not(.new) .search.scholarship #stateresidenceFilterSub,
.body-search:not(.new) .search.scholarship #statestudyFilterSub {
  overflow-y: scroll;
}

.save.saved i,
.save:hover i {
  color: #edae49 !important;
}

.share:hover i {
  color: #f85a3e !important;
}

.body-search:not(.new) .search--header {
  position: fixed;
  top: 60px;
  z-index: 10;
}

.body-search:not(.new) .search.scholarship {
  margin-top: 170px;
}

@media (min-width: 768px) {
  .body-search:not(.new) .search--header {
    position: -webkit-sticky;
    position: sticky;
  }

  .body-search:not(.new) .search.scholarship {
    margin-top: 0;
  }
}

@media (min-width: 1050px) {
  .body-search:not(.new) .search--header {
    top: 104px;
  }
}

.auth-reset {
  border-radius: 8px;
  box-shadow: -16px 8px 32px 0 rgba(0, 0, 0, 0.122);
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: 100%;
  width: 480px;
}

.auth-reset__title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.is-loading {
  position: relative;
}

.is-loading:before {
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.6);
  content: "";
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 9998;
}

.is-loading:after {
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
  animation: lds-dual-ring 1.2s linear infinite;
  border-color: #e1501f transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 6px;
  content: "";
  display: block;
  height: 64px;
  left: 50%;
  margin: -32px 0 0 -32px;
  position: absolute;
  top: 50%;
  width: 64px;
  z-index: 9999;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.dialog-container {
  max-width: 1240px;
}

#content {
  min-height: 1300px;
}

/*# sourceMappingURL=app.css.map*/


/* -----------------------------Custom-css----------------------------------------------- */

#top-colleges.profile--main nav a {
  font-size: 1rem;
}

#top-colleges.profile--main ul {
  padding: 0;
}

.our-rmi .school-list-item__logo {
  height: calc(50px + 0.2rem);
}

.our-rmi .school-list-item__logo .logo {
  max-width: 100%;
  width: calc(50px + 1rem);
  margin: 0 !important;
  font-size: 12px;
}

.our-rmi:hover {
  color: #000 !important;
}

.our-rmi .col:hover {
  color: #000 !important;
}

.school-list-item__actions {
  margin: 0;
}

.school-actions .button-group {
  display: flex;
}

.school-actions .button-group .btn {
  margin-top: 0 !important;
}

.school-list-item__stats {
  padding-left: calc(50px + 1.5rem);
}

.school-list-item__stats strong,
.school-list-item__stats span,
.school-list-item__stats .line-height-adj {
  font-size: 12px;
}

.school-list-item__stats strong {
  line-height: 1.2;
  font-weight: 500;
}

.border-bottom.our-rmi.media-7 {
  border-bottom: none !important;
}

.main-card {
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, .1);
}

@media (min-width: 991px) {
  .border-bottom.our-rmi.media-7 {
    padding-left: 20px;
  }
}

.WordSection1 {
  padding: 20px 25px;
  box-shadow: 0 0 4px rgba(16, 24, 64, .12);
  border-radius: 12px;
  margin-left: 0;
  margin-top: 0;
}

.MsoListParagraphCxSpLast,
.MsoListParagraphCxSpFirst,
.MsoListParagraphCxSpMiddle {
  margin-left: 0 !important;
}

.MsoTableGrid th,
.MsoTableGrid td,
#display-data th,
#display-data td {
  padding: 0.5rem !important;
  border-bottom: 0;
}


b.table-details {
  padding-bottom: 15px;
  display: block;
}

.MsoTableGrid,
#display-data {
  width: 100%;
}

.required-skills .cont {
  margin-left: 25px;
}

.required-skills .cont u {
  font-weight: 600;
  text-decoration: none;
}

.custom-sidebar {
  max-width: 100%;
  padding: 12px 17px;
  border-radius: 12px;
  box-shadow: 0 0 4px rgba(16, 24, 64, .12);
  right: 50px;
  width: 100%;
  position: initial;
}

.custom-sidebar img {
  max-width: 100%;
  min-height: 150px;
  object-fit: fill;
  width: 100%;
}


@media (max-width: 1075px) {
  .custom-page {
    width: 68% !important;
  }
}


@media (max-width: 992px) {
  .custom-page {
    width: 100% !important;
  }
}

.login-profile a:hover {
  background-color: transparent !important;
}

.custom-pages {
  margin: 145px auto;
}

.custom-pages .row::after {
  display: none;
}


.custom-pages p b {
  font-size: 14px;
}

.main-content,
.section2-amz {
  margin-left: 0;
}

.custom-inner-pages .container {
  max-width: 1366px;
}

.custom-inner-pages {
  background-color: rgb(239, 232, 232);
  padding: 51px 0;
}

section:nth-child(1) {
  margin-top: 160px;
}

.hero-banner .row::after {
  display: none;
}

.hero-banner .row {
  margin-left: 0;
  margin-right: 0;
}

.hero-banner .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.hero-banner .row img {
  width: 100%;
  max-width: 100%;
  height: 200px;
  object-fit: cover;
}

.compare-design .card-group .card {
  position: initial;
  box-shadow: none;
  max-width: 100%;
  border-radius: 0;
  border: 1px solid #d2d2d2;
  text-align: start;
  margin-top: inherit;
}

.compare-design .card-group .card .card-body {
  margin-top: 0;
  margin-right: 0;
  padding: 20px 0;
  border-top: 1px solid #d2d2d2;
}

.compare-design .card-group .card img {
  margin: 30px auto;
  max-width: 120px;
  width: 100%;
}

.compare-design .card-group .card .card-body .location {
  font-size: 12px;
  line-height: 1.33;
  color: #000;
  margin: 4px 0 8px;
}

.institute-name,
.course-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.course-name {
  font-size: 14px;
  line-height: 1.64;
  color: #111;
  color: #000 !important;
}


.compare-design .card-group .card .card-body hr {
  border-top: 1px solid #d2d2d2;
  height: 0;
  opacity: 100%;
}

.compare-design .card-group .card .card-body p {
  color: #666;
  font-size: 14px;
  margin-bottom: 5px;
}

.compare-design .card-group .card .card-body p span {
  color: #000;
}

.add-collage {
  padding: 0 50px;
}

.add-collage-button {
  width: 100% !important;
  border: 1px dashed #666 !important;
  padding: 16px 0 !important;
  font-size: 14px !important;
  color: #000 !important;
  cursor: pointer !important;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
}

.add-collage-button:hover {
  background: #f0f6ff !important;
}

.add-collage-button .plus {
  font-size: 45px;
  line-height: 1;
}

.add-collage .another-addition {
  margin: 16px 0;
  display: block;
  font-size: 0.875rem;
}

.similar-button {
  background-color: #fff !important;
  border: 1px solid #1048c3 !important;
  color: #1048c3 !important;
  padding: 0px 10px !important;
  font-size: 0.875rem !important;
  max-width: 150px;
  font-weight: 600;
  height: 34px;
  line-height: 32px;
  border-radius: 2px;
  width: 100%;
}

@media (max-width: 900px) {
  .WordSection1 {
    position: initial;
    width: 100%;
    text-align: start;
    margin-bottom: 0;
  }

  .custom-sidebar {
    margin-top: 0;
  }
}




@media (max-width: 767.5px) {
  .custom-sidebar {
    margin-top: 20px;
  }

  .compare-design .card-group {
    flex-flow: column;
    gap: 30px;
  }

  .compare-design .card-group .card {
    width: 100%;
  }

  .add-collage {
    padding-bottom: 50px;
  }

  .compare-design.media-5 {
    margin-left: 0;
    max-width: 100%;
  }

  .compare-design .card-group .card .card-body {
    margin-left: 0;
  }

  .hero-banner .row img {
    height: 100%;
  }

  .MsoTableGrid,
  #display-data {
    width: 100% !important;
  }
}


.custom-card {
  top: -190px !important;
  right: 10px !important;
}


.college-list-card {
  margin: 0 !important;
  text-align: start !important;
  position: initial !important;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}

@media (min-width: 375px) and (max-width: 600px) {


  .our-rmi.media-7 #school_1 {
    flex-direction: column !important;
  }

  .our-rmi.media-7 .school-list-item__main {
    margin-top: 10px !important;
  }

  .our-rmi.media-7 .media-name2 {
    padding-left: 0;
  }

  .our-rmi.media-7 .media-name2 {
    margin-left: 0;
  }

  .our-rmi.media-7 .school-list-item__actions {
    padding-right: 0;
  }

  .our-rmi.media-7 .school-list-item__actions .button-group {
    justify-content: end;
  }

  .our-rmi.media-7 .school-list-item__logo {
    margin-right: 0 !important;
  }

  .our-rmi.media-7 .media-name {
    margin: 0;
  }

  .our-rmi.media-7 .media-img {
    width: 100%;
    position: initial;
    margin-left: 0;
  }

  .our-rmi.media-7 .school-list-item__stats .col {
    margin-left: 0;
    margin-top: 10px;
  }
}

.footer.-type-1 .footer-header {
  padding: 25px 0;
}

.footer.-type-1 .footer-columns {
  padding-top: 35px;
  padding-bottom: 35px;
}

.contact-us .card {
  max-width: 100%;
  position: initial;
  box-shadow: none;
  margin-bottom: 1rem;
  text-align: start;
  cursor: auto;
}

.contact-us .card .card-body {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.contact-us {
  padding: 40px 0;
  background-color: #f1f1f1;
}

.contact-us .col:hover {
  color: #000;
}

.contact-us .col {
  margin-left: 0;
}

.contact-us .btn {
  background-color: #c82333;
  border-color: #c82333;
  padding: 7px;
  width: 140px;
  font-size: 16px;
}

.add-collage .fade:not(.show) {
  opacity: 100%;
}

.sign-up input[type="text"],
.sign-up input[ type="email"] {

  padding: 10px 22px !important;

}

.sign-up .button.-md {
  height: 40px;
}

.sign-up .upload-container {
  margin-top: 0 !important;
}

.college-list-banner img {
  height: 400px !important;
  width: 100% !important;
  object-fit: cover !important;
  max-width: 100%;
}


.overlap-card .card {
  position: initial;
  max-width: 400px;
  width: 100%;
}

.overlap-card .col:hover .card {
  color: #000;
  background-color: transparent;
}

.overlap-card .card .card-body {
  margin: 0;
  padding: 0;
  text-align: start;
  height: 400px;
  overflow-y: auto;
}

.overlap-card .card-header {
  display: flex;
  justify-content: space-between;
  background-color: #140342;
}

.overlap-card .card-header p {
  color: #fff;
}

.overlap-card .card-header button {
  background-color: transparent;
  padding: 0;
  color: #000;
  width: auto;
  color: #fff;
}

.overlap-card .card .card-body .heading {
  padding: 8px 1.25rem;
  text-align: start;
  position: sticky;
  background: #fff;
  top: 0;
}

.overlap-card .card .card-body form {
  position: sticky;
  top: 41px;
}

.overlap-card .card .card-body .form-group {
  padding: 12px 16px;
  background-color: #f1f1f1;
}

.overlap-card .card .card-body .form-group input {
  width: 100%;
  height: 28px;
  border-radius: 4px;
  border: 1px solid #999;
  background-color: #fff;
  padding: 7px 11px 7px 22px;
  box-sizing: border-box;
  line-height: 13px;
  font-size: .75rem;
  box-shadow: none;
}

.overlap-card .card .card-body .form-group svg {
  position: absolute;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
  width: 13px;
}

.overlap-card .card .card-body .selected {
  padding: 12px 16px;
}

form.contact-form input[type="text"],
form.contact-form input[type="tel"],
form.contact-form input[type="email"],
form.contact-form textarea,
form.contact-form input[type="password"] {
  padding: 10px 22px;
}

.home-page .masthead__content .masthead__title {
  font-size: 40px;
  line-height: normal;
}

.home-page .masthead__content .masthead-info__title {
  margin-top: 30px;
  font-size: 25px;
  font-family: monospace;
}

.home-page .masthead__content .masthead__buttons {
  justify-content: end;
}


.MsoTableGrid tbody tr:nth-of-type(even),
#display-data tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}


@media (max-width: 1192px) {
  .login-profile img {
    margin-left: 0 !important;
  }
}

@media (max-width: 1063px) {
  .login-profile {
    margin-left: 160px !important;
  }
}



@media (max-width: 1024px) {
  .home-page {
    padding-top: 170px !important;
    padding-bottom: 180px !important;
  }

  section:nth-child(1) {
    margin-top: 80px;
  }

  .navbar {
    height: 70px !important;
  }

  .navbar .nav-header .nav-logo {
    margin-top: -28px !important;
  }
}

@media (max-width: 1150px) {
  .add-collage {
    padding: 0 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .add-collage .similar-button {
    max-width: 100%;
    font-size: 10px !important;
  }

  .add-collage {
    padding: 0 20px;
  }
}

@media (max-width: 800px) {
  .media .profile-title {
    left: 0 !important;
  }
}

.header-search-result {
  position: absolute;
  background: #fff;
  width: 100%;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
  z-index: 999;
  padding: 10px;
  height: 250px;
  overflow-y: auto;
  right: 0;
  left: 0;
}

.header-search-result li:not(:last-child) {
  margin-bottom: 1rem;
}


main {
  overflow: inherit !important;
}

.header.-type-1 {
  background-color: #89cff0;
  box-shadow: 0 0 30px 0 rgba(15, 19, 25, 0.1);
}

header.-type-1 .dropdown {
  color: #970833;
}

.margin-left-25 {
  margin-left: 40px !important;
}

.header #search:focus::placeholder,
.header #search:focus::-webkit-input-placeholder, .masthead.home-page input:focus::placeholder {
  color: #fff !important;
}