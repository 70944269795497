@charset "UTF-8";
.ratio {
  position: relative;
  display: block;
  overflow: hidden;
}

.ratio::before {
  display: block;
  width: 100%;
  content: "";
}

.ratio-1\:1::before {
  padding-bottom: 100%;
}

.ratio-2\:1::before {
  padding-bottom: 50%;
}

.ratio-2\:3::before {
  padding-bottom: 150%;
}

.ratio-3\:2::before {
  padding-bottom: 66.66667%;
}

.ratio-3\:4::before {
  padding-bottom: 133.33333%;
}

.ratio-4\:3::before {
  padding-bottom: 75%;
}

.ratio-5\:4::before {
  padding-bottom: 80%;
}

.ratio-8\:5::before {
  padding-bottom: 62.5%;
}

.ratio-16\:9::before {
  padding-bottom: 56.25%;
}

.ratio-30\:16::before {
  padding-bottom: 53.33333%;
}

.ratio-30\:35::before {
  padding-bottom: 116.66667%;
}

.ratio-63\:57::before {
  padding-bottom: 90.47619%;
}

@font-face {
  font-family: "GT Walsheim Pro";
  font-weight: 100;
  src: url("../fonts/font/GT-Walsheim-Pro-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "GT Walsheim Pro";
  font-weight: 200;
  src: url("../fonts/font/GT-Walsheim-Pro-Ultra-Light.woff2") format("woff2");
}

@font-face {
  font-family: "GT Walsheim Pro";
  font-weight: 300;
  src: url("../fonts/font/GT-Walsheim-Pro-Light.woff2") format("woff2");
}

@font-face {
  font-family: "GT Walsheim Pro";
  font-weight: 400;
  src: url("../fonts/font/GT-Walsheim-Pro-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "GT Walsheim Pro";
  font-weight: 500;
  src: url("../fonts/font/GT-Walsheim-Pro-Medium.woff2") format("woff2");
}

/* @font-face {
  font-family: 'GT Walsheim Pro';
  font-weight: 600;
  src: url("../fonts/font/GT-Walsheim-Pro-Semi-Bold.woff2") format("woff2");
} */

@font-face {
  font-family: "GT Walsheim Pro";
  font-weight: 700;
  src: url("../fonts/font/GT-Walsheim-Pro-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "GT Walsheim Pro";
  font-weight: 800;
  src: url("../fonts/font/GT-Walsheim-Pro-Black.woff2") format("woff2");
}

:root {
  --font-primary: "GT Walsheim Pro", sans-serif;
  --text-6: 6px;
  --text-9: 9px;
  --text-11: 11px;
  --text-13: 13px;
  --text-14: 14px;
  --text-15: 15px;
  --text-16: 16px;
  --text-17: 17px;
  --text-18: 18px;
  --text-20: 20px;
  --text-24: 24px;
  --text-30: 30px;
  --text-35: 35px;
  --text-40: 40px;
  --text-45: 45px;
  --text-50: 50px;
  --text-55: 55px;
  --text-60: 60px;
  --text-64: 64px;
}

:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dark-1: #140342;
  --color-dark-2: #1a064f;
  --color-dark-3: #6a7a99;
  --color-dark-4: #242239;
  --color-dark-5: #282664;
  --color-dark-6: #311f61;
  --color-dark-7: #eae9ef;
  --color-dark-8: #202124;
  --color-light-1: #4f547b;
  --color-light-2: #e4e7ea;
  --color-light-3: #eef2f6;
  --color-light-4: #f7f8fb;
  --color-light-5: #ededed;
  --color-light-6: #f5f7fe;
  --color-light-7: #e5f0fd;
  --color-light-8: #dddddd;
  --color-light-9: #eef2f6;
  --color-light-10: #f4f3f6;
  --color-light-11: #ebeafe;
  --color-light-12: #cce0f8;
  --color-purple-1: #6440fb;
  --color-purple-2: #e3edfd;
  --color-purple-3: #f4f1fe;
  --color-purple-4: #7545f0;
  --color-purple-5: #eae3fd;
  --color-green-1: #00ff84;
  --color-green-2: #def5f0;
  --color-green-3: #eef8f5;
  --color-green-4: #04d697;
  --color-green-5: #34a853;
  --color-green-6: #dff1dd;
  --color-green-7: #d9ffed;
  --color-orange-1: #e8543e;
  --color-orange-2: #f7e9e7;
  --color-orange-3: #fdf2eb;
  --color-orange-4: #e97d38;
  --color-orange-5: #fdeeec;
  --color-orange-6: #e78e34;
  --color-orange-7: #fbeee1;
  --color-red-1: #f01e00;
  --color-red-2: #fce5e2;
  --color-red-3: #d93025;
  --color-beige-1: #fefbf4;
  --color-blue-1: #65c2f9;
  --color-blue-2: #eff9fe;
  --color-blue-3: #1967d2;
  --color-blue-4: #508ef0;
  --color-blue-5: #445b95;
  --color-blue-6: #e5eefd;
  --color-blue-7: #e3e6ef;
  --color-yellow-1: #e59819;
  --color-yellow-2: #fdf8eb;
  --color-yellow-3: #ecb53e;
  --color-yellow-4: #f9ab00;
  --color-yellow-5: #fcedcd;
  --color-info-1: #cde9f6;
  --color-info-2: #4780aa;
  --color-warning-1: #f7f3d7;
  --color-warning-2: #927238;
  --color-error-1: #ecc8c5;
  --color-error-2: #ab3331;
  --color-success-1: #def2d7;
  --color-success-2: #5b7052;
}

[data-anim-wrap] {
  pointer-events: none;
}

[data-anim-wrap].animated {
  pointer-events: auto;
}

[data-anim*="cover-white"]::after,
[data-anim-child*="cover-white"]::after {
  background-color: white;
}

[data-anim*="cover-black"]::after,
[data-anim-child*="cover-black"]::after {
  background-color: black;
}

[data-anim*="cover-light-1"]::after,
[data-anim-child*="cover-light-1"]::after {
  background-color: var(--bg-light-1);
}

[data-anim*="cover-light-2"]::after,
[data-anim-child*="cover-light-2"]::after {
  background-color: var(--bg-light-2);
}

[data-anim*="cover-dark-1"]::after,
[data-anim-child*="cover-dark-1"]::after {
  background-color: var(--bg-dark-1);
}

[data-anim*="cover-dark-2"]::after,
[data-anim-child*="cover-dark-2"]::after {
  background-color: var(--bg-dark-2);
}

[data-anim*="cover-accent"]::after,
[data-anim-child*="cover-accent"]::after {
  background-color: var(--accent-color);
}

@-webkit-keyframes reveal {
  100% {
    opacity: 1;
  }
}

@keyframes reveal {
  100% {
    opacity: 1;
  }
}

[data-anim^="cover-right"],
[data-anim-child^="cover-right"] {
  display: block;
  position: relative;
}

[data-anim^="cover-right"]::after,
[data-anim-child^="cover-right"]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transform: scaleX(0);
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-anim^="cover-right"] > *,
[data-anim-child^="cover-right"] > * {
  opacity: 0;
  pointer-events: none;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 20ms;
  animation-duration: 20ms;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

[data-anim^="cover-right"].is-in-view::after,
[data-anim-child^="cover-right"].is-in-view::after {
  -webkit-animation-name: coverRight;
  animation-name: coverRight;
}

[data-anim^="cover-right"].is-in-view > *,
[data-anim-child^="cover-right"].is-in-view > * {
  -webkit-animation-name: reveal;
  animation-name: reveal;
  pointer-events: auto;
}

[data-anim^="cover-up"],
[data-anim-child^="cover-up"] {
  display: block;
  position: relative;
}

[data-anim^="cover-up"]::after,
[data-anim-child^="cover-up"]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transform: scaleY(0);
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-anim^="cover-up"] > *,
[data-anim-child^="cover-up"] > * {
  opacity: 0;
  pointer-events: none;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 20ms;
  animation-duration: 20ms;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

[data-anim^="cover-up"].is-in-view::after,
[data-anim-child^="cover-up"].is-in-view::after {
  -webkit-animation-name: coverUp;
  animation-name: coverUp;
}

[data-anim^="cover-up"].is-in-view > *,
[data-anim-child^="cover-up"].is-in-view > * {
  -webkit-animation-name: reveal;
  animation-name: reveal;
  pointer-events: auto;
}

@-webkit-keyframes coverRight {
  0% {
    transform-origin: 0 50%;
    transform: scaleX(0);
  }
  42% {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  58% {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scaleX(0);
  }
}

@keyframes coverRight {
  0% {
    transform-origin: 0 50%;
    transform: scaleX(0);
  }
  42% {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  58% {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scaleX(0);
  }
}

@-webkit-keyframes coverUp {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  42% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  58% {
    transform-origin: 50% 0;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0;
    transform: scaleY(0);
  }
}

@keyframes coverUp {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0);
  }
  42% {
    transform-origin: 50% 100%;
    transform: scaleY(1);
  }
  58% {
    transform-origin: 50% 0;
    transform: scaleY(1);
  }
  100% {
    transform-origin: 50% 0;
    transform: scaleY(0);
  }
}

[data-anim^="img-fade"],
[data-anim-child^="img-fade"] {
  overflow: hidden;
  display: block;
  position: relative;
}

[data-anim^="img-fade"] > *,
[data-anim-child^="img-fade"] > * {
  pointer-events: none;
  opacity: 0;
  transform: scale(1.5);
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-anim^="img-fade"].is-in-view > *,
[data-anim-child^="img-fade"].is-in-view > * {
  pointer-events: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-anim^="img-right"],
[data-anim-child^="img-right"] {
  overflow: hidden;
  display: block;
  position: relative;
}

[data-anim^="img-right"]::after,
[data-anim-child^="img-right"]::after {
  content: "";
  position: absolute;
  top: -0.06667rem;
  left: -0.06667rem;
  right: 0;
  z-index: 20;
  height: calc(100% + 0.125rem);
  width: calc(100% + 0.125rem);
  transform-origin: right;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

[data-anim^="img-right"] > *,
[data-anim-child^="img-right"] > * {
  transform-origin: center;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

[data-anim^="img-right"].is-in-view::after,
[data-anim-child^="img-right"].is-in-view::after {
  -webkit-animation-name: imgRight;
  animation-name: imgRight;
}

[data-anim^="img-right"].is-in-view > *,
[data-anim-child^="img-right"].is-in-view > * {
  -webkit-animation-name: imgReveal;
  animation-name: imgReveal;
}

@-webkit-keyframes imgRight {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes imgRight {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@-webkit-keyframes imgReveal {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.01);
  }
}

@keyframes imgReveal {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.01);
  }
}

[data-anim^="slide-"],
[data-anim-child^="slide-"] {
  opacity: 0;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^="slide-"].is-in-view,
[data-anim-child^="slide-"].is-in-view {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

[data-anim^="slide-up"],
[data-anim-child^="slide-up"] {
  transform: translate3d(0, 40px, 0);
}

[data-anim^="slide-down"],
[data-anim-child^="slide-down"] {
  transform: translate3d(0, -40px, 0);
}

[data-anim^="slide-right"],
[data-anim-child^="slide-right"] {
  transform: translate3d(-40px, 0, 0);
}

[data-anim^="slide-left"],
[data-anim-child^="slide-left"] {
  transform: translate3d(40px, 0, 0);
}

[data-anim^="fade"],
[data-anim-child^="fade"] {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-anim^="fade"].is-in-view,
[data-anim-child^="fade"].is-in-view {
  opacity: 1;
  pointer-events: auto;
}

[data-anim^="scale"],
[data-anim-child^="scale"] {
  opacity: 0;
  transform: scale(0.9) translateY(60px);
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^="scale"].is-in-view,
[data-anim-child^="scale"].is-in-view {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

[data-anim^="fancy-1"],
[data-anim-child^="fancy-1"] {
  opacity: 0;
  transform: translateY(120px) rotateY(20deg);
  transform-origin: left;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^="fancy-1"].is-in-view,
[data-anim-child^="fancy-1"].is-in-view {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

[data-anim^="fancy-2"],
[data-anim-child^="fancy-2"] {
  opacity: 0;
  transform: translateY(120px) rotateY(40deg) scaleX(1.3);
  transform-origin: left;
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^="fancy-2"].is-in-view,
[data-anim-child^="fancy-2"].is-in-view {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.float-animation {
  -webkit-animation: floatOne 1s ease-out infinite alternate;
  animation: floatOne 1s ease-out infinite alternate;
}

@-webkit-keyframes floatOne {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3%);
  }
}

@keyframes floatOne {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3%);
  }
}

.float-animation-sm {
  -webkit-animation: floatTwo 1s ease-out infinite alternate;
  animation: floatTwo 1s ease-out infinite alternate;
}

@-webkit-keyframes floatTwo {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

@keyframes floatTwo {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}

.pulse-animation {
  position: relative;
  z-index: 1;
}

.pulse-animation::after {
  z-index: -1;
  content: "";
  position: absolute;
  border-radius: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-animation: video_btn_pulse 3s infinite;
  animation: video_btn_pulse 3s infinite;
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes video_btn_pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  75% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

@keyframes video_btn_pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  75% {
    opacity: 0;
    transform: scale(1.6);
  }
  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

[data-anim*="delay-1"],
[data-anim-child*="delay-1"] {
  transition-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

[data-anim*="delay-1"]::after,
[data-anim-child*="delay-1"]::after {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

[data-anim*="delay-1"] > *,
[data-anim-child*="delay-1"] > * {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

[data-anim*="delay-2"],
[data-anim-child*="delay-2"] {
  transition-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

[data-anim*="delay-2"]::after,
[data-anim-child*="delay-2"]::after {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

[data-anim*="delay-2"] > *,
[data-anim-child*="delay-2"] > * {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

[data-anim*="delay-3"],
[data-anim-child*="delay-3"] {
  transition-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

[data-anim*="delay-3"]::after,
[data-anim-child*="delay-3"]::after {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

[data-anim*="delay-3"] > *,
[data-anim-child*="delay-3"] > * {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

[data-anim*="delay-4"],
[data-anim-child*="delay-4"] {
  transition-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

[data-anim*="delay-4"]::after,
[data-anim-child*="delay-4"]::after {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

[data-anim*="delay-4"] > *,
[data-anim-child*="delay-4"] > * {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

[data-anim*="delay-5"],
[data-anim-child*="delay-5"] {
  transition-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

[data-anim*="delay-5"]::after,
[data-anim-child*="delay-5"]::after {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

[data-anim*="delay-5"] > *,
[data-anim-child*="delay-5"] > * {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

[data-anim*="delay-6"],
[data-anim-child*="delay-6"] {
  transition-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

[data-anim*="delay-6"]::after,
[data-anim-child*="delay-6"]::after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

[data-anim*="delay-6"] > *,
[data-anim-child*="delay-6"] > * {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

[data-anim*="delay-7"],
[data-anim-child*="delay-7"] {
  transition-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

[data-anim*="delay-7"]::after,
[data-anim-child*="delay-7"]::after {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

[data-anim*="delay-7"] > *,
[data-anim-child*="delay-7"] > * {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

[data-anim*="delay-8"],
[data-anim-child*="delay-8"] {
  transition-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

[data-anim*="delay-8"]::after,
[data-anim-child*="delay-8"]::after {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

[data-anim*="delay-8"] > *,
[data-anim-child*="delay-8"] > * {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

[data-anim*="delay-9"],
[data-anim-child*="delay-9"] {
  transition-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

[data-anim*="delay-9"]::after,
[data-anim-child*="delay-9"]::after {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

[data-anim*="delay-9"] > *,
[data-anim-child*="delay-9"] > * {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

[data-anim*="delay-10"],
[data-anim-child*="delay-10"] {
  transition-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

[data-anim*="delay-10"]::after,
[data-anim-child*="delay-10"]::after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

[data-anim*="delay-10"] > *,
[data-anim-child*="delay-10"] > * {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

[data-anim*="delay-11"],
[data-anim-child*="delay-11"] {
  transition-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

[data-anim*="delay-11"]::after,
[data-anim-child*="delay-11"]::after {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

[data-anim*="delay-11"] > *,
[data-anim-child*="delay-11"] > * {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

[data-anim*="delay-12"],
[data-anim-child*="delay-12"] {
  transition-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

[data-anim*="delay-12"]::after,
[data-anim-child*="delay-12"]::after {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

[data-anim*="delay-12"] > *,
[data-anim-child*="delay-12"] > * {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

[data-anim*="delay-13"],
[data-anim-child*="delay-13"] {
  transition-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

[data-anim*="delay-13"]::after,
[data-anim-child*="delay-13"]::after {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

[data-anim*="delay-13"] > *,
[data-anim-child*="delay-13"] > * {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

[data-anim*="delay-14"],
[data-anim-child*="delay-14"] {
  transition-delay: 1.4s;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

[data-anim*="delay-14"]::after,
[data-anim-child*="delay-14"]::after {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

[data-anim*="delay-14"] > *,
[data-anim-child*="delay-14"] > * {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

[data-anim*="delay-15"],
[data-anim-child*="delay-15"] {
  transition-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

[data-anim*="delay-15"]::after,
[data-anim-child*="delay-15"]::after {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

[data-anim*="delay-15"] > *,
[data-anim-child*="delay-15"] > * {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

[data-anim*="delay-16"],
[data-anim-child*="delay-16"] {
  transition-delay: 1.6s;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

[data-anim*="delay-16"]::after,
[data-anim-child*="delay-16"]::after {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

[data-anim*="delay-16"] > *,
[data-anim-child*="delay-16"] > * {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

[data-anim*="delay-17"],
[data-anim-child*="delay-17"] {
  transition-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

[data-anim*="delay-17"]::after,
[data-anim-child*="delay-17"]::after {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

[data-anim*="delay-17"] > *,
[data-anim-child*="delay-17"] > * {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

[data-anim*="delay-18"],
[data-anim-child*="delay-18"] {
  transition-delay: 1.8s;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

[data-anim*="delay-18"]::after,
[data-anim-child*="delay-18"]::after {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

[data-anim*="delay-18"] > *,
[data-anim-child*="delay-18"] > * {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

[data-anim*="delay-19"],
[data-anim-child*="delay-19"] {
  transition-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

[data-anim*="delay-19"]::after,
[data-anim-child*="delay-19"]::after {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

[data-anim*="delay-19"] > *,
[data-anim-child*="delay-19"] > * {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

[data-anim*="delay-20"],
[data-anim-child*="delay-20"] {
  transition-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

[data-anim*="delay-20"]::after,
[data-anim-child*="delay-20"]::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

[data-anim*="delay-20"] > *,
[data-anim-child*="delay-20"] > * {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-primary);
  min-height: 100%;
  font-size: 15px;
  line-height: 1;
}

html:not(.-dark-mode) .-light-d-none {
  display: none;
}

html.-dark-mode .-dark-d-none {
  display: none;
}

html.-dark-mode {
  --color-dark-1: #fff !important;
  --color-light-3: #2b1c55 !important;
  --color-light-4: #2b1c55 !important;
  --color-light-5: #2b1c55 !important;
  --color-light-6: #2b1c55 !important;
  --color-light-9: #2b1c55 !important;
  --color-purple-3: #2b1c55 !important;
}

html.-dark-mode body {
  background-color: #140342 !important;
  color: var(--color-dark-3) !important;
}

html.-dark-mode .icon-arrow-left,
html.-dark-mode .icon-arrow-right {
  color: white !important;
}

html.-dark-mode .header.-base-sidebar {
  background-color: #140342 !important;
}

html.-dark-mode .header.-base-sidebar .icon {
  color: #6a7a99 !important;
}

html.-dark-mode .dashboard__sidebar {
  background-color: #140342;
}

html.-dark-mode .-dark-sidebar-white {
  color: white !important;
}

html.-dark-mode .-dark-bg-dark-1 {
  background-color: #140342 !important;
}

html.-dark-mode .-dark-bg-dark-2 {
  background-color: #2b1c55 !important;
}

html.-dark-mode .-dark-text-dark-1 {
  color: #140342 !important;
}

html.-dark-mode .-dark-text-white {
  color: white !important;
}

html.-dark-mode .-dark-text-light-1 {
  color: #6a7a99 !important;
}

html.-dark-mode .-dark-border-top-light-5 {
  border-top-color: #ededed !important;
}

html.-dark-mode .-dark-bg-purple-1 {
  background-color: var(--color-purple-1) !important;
}

html.-dark-mode .-dark-border-white-10 {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

html.-dark-mode .-dark-border-dark-2 {
  border: 1px solid #2b1c55 !important;
}

html.-dark-mode .sidebar.-base-sidebar .sidebar__item.-is-active {
  color: var(--color-purple-1) !important;
}

html.-dark-mode .-dark-button-dark-1 {
  border-color: #140342 !important;
  background-color: #140342 !important;
}

html.-dark-mode .-dark-button-dark-1:hover {
  color: #140342 !important;
}

html.-dark-mode .form-switch .switch__slider {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

html.-dark-mode .form-switch .switch__slider::before {
  background-color: rgba(255, 255, 255, 0.5);
}

html.-dark-mode .-dark-button-dark-2 {
  border-color: #2b1c55 !important;
  background-color: #2b1c55 !important;
}

html.-dark-mode .-dark-button-white {
  border-color: white !important;
  background-color: white !important;
  color: #140342 !important;
}

html.-dark-mode .-dark-button-white:hover {
  color: white !important;
}

html.-dark-mode .button.-purple-3 {
  color: white !important;
  border-color: transparent !important;
}

body {
  overflow-x: hidden;
  padding: 0;
  font-family: var(--font-primary);
  width: 100%;
  max-width: 100%;
  background-color: white;
  color: var(--color-light-1);
  font-size: 15px;
  line-height: 1.7;
}

.container {
  padding-top: 0;
  padding-bottom: 0;
}

main {
  overflow: hidden;
}

img {
  max-width: 80%;
  height: auto;
}

a {
  color: inherit !important;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--color-purple-1);
}

a,
a:focus,
a:visited {
  text-decoration: inherit;
  font-weight: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  font-size: 15px;
  line-height: 26px;
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;
}

button:focus {
  outline: 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  border-radius: 8px;
  line-height: 1;
  font-weight: 400;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.-sm {
  padding: 6px 34px;
  font-size: 15px;
  height: 40px;
}

.button.-md {
  padding: 12px 55px;
  font-size: 20px;
  line-height: 18px;
  font-weight: 500;
  height: 60px;
}

.button.-icon {
  min-width: 120px;
  padding: 14px 30px;
  font-size: 15px;
  height: 50px;
}

.button.-single-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.-narrow {
  min-width: unset;
  padding-left: 25px;
  padding-right: 25px;
  height: 50px;
}

.button.-rounded {
  border-radius: 100px;
}

.button.-underline:hover {
  text-decoration: underline;
}

.button.-white {
  background-color: var(--color-white);
  border: 2px solid var(--color-white);
}

.button.-white:hover {
  background-color: transparent !important;
  color: var(--color-white) !important;
}

.button.-outline-white {
  border: 2px solid var(--color-white);
}

.button.-outline-white:hover {
  background-color: var(--color-white);
  border-color: transparent;
  color: white !important;
}

.button.-black {
  background-color: var(--color-black);
  border: 2px solid var(--color-black);
}

.button.-black:hover {
  background-color: transparent !important;
  color: var(--color-black) !important;
}

.button.-outline-black {
  border: 2px solid var(--color-black);
}

.button.-outline-black:hover {
  background-color: var(--color-black);
  border-color: transparent;
  color: white !important;
}

.button.-dark-1 {
  background-color: var(--color-dark-1);
  border: 2px solid var(--color-dark-1);
}

.button.-dark-1:hover {
  background-color: transparent !important;
  color: var(--color-dark-1) !important;
}

.button.-outline-dark-1 {
  border: 2px solid var(--color-dark-1);
}

.button.-outline-dark-1:hover {
  background-color: var(--color-dark-1);
  border-color: transparent;
  color: white !important;
}

.button.-dark-2 {
  background-color: var(--color-dark-2);
  border: 2px solid var(--color-dark-2);
}

.button.-dark-2:hover {
  background-color: transparent !important;
  color: var(--color-dark-2) !important;
}

.button.-outline-dark-2 {
  border: 2px solid var(--color-dark-2);
}

.button.-outline-dark-2:hover {
  background-color: var(--color-dark-2);
  border-color: transparent;
  color: white !important;
}

.button.-dark-3 {
  background-color: var(--color-dark-3);
  border: 2px solid var(--color-dark-3);
}

.button.-dark-3:hover {
  background-color: transparent !important;
  color: var(--color-dark-3) !important;
}

.button.-outline-dark-3 {
  border: 2px solid var(--color-dark-3);
}

.button.-outline-dark-3:hover {
  background-color: var(--color-dark-3);
  border-color: transparent;
  color: white !important;
}

.button.-dark-4 {
  background-color: var(--color-dark-4);
  border: 2px solid var(--color-dark-4);
}

.button.-dark-4:hover {
  background-color: transparent !important;
  color: var(--color-dark-4) !important;
}

.button.-outline-dark-4 {
  border: 2px solid var(--color-dark-4);
}

.button.-outline-dark-4:hover {
  background-color: var(--color-dark-4);
  border-color: transparent;
  color: white !important;
}

.button.-dark-5 {
  background-color: var(--color-dark-5);
  border: 2px solid var(--color-dark-5);
}

.button.-dark-5:hover {
  background-color: transparent !important;
  color: var(--color-dark-5) !important;
}

.button.-outline-dark-5 {
  border: 2px solid var(--color-dark-5);
}

.button.-outline-dark-5:hover {
  background-color: var(--color-dark-5);
  border-color: transparent;
  color: white !important;
}

.button.-dark-6 {
  background-color: var(--color-dark-6);
  border: 2px solid var(--color-dark-6);
}

.button.-dark-6:hover {
  background-color: transparent !important;
  color: var(--color-dark-6) !important;
}

.button.-outline-dark-6 {
  border: 2px solid var(--color-dark-6);
}

.button.-outline-dark-6:hover {
  background-color: var(--color-dark-6);
  border-color: transparent;
  color: white !important;
}

.button.-dark-7 {
  background-color: var(--color-dark-7);
  border: 2px solid var(--color-dark-7);
}

.button.-dark-7:hover {
  background-color: transparent !important;
  color: var(--color-dark-7) !important;
}

.button.-outline-dark-7 {
  border: 2px solid var(--color-dark-7);
}

.button.-outline-dark-7:hover {
  background-color: var(--color-dark-7);
  border-color: transparent;
  color: white !important;
}

.button.-dark-8 {
  background-color: var(--color-dark-8);
  border: 2px solid var(--color-dark-8);
}

.button.-dark-8:hover {
  background-color: transparent !important;
  color: var(--color-dark-8) !important;
}

.button.-outline-dark-8 {
  border: 2px solid var(--color-dark-8);
}

.button.-outline-dark-8:hover {
  background-color: var(--color-dark-8);
  border-color: transparent;
  color: white !important;
}

.button.-light-1 {
  background-color: var(--color-light-1);
  border: 2px solid var(--color-light-1);
}

.button.-light-1:hover {
  background-color: transparent !important;
  color: var(--color-light-1) !important;
}

.button.-outline-light-1 {
  border: 2px solid var(--color-light-1);
}

.button.-outline-light-1:hover {
  background-color: var(--color-light-1);
  border-color: transparent;
  color: white !important;
}

.button.-light-2 {
  background-color: var(--color-light-2);
  border: 2px solid var(--color-light-2);
}

.button.-light-2:hover {
  background-color: transparent !important;
  color: var(--color-light-2) !important;
}

.button.-outline-light-2 {
  border: 2px solid var(--color-light-2);
}

.button.-outline-light-2:hover {
  background-color: var(--color-light-2);
  border-color: transparent;
  color: white !important;
}

.button.-light-3 {
  background-color: var(--color-light-3);
  border: 2px solid var(--color-light-3);
}

.button.-light-3:hover {
  background-color: transparent !important;
  color: var(--color-light-3) !important;
}

.button.-outline-light-3 {
  border: 2px solid var(--color-light-3);
}

.button.-outline-light-3:hover {
  background-color: var(--color-light-3);
  border-color: transparent;
  color: white !important;
}

.button.-light-4 {
  background-color: var(--color-light-4);
  border: 2px solid var(--color-light-4);
}

.button.-light-4:hover {
  background-color: transparent !important;
  color: var(--color-light-4) !important;
}

.button.-outline-light-4 {
  border: 2px solid var(--color-light-4);
}

.button.-outline-light-4:hover {
  background-color: var(--color-light-4);
  border-color: transparent;
  color: white !important;
}

.button.-light-5 {
  background-color: var(--color-light-5);
  border: 2px solid var(--color-light-5);
}

.button.-light-5:hover {
  background-color: transparent !important;
  color: var(--color-light-5) !important;
}

.button.-outline-light-5 {
  border: 2px solid var(--color-light-5);
}

.button.-outline-light-5:hover {
  background-color: var(--color-light-5);
  border-color: transparent;
  color: white !important;
}

.button.-light-6 {
  background-color: var(--color-light-6);
  border: 2px solid var(--color-light-6);
}

.button.-light-6:hover {
  background-color: transparent !important;
  color: var(--color-light-6) !important;
}

.button.-outline-light-6 {
  border: 2px solid var(--color-light-6);
}

.button.-outline-light-6:hover {
  background-color: var(--color-light-6);
  border-color: transparent;
  color: white !important;
}

.button.-light-7 {
  background-color: var(--color-light-7);
  border: 2px solid var(--color-light-7);
}

.button.-light-7:hover {
  background-color: transparent !important;
  color: var(--color-light-7) !important;
}

.button.-outline-light-7 {
  border: 2px solid var(--color-light-7);
}

.button.-outline-light-7:hover {
  background-color: var(--color-light-7);
  border-color: transparent;
  color: white !important;
}

.button.-light-8 {
  background-color: var(--color-light-8);
  border: 2px solid var(--color-light-8);
}

.button.-light-8:hover {
  background-color: transparent !important;
  color: var(--color-light-8) !important;
}

.button.-outline-light-8 {
  border: 2px solid var(--color-light-8);
}

.button.-outline-light-8:hover {
  background-color: var(--color-light-8);
  border-color: transparent;
  color: white !important;
}

.button.-light-9 {
  background-color: var(--color-light-9);
  border: 2px solid var(--color-light-9);
}

.button.-light-9:hover {
  background-color: transparent !important;
  color: var(--color-light-9) !important;
}

.button.-outline-light-9 {
  border: 2px solid var(--color-light-9);
}

.button.-outline-light-9:hover {
  background-color: var(--color-light-9);
  border-color: transparent;
  color: white !important;
}

.button.-light-10 {
  background-color: var(--color-light-10);
  border: 2px solid var(--color-light-10);
}

.button.-light-10:hover {
  background-color: transparent !important;
  color: var(--color-light-10) !important;
}

.button.-outline-light-10 {
  border: 2px solid var(--color-light-10);
}

.button.-outline-light-10:hover {
  background-color: var(--color-light-10);
  border-color: transparent;
  color: white !important;
}

.button.-light-11 {
  background-color: var(--color-light-11);
  border: 2px solid var(--color-light-11);
}

.button.-light-11:hover {
  background-color: transparent !important;
  color: var(--color-light-11) !important;
}

.button.-outline-light-11 {
  border: 2px solid var(--color-light-11);
}

.button.-outline-light-11:hover {
  background-color: var(--color-light-11);
  border-color: transparent;
  color: white !important;
}

.button.-light-12 {
  background-color: var(--color-light-12);
  border: 2px solid var(--color-light-12);
}

.button.-light-12:hover {
  background-color: transparent !important;
  color: var(--color-light-12) !important;
}

.button.-outline-light-12 {
  border: 2px solid var(--color-light-12);
}

.button.-outline-light-12:hover {
  background-color: var(--color-light-12);
  border-color: transparent;
  color: white !important;
}

.button.-purple-1 {
  background-color: #d3a802;
    border: 2px solid #d3a802;
}

.button.-purple-1:hover {
  background-color: transparent !important;
  color: var(--color-purple-1) !important;
}

.button.-outline-purple-1 {
  border: 2px solid var(--color-purple-1);
}

.button.-outline-purple-1:hover {
  background-color: var(--color-purple-1);
  border-color: transparent;
  color: white !important;
}

.button.-purple-2 {
  background-color: var(--color-purple-2);
  border: 2px solid var(--color-purple-2);
}

.button.-purple-2:hover {
  background-color: transparent !important;
  color: var(--color-purple-2) !important;
}

.button.-outline-purple-2 {
  border: 2px solid var(--color-purple-2);
}

.button.-outline-purple-2:hover {
  background-color: var(--color-purple-2);
  border-color: transparent;
  color: white !important;
}

.button.-purple-3 {
  background-color: var(--color-purple-3);
  border: 2px solid var(--color-purple-3);
}

.button.-purple-3:hover {
  background-color: transparent !important;
  color: var(--color-purple-3) !important;
}

.button.-outline-purple-3 {
  border: 2px solid var(--color-purple-3);
}

.button.-outline-purple-3:hover {
  background-color: var(--color-purple-3);
  border-color: transparent;
  color: white !important;
}

.button.-purple-4 {
  background-color: var(--color-purple-4);
  border: 2px solid var(--color-purple-4);
}

.button.-purple-4:hover {
  background-color: transparent !important;
  color: var(--color-purple-4) !important;
}

.button.-outline-purple-4 {
  border: 2px solid var(--color-purple-4);
}

.button.-outline-purple-4:hover {
  background-color: var(--color-purple-4);
  border-color: transparent;
  color: white !important;
}

.button.-purple-5 {
  background-color: var(--color-purple-5);
  border: 2px solid var(--color-purple-5);
}

.button.-purple-5:hover {
  background-color: transparent !important;
  color: var(--color-purple-5) !important;
}

.button.-outline-purple-5 {
  border: 2px solid var(--color-purple-5);
}

.button.-outline-purple-5:hover {
  background-color: var(--color-purple-5);
  border-color: transparent;
  color: white !important;
}

.button.-green-1 {
  background-color: var(--color-green-1);
  border: 2px solid var(--color-green-1);
}

.button.-green-1:hover {
  background-color: transparent !important;
  color: var(--color-green-1) !important;
}

.button.-outline-green-1 {
  border: 2px solid var(--color-green-1);
}

.button.-outline-green-1:hover {
  background-color: var(--color-green-1);
  border-color: transparent;
  color: white !important;
}

.button.-green-2 {
  background-color: var(--color-green-2);
  border: 2px solid var(--color-green-2);
}

.button.-green-2:hover {
  background-color: transparent !important;
  color: var(--color-green-2) !important;
}

.button.-outline-green-2 {
  border: 2px solid var(--color-green-2);
}

.button.-outline-green-2:hover {
  background-color: var(--color-green-2);
  border-color: transparent;
  color: white !important;
}

.button.-green-3 {
  background-color: var(--color-green-3);
  border: 2px solid var(--color-green-3);
}

.button.-green-3:hover {
  background-color: transparent !important;
  color: var(--color-green-3) !important;
}

.button.-outline-green-3 {
  border: 2px solid var(--color-green-3);
}

.button.-outline-green-3:hover {
  background-color: var(--color-green-3);
  border-color: transparent;
  color: white !important;
}

.button.-green-4 {
  background-color: var(--color-green-4);
  border: 2px solid var(--color-green-4);
}

.button.-green-4:hover {
  background-color: transparent !important;
  color: var(--color-green-4) !important;
}

.button.-outline-green-4 {
  border: 2px solid var(--color-green-4);
}

.button.-outline-green-4:hover {
  background-color: var(--color-green-4);
  border-color: transparent;
  color: white !important;
}

.button.-green-5 {
  background-color: var(--color-green-5);
  border: 2px solid var(--color-green-5);
}

.button.-green-5:hover {
  background-color: transparent !important;
  color: var(--color-green-5) !important;
}

.button.-outline-green-5 {
  border: 2px solid var(--color-green-5);
}

.button.-outline-green-5:hover {
  background-color: var(--color-green-5);
  border-color: transparent;
  color: white !important;
}

.button.-green-6 {
  background-color: var(--color-green-6);
  border: 2px solid var(--color-green-6);
}

.button.-green-6:hover {
  background-color: transparent !important;
  color: var(--color-green-6) !important;
}

.button.-outline-green-6 {
  border: 2px solid var(--color-green-6);
}

.button.-outline-green-6:hover {
  background-color: var(--color-green-6);
  border-color: transparent;
  color: white !important;
}

.button.-green-7 {
  background-color: var(--color-green-7);
  border: 2px solid var(--color-green-7);
}

.button.-green-7:hover {
  background-color: transparent !important;
  color: var(--color-green-7) !important;
}

.button.-outline-green-7 {
  border: 2px solid var(--color-green-7);
}

.button.-outline-green-7:hover {
  background-color: var(--color-green-7);
  border-color: transparent;
  color: white !important;
}

.button.-orange-1 {
  background-color: var(--color-orange-1);
  border: 2px solid var(--color-orange-1);
}

.button.-orange-1:hover {
  background-color: transparent !important;
  color: var(--color-orange-1) !important;
}

.button.-outline-orange-1 {
  border: 2px solid var(--color-orange-1);
}

.button.-outline-orange-1:hover {
  background-color: var(--color-orange-1);
  border-color: transparent;
  color: white !important;
}

.button.-orange-2 {
  background-color: var(--color-orange-2);
  border: 2px solid var(--color-orange-2);
}

.button.-orange-2:hover {
  background-color: transparent !important;
  color: var(--color-orange-2) !important;
}

.button.-outline-orange-2 {
  border: 2px solid var(--color-orange-2);
}

.button.-outline-orange-2:hover {
  background-color: var(--color-orange-2);
  border-color: transparent;
  color: white !important;
}

.button.-orange-3 {
  background-color: var(--color-orange-3);
  border: 2px solid var(--color-orange-3);
}

.button.-orange-3:hover {
  background-color: transparent !important;
  color: var(--color-orange-3) !important;
}

.button.-outline-orange-3 {
  border: 2px solid var(--color-orange-3);
}

.button.-outline-orange-3:hover {
  background-color: var(--color-orange-3);
  border-color: transparent;
  color: white !important;
}

.button.-orange-4 {
  background-color: var(--color-orange-4);
  border: 2px solid var(--color-orange-4);
}

.button.-orange-4:hover {
  background-color: transparent !important;
  color: var(--color-orange-4) !important;
}

.button.-outline-orange-4 {
  border: 2px solid var(--color-orange-4);
}

.button.-outline-orange-4:hover {
  background-color: var(--color-orange-4);
  border-color: transparent;
  color: white !important;
}

.button.-orange-5 {
  background-color: var(--color-orange-5);
  border: 2px solid var(--color-orange-5);
}

.button.-orange-5:hover {
  background-color: transparent !important;
  color: var(--color-orange-5) !important;
}

.button.-outline-orange-5 {
  border: 2px solid var(--color-orange-5);
}

.button.-outline-orange-5:hover {
  background-color: var(--color-orange-5);
  border-color: transparent;
  color: white !important;
}

.button.-orange-6 {
  background-color: var(--color-orange-6);
  border: 2px solid var(--color-orange-6);
}

.button.-orange-6:hover {
  background-color: transparent !important;
  color: var(--color-orange-6) !important;
}

.button.-outline-orange-6 {
  border: 2px solid var(--color-orange-6);
}

.button.-outline-orange-6:hover {
  background-color: var(--color-orange-6);
  border-color: transparent;
  color: white !important;
}

.button.-orange-7 {
  background-color: var(--color-orange-7);
  border: 2px solid var(--color-orange-7);
}

.button.-orange-7:hover {
  background-color: transparent !important;
  color: var(--color-orange-7) !important;
}

.button.-outline-orange-7 {
  border: 2px solid var(--color-orange-7);
}

.button.-outline-orange-7:hover {
  background-color: var(--color-orange-7);
  border-color: transparent;
  color: white !important;
}

.button.-red-1 {
  background-color: var(--color-red-1);
  border: 2px solid var(--color-red-1);
}

.button.-red-1:hover {
  background-color: transparent !important;
  color: var(--color-red-1) !important;
}

.button.-outline-red-1 {
  border: 2px solid var(--color-red-1);
}

.button.-outline-red-1:hover {
  background-color: var(--color-red-1);
  border-color: transparent;
  color: white !important;
}

.button.-red-2 {
  background-color: var(--color-red-2);
  border: 2px solid var(--color-red-2);
}

.button.-red-2:hover {
  background-color: transparent !important;
  color: var(--color-red-2) !important;
}

.button.-outline-red-2 {
  border: 2px solid var(--color-red-2);
}

.button.-outline-red-2:hover {
  background-color: var(--color-red-2);
  border-color: transparent;
  color: white !important;
}

.button.-red-3 {
  background-color: var(--color-red-3);
  border: 2px solid var(--color-red-3);
}

.button.-red-3:hover {
  background-color: transparent !important;
  color: var(--color-red-3) !important;
}

.button.-outline-red-3 {
  border: 2px solid var(--color-red-3);
}

.button.-outline-red-3:hover {
  background-color: var(--color-red-3);
  border-color: transparent;
  color: white !important;
}

.button.-beige-1 {
  background-color: var(--color-beige-1);
  border: 2px solid var(--color-beige-1);
}

.button.-beige-1:hover {
  background-color: transparent !important;
  color: var(--color-beige-1) !important;
}

.button.-outline-beige-1 {
  border: 2px solid var(--color-beige-1);
}

.button.-outline-beige-1:hover {
  background-color: var(--color-beige-1);
  border-color: transparent;
  color: white !important;
}

.button.-blue-1 {
  background-color: var(--color-blue-1);
  border: 2px solid var(--color-blue-1);
}

.button.-blue-1:hover {
  background-color: transparent !important;
  color: var(--color-blue-1) !important;
}

.button.-outline-blue-1 {
  border: 2px solid var(--color-blue-1);
}

.button.-outline-blue-1:hover {
  background-color: var(--color-blue-1);
  border-color: transparent;
  color: white !important;
}

.button.-blue-2 {
  background-color: var(--color-blue-2);
  border: 2px solid var(--color-blue-2);
}

.button.-blue-2:hover {
  background-color: transparent !important;
  color: var(--color-blue-2) !important;
}

.button.-outline-blue-2 {
  border: 2px solid var(--color-blue-2);
}

.button.-outline-blue-2:hover {
  background-color: var(--color-blue-2);
  border-color: transparent;
  color: white !important;
}

.button.-blue-3 {
  background-color: var(--color-blue-3);
  border: 2px solid var(--color-blue-3);
}

.button.-blue-3:hover {
  background-color: transparent !important;
  color: var(--color-blue-3) !important;
}

.button.-outline-blue-3 {
  border: 2px solid var(--color-blue-3);
}

.button.-outline-blue-3:hover {
  background-color: var(--color-blue-3);
  border-color: transparent;
  color: white !important;
}

.button.-blue-4 {
  background-color: var(--color-blue-4);
  border: 2px solid var(--color-blue-4);
}

.button.-blue-4:hover {
  background-color: transparent !important;
  color: var(--color-blue-4) !important;
}

.button.-outline-blue-4 {
  border: 2px solid var(--color-blue-4);
}

.button.-outline-blue-4:hover {
  background-color: var(--color-blue-4);
  border-color: transparent;
  color: white !important;
}

.button.-blue-5 {
  background-color: var(--color-blue-5);
  border: 2px solid var(--color-blue-5);
}

.button.-blue-5:hover {
  background-color: transparent !important;
  color: var(--color-blue-5) !important;
}

.button.-outline-blue-5 {
  border: 2px solid var(--color-blue-5);
}

.button.-outline-blue-5:hover {
  background-color: var(--color-blue-5);
  border-color: transparent;
  color: white !important;
}

.button.-blue-6 {
  background-color: var(--color-blue-6);
  border: 2px solid var(--color-blue-6);
}

.button.-blue-6:hover {
  background-color: transparent !important;
  color: var(--color-blue-6) !important;
}

.button.-outline-blue-6 {
  border: 2px solid var(--color-blue-6);
}

.button.-outline-blue-6:hover {
  background-color: var(--color-blue-6);
  border-color: transparent;
  color: white !important;
}

.button.-blue-7 {
  background-color: var(--color-blue-7);
  border: 2px solid var(--color-blue-7);
}

.button.-blue-7:hover {
  background-color: transparent !important;
  color: var(--color-blue-7) !important;
}

.button.-outline-blue-7 {
  border: 2px solid var(--color-blue-7);
}

.button.-outline-blue-7:hover {
  background-color: var(--color-blue-7);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-1 {
  background-color: var(--color-yellow-1);
  border: 2px solid var(--color-yellow-1);
}

.button.-yellow-1:hover {
  background-color: transparent !important;
  color: var(--color-yellow-1) !important;
}

.button.-outline-yellow-1 {
  border: 2px solid var(--color-yellow-1);
}

.button.-outline-yellow-1:hover {
  background-color: var(--color-yellow-1);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-2 {
  background-color: var(--color-yellow-2);
  border: 2px solid var(--color-yellow-2);
}

.button.-yellow-2:hover {
  background-color: transparent !important;
  color: var(--color-yellow-2) !important;
}

.button.-outline-yellow-2 {
  border: 2px solid var(--color-yellow-2);
}

.button.-outline-yellow-2:hover {
  background-color: var(--color-yellow-2);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-3 {
  background-color: var(--color-yellow-3);
  border: 2px solid var(--color-yellow-3);
}

.button.-yellow-3:hover {
  background-color: transparent !important;
  color: var(--color-yellow-3) !important;
}

.button.-outline-yellow-3 {
  border: 2px solid var(--color-yellow-3);
}

.button.-outline-yellow-3:hover {
  background-color: var(--color-yellow-3);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-4 {
  background-color: var(--color-yellow-4);
  border: 2px solid var(--color-yellow-4);
}

.button.-yellow-4:hover {
  background-color: transparent !important;
  color: var(--color-yellow-4) !important;
}

.button.-outline-yellow-4 {
  border: 2px solid var(--color-yellow-4);
}

.button.-outline-yellow-4:hover {
  background-color: var(--color-yellow-4);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-5 {
  background-color: var(--color-yellow-5);
  border: 2px solid var(--color-yellow-5);
}

.button.-yellow-5:hover {
  background-color: transparent !important;
  color: var(--color-yellow-5) !important;
}

.button.-outline-yellow-5 {
  border: 2px solid var(--color-yellow-5);
}

.button.-outline-yellow-5:hover {
  background-color: var(--color-yellow-5);
  border-color: transparent;
  color: white !important;
}

.button.-info-1 {
  background-color: var(--color-info-1);
  border: 2px solid var(--color-info-1);
}

.button.-info-1:hover {
  background-color: transparent !important;
  color: var(--color-info-1) !important;
}

.button.-outline-info-1 {
  border: 2px solid var(--color-info-1);
}

.button.-outline-info-1:hover {
  background-color: var(--color-info-1);
  border-color: transparent;
  color: white !important;
}

.button.-info-2 {
  background-color: var(--color-info-2);
  border: 2px solid var(--color-info-2);
}

.button.-info-2:hover {
  background-color: transparent !important;
  color: var(--color-info-2) !important;
}

.button.-outline-info-2 {
  border: 2px solid var(--color-info-2);
}

.button.-outline-info-2:hover {
  background-color: var(--color-info-2);
  border-color: transparent;
  color: white !important;
}

.button.-warning-1 {
  background-color: var(--color-warning-1);
  border: 2px solid var(--color-warning-1);
}

.button.-warning-1:hover {
  background-color: transparent !important;
  color: var(--color-warning-1) !important;
}

.button.-outline-warning-1 {
  border: 2px solid var(--color-warning-1);
}

.button.-outline-warning-1:hover {
  background-color: var(--color-warning-1);
  border-color: transparent;
  color: white !important;
}

.button.-warning-2 {
  background-color: var(--color-warning-2);
  border: 2px solid var(--color-warning-2);
}

.button.-warning-2:hover {
  background-color: transparent !important;
  color: var(--color-warning-2) !important;
}

.button.-outline-warning-2 {
  border: 2px solid var(--color-warning-2);
}

.button.-outline-warning-2:hover {
  background-color: var(--color-warning-2);
  border-color: transparent;
  color: white !important;
}

.button.-error-1 {
  background-color: var(--color-error-1);
  border: 2px solid var(--color-error-1);
}

.button.-error-1:hover {
  background-color: transparent !important;
  color: var(--color-error-1) !important;
}

.button.-outline-error-1 {
  border: 2px solid var(--color-error-1);
}

.button.-outline-error-1:hover {
  background-color: var(--color-error-1);
  border-color: transparent;
  color: white !important;
}

.button.-error-2 {
  background-color: var(--color-error-2);
  border: 2px solid var(--color-error-2);
}

.button.-error-2:hover {
  background-color: transparent !important;
  color: var(--color-error-2) !important;
}

.button.-outline-error-2 {
  border: 2px solid var(--color-error-2);
}

.button.-outline-error-2:hover {
  background-color: var(--color-error-2);
  border-color: transparent;
  color: white !important;
}

.button.-success-1 {
  background-color: var(--color-success-1);
  border: 2px solid var(--color-success-1);
}

.button.-success-1:hover {
  background-color: transparent !important;
  color: var(--color-success-1) !important;
}

.button.-outline-success-1 {
  border: 2px solid var(--color-success-1);
}

.button.-outline-success-1:hover {
  background-color: var(--color-success-1);
  border-color: transparent;
  color: white !important;
}

.button.-success-2 {
  background-color: var(--color-success-2);
  border: 2px solid var(--color-success-2);
}

.button.-success-2:hover {
  background-color: transparent !important;
  color: var(--color-success-2) !important;
}

.button.-outline-success-2 {
  border: 2px solid var(--color-success-2);
}

.button.-outline-success-2:hover {
  background-color: var(--color-success-2);
  border-color: transparent;
  color: white !important;
}

.button.-white-20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.button.-white-20:hover {
  background-color: white;
  color: var(--color-dark-1) !important;
}

.button.-gradient-1 {
  background: linear-gradient(90deg, #6440fb 0%, #e8543e 108.34%);
}

.button.-gradient-1:hover {
  color: var(--color-dark-1) !important;
}

.button.-purple-3:hover {
  color: white !important;
  background-color: var(--color-purple-1) !important;
}

.button.-dark-6:hover {
  color: var(--color-dark-1) !important;
  background-color: white !important;
}

.button.-light-7:hover {
  color: white !important;
  background-color: var(--color-purple-1) !important;
  border-color: var(--color-purple-1) !important;
}

.button.-outline-green-1:hover {
  color: var(--color-dark-1) !important;
}

.button.-outline-white:hover {
  color: var(--color-dark-1) !important;
}

.button.-outline-light-5:hover {
  color: var(--color-dark-1) !important;
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.dropdown__button {
  height: 50px;
  border-radius: 8px;
  background-color: var(--color-light-3);
  padding: 12px 16px;
}

.dropdown .icon {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.dropdown.-is-dd-active .icon {
  transform: rotate(180deg);
}

.dropdown__item {
  position: absolute;
  z-index: 500;
  pointer-events: none;
  top: 100%;
  left: 50%;
  padding: 26px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 180px;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
  opacity: 0;
}

.dropdown:hover .dropdown__item {
  z-index: 20;
  transform: translateX(-50%);
  opacity: 1;
  pointer-events: auto;
}

select,
input[type="text"],
input[type="search"],
textarea,
input[type="email"] {
  border: 0;
}

.-dark-mode form.contact-form select,
.-dark-mode form.contact-form input[type="text"],
.-dark-mode form.contact-form input[type="password"],
.-dark-mode form.contact-form input[type="search"],
.-dark-mode form.contact-form textarea,
.-dark-mode form.contact-form input[type="email"] {
  border-color: rgba(255, 255, 255, 0.2);
  color: white !important;
}

form.contact-form select,
form.contact-form input[type="text"],
form.contact-form input[type="password"],
form.contact-form input[type="search"],
form.contact-form textarea,
form.contact-form input[type="email"] {
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #dddddd;
  font-size: 15px;
  line-height: 1.5;
  padding: 15px 22px;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

form.contact-form select:focus,
form.contact-form input[type="text"]:focus,
form.contact-form input[type="password"]:focus,
form.contact-form input[type="search"]:focus,
form.contact-form textarea:focus,
form.contact-form input[type="email"]:focus {
  outline: none;
}

form.contact-form select.-border-dark,
form.contact-form input[type="text"].-border-dark,
form.contact-form input[type="password"].-border-dark,
form.contact-form input[type="search"].-border-dark,
form.contact-form textarea.-border-dark,
form.contact-form input[type="email"].-border-dark {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

form.contact-form ::-moz-placeholder {
  font-weight: 400;
}

form.contact-form :-ms-input-placeholder {
  font-weight: 400;
}

form.contact-form ::placeholder {
  font-weight: 400;
}

form.contact-form.-light label,
form.contact-form.-light p {
  color: white;
}

form.contact-form.-light select,
form.contact-form.-light input[type="text"],
form.contact-form.-light input[type="search"],
form.contact-form.-light textarea,
form.contact-form.-light input[type="email"] {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

form.contact-form.-light ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

form.contact-form.-light :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

form.contact-form.-light ::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.form-checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.form-checkbox label {
  margin: 0;
  margin-left: 9px;
  font-size: 14px;
}

.form-checkbox__mark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border: 2px solid var(--color-dark-3);
}

.form-checkbox__icon {
  display: none;
  position: absolute;
  font-size: 6px;
  color: white;
}

.form-checkbox input {
  cursor: pointer;
  z-index: 5;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.form-checkbox input:checked ~ .form-checkbox__mark {
  background-color: var(--color-dark-1);
  border-color: var(--color-dark-1);
}

.form-checkbox input:checked ~ .form-checkbox__mark .form-checkbox__icon {
  display: block;
}

.form-checkbox:hover input ~ .form-checkbox__mark {
  background-color: var(--color-dark-7);
}

.form-checkbox:hover input:checked ~ .form-checkbox__mark {
  background-color: var(--color-dark-1);
}

.form-radio {
  display: flex;
  align-items: center;
}

.form-radio .radio {
  cursor: pointer;
  position: relative;
  display: block;
}

.form-radio .radio__mark {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 2px solid var(--color-dark-3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-radio .radio__icon {
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--color-dark-1);
}

.form-radio .radio input {
  z-index: 5;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.form-radio .radio input:checked ~ .radio__mark {
  border-color: var(--color-dark-1);
}

.form-radio .radio input:checked ~ .radio__mark .radio__icon {
  display: block;
}

.form-radio .radio:hover input ~ .radio__mark {
  background-color: rgba(0, 0, 0, 0.05);
}

.form-switch .switch {
  position: relative;
  width: 60px;
  height: 30px;
}

.form-switch .switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  border-radius: 30px;
  border: 1px solid #e7e7ec;
}

.form-switch .switch__slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  background-color: var(--color-light-2);
  border-radius: 50%;
  transition: 0.3s;
}

.form-switch .switch input {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-switch .switch input:checked + .switch__slider {
  background-color: var(--color-purple-1);
}

.form-switch .switch input:checked + .switch__slider::before {
  transform: translateX(29px);
  background-color: #ffffff;
}

.form-single-field.-base {
  position: relative;
  height: 70px;
}

.form-single-field.-base > input {
  width: 100%;
  height: 100%;
}

.form-single-field.-base > input.text-white::-moz-placeholder {
  color: white;
}

.form-single-field.-base > input.text-white:-ms-input-placeholder {
  color: white;
}

.form-single-field.-base > input.text-white::placeholder {
  color: white;
}

.form-single-field.-base > input.text-dark-1::-moz-placeholder {
  color: var(--color-dark-1);
}

.form-single-field.-base > input.text-dark-1:-ms-input-placeholder {
  color: var(--color-dark-1);
}

.form-single-field.-base > input.text-dark-1::placeholder {
  color: var(--color-dark-1);
}

.form-single-field.-base > button {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  min-width: calc(70px - 20px);
}

.form-single-field.-help {
  position: relative;
  height: 70px;
}

.form-single-field.-help > input {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-light-5);
  padding: 24px;
  border-radius: 8px;
}

.form-single-field.-help > button {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  width: 144px;
}

.form-upload__wrap {
  position: relative;
}

.form-upload button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 28px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: 700;
  margin: 0;
  color: var(--color-dark-1);
}

.text-6 {
  font-size: var(--text-6) !important;
}

.text-9 {
  font-size: var(--text-9) !important;
}

.text-11 {
  font-size: var(--text-11) !important;
}

.text-13 {
  font-size: var(--text-13) !important;
}

.text-14 {
  font-size: var(--text-14) !important;
}

.text-15 {
  font-size: var(--text-15) !important;
}

.text-16 {
  font-size: var(--text-16) !important;
}

.text-17 {
  font-size: var(--text-17) !important;
}

.text-18 {
  font-size: var(--text-18) !important;
}

.text-20 {
  font-size: var(--text-20) !important;
}

.text-24 {
  font-size: var(--text-24) !important;
}

.text-30 {
  font-size: var(--text-30) !important;
}

.text-35 {
  font-size: var(--text-35) !important;
}

.text-40 {
  font-size: var(--text-40) !important;
}

.text-45 {
  font-size: var(--text-45) !important;
}

.text-50 {
  font-size: var(--text-50) !important;
}

.text-55 {
  font-size: var(--text-55) !important;
}

.text-60 {
  font-size: var(--text-60) !important;
}

.text-64 {
  font-size: var(--text-64) !important;
}

@media (max-width: 1199px) {
  .xl\:text-6 {
    font-size: var(--text-6) !important;
  }
  .xl\:text-9 {
    font-size: var(--text-9) !important;
  }
  .xl\:text-11 {
    font-size: var(--text-11) !important;
  }
  .xl\:text-13 {
    font-size: var(--text-13) !important;
  }
  .xl\:text-14 {
    font-size: var(--text-14) !important;
  }
  .xl\:text-15 {
    font-size: var(--text-15) !important;
  }
  .xl\:text-16 {
    font-size: var(--text-16) !important;
  }
  .xl\:text-17 {
    font-size: var(--text-17) !important;
  }
  .xl\:text-18 {
    font-size: var(--text-18) !important;
  }
  .xl\:text-20 {
    font-size: var(--text-20) !important;
  }
  .xl\:text-24 {
    font-size: var(--text-24) !important;
  }
  .xl\:text-30 {
    font-size: var(--text-30) !important;
  }
  .xl\:text-35 {
    font-size: var(--text-35) !important;
  }
  .xl\:text-40 {
    font-size: var(--text-40) !important;
  }
  .xl\:text-45 {
    font-size: var(--text-45) !important;
  }
  .xl\:text-50 {
    font-size: var(--text-50) !important;
  }
  .xl\:text-55 {
    font-size: var(--text-55) !important;
  }
  .xl\:text-60 {
    font-size: var(--text-60) !important;
  }
  .xl\:text-64 {
    font-size: var(--text-64) !important;
  }
}

@media (max-width: 991px) {
  .lg\:text-6 {
    font-size: var(--text-6) !important;
  }
  .lg\:text-9 {
    font-size: var(--text-9) !important;
  }
  .lg\:text-11 {
    font-size: var(--text-11) !important;
  }
  .lg\:text-13 {
    font-size: var(--text-13) !important;
  }
  .lg\:text-14 {
    font-size: var(--text-14) !important;
  }
  .lg\:text-15 {
    font-size: var(--text-15) !important;
  }
  .lg\:text-16 {
    font-size: var(--text-16) !important;
  }
  .lg\:text-17 {
    font-size: var(--text-17) !important;
  }
  .lg\:text-18 {
    font-size: var(--text-18) !important;
  }
  .lg\:text-20 {
    font-size: var(--text-20) !important;
  }
  .lg\:text-24 {
    font-size: var(--text-24) !important;
  }
  .lg\:text-30 {
    font-size: var(--text-30) !important;
  }
  .lg\:text-35 {
    font-size: var(--text-35) !important;
  }
  .lg\:text-40 {
    font-size: var(--text-40) !important;
  }
  .lg\:text-45 {
    font-size: var(--text-45) !important;
  }
  .lg\:text-50 {
    font-size: var(--text-50) !important;
  }
  .lg\:text-55 {
    font-size: var(--text-55) !important;
  }
  .lg\:text-60 {
    font-size: var(--text-60) !important;
  }
  .lg\:text-64 {
    font-size: var(--text-64) !important;
  }
}

@media (max-width: 767px) {
  .md\:text-6 {
    font-size: var(--text-6) !important;
  }
  .md\:text-9 {
    font-size: var(--text-9) !important;
  }
  .md\:text-11 {
    font-size: var(--text-11) !important;
  }
  .md\:text-13 {
    font-size: var(--text-13) !important;
  }
  .md\:text-14 {
    font-size: var(--text-14) !important;
  }
  .md\:text-15 {
    font-size: var(--text-15) !important;
  }
  .md\:text-16 {
    font-size: var(--text-16) !important;
  }
  .md\:text-17 {
    font-size: var(--text-17) !important;
  }
  .md\:text-18 {
    font-size: var(--text-18) !important;
  }
  .md\:text-20 {
    font-size: var(--text-20) !important;
  }
  .md\:text-24 {
    font-size: var(--text-24) !important;
  }
  .md\:text-30 {
    font-size: var(--text-30) !important;
  }
  .md\:text-35 {
    font-size: var(--text-35) !important;
  }
  .md\:text-40 {
    font-size: var(--text-40) !important;
  }
  .md\:text-45 {
    font-size: var(--text-45) !important;
  }
  .md\:text-50 {
    font-size: var(--text-50) !important;
  }
  .md\:text-55 {
    font-size: var(--text-55) !important;
  }
  .md\:text-60 {
    font-size: var(--text-60) !important;
  }
  .md\:text-64 {
    font-size: var(--text-64) !important;
  }
}

@media (max-width: 575px) {
  .sm\:text-6 {
    font-size: var(--text-6) !important;
  }
  .sm\:text-9 {
    font-size: var(--text-9) !important;
  }
  .sm\:text-11 {
    font-size: var(--text-11) !important;
  }
  .sm\:text-13 {
    font-size: var(--text-13) !important;
  }
  .sm\:text-14 {
    font-size: var(--text-14) !important;
  }
  .sm\:text-15 {
    font-size: var(--text-15) !important;
  }
  .sm\:text-16 {
    font-size: var(--text-16) !important;
  }
  .sm\:text-17 {
    font-size: var(--text-17) !important;
  }
  .sm\:text-18 {
    font-size: var(--text-18) !important;
  }
  .sm\:text-20 {
    font-size: var(--text-20) !important;
  }
  .sm\:text-24 {
    font-size: var(--text-24) !important;
  }
  .sm\:text-30 {
    font-size: var(--text-30) !important;
  }
  .sm\:text-35 {
    font-size: var(--text-35) !important;
  }
  .sm\:text-40 {
    font-size: var(--text-40) !important;
  }
  .sm\:text-45 {
    font-size: var(--text-45) !important;
  }
  .sm\:text-50 {
    font-size: var(--text-50) !important;
  }
  .sm\:text-55 {
    font-size: var(--text-55) !important;
  }
  .sm\:text-60 {
    font-size: var(--text-60) !important;
  }
  .sm\:text-64 {
    font-size: var(--text-64) !important;
  }
}

@media (max-width: 479px) {
  .xs\:text-6 {
    font-size: var(--text-6) !important;
  }
  .xs\:text-9 {
    font-size: var(--text-9) !important;
  }
  .xs\:text-11 {
    font-size: var(--text-11) !important;
  }
  .xs\:text-13 {
    font-size: var(--text-13) !important;
  }
  .xs\:text-14 {
    font-size: var(--text-14) !important;
  }
  .xs\:text-15 {
    font-size: var(--text-15) !important;
  }
  .xs\:text-16 {
    font-size: var(--text-16) !important;
  }
  .xs\:text-17 {
    font-size: var(--text-17) !important;
  }
  .xs\:text-18 {
    font-size: var(--text-18) !important;
  }
  .xs\:text-20 {
    font-size: var(--text-20) !important;
  }
  .xs\:text-24 {
    font-size: var(--text-24) !important;
  }
  .xs\:text-30 {
    font-size: var(--text-30) !important;
  }
  .xs\:text-35 {
    font-size: var(--text-35) !important;
  }
  .xs\:text-40 {
    font-size: var(--text-40) !important;
  }
  .xs\:text-45 {
    font-size: var(--text-45) !important;
  }
  .xs\:text-50 {
    font-size: var(--text-50) !important;
  }
  .xs\:text-55 {
    font-size: var(--text-55) !important;
  }
  .xs\:text-60 {
    font-size: var(--text-60) !important;
  }
  .xs\:text-64 {
    font-size: var(--text-64) !important;
  }
}

.lh-1 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.lh-17 {
  line-height: 1.7 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.about-content__title {
  font-size: 45px;
  line-height: 52px;
  font-weight: 700;
  color: var(--color-dark-1);
}

.about-content__title span {
  color: #6440fb;
}

.about-content__text {
  margin-top: 20px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-dark-1);
}

.about-content__btn {
  margin-top: 30px;
}

.about-content__btn a {
  display: inline-block;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  background-color: #1a064f;
  padding: 21px 55px;
  color: white;
}

.about-content .about-content-list {
  margin-top: 30px;
}

.about-content .about-content-list > * + * {
  margin-top: 35px;
}

.about-content .about-content-list__item {
  display: flex;
  align-items: center;
}

.about-content .about-content-list__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #6440fb;
}

.about-content .about-content-list__icon .icon {
  color: white;
  width: 12px;
  height: 12px;
  stroke-width: 2;
}

.about-content .about-content-list__title {
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  color: var(--color-dark-1);
}

.about-image img {
  width: 100%;
}

.app-content {
  padding-left: 40px;
}

@media (max-width: 767px) {
  .app-content {
    padding-left: 0;
  }
}

.app-content__title {
  font-size: 45px;
  line-height: 52px;
  font-weight: 700;
  color: var(--color-dark-1);
}

.app-content__title span {
  color: #6440fb;
}

.app-content__text {
  margin-top: 20px;
  color: var(--color-dark-1);
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
}

.app-content__buttons {
  margin-top: 30px;
  display: flex;
}

.app-content__buttons > * + * {
  margin-left: 20px;
}

.blogCard.-type-1 .blogCard__image img {
  width: 100%;
}

.blogCard.-type-1 .blogCard__content {
  margin-top: 20px;
}

.blogCard.-type-1 .blogCard__category {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #6440fb;
}

.blogCard.-type-1 .blogCard__title {
  margin-top: 10px;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: #242239;
}

.blogCard.-type-1 .blogCard__date {
  margin-top: 10px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: var(--color-light-1);
}

.blogCard.-type-2 {
  display: flex;
  align-items: center;
}

.blogCard.-type-2 .blogCard__image {
  flex-shrink: 0;
}

.blogCard.-type-2 .blogCard__image img {
  width: 100%;
}

.blogCard.-type-2 .blogCard__content {
  margin-left: 17px;
}

.blogCard.-type-2 .blogCard__category {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  text-transform: uppercase;
  color: #6440fb;
}

.blogCard.-type-2 .blogCard__title {
  margin-top: 10px;
  color: #242239;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
}

.blogCard.-type-2 .blogCard__date {
  margin-top: 10px;
  color: var(--color-light-1);
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
}

.blogCard.-type-3 .blogCard__image img {
  width: 100%;
}

.blogCard.-type-3 .blogCard__category {
  text-transform: uppercase;
}

.coursesCard.-type-1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-1 .coursesCard__image {
  overflow: hidden;
}

.coursesCard.-type-1 .coursesCard__image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-1);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-1 .coursesCard__image img {
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-1 .coursesCard-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ededed;
}

.coursesCard.-type-1 .coursesCard-footer__author {
  display: flex;
  align-items: center;
}

.coursesCard.-type-1 .coursesCard-footer__author img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.coursesCard.-type-1 .coursesCard-footer__author div {
  margin-left: 10px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color-light-1);
}

.coursesCard.-type-1 .coursesCard-footer__price {
  display: flex;
  align-items: center;
}

.coursesCard.-type-1 .coursesCard-footer__price > div:nth-child(1) {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  margin-right: 10px;
  color: var(--color-light-1);
  text-decoration: line-through;
}

.coursesCard.-type-1 .coursesCard-footer__price > div:nth-child(2) {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: var(--color-dark-1);
}

.coursesCard.-type-1.-hover-shadow:hover {
  box-shadow: 0px 6px 15px 0px #404f680d;
}

.coursesCard.-type-1:hover {
  color: inherit;
}

.coursesCard.-type-1:hover .coursesCard__image_overlay {
  opacity: 0.5;
}

.coursesCard.-type-1:hover .coursesCard__image img {
  transform: scale(1.12);
}

.coursesCard.-type-2 {
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-2:hover {
  box-shadow: 0px 25px 70px 0px #01213a12;
}

.coursesCard.-type-3 {
  padding: 0 40px;
}

@media (max-width: 991px) {
  .coursesCard.-type-3 {
    padding: 0;
  }
}

.coursesCard.-type-3 .coursesCard__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  font-size: 50px;
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-3 .coursesCard__icon .icon {
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-3:hover .coursesCard__icon {
  background-color: var(--color-purple-1) !important;
}

.coursesCard.-type-3:hover .coursesCard__icon .icon {
  color: white !important;
}

.coursesCard.-type-4 {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-4 .coursesCard__image {
  position: relative;
  overflow: hidden;
}

.coursesCard.-type-4 .coursesCard__image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-1);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-4 .coursesCard__image img {
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.coursesCard.-type-4:hover {
  color: inherit;
}

.coursesCard.-type-4:hover .coursesCard__image_overlay {
  opacity: 0.5;
}

.coursesCard.-type-4:hover .coursesCard__image img {
  transform: scale(1.12);
}

.cta.-type-1 {
  z-index: 0;
  position: relative;
  padding-top: 208px;
  padding-bottom: 168px;
}

@media (max-width: 767px) {
  .cta.-type-1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 575px) {
  .cta.-type-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.cta.-type-1 .cta__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.featureCard.-type-1 {
  height: 100%;
}

.featureCard.-type-1 .featureCard__content {
  height: 100%;
  background: var(--color-light-3);
  border-radius: 8px;
  padding: 26px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.featureCard.-type-1 .featureCard__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0%;
  background-color: white;
  width: auto;
  height: auto;
}

.featureCard.-type-1 .featureCard__title {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  color: var(--color-dark-1);
}

.featureCard.-type-1 .featureCard__text {
  margin-top: 10px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  color: var(--color-light-1);
}

.footer.-type-1 {
  color: white;
}

.footer.-type-1.-dark {
  color: var(--color-dark-1);
}

.footer.-type-1.-dark a {
  color: var(--color-light-1);
}

.footer.-type-1 .footer-header {
  padding: 60px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

@media (max-width: 767px) {
  .footer.-type-1 .footer-header {
    padding-bottom: 0;
  }
}

.footer.-type-1 .footer-header .footer-header-socials {
  display: flex;
  align-items: center;
}

.footer.-type-1 .footer-header .footer-header-socials__title {
  font-size: 17px;
  line-height: 26px;
  margin-right: 20px;
}

.footer.-type-1 .footer-header .footer-header-socials__list {
  display: flex;
}

.footer.-type-1 .footer-header .footer-header-socials__list > * + * {
  margin-left: 4px;
}

.footer.-type-1 .footer-header .footer-header-socials__list a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: 14px;
}

.footer.-type-1 .footer-header .footer-header-socials__list a:hover {
  color: white !important;
  background-color: #2b1c55 !important;
}

.footer.-type-1 .footer-columns {
  padding-top: 60px;
  padding-bottom: 127px;
}

@media (max-width: 767px) {
  .footer.-type-1 .footer-columns {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.footer.-type-1 .footer-columns__title {
  margin-bottom: 26px;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: 500;
}

.footer.-type-1 .footer-columns__links {
  display: flex;
  flex-direction: column;
}

.footer.-type-1 .footer-columns__links a {
  display: block;
  font-size: 15px;
  line-height: 35px;
  font-weight: 400;
}

.footer.-type-1 .footer-columns__links a:hover {
  color: var(--color-purple-1);
}

.footer.-type-1 .footer-columns .footer-columns-form > div {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer.-type-1 .footer-columns .footer-columns-form .form-group {
  position: relative;
}

.footer.-type-1 .footer-columns .footer-columns-form .form-group input {
  width: 100%;
  border-radius: 100px;
  background-color: white;
  padding: 22px 30px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color-light-1);
}

.footer.-type-1
  .footer-columns
  .footer-columns-form
  .form-group
  input::-moz-placeholder {
  color: var(--color-light-1);
}

.footer.-type-1
  .footer-columns
  .footer-columns-form
  .form-group
  input:-ms-input-placeholder {
  color: var(--color-light-1);
}

.footer.-type-1
  .footer-columns
  .footer-columns-form
  .form-group
  input::placeholder {
  color: var(--color-light-1);
}

.footer.-type-1 .footer-columns .footer-columns-form .form-group button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: white;
  padding: 12px 24px;
  background-color: #6440fb;
  border-radius: 100px;
}

.footer.-type-1 .footer-footer {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.footer.-type-1 .footer-footer__copyright {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
}

.footer.-type-1 .footer-footer__right {
  display: flex;
  align-items: center;
}

@media (max-width: 575px) {
  .footer.-type-1 .footer-footer__right {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer.-type-1 .footer-footer__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer.-type-1 .footer-footer__list > * {
  margin: 0 5px;
}

.footer.-type-1 .footer-footer__list a {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
}

.footer.-type-1 .footer-footer__button {
  margin-left: 30px;
}

@media (max-width: 575px) {
  .footer.-type-1 .footer-footer__button {
    margin-left: 0;
    margin-top: 24px;
  }
}

.footer.-type-1 .footer-footer__button a {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px 30px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  border-radius: 100px;
  color: white;
}

.footer.-type-1.-green-links .footer-columns a:hover {
  text-decoration: underline;
  color: var(--color-green-1) !important;
}

.footer.-type-4 .footer-header-socials__list {
  margin-left: -15px;
}

.footer.-type-4 .footer-header-socials__list a {
  position: relative;
  z-index: 1;
}

.footer.-type-4 .footer-header-socials__list a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: var(--color-dark-6);
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.footer.-type-4 .footer-header-socials__list a:hover::after {
  opacity: 1;
}

.footer.-type-5 .footer-header-socials__list {
  margin-left: -15px;
}

.footer.-type-5 .footer-header-socials__list a {
  position: relative;
  z-index: 1;
}

.footer.-type-5 .footer-header-socials__list a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: var(--color-dark-6);
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.footer.-type-5 .footer-header-socials__list a:hover::after {
  opacity: 1;
}

.header {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.header__explore > a {
  position: relative;
  z-index: 1;
}

.header__explore > a::before {
  content: "";
  position: absolute;
  top: -6px;
  bottom: -6px;
  left: -14px;
  right: -14px;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.header__explore > a:hover {
  color: var(--color-green-1) !important;
}

.header__explore > a:hover::before {
  opacity: 1;
}

.header.-type-1 {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-dark-1);
  padding: 0 0px;
}

@media (max-width: 991px) {
  .header.-type-1 {
    padding: 0;
  }
}

.header.-type-1 .header__container {
  position: relative;
  max-width: calc(1500px);
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}



@media (max-width: 991px) {
  .header.-type-1 .header__container {
    padding: 20px;
  }
}

.header.-type-1 .header-left {
  display: flex;
  align-items: center;
}

@media (min-width: 1200px) {
  .header .header-menu {
    position: absolute;
    left: 0;
    z-index: 5;
  }
  .header .header-menu .header-menu__content {
    display: flex;
    align-items: center;
  }
  .header .header-menu-close {
    display: none;
  }
  .header .header-menu-bg {
    display: none;
  }
  .header .header-menu .menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .header .header-menu .menu__nav {
    display: flex;
  }
  .header .header-menu .menu__nav a {
    display: flex;
    align-items: center;
    padding: 7px 16px;
    border-radius: 8px;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .header .header-menu .menu__nav a {
    padding: 6px 6px;
  }
}

@media (min-width: 1200px) {
  .header .header-menu .menu__nav li.menu-item-has-children a {
    display: flex;
    align-items: center;
  }
  .header
    .header-menu
    .menu__nav
    > li.menu-item-has-children
    > a
    .icon-chevron-right {
    transform: rotate(90deg);
  }
  .header .header-menu .menu__nav > li {
    padding: 17px 0;
  }
  .header .header-menu .menu__nav > li:hover > a {
    color: var(--color-green-1);
    background-color: rgba(255, 255, 255, 0.15);
  }
  .header .header-menu .menu__nav > li > .subnav::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 10px;
    height: 10px;
    background-color: white;
    transform: rotate(45deg);
  }
  .header .header-menu .menu li.-has-mega-menu:hover > .mega {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
  .header .header-menu .menu li:hover > .subnav {
    opacity: 1;
    pointer-events: auto;
  }
  .header .header-menu .menu .subnav {
    position: absolute;
    top: 92%;
    background-color: white;
    border-radius: 8px;
    color: black !important;
    min-width: 230px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 25px 70px 0px #01213a12;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .header .header-menu .menu .subnav .subnav {
    top: 0;
    left: 97%;
  }
  .header .header-menu .menu .subnav > li {
    position: relative;
  }
  .header .header-menu .menu .subnav > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
  }
  .header .header-menu .menu .subnav > li > a:hover {
    color: var(--color-purple-1);
  }
  .header .header-menu .menu .subnav .menu__backButton {
    display: none;
  }
  .header .header-menu .menu > .subnav {
    top: 100%;
    left: 0;
  }
  .header .header-menu .mobile-bg {
    display: none;
  }
  .header .header-menu .mobile-back-button {
    display: none;
  }
  .header .header-menu .mobile-footer {
    display: none;
  }
}

@media (max-width: 1199px) {
  .header .header-menu {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 10;
    max-width: calc(100vw - 80px);
    width: 60vw;
    height: 100vh;
    padding: 0 !important;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    pointer-events: none;
  }
  .header .header-menu .header-menu__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateX(-20%);
  }
  .header .header-menu ul {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .header .header-menu.-is-el-visible {
    opacity: 1;
    pointer-events: auto;
  }
  .header .header-menu.-is-el-visible .-is-active {
    pointer-events: auto !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .header .header-menu.-is-el-visible .header-menu__content {
    transform: none;
  }
}

@media (max-width: 1199px) and (max-width: 767px) {
  .header .header-menu {
    width: 80vw;
  }
}

@media (max-width: 1199px) and (max-width: 575px) {
  .header .header-menu {
    width: calc(100vw - 60px);
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .header .header-menu-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: -1;
  }
}

@media (max-width: 1199px) and (max-width: 575px) {
  .header .header-menu-close {
    top: 10px;
    right: 10px;
  }
}

@media (max-width: 1199px) {
  .header .header-menu-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(24, 24, 26, 0.7);
    z-index: -2;
  }
  .header .header-menu .menu {
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    padding: 20px;
    height: 100%;
  }
  .header .header-menu .menu ul {
    pointer-events: none;
  }
  .header .header-menu .menu__nav {
    display: flex;
    flex-direction: column;
  }
  .header .header-menu .menu__nav a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    border-radius: 8px;
    color: var(--color-dark-1) !important;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .header .header-menu .menu__nav a:hover {
    color: var(--color-purple-1) !important;
    background-color: #f4f2ff;
  }
  .header .header-menu .menu__nav > li > a {
    font-weight: 500;
    font-size: 16px;
  }
  .header .header-menu .menu__nav li {
    overflow: hidden;
  }
  .header .header-menu .menu .subnav {
    position: absolute;
    top: 0;
    left: 0;
    color: black !important;
    padding: 20px;
    width: 100%;
    height: 100%;
  }
  .header .header-menu .menu .subnav a {
    transform: translateY(100%);
  }
  .header .header-menu .menu .subnav > li > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    padding: 0 20px;
    color: var(--color-dark-1) !important;
    transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .header .header-menu .menu .subnav > li > a:hover {
    color: var(--color-purple-1) !important;
  }
  .header .header-menu .menu .subnav .menu__backButton a {
    justify-content: flex-start !important;
    height: 60px !important;
    font-weight: 600 !important;
    color: var(--color-purple-1) !important;
    font-size: 16px;
    background-color: var(--color-purple-3);
  }
  .header .header-menu .mobile-bg {
    position: fixed;
    top: 0;
    left: 0;
    max-width: calc(100vw - 80px);
    width: 60vw;
    height: 100vh;
    z-index: -1;
    background-color: white;
  }
}

@media (max-width: 1199px) and (max-width: 767px) {
  .header .header-menu .mobile-bg {
    width: 80vw;
  }
}

@media (max-width: 1199px) and (max-width: 575px) {
  .header .header-menu .mobile-bg {
    width: calc(100vw - 60px);
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .header .header-menu .mobile-footer .mobile-socials {
    display: flex;
    margin-left: -15px;
  }
  .header .header-menu .mobile-footer .mobile-socials a {
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .header .header-menu .mobile-footer .mobile-socials a:hover {
    background-color: var(--color-light-4);
    color: var(--color-purple-1) !important;
  }
}

.header.-base {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.header.-base .header__container {
  position: relative;
  padding: 0 60px;
}

@media (max-width: 991px) {
  .header.-base .header__container {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .header.-base .header__container {
    padding: 0 15px;
  }
}

.header.-base .header-menu {
  position: relative;
}

.header.-base .header-menu .menu {
  position: relative;
  left: unset;
  transform: none;
}

.header.-type-3 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: white;
  width: 100%;
  padding: 0 60px;
}

@media (max-width: 991px) {
  .header.-type-3 {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .header.-type-3 {
    padding: 0 15px;
  }
}

.header.-type-3 .header__container {
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.header.-type-3 .header-menu {
  position: relative;
}

.header.-type-3 .header-menu .menu {
  position: relative;
  left: unset;
  transform: none;
}

@media (max-width: 1670px) {
  .header.-type-3 .header-search-field {
    display: none;
  }
}

.header.-type-3 .header-search-field__group {
  position: relative;
  max-width: 340px;
  min-width: 260px;
}

.header.-type-3 .header-search-field input {
  width: 100%;
  font-size: 13px;
  line-height: 1;
  background-color: var(--color-light-3);
  border-radius: 8px;
  padding: 18px 28px;
}

.header.-type-3 .header-search-field button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
}

.header.-type-3 .header-search-field button .icon {
  font-size: 20px;
  color: var(--color-dark-1);
}

.header.-type-4 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0 60px;
}

@media (max-width: 1199px) {
  .header.-type-4 {
    padding: 0 45px;
  }
}

@media (max-width: 991px) {
  .header.-type-4 {
    padding: 0 15px;
  }
}

.header.-type-4.-shadow {
  box-shadow: 0px 6px 15px 0px #404f680d;
}

.header.-type-4 *.-before-border {
  position: relative;
}

.header.-type-4 *.-before-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #ededed;
}

.header.-type-4 *.-after-border {
  position: relative;
}

.header.-type-4 *.-after-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #ededed;
}

.header.-type-4 .header__container {
  position: relative;
}

.header.-type-4 .header__container .row {
  flex-wrap: nowrap;
}

.header.-type-4 .header-menu {
  position: relative;
}

.header.-type-4 .header-menu .menu {
  position: relative;
  left: unset;
  transform: none;
}

.header.-type-4 .header-search-field {
  position: relative;
}

@media (max-width: 1670px) {
  .header.-type-4 .header-search-field {
    display: none;
  }
}

.header.-type-4 .header-search-field__group {
  position: relative;
  max-width: 300px;
  min-width: 250px;
}

.header.-type-4 .header-search-field input {
  width: 100%;
  font-size: 13px;
  line-height: 1;
  background-color: white;
  border: 1px solid #ededed;
  border-radius: 100px;
  padding: 17px 28px;
}

.header.-type-4 .header-search-field button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60px;
}

.header.-type-4 .header-search-field button .icon {
  font-size: 20px;
  color: var(--color-dark-1);
}

.header.-type-5 {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.header.-type-5 .header__container {
  width: 100%;
  padding: 0 60px;
}

@media (max-width: 1199px) {
  .header.-type-5 .header__container {
    padding: 0 45px;
  }
}

@media (max-width: 991px) {
  .header.-type-5 .header__container {
    padding: 0 15px;
  }
}

.header.-type-5 .header__container .row {
  flex-wrap: nowrap;
}

.header.-type-5 .header-menu {
  position: relative;
}

.header.-type-5 .header-menu .menu {
  position: relative;
  left: unset;
  transform: none;
}

.header.-base-sidebar {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
}

.header.-base-sidebar .row {
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .header.-base-sidebar .row {
    margin: 0;
  }
  .header.-base-sidebar .col-auto {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .header.-base-sidebar .header__logo img {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .dashboard.-home-9 {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .dashboard.-home-9 {
    margin-top: 60px;
  }
}

.dashboard.-home-9 .dashboard__sidebar {
  will-change: transform;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 991px) {
  .dashboard.-home-9 .dashboard__sidebar {
    z-index: 110;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 300px;
    margin-top: 90px;
  }
}

@media (max-width: 767px) {
  .dashboard.-home-9 .dashboard__sidebar {
    width: 80%;
  }
}

.dashboard.-home-9 .dashboard__main {
  will-change: padding-left;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 991px) {
  .dashboard.-home-9 .dashboard__main::after {
    content: "";
    position: fixed;
    z-index: 50;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.dashboard.-home-9.-is-sidebar-hidden .dashboard__sidebar {
  transform: translateX(-100%);
}

.dashboard.-home-9.-is-sidebar-hidden .dashboard__main {
  padding-left: 0;
}

.dashboard.-home-9.-is-sidebar-hidden .dashboard__main::after {
  pointer-events: none;
  opacity: 0;
}

.sidebar.-base-sidebar .sidebar__inner {
  padding-top: 40px;
}

.sidebar.-base-sidebar .sidebar__item {
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 0 20px;
  height: 55px;
}

@media (max-width: 575px) {
  .sidebar.-base-sidebar .sidebar__item {
    border-radius: 0;
    height: 45px;
  }
}

.sidebar.-base-sidebar .sidebar__item.-is-active {
  background-color: var(--color-purple-3);
  color: var(--color-purple-1) !important;
}

.sidebar.-base-sidebar .sidebar__links > div > a {
  display: flex;
  align-items: center;
}

.sidebar.-base-sidebar .sidebar__links > div > a::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  background-color: currentColor;
  border-radius: 100%;
  width: 5px;
  height: 5px;
}

.sidebar.-base-sidebar .sidebar__links > div > a:hover {
  color: var(--color-dark-1);
}

.sidebar.-base-sidebar .sidebar__links > div > a:hover::before {
  background-color: var(--color-dark-1);
}

.header .header-search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 590px;
}

.header .header-search__field {
  position: relative;
}

.header .header-search__field input {
  padding: 18px 36px;
  border-bottom: 1px solid #ededed;
}

.header .header-search__field input:focus {
  outline: none;
  border-bottom: 1px solid var(--color-dark-1);
}

.header .header-search__field input::-moz-placeholder {
  color: var(--color-dark-1);
}

.header .header-search__field input:-ms-input-placeholder {
  color: var(--color-dark-1);
}

.header .header-search__field input::placeholder {
  color: var(--color-dark-1);
}

.header .header-search__field .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 20px;
}

.header .header-search__field button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.header .header-search__bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 26, 0.7);
}

.header .header-cart {
  position: absolute;
  top: calc(100% + 24px);
  right: -16px;
  z-index: 10;
  min-width: 410px;
  box-shadow: 0px 0px 70px 0px #01213a12;
}

@media (max-width: 575px) {
  .header .header-cart {
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    width: 100vw;
    min-width: 100vw;
  }
}

.header .explore-content {
  position: absolute;
  top: 100%;
  box-shadow: inset 0px 0px 0px 1px #dddddd;
  min-width: 300px;
}

.header .explore-content::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 25px 70px 0px #01213a12;
}

.header .explore-content a {
  display: flex;
  padding: 5px 0;
}

.header .explore-content .explore__subnav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 95%;
  min-width: 300px;
  padding: 22px 40px;
  box-shadow: 0px 25px 70px 0px #01213a12;
  background-color: white;
  z-index: -1;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  pointer-events: none;
}

.header .explore-content .explore__item {
  z-index: -1;
  padding: 0 30px;
}

.header .explore-content .explore__item:hover .explore__subnav {
  opacity: 1;
  pointer-events: auto;
}

.header .header-menu .mega {
  position: fixed;
  left: 50%;
  background-color: white;
  padding: 30px;
  width: 1500px;
  margin-top: 10px;
  min-height: 430px;
  transform: translate(-50%);
  border-radius: 8px;
  box-shadow: 0px 25px 70px 0px #01213a12;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: 1800px) {
  .header .header-menu .mega {
    width: 85vw;
  }
}

@media screen and (max-width: 1580px) {
  .header .header-menu .mega {
    width: 100vw;
  }
}

.header .header-menu .mega__menu {
  display: flex;
  height: 100%;
}

.header .header-menu .mega__menu .row {
  width: 100%;
}

.header .header-menu .mega__menu a {
  padding: 6px 0;
  color: var(--color-dark-1) !important;
}

.header .header-menu .mega__menu a:hover {
  color: var(--color-purple-1) !important;
}

.header .header-menu .mega .mega-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  height: 100%;
  max-width: 310px;
  padding: 30px;
  border-radius: 8px;
}

.masthead.-type-1 {
  z-index: 1;
  position: relative;
  padding: 140px 0 160px 0;
  padding: 140px 0 180px 0;
}

@media (max-width: 991px) {
  .masthead.-type-1 {
    padding-top: 140px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 {
    padding-bottom: 80px;
    padding-bottom: 160px;
  }
}

.masthead.-type-1 .container {
  max-width: 1530px;
  padding: 0 15px;
  margin-top: -100px;
}

.masthead.-type-1 .masthead__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-1);
}

.masthead.-type-1 .masthead__bg img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.masthead.-type-1 .masthead__title {
  font-size: 55px;
  line-height: 80px;
  font-weight: 700;
  color: white;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-1 .masthead__title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-1 .masthead__title {
    font-size: 30px;
  }
}

.masthead.-type-1 .masthead__text {
  margin-top: 13px;
  font-size: 17px;
  line-height: 36px;
  color: white;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead__text {
    font-size: 16px;
    line-height: 1.6;
  }
  .masthead.-type-1 .masthead__text br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-1 .masthead__text {
    margin-top: 10px;
    font-size: 15px;
  }
}

.masthead.-type-1 .masthead__buttons {
  padding-top: 27px;
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead__buttons {
    padding-top: 15px;
  }
  .masthead.-type-1 .masthead__buttons a {
    width: 100%;
  }
}

.masthead.-type-1 .masthead-image {
  display: grid;
  gap: 66px;
  grid-template-columns: 0.6fr 0.4fr;
  padding-left: 80px;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead-image {
    padding-left: 0px;
    grid-template-columns: 1fr 1fr;
    gap: 40px 24px;
    width: 85%;
  }
}

@media (max-width: 767px) {
  .masthead.-type-1 .masthead-image {
    width: 100%;
  }
}

.masthead.-type-1 .masthead-image > * {
  position: relative;
}

.masthead.-type-1 .masthead-image__el1 {
  grid-row: span 2;
  padding-top: 48px;
}

.masthead.-type-1 .masthead-image__el1 > div {
  position: relative;
  top: -54px;
  left: -132px;
}

.masthead.-type-1 .masthead-image__el2 {
  display: flex;
  justify-content: flex-end;
}

.masthead.-type-1 .masthead-image__el2 > div {
  position: absolute;
  bottom: -52px;
  right: 112px;
}

.masthead.-type-1 .masthead-image__el3 {
  display: flex;
  justify-content: flex-end;
}

.masthead.-type-1 .masthead-image__el3 > div {
  position: absolute;
  bottom: -50px;
  right: 15px;
}

.masthead.-type-1 .masthead-info {
  padding-top: 85px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .masthead.-type-1 .masthead-info {
    padding-top: 30px;
  }
}

.masthead.-type-1 .masthead-info__item {
  width: auto;
}

.masthead.-type-2 {
  position: relative;
  z-index: 0;
  margin-top: 91px;
  padding: 122px 0;
}

@media (max-width: 991px) {
  .masthead.-type-2 {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 {
    padding: 60px 0;
  }
}

.masthead.-type-2 .row {
  padding: 0 60px;
}

@media (max-width: 991px) {
  .masthead.-type-2 .row {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .row {
    padding: 0;
  }
}

.masthead.-type-2 .masthead__bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 60px;
  right: 60px;
  z-index: -1;
  height: 100%;
  border-radius: 16px;
}

@media (max-width: 991px) {
  .masthead.-type-2 .masthead__bg {
    left: 30px;
    right: 30px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__bg {
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.masthead.-type-2 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-1);
  opacity: 0.7;
}

.masthead.-type-2 .masthead__bg img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.masthead.-type-2 .masthead__title {
  text-transform: capitalize;
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
}

@media (max-width: 991px) {
  .masthead.-type-2 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-2 .masthead__title br {
    display: none;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__title {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-2 .masthead__title {
    font-size: 30px;
  }
}

.masthead.-type-2 .masthead__button {
  display: inline-block;
}

.masthead.-type-2 .masthead__button a {
  font-weight: 500;
}

.masthead.-type-2 .masthead-form {
  padding: 30px;
  padding-bottom: 38px;
  background-color: white;
  border-radius: 16px;
}

.masthead.-type-2 .masthead-form .masthead-form__group + .masthead-form__group {
  margin-top: 30px;
}

.masthead.-type-2 .masthead-form__group {
  display: flex;
  flex-direction: column;
}

.masthead.-type-2 .masthead-form__group label {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-dark-1);
}

.masthead.-type-2 .masthead-form__group input {
  border: 1px solid var(--color-light-8);
  padding: 15px 22px;
  border-radius: 8px;
}

.masthead.-type-2 .masthead-form__group button {
  width: 100%;
  height: 60px;
}

.masthead.-type-2 .masthead-form__desc {
  margin-top: 17px;
  font-size: 13px;
  line-height: 22px;
}

.masthead.-type-3 {
  position: relative;
  z-index: 0;
  margin-top: 70px;
  padding-top: 50px;
  padding-bottom: 60px;
}

@media (max-width: 991px) {
  .masthead.-type-3 {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 {
    padding-top: 40px;
  }
}

.masthead.-type-3 .masthead-image {
  position: relative;
  margin-top: -40px;
}

@media (max-width: 991px) {
  .masthead.-type-3 .masthead-image {
    padding-left: 0;
    grid-template-columns: 1fr 1fr;
    gap: 40px 24px;
    width: 85%;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-image {
    width: 100%;
  }
}

.masthead.-type-3 .masthead-image__img1 img {
  max-width: unset;
  width: 100%;
}

.masthead.-type-3 .masthead-image__shape > img {
  position: absolute;
  top: -18vw;
  right: -15vw;
  z-index: -1;
  width: 50vw;
}

.masthead.-type-3 .masthead-image__el1 {
  position: absolute;
  bottom: 210px;
  right: -40px;
  width: 234px;
}

.masthead.-type-3 .masthead-image__el2 {
  position: absolute;
  bottom: 0;
  left: 35px;
}

.masthead.-type-3 .masthead-search__form {
  position: relative;
  width: 600px;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-search__form {
    width: 100%;
  }
}

.masthead.-type-3 .masthead-search__form input {
  width: 100%;
  height: 80px;
  border-radius: 100px;
  box-shadow: 0px 6px 15px 0px #404f680d;
  border: 1px solid #ecedf2;
  padding: 0 40px;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-search__form input {
    height: 64px;
    padding: 0 30px;
    padding-right: 60px;
  }
}

.masthead.-type-3 .masthead-search__form input::-moz-placeholder {
  color: var(--color-light-1);
}

.masthead.-type-3 .masthead-search__form input:-ms-input-placeholder {
  color: var(--color-light-1);
}

.masthead.-type-3 .masthead-search__form input::placeholder {
  color: var(--color-light-1);
}

.masthead.-type-3 .masthead-search__form .button {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border-radius: 100%;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead-search__form .button {
    width: 44px;
  }
}

.masthead.-type-3 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
}

@media (max-width: 1399px) {
  .masthead.-type-3 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-3 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-3 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-3 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-3 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-3 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-4 {
  position: relative;
  z-index: 0;
  margin-top: 91px;
  padding-top: 102px;
}

@media (max-width: 991px) {
  .masthead.-type-4 {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 {
    padding-top: 40px;
  }
}

.masthead.-type-4 .masthead__image img {
  max-width: unset;
  width: 52vw;
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__image img {
    width: 100%;
  }
}

.masthead.-type-4 .masthead__content {
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__content {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__content {
    padding-bottom: 40px;
  }
}

.masthead.-type-4 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 55px;
  line-height: 80px;
}

@media (max-width: 1399px) {
  .masthead.-type-4 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-4 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-4 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-4 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-4 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-5 {
  position: relative;
  z-index: 0;
  padding-top: 180px;
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .masthead.-type-5 {
    padding-top: 160px;
  }
}

.masthead.-type-5 .container {
  max-width: 1530px;
  padding: 0 15px;
}

.masthead.-type-5 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-5 .masthead__bg img {
  height: 100%;
}

@media (max-width: 767px) {
  .masthead.-type-5 .masthead__bg img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.masthead.-type-5 .masthead__container {
  padding-left: 210px;
  padding-right: 60px;
}

@media (max-width: 991px) {
  .masthead.-type-5 .masthead__container {
    padding-left: 60px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-5 .masthead__container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.masthead.-type-5 .masthead__image img {
  width: 100%;
}

.masthead.-type-5 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 55px;
  line-height: 80px;
}

@media (max-width: 1399px) {
  .masthead.-type-5 .masthead__title {
    font-size: 50px;
    line-height: 1.3;
  }
  .masthead.-type-5 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-5 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-5 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-5 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-5 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-5 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-6 {
  position: relative;
  z-index: 0;
  padding-top: 140px;
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .masthead.-type-6 {
    padding-top: 120px;
  }
}

.masthead.-type-6 .masthead__bg > img {
  position: absolute;
  top: 0;
}

.masthead.-type-6 .masthead__bg > img:nth-child(1) {
  left: 0;
}

.masthead.-type-6 .masthead__bg > img:nth-child(2) {
  right: 0;
}

.masthead.-type-6 .masthead__bg > img:nth-child(3) {
  left: 0;
  width: 100%;
}

.masthead.-type-6 .masthead__image {
  margin-right: -40px;
}

.masthead.-type-6 .masthead__image img {
  width: 100%;
}

.masthead.-type-6 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 55px;
  line-height: 1.2;
}

@media (max-width: 1399px) {
  .masthead.-type-6 .masthead__title {
    font-size: 50px;
  }
  .masthead.-type-6 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-6 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-6 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-6 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-6 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-6 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-7 {
  position: relative;
  z-index: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 120px;
}

@media (max-width: 1700px) {
  .masthead.-type-7 {
    margin-top: 90px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-7 {
    padding-top: 90px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-7 {
    margin-top: 0;
  }
}

.masthead.-type-7 .masthead__bg {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 100%;
}

@media (max-width: 1700px) {
  .masthead.-type-7 .masthead__bg {
    top: 0;
    left: -15px;
    right: -15px;
    border-radius: 0 !important;
  }
}

.masthead.-type-7 .masthead__bg > img {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-7 .masthead__image img {
  width: 100%;
}

.masthead.-type-7 .masthead__title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 55px;
  line-height: 1.2;
}

@media (max-width: 1399px) {
  .masthead.-type-7 .masthead__title {
    font-size: 50px;
  }
  .masthead.-type-7 .masthead__title br {
    display: none;
  }
}

@media (max-width: 991px) {
  .masthead.-type-7 .masthead__title {
    font-size: 45px;
  }
  .masthead.-type-7 .masthead__title br {
    display: block;
  }
}

@media (max-width: 767px) {
  .masthead.-type-7 .masthead__title {
    font-size: 40px;
  }
  .masthead.-type-7 .masthead__title br {
    display: none;
  }
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead__title {
    font-size: 35px;
  }
}

.masthead.-type-7 .masthead-image {
  position: relative;
}

.masthead.-type-7 .masthead-image__img1 > img {
  width: 100%;
}

.masthead.-type-7 .masthead-image .-el-1 {
  position: absolute;
  bottom: 1.6vw;
  right: 5vw;
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead-image .-el-1 {
    display: none;
  }
}

.masthead.-type-7 .masthead-image .-el-2 {
  position: absolute;
  top: 7vw;
  left: 0.5vw;
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead-image .-el-2 {
    display: none;
  }
}

.masthead.-type-7 .masthead-image .-el-3 {
  position: absolute;
  top: 9vw;
  right: 2.6vw;
}

@media (max-width: 575px) {
  .masthead.-type-7 .masthead-image .-el-3 {
    display: none;
  }
}

.masthead.-type-7 .masthead-form {
  position: relative;
}

.masthead.-type-7 .masthead-form__item {
  display: flex;
  align-items: center;
  height: 60px;
  flex: 1 0 auto;
}

.masthead.-type-7 .masthead-form__item + .masthead-form__item {
  border-left: 1px solid var(--color-light-5);
}

@media (max-width: 960px) {
  .masthead.-type-7 .masthead-form__item + .masthead-form__item {
    border-left: 0;
  }
}

.masthead.-type-7 .masthead-form__item input {
  max-width: 100px;
}

.masthead.-type-7 .masthead-form__button {
  height: 60px;
  width: 25%;
  flex: 1 0 auto;
}

@media (max-width: 1350px) {
  .masthead.-type-7 .masthead-form__button {
    width: 100%;
  }
}

.masthead.-type-7 .masthead-form__button button {
  width: 100%;
  min-width: 120px;
  height: 100%;
  border-radius: 16px;
}

.stepCard.-type-1 {
  border-radius: 8px;
}

.stepCard.-type-1 .stepCard__content {
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  padding: 40px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stepCard.-type-1 .stepCard__title {
  margin-top: 37px;
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  color: white;
}

.stepCard.-type-1 .stepCard__text {
  margin-top: 17px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.teamCard.-type-1 .teamCard__image {
  border-radius: 8px;
}

.teamCard.-type-1 .teamCard__image img {
  width: 100%;
}

.teamCard.-type-1 .teamCard__content {
  margin-top: 20px;
}

.teamCard.-type-1 .teamCard__title {
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  color: var(--color-dark-1);
}

.teamCard.-type-1 .teamCard__text {
  margin-top: 5px;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: var(--color-light-1);
}

.teamCard.-type-1 .teamCard-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.teamCard.-type-1 .teamCard-info__item {
  display: flex;
  align-items: center;
}

.teamCard.-type-2 {
  box-shadow: 0px 6px 15px 0px #404f680d;
  border-radius: 8px;
  border: 1px solid var(--color-light-5);
  padding: 30px;
}

.teamCard.-type-2 .teamCard__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teamCard.-type-2 .teamCard__button {
  width: 100%;
}

.teamCard.-type-2 .teamCard-tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -3px;
}

.teamCard.-type-2 .teamCard-tags__item {
  padding: 3px;
}

.teamCard.-type-2 .teamCard-tags__tag {
  padding: 10px 19px;
  font-size: 13px;
  line-height: 15px;
  border-radius: 100px;
  border: 1px solid var(--color-light-5);
}

.testimonials.-type-1 .testimonials__content {
  border-radius: 8px;
  background-color: white;
  padding: 30px;
  padding-bottom: 20px;
}

.testimonials.-type-1 .testimonials__title {
  font-size: 18px;
  line-height: 36px;
  font-weight: 400;
  color: #6440fb;
}

.testimonials.-type-1 .testimonials__text {
  margin-top: 14px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  color: var(--color-dark-1);
}

.testimonials.-type-1 .testimonials-footer {
  display: flex;
  align-items: center;
  margin-top: 26px;
  padding-top: 20px;
  border-top: 1px solid #ededed;
}

.testimonials.-type-1 .testimonials-footer__image {
  margin-right: 20px;
}

.testimonials.-type-1 .testimonials-footer__image img {
  border-radius: 100%;
  width: 60px;
  height: 60px;
}

.testimonials.-type-1 .testimonials-footer__title {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
}

.testimonials.-type-1 .testimonials-footer__text {
  margin-top: 5px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  color: var(--color-light-1);
}

.counter {
  text-align: center;
}

.counter__row {
  padding-top: 180px;
}

@media (max-width: 767px) {
  .counter__row {
    padding-top: 80px;
  }
}

.counter__number {
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  color: #00ff84;
}

.counter__title {
  margin-top: 8px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: white;
}

.testimonials.-type-2 .testimonials__text {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-top: 30px;
}

.testimonials.-type-2 .testimonials__author {
  margin-top: 60px;
}

.testimonials.-type-3 {
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
  padding: 60px;
  padding-right: 50px;
}

.testimonials.-type-3 .testimonials__image {
  flex-shrink: 0;
}

.testimonials.-type-3 .testimonials__image img {
  border-radius: 100%;
  width: 170px;
  height: auto;
}

.sectionTitle__title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  color: var(--color-dark-1);
}

.sectionTitle__text {
  margin-top: 10px;
  color: var(--color-light-1);
}

.sectionTitle.-light .sectionTitle__title {
  color: white;
}

.sectionTitle.-light .sectionTitle__text {
  color: white;
}

.mainSlider.-type-1 {
  position: relative;
  z-index: 0;
}

.mainSlider.-type-1 .swiper-wrapper {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mainSlider.-type-1 .swiper-slide {
  z-index: 1;
  position: relative;
  padding-top: 232px;
  padding-bottom: 34px;
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .swiper-slide {
    padding-top: 180px;
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .mainSlider.-type-1 .swiper-slide {
    padding-top: 160px;
    padding-bottom: 50px;
  }
}

.mainSlider.-type-1 .swiper-prev {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .swiper-prev {
    left: 15px;
  }
}

@media (max-width: 767px) {
  .mainSlider.-type-1 .swiper-prev {
    display: none !important;
  }
}

.mainSlider.-type-1 .swiper-next {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .swiper-next {
    right: 15px;
  }
}

@media (max-width: 767px) {
  .mainSlider.-type-1 .swiper-next {
    display: none !important;
  }
}

.mainSlider.-type-1 .mainSlider__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mainSlider.-type-1 .mainSlider__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-1);
  opacity: 0.5;
}

.mainSlider.-type-1 .mainSlider__content {
  padding-top: 260px;
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .mainSlider__content {
    padding-top: 140px;
  }
}

@media (max-width: 767px) {
  .mainSlider.-type-1 .mainSlider__content {
    padding-top: 180px;
  }
}

.mainSlider.-type-1 .mainSlider__title {
  font-size: 55px;
  line-height: 80px;
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .mainSlider__title {
    font-size: 45px;
    line-height: 1.3;
  }
}

@media (max-width: 767px) {
  .mainSlider.-type-1 .mainSlider__title {
    font-size: 40px;
  }
}

.mainSlider.-type-1 .mainSlider__text {
  font-size: 17px;
  line-height: 36px;
  margin-top: 13px;
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .mainSlider__text {
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.6;
  }
}

.mainSlider.-type-1 .mainSlider__form {
  position: relative;
  margin-top: 43px;
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .mainSlider__form {
    margin-top: 35px;
  }
}

.mainSlider.-type-1 .mainSlider__form input {
  width: 100%;
  height: 70px;
  font-size: 15px;
  line-height: 26px;
  background-color: white;
  color: var(--color-light-1);
  border-radius: 100px;
  padding: 22px 35px;
}

@media (max-width: 767px) {
  .mainSlider.-type-1 .mainSlider__form input {
    padding: 14px 24px;
    height: 50px;
  }
}

.mainSlider.-type-1 .mainSlider__form input::-moz-placeholder {
  color: inherit;
}

.mainSlider.-type-1 .mainSlider__form input:-ms-input-placeholder {
  color: inherit;
}

.mainSlider.-type-1 .mainSlider__form input::placeholder {
  color: inherit;
}

.mainSlider.-type-1 .mainSlider__form button {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 10px;
  height: auto;
  min-width: auto;
  padding: 15px 32px;
  border-radius: 100px;
}

@media (max-width: 767px) {
  .mainSlider.-type-1 .mainSlider__form button {
    top: unset;
    bottom: unset;
    right: unset;
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: 15px;
  }
}

.mainSlider.-type-1 .mainSlider__items {
  padding-top: 120px;
  padding-bottom: 30px;
}

@media (max-width: 1199px) {
  .mainSlider.-type-1 .mainSlider__items {
    padding-top: 80px;
  }
}

@media (max-width: 991px) {
  .mainSlider.-type-1 .mainSlider__items {
    padding-top: 50px;
  }
}

.pagination.-arrows {
  display: flex;
  color: var(--color-light-2);
}

.pagination.-arrows > * + * {
  margin-left: 12px;
}

.pagination.-arrows .pagination__item {
  background-color: currentColor;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pagination.-arrows .pagination__item.is-active {
  background-color: var(--color-purple-1);
}

.arrow-left-hover {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.arrow-left-hover:hover {
  color: var(--color-purple-1);
  transform: translateX(-5px) scale(1.25);
}

.arrow-right-hover {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.arrow-right-hover:hover {
  color: var(--color-purple-1);
  transform: translateX(5px) scale(1.25);
}

.pagination.-buttons {
  display: flex;
  align-items: center;
}

.pagination.-buttons > * + * {
  margin-left: 40px;
}

@media screen and (min-width:270px) and (max-width:400px) {
  .pagination.-buttons > * + * {
    margin-left: 21px;
  }
  
  
}
.pagination.-buttons .pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 100%;
  background-color: var(--color-light-7);
  color: var(--color-purple-1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pagination.-buttons .pagination__button .icon {
  font-size: 12px;
}

.pagination.-buttons .pagination__button:hover {
  background-color: var(--color-purple-1);
  color: white;
}

.pagination.-buttons .pagination__count > * {
  position: relative;
  font-size: 14px;
  font-weight: 200px;
  line-height: 16px;
  color: var(--color-dark-1);
}

.pagination.-buttons .pagination__count > * + * {
  margin-left: 40px;
}

.pagination.-buttons .pagination__count a::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 20%;
  width: 10px;
  height: 2px;
  transform: translateX(-50%);
  background-color: var(--color-purple-1);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.pagination.-buttons .pagination__count a.-count-is-active {
  color: var(--color-purple-1);
}

.pagination.-buttons .pagination__count a.-count-is-active::after {
  opacity: 1;
}

.pagination.-avatars {
  display: flex;
  align-items: center;
}

.pagination.-avatars .pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.-avatars .pagination__item::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 92px;
  height: 92px;
  border: 2px solid var(--color-dark-1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.pagination.-avatars .pagination__item.is-active::before {
  opacity: 1;
}

.featureIcon.-type-1 {
  display: flex;
}

.featureIcon.-type-1 .featureIcon__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

@media (max-width: 767px) {
  .featureIcon.-type-1 .featureIcon__icon {
    width: 60px;
    height: 60px;
  }
}

.featureIcon.-type-1 .featureIcon__icon .icon {
  font-size: 38px;
}

@media (max-width: 767px) {
  .featureIcon.-type-1 .featureIcon__icon .icon {
    font-size: 32px;
  }
}

.eventCard.-type-1 .eventCard__img img {
  border-radius: 8px;
  width: 100%;
}

.eventCard.-type-1 .eventCard__bg {
  z-index: 5;
  position: relative;
  width: calc(100% - 20px);
  box-shadow: 0px 6px 15px 0px #404f680d;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  margin-top: -45px;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.eventCard.-type-1 .eventCard__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.eventCard.-type-1:hover .eventCard__bg {
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

.eventCard.-type-2 {
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0px 25px 70px 0px #01213a12;
  border: 1px solid #ededed;
}

.eventCard.-type-2 .eventCard__img img {
  width: 100%;
}

.eventCard.-type-2 .eventCard__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.eventCard.-type-3 {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
  padding: 40px;
  padding-bottom: 28px;
  padding-right: 50px;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.eventCard.-type-3 .eventCard__date {
  display: flex;
  align-items: center;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.eventCard.-type-3 .eventCard__title {
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.eventCard.-type-3 .eventCard__button {
  position: absolute;
  bottom: 28px;
  left: 40px;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.05s;
  transform: translateY(50px);
  opacity: 0;
}

.eventCard.-type-3:hover {
  background-color: var(--color-dark-5);
}

.eventCard.-type-3:hover .eventCard__date span {
  color: white !important;
}

.eventCard.-type-3:hover .eventCard__title {
  color: white !important;
  transform: translateY(-67px);
}

.eventCard.-type-3:hover .eventCard__button {
  transform: translateY(0);
  opacity: 1;
}

.eventCard.-type-4 {
  display: flex;
  align-items: center;
}

.eventCard.-type-4 .eventCard__date {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  width: 100px;
  height: 100px;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.eventCard.-type-4 .eventCard__date span {
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.eventCard.-type-4:hover .eventCard__date {
  background-color: var(--color-purple-1) !important;
}

.eventCard.-type-4:hover .eventCard__date span {
  color: white !important;
}

.categoryCard.-type-1 {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}

.categoryCard.-type-1 .categoryCard__content {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.categoryCard.-type-1 .categoryCard__content::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #6440fb;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-1:hover .categoryCard__content::before {
  opacity: 0.85;
}

.categoryCard.-type-2 {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.categoryCard.-type-2 .categoryCard__title {
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-2:hover .categoryCard__title {
  color: var(--color-purple-1);
}

.categoryCard.-type-3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ededed;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-3 .categoryCard__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-3 .categoryCard__icon .icon {
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-3 .categoryCard__title {
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-3:hover {
  box-shadow: 0px 6px 15px 0px #404f680d;
}

.categoryCard.-type-3:hover .categoryCard__icon {
  background-color: var(--color-purple-1);
}

.categoryCard.-type-3:hover .categoryCard__icon .icon {
  color: white;
}

.categoryCard.-type-3:hover .categoryCard__title {
  color: var(--color-purple-1);
}

.categoryCard.-type-4 .categoryCard__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  border-radius: 100%;
  border-radius: 8px;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-4 .categoryCard__icon .icon {
  font-size: 75px;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-4 .categoryCard__title {
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.categoryCard.-type-4:hover .categoryCard__icon .icon {
  color: var(--color-purple-1);
}

.categoryCard.-type-4:hover .categoryCard__title {
  color: var(--color-purple-1);
}

.page-header.-type-1 {
  padding-top: 90px;
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  .page-header.-type-1 {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

.page-header.-type-1 .page-header__title {
  font-size: 40px;
  line-height: 45px;
}

@media (max-width: 991px) {
  .page-header.-type-1 .page-header__title {
    font-size: 35px;
    line-height: 40px;
  }
}

.page-header.-type-1 .page-header__text {
  font-size: 17px;
  line-height: 36px;
  margin-top: 5px;
}

@media (max-width: 991px) {
  .page-header.-type-1 .page-header__text {
    font-size: 16px;
    line-height: 1.5;
  }
}

.page-header.-type-2 {
  position: relative;
  padding-top: 135px;
  padding-bottom: 135px;
  margin-top: 90px;
  z-index: 0;
}

@media (max-width: 767px) {
  .page-header.-type-2 {
    margin-top: 0px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.page-header.-type-2 .page-header__bg {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: black;
  max-width: 1500px;
  transform: translateX(-50%);
  border-radius: 8px;
}

@media (max-width: 767px) {
  .page-header.-type-2 .page-header__bg {
    border-radius: 0;
  }
}

.page-header.-type-2 .page-header__bg div {
  border-radius: 8px;
}

@media (max-width: 767px) {
  .page-header.-type-2 .page-header__bg div {
    border-radius: 0;
  }
}

.page-header.-type-2 .page-header__title {
  font-size: 40px;
  line-height: 45px;
}

@media (max-width: 991px) {
  .page-header.-type-2 .page-header__title {
    font-size: 35px;
    line-height: 40px;
  }
}

.page-header.-type-3 {
  position: relative;
  margin-top: 90px;
  padding-top: 75px;
  padding-bottom: 75px;
}

@media (max-width: 767px) {
  .page-header.-type-3 {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.page-header.-type-3 .page-header__bg {
  position: absolute;
  top: 0;
  left: 180px;
  right: 180px;
  z-index: -1;
  height: 100%;
  border-radius: 8px;
}

@media (max-width: 1199px) {
  .page-header.-type-3 .page-header__bg {
    left: 100px;
    right: 100px;
  }
}

@media (max-width: 991px) {
  .page-header.-type-3 .page-header__bg {
    left: 50px;
    right: 50px;
  }
}

@media (max-width: 767px) {
  .page-header.-type-3 .page-header__bg {
    left: 30px;
    right: 30px;
  }
}

@media (max-width: 575px) {
  .page-header.-type-3 .page-header__bg {
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.page-header.-type-3 .page-header__title {
  font-size: 40px;
  line-height: 45px;
}

@media (max-width: 991px) {
  .page-header.-type-3 .page-header__title {
    font-size: 35px;
    line-height: 40px;
  }
}

.page-header.-type-3 .page-header__text {
  font-size: 17px;
  line-height: 36px;
  margin-top: 5px;
}

@media (max-width: 991px) {
  .page-header.-type-3 .page-header__text {
    font-size: 16px;
    line-height: 1.5;
  }
}

.page-header.-type-4 {
  position: relative;
  margin-top: 90px;
  padding-top: 210px;
  padding-bottom: 175px;
}

@media (max-width: 991px) {
  .page-header.-type-4 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .page-header.-type-4 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.page-header.-type-4 .page-header__title {
  font-size: 40px;
  line-height: 45px;
}

@media (max-width: 991px) {
  .page-header.-type-4 .page-header__title {
    font-size: 35px;
    line-height: 40px;
  }
}

.page-header.-type-4 .page-header__text {
  font-size: 17px;
  line-height: 36px;
  margin-top: 5px;
}

@media (max-width: 991px) {
  .page-header.-type-4 .page-header__text {
    font-size: 16px;
    line-height: 1.5;
  }
}

.page-header.-type-6 {
  position: relative;
  margin-top: 90px;
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (max-width: 991px) {
  .page-header.-type-6 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .page-header.-type-6 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.page-header.-type-6 .page-header {
  position: relative;
}

.page-header.-type-6 .page-header__bg {
  position: absolute;
  top: 0;
  left: 60px;
  right: 60px;
  z-index: -1;
  height: 100%;
  border-radius: 8px;
}

@media (max-width: 1300px) {
  .page-header.-type-6 .page-header__bg {
    left: 40px;
    right: 40px;
  }
}

@media (max-width: 1199px) {
  .page-header.-type-6 .page-header__bg {
    left: 40px;
    right: 40px;
  }
}

@media (max-width: 767px) {
  .page-header.-type-6 .page-header__bg {
    left: 20px;
    right: 20px;
  }
}

@media (max-width: 575px) {
  .page-header.-type-6 .page-header__bg {
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.breadcrumbs {
  margin-top: 90px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  background-color: var(--color-light-6);
}

.breadcrumbs__content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}

.breadcrumbs__content > * {
  padding: 0 4px;
}

.breadcrumbs__content > * + *::before {
  content: "•";
  display: inline-block;
  margin-right: 8px;
}

.breadcrumbs__content > * + *:last-child a {
  color: var(--color-dark-1);
}

.breadcrumbs__item a {
  font-size: 13px;
  line-height: 1;
}

.infoCard.-type-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  background-color: white;
  padding: 20px 20px;
  box-shadow: 0px 20px 30px rgba(25, 25, 46, 0.04);
  border: 1px solid var(--color-light-5);
  border-radius: 8px;
}

.infoCard.-type-1 .infoCard__image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.infoCard.-type-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 8px;
}

.accordion__icon {
  position: relative;
}

.accordion__icon .icon {
  transition: all 0.2s ease-out;
}

.accordion__icon .icon:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.accordion__icon .icon:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion__item.is-active .accordion__icon > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion__item.is-active .accordion__icon > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion__button {
  cursor: pointer;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: 0.4s;
}

.accordion__button button {
  color: #454545;
}

.accordion__button:active {
  color: #454545;
}

.accordion.-simple .accordion__item {
  padding: 0;
}

.accordion.-simple .accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion.-simple .accordion__content__inner {
  padding-bottom: 1.33333rem;
}

.accordion.-simple .accordion__button {
  cursor: pointer;
  color: var(--text-dark-1);
  padding: 1.26667rem 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  outline: none;
  transition: 0.4s;
}

.accordion.-simple .accordion__button button {
  color: var(--text-dark-1);
}

.accordion.-simple .accordion__icon {
  position: relative;
  margin-right: 1rem;
}

.accordion.-simple .accordion__icon .icon {
  width: 1.6rem;
  height: 1.6rem;
  stroke-width: 2;
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__icon .icon:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.accordion.-simple .accordion__icon .icon:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion.-simple
  .accordion__item.is-active
  .accordion__icon
  > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-simple
  .accordion__item.is-active
  .accordion__icon
  > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion.-simple.-light .accordion__item + .accordion__item {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.accordion.-block .accordion__item {
  box-shadow: 0px 1px 4px rgba(20, 3, 66, 0.07);
  border-radius: 16px;
  padding: 0;
}

.accordion.-block .accordion__item + .accordion__item {
  margin-top: 20px;
}

.accordion.-block .accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion.-block .accordion__content__inner {
  padding: 0 85px 35px 82px;
}

@media (max-width: 575px) {
  .accordion.-block .accordion__content__inner {
    padding: 0 25px 35px 82px;
  }
}

.accordion.-block .accordion__button {
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.accordion.-block .accordion__icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-color: var(--color-light-7);
  border-radius: 100%;
  margin-right: 22px;
  transition: all 0.2s ease-out;
}

.accordion.-block .accordion__icon .icon {
  position: absolute;
  width: 18px;
  height: 18px;
  stroke-width: 2;
  color: var(--color-purple-1);
  transition: all 0.2s ease-out;
}

.accordion.-block .accordion__icon .icon:nth-child(1) {
  top: unset;
  left: unset;
}

.accordion.-block .accordion__icon .icon:nth-child(2) {
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion.-block .accordion__item.is-active .accordion__icon {
  background-color: var(--color-purple-1);
}

.accordion.-block .accordion__item.is-active .accordion__icon .icon {
  color: white;
}

.accordion.-block .accordion__item.is-active .accordion__icon > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-block .accordion__item.is-active .accordion__icon > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.-dark-mode .accordion.-block-2 .accordion__item {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.accordion.-block-2 .accordion__item {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 0;
  border: 1px solid #ededed;
}

.accordion.-block-2 .accordion__item + .accordion__item {
  margin-top: 1.33333rem;
}

.accordion.-block-2 .accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion.-block-2 .accordion__button {
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.accordion.-block-2 .accordion__icon {
  position: relative;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 14px;
  transition: all 0.2s ease-out;
}

.accordion.-block-2 .accordion__icon .icon {
  position: absolute;
  width: 24px;
  height: 24px;
  stroke-width: 2;
  transition: all 0.2s ease-out;
}

.accordion.-block-2 .accordion__icon .icon:nth-child(2) {
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion.-block-2
  .accordion__item.is-active
  .accordion__icon
  > *:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-block-2
  .accordion__item.is-active
  .accordion__icon
  > *:nth-child(2) {
  transform: none;
  opacity: 1;
}

.tabs__content {
  position: relative;
}

.tabs__pane {
  position: absolute;
  top: 0;
  visibility: hidden;
  transition: none;
  opacity: 0;
}

.tabs__pane.is-active {
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
  position: relative;
  opacity: 1;
  visibility: visible;
}

.tabs.-buttons .tabs__button {
  padding: 0.53333rem 1.6rem;
  border-radius: 0.53333rem;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--bg-light-4);
}

.tabs.-buttons .tabs__button.is-active {
  color: white !important;
  background-color: var(--accent-color) !important;
}

.tabs.-buttons .tabs__button:not(:first-child) {
  margin-left: 0.8rem;
}

.tabs.-buttons .tabs__pane {
  display: none;
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-buttons .tabs__pane.is-active {
  display: block;
  opacity: 1;
}

.tabs.-active-1 .tabs__controls button.is-active {
  color: black !important;
  background-color: white !important;
}

.tabs.-active-2 .tabs__controls .tabs__button.is-active {
  background-color: var(--bg-dark-5) !important;
}

.tabs.-active-3 .tabs__controls .tabs__button.is-active .-tab-active {
  background-color: var(--dark-blue-color) !important;
  color: white !important;
}

.tabs.-active-purple-1 .tabs__controls .tabs__button.is-active {
  color: var(--color-purple-1) !important;
}

.tabs.-active-purple-2 .tabs {
  justify-content: unset;
}

.tabs.-active-purple-2 .tabs__controls {
  width: 100%;
  border-bottom: 2px solid var(--color-light-5);
  margin: 0;
}

.tabs.-active-purple-2 .tabs__controls .tabs__button {
  padding-bottom: 12px;
  position: relative;
}

.tabs.-active-purple-2 .tabs__controls .tabs__button::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-purple-1);
  opacity: 0;
}

.tabs.-active-purple-2 .tabs__controls .tabs__button.is-active {
  color: var(--color-purple-1) !important;
}

.tabs.-active-purple-2 .tabs__controls .tabs__button.is-active::after {
  opacity: 1;
}

.tabs.-active-purple-2 .tabs__content {
  width: 100%;
}

.tabs.-pills .tabs__controls .tabs__button.is-active {
  color: var(--color-purple-1) !important;
  font-weight: 500;
  background-color: var(--color-purple-3);
}

.tabs.-pills-2 .tabs__controls .tabs__button.is-active {
  color: var(--color-purple-1) !important;
  background-color: white !important;
}

.tabs.-side .tabs__controls {
  border-left: 1px solid var(--color-light-5);
}

.tabs.-side .tabs__controls .tabs__button {
  position: relative;
  padding-left: 22px;
}

.tabs.-side .tabs__controls .tabs__button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 2px;
  background-color: var(--color-purple-1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tabs.-side .tabs__controls .tabs__button.is-active {
  color: var(--color-purple-1) !important;
}

.tabs.-side .tabs__controls .tabs__button.is-active::before {
  opacity: 1;
}

.tabs.-lines .tabs__controls {
  border-bottom: 1px solid #eeeeee;
  display: flex;
}

.tabs.-lines .tabs__button {
  margin-bottom: -1px;
  padding: 0.66667rem 0.8rem;
  padding-bottom: 2.26667rem;
  font-weight: 600;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
}

.tabs.-lines .tabs__button.is-active {
  color: var(--accent-color);
  border-bottom: 1px solid var(--accent-color);
}

.tabs.-lines .tabs__button + .tabs__button {
  margin-left: 0.53333rem;
}

.tabs.-lines-small .tabs__controls {
  border-bottom: 1px solid #eeeeee;
  display: flex;
}

.tabs.-lines-small .tabs__button {
  margin-bottom: -1px;
  padding: 0.66667rem 0.8rem;
  font-weight: 600;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-bottom: 2px solid transparent;
}

.tabs.-lines-small .tabs__button.is-active {
  color: var(--accent-color) !important;
  border-bottom: 2px solid var(--accent-color);
}

.tabs.-lines-small .tabs__button + .tabs__button {
  margin-left: 0.53333rem;
}

.tabs.-lines-small .tabs__pane {
  display: none;
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-lines-small .tabs__pane.is-active {
  display: block;
  opacity: 1;
}

.tabs.-button-bg-white .tabs__button.is-active {
  background-color: white !important;
  box-shadow: 0px 5px 70px rgba(1, 33, 58, 0.03);
}

.tabs.-button-bg-white .tabs__pane {
  display: none;
  opacity: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-button-bg-white .tabs__pane.is-active {
  display: block;
  opacity: 1;
}

.badge {
  border-radius: 200px;
  font-weight: 500;
  line-height: 1;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.badge.-sm {
  padding: 8px 17px;
}

.badge.-light-3 {
  background-color: var(--color-light-3);
}

.badge.-light-3:hover {
  background-color: var(--color-purple-1);
  color: white !important;
}

.related-nav {
  border-top: 1px solid #eeeeee;
}

.related-nav__icon {
  width: 20px;
  height: 20px;
}

.related-nav__icon > * {
  padding: 2px;
}

.related-nav__icon span {
  display: block;
  width: 4px;
  height: 4px;
  background-color: #404046;
}

.related-nav__item {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -10px;
  margin-right: -10px;
}

.related-nav__item > * {
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 767px) {
  .related-nav__item > * {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.related-nav__item:hover span,
.related-nav__item:hover .icon {
  color: var(--color-accent) !important;
}

.related-nav__item.-next {
  justify-content: flex-end;
  text-align: right;
}

.related-nav__arrow .icon {
  font-size: 24px;
  color: black;
  transition: all 0.1s ease-out;
}

.related-nav__content span,
.related-nav__content p {
  text-decoration: none !important;
}

.priceCard.-type-1 {
  box-shadow: 0px 20px 30px 0px #19192e0a;
}

.priceCard.-type-1 .priceCard__list > * {
  display: flex;
  align-items: center;
}

.priceCard.-type-1 .priceCard__list > * + * {
  margin-top: 10px;
}

.select {
  position: relative;
}

.select__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 0 20px;
  height: 55px;
  border-radius: 6px;
  border: 1px solid var(--color-light-8);
  background-color: white;
}

.select__icon {
  width: 20px;
  height: 20px;
}

.select__dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--color-light-8);
  background-color: white;
  padding: 10px 20px;
  width: 100%;
  min-height: 150px;
  max-height: 290px;
  transition: all 0.2s ease;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}

.select__dropdown.-is-visible {
  opacity: 1;
  pointer-events: auto;
}

.select__options {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.select__options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.select__options::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.select__options__button {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 6px 0;
  text-align: left;
  transition: all 0.15s ease;
}

.select__options__button:hover {
  cursor: pointer;
  color: var(--color-purple-1) !important;
}

.select__options__button:focus {
  color: var(--color-purple-1) !important;
}

.select__search {
  height: 50px;
  border-radius: 8px;
  background-color: #eef2f6;
  flex-shrink: 0;
  padding: 0 20px;
  margin-bottom: 12px;
}

.select__select {
  display: none;
}

.productCard.-type-1 .productCard__image {
  position: relative;
}

.productCard.-type-1 .productCard__image img {
  width: 100%;
}

.productCard.-type-1 .productCard__controls {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

.productCard.-type-1 .productCard__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: white;
  box-shadow: 0px 1px 4px 0px #14034212;
  margin: 0 5px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.productCard.-type-1 .productCard__icon .icon {
  color: black;
  width: 15px;
  height: 15px;
}

.productCard.-type-1 .productCard__button a {
  font-size: 15px;
  line-height: 1;
  height: 50px;
  padding: 16px 50px;
}

.productCard.-type-1:hover .productCard__icon {
  opacity: 1;
}

.shopCart-header {
  padding: 20px 70px;
  padding-right: 0;
  background-color: #f9f6f6;
}

@media (max-width: 991px) {
  .shopCart-header {
    padding-left: 40px;
  }
}

.shopCart-header__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

@media (max-width: 575px) {
  .shopCart-products .shopCart-products__cross {
    left: unset;
    top: 36px;
    right: 0;
  }
  .shopCart-products .shopCart-products__item {
    padding-left: 0px;
  }
}

.shopCart-products__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.shopCart-products__item {
  position: relative;
  padding: 30px 0;
  padding-left: 70px;
  border-bottom: 1px solid #e4e4e4;
}

@media (max-width: 991px) {
  .shopCart-products__item {
    padding-left: 40px;
  }
}

.shopCart-products__cross {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.shopCart-products__cross .icon {
  height: 24px;
  width: 24px;
  stroke-width: 1.5;
}

.shopCart-products__product {
  display: flex;
  align-items: center;
}

.shopCart-products__product .image {
  position: relative;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
}

.shopCart-products__product .image > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shopCart-products__product .title {
  margin-left: 20px;
  font-size: 14px;
  line-height: 1;
}

.shopCart-products__price {
  font-size: 14px;
  line-height: 16px;
}

.shopCart-products__quantity {
  font-size: 14px;
  line-height: 16px;
}

.shopCart-products__subtotal {
  font-size: 14px;
  line-height: 16px;
}

.shopCart-sidebar {
  width: 345px;
}

@media (max-width: 1199px) {
  .shopCart-sidebar {
    width: 240px;
  }
}

@media (max-width: 767px) {
  .shopCart-sidebar {
    width: 100%;
    margin-top: 60px;
  }
}

.shopCart-sidebar__totals {
  border: 1px solid #e4e4e4;
  padding: 30px;
}

.shopCart-sidebar__totals .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: black;
  margin-bottom: 28px;
}

.shopCart-sidebar__totals .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 45px;
  text-align: right;
}

.shopCart-sidebar__totals .item.-border-none {
  border: none !important;
}

.shopCart-sidebar__totals .item + .item {
  border-top: 1px solid #e4e4e4;
}

.shopCart-sidebar__button {
  margin-top: 20px;
}

.shopCart-footer__item {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 1199px) {
  .shopCart-footer__item button {
    min-width: unset !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.shopCart-footer__item form {
  width: 250px;
}

@media (max-width: 575px) {
  .shopCart-footer__item form {
    width: 150px;
  }
}

.shopCart-footer__item input {
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #e4e4e4 !important;
}

.shopCart-footer__item input:focus {
  border-bottom: 1px solid #000 !important;
}

.input-counter {
  display: flex;
  position: relative;
  width: 140px;
  height: 52px;
  /* Firefox */
}

.input-counter input::-webkit-outer-spin-button,
.input-counter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-counter input[type="number"] {
  -moz-appearance: textfield;
}

.input-counter__counter {
  z-index: 5;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border: 2px solid #ededed;
  border-radius: 8px;
}

.input-counter__controls {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-counter__up {
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-counter__up .icon {
  stroke-width: 2;
  color: black;
  width: 16px;
  height: 16px;
}

.input-counter__down {
  width: 48px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-counter__down .icon {
  stroke-width: 2;
  color: black;
  width: 16px;
  height: 16px;
}

.shopSingle-preview {
  display: flex;
}

@media (max-width: 767px) {
  .shopSingle-preview {
    flex-direction: column-reverse;
  }
}

.shopSingle-preview__imageList {
  width: 140px;
  margin: -5px -10px;
  padding-right: 10px;
}

.shopSingle-preview__imageList > * {
  padding: 5px 10px;
}

@media (max-width: 767px) {
  .shopSingle-preview__imageList {
    padding-right: 0;
    padding-top: 0.66667rem;
    display: flex;
    width: 100%;
    margin: -5px;
  }
  .shopSingle-preview__imageList > * {
    padding: 5px;
    width: 100%;
  }
}

.shopSingle-preview__image {
  overflow: hidden;
  width: 100%;
}

.shopSingle-info__subtitle {
  font-size: 0.93333rem;
  line-height: 1;
}

.shopSingle-info__title {
  font-size: 2.33333rem;
  line-height: 1.2;
  font-weight: 600;
}

.shopSingle-info__content p {
  font-size: 0.93333rem;
  line-height: 1.6;
}

.shopSingle-info__content p + p {
  margin-top: 1.06667rem;
}

.shopSingle-info__action {
  display: flex;
  flex-wrap: wrap;
}

.shopSingle-info__footer span {
  display: block;
  font-size: 0.93333rem;
  line-height: 1.6;
}

.shopCompleted-header {
  text-align: center;
}

.shopCompleted-header .icon {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-purple-1);
  border-radius: 100%;
}

.shopCompleted-header .icon svg {
  padding-top: 6px;
  stroke-width: 4;
  color: white;
  width: 36px;
  height: 36px;
}

.shopCompleted-header .title {
  font-size: 30px;
  line-height: 1.1;
  letter-spacing: 0em;
  margin-top: 20px;
}

@media (max-width: 575px) {
  .shopCompleted-header .title {
    font-size: 32px;
  }
}

.shopCompleted-header .subtitle {
  margin-top: 20px;
}

.shopCompleted-info {
  margin-top: 40px;
  border: 1px dashed var(--color-purple-1);
  border-radius: 8px;
  background-color: var(--accent-light-color);
  padding: 40px 52px;
}

@media (max-width: 575px) {
  .shopCompleted-info {
    padding: 34px;
  }
}

.shopCompleted-info__item .subtitle {
  font-size: 14px;
}

.shopCompleted-info__item .title {
  font-size: 15px;
  margin-top: 15px;
  font-weight: 500;
}

.shopCompleted-footer {
  margin-top: 60px;
}

@media (max-width: 575px) {
  .shopCompleted-footer {
    margin-top: 40px;
  }
}

.shopCompleted-footer__wrap {
  padding: 60px;
}

@media (max-width: 575px) {
  .shopCompleted-footer__wrap {
    padding: 34px;
  }
}

.shopCompleted-footer__wrap .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 28px;
}

.shopCompleted-footer__wrap .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 60px;
  text-align: right;
}

.shopCompleted-footer__wrap .item.-border-none {
  border: none !important;
}

.shopCompleted-footer__wrap .item + .item {
  border-top: 1px solid #e4e4e4;
  margin-top: 3px;
}

.shopCompleted-footer__button {
  margin-top: 20px;
}

.dashboard {
  display: flex;
  padding-right: 30px;
  padding-left: 30px;
}

@media (max-width: 991px) {
  .dashboard {
    flex-direction: column;
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .dashboard {
    padding: 0;
  }
}

.dashboard__main {
  margin-top: 90px;
  width: 100%;
  padding-left: 300px;
}

@media (max-width: 1199px) {
  .dashboard__main {
    padding-left: 250px;
  }
}

@media (max-width: 991px) {
  .dashboard__main {
    padding-left: 0;
    margin-top: 30px;
  }
}

.dashboard__content {
  width: 100%;
  padding: 60px;
  border-radius: 16px;
}

@media (max-width: 767px) {
  .dashboard__content {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .dashboard__content {
    border-radius: 0;
    padding: 20px;
  }
}

.dashboard__sidebar {
  overflow-y: scroll;
  position: fixed;
  left: 0;
  background-color: white;
  flex-shrink: 0;
  width: 300px;
  height: calc(100% - 90px);
  padding: 30px;
  padding-top: 60px;
  padding-bottom: 40px;
  margin-top: 90px;
}

@media (max-width: 1199px) {
  .dashboard__sidebar {
    width: 250px;
  }
}

@media (max-width: 991px) {
  .dashboard__sidebar {
    position: relative;
    width: 100%;
    padding: 20px;
    margin-top: 80px;
  }
}

.dashboard__sidebar.-base {
  padding-top: 0;
}

.header.-dashboard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
}

.footer.-dashboard {
  max-width: 1290px;
  margin: 0 auto;
}

@media (max-width: 575px) {
  .footer.-dashboard {
    padding: 0 20px;
  }
}

.sidebar.-dashboard .sidebar__item {
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 0 20px;
  height: 55px;
}

@media (max-width: 575px) {
  .sidebar.-dashboard .sidebar__item {
    border-radius: 0;
    height: 45px;
  }
}

.sidebar.-dashboard .sidebar__item.-is-active {
  background-color: var(--color-dark-1);
  color: white !important;
}

.sidebar.-courses > * + * {
  border-top: 1px solid #dddddd;
  padding-top: 30px;
  margin-top: 30px;
}

.sidebar.-courses .sidebar__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
}

.sidebar.-courses .sidebar-checkbox {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.sidebar.-courses .sidebar-checkbox > * + * {
  margin-top: 15px;
}

.sidebar.-courses .sidebar-checkbox__item {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar.-courses .sidebar-checkbox .form-checkbox {
  margin-right: 10px;
}

.sidebar.-courses .sidebar-checkbox__title {
  font-size: 14px;
  line-height: 1;
  color: var(--color-dark-1);
}

.sidebar.-courses .sidebar-checkbox__count {
  font-size: 14px;
  line-height: 1;
  margin-left: auto;
  text-align: right;
}

.sidebar.-blog > * + * {
  border-top: 1px solid #dddddd;
  padding-top: 30px;
  margin-top: 30px;
}

.sidebar.-blog.-no-border > * + * {
  border-top: 0;
  padding-top: 0;
  margin-top: 30px;
}

.sidebar.-blog .sidebar__title {
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
}

.sidebar.-blog .sidebar-content {
  padding-top: 30px;
}

.sidebar.-blog .sidebar-content.-list {
  display: flex;
  flex-direction: column;
}

.sidebar.-blog .sidebar-content.-list a:hover {
  font-weight: 500;
  color: var(--color-purple-1);
}

.sidebar.-blog .sidebar-content.-list > * + * {
  margin-top: 4px;
}

.sidebar.-blog .sidebar-content.-tags {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.sidebar.-blog .sidebar-content.-tags > * {
  padding: 5px;
}

.sidebar.-blog .sidebar-content.-event > * + * {
  margin-top: 20px;
}

.sidebar.-blog .sidebar-recent__image {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
}

.sidebar.-blog .sidebar-recent__image img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.sidebar.-blog .sidebar-tag a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 18px;
  background-color: var(--color-light-3);
  border-radius: 100px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.sidebar.-blog .sidebar-tag a:hover {
  color: white !important;
  background-color: var(--color-purple-1);
}

.sidebar.-blog .sidebar-event {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 17px 20px;
}

.sidebar.-shop > * + * {
  border-top: 1px solid #dddddd;
  padding-top: 30px;
  margin-top: 30px;
}

.sidebar.-shop.-no-border > * + * {
  border-top: 0;
  padding-top: 0;
  margin-top: 30px;
}

.sidebar.-shop .sidebar__title {
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
}

.sidebar.-shop .sidebar__search .search > form {
  position: relative;
  display: flex;
  align-items: center;
}

.sidebar.-shop .sidebar__search .search .field {
  z-index: 1;
  width: 100%;
  font-size: 14px;
  line-height: 1;
  padding: 16px;
  padding-left: 56px;
  border-radius: 8px;
  border: 1px solid var(--color-light-8);
}

.sidebar.-shop .sidebar__search .search .submit {
  z-index: 5;
  position: absolute;
  left: 0;
  padding-left: 16px;
}

.sidebar.-shop .sidebar__search .search .submit::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
}

.sidebar.-shop .sidebar__search .search .icon {
  width: 22px;
  height: 22px;
  stroke-width: 1.5;
}

.sidebar.-shop .sidebar-content {
  padding-top: 30px;
}

.sidebar.-shop .sidebar-content.-list {
  display: flex;
  flex-direction: column;
}

.sidebar.-shop .sidebar-content.-list a:hover {
  font-weight: 500;
  color: var(--color-purple-1);
}

.sidebar.-shop .sidebar-content.-list > * + * {
  margin-top: 4px;
}

.sidebar.-shop .sidebar-content.-tags {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.sidebar.-shop .sidebar-content.-tags > * {
  padding: 5px;
}

.sidebar.-shop .sidebar-content.-event > * + * {
  margin-top: 20px;
}

.sidebar.-shop .sidebar-recent__image {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
}

.sidebar.-shop .sidebar-recent__image img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.sidebar.-shop .sidebar-tag a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 18px;
  background-color: var(--color-light-3);
  border-radius: 100px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.sidebar.-shop .sidebar-tag a:hover {
  color: white !important;
  background-color: var(--color-purple-1);
}

.sidebar.-shop .sidebar-event {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 17px 20px;
}

.noUi-target {
  height: 5px;
  border: none;
  background-color: #cce0f8;
  border-radius: 4px;
  position: relative;
}

.noUi-connect {
  background-color: var(--color-purple-1) !important;
  box-shadow: 0 !important;
}

.noUi-handle {
  border-radius: 100%;
  width: 17px !important;
  height: 17px !important;
  top: 50% !important;
  transform: translateY(-35%);
  right: -10px !important;
  border: 2px solid var(--color-purple-1);
  box-shadow: 0 !important;
}

.noUi-handle::before {
  display: none;
}

.noUi-handle::after {
  display: none;
}

.form-page {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
}

@media (max-width: 991px) {
  .form-page {
    display: block;
    height: auto;
  }
}

.form-page__img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40vw;
}

@media (max-width: 991px) {
  .form-page__img {
    width: 100%;
    position: relative;
  }
}

.form-page__content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;
  height: 100%;
}

@media (max-width: 991px) {
  .form-page__content {
    width: 100%;
  }
}

.form-page-composition {
  position: relative;
}

.form-page-composition .-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.form-page-composition .-bg img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.form-page-composition .-el-1 {
  width: 100%;
}

.form-page-composition .-el-2 {
  position: absolute;
  bottom: 33px;
  right: 90px;
}

.form-page-composition .-el-3 {
  position: absolute;
  bottom: 290px;
  left: 20px;
}

.form-page-composition .-el-4 {
  position: absolute;
  bottom: 300px;
  right: 52px;
}

/*--------------------------------------------------
	Blog comments
---------------------------------------------------*/
.blogPost.-comments .blogPost__content .comments__list li {
  list-style: none;
}

.blogPost.-comments
  .blogPost__content
  .comments__list
  > li:first-child
  > .comments-item-inner {
  border-top: none;
}

.blogPost.-comments .blogPost__content .comments__list > li:first-child {
  border-top: none;
}

.blogPost.-comments .blogPost__content .comments__list .trackback a,
.blogPost.-comments .blogPost__content .comments__list .pingback a {
  line-height: 24px;
  text-decoration: none !important;
}

.blogPost.-comments .blogPost__content .comments__list {
  margin: 0;
}

.blogPost.-comments .blogPost__content .comments__list > li:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 60px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .blogPost.-comments .blogPost__content .comments__list > li:not(:last-child) {
    padding-bottom: 40px;
  }
}

.blogPost.-comments .blogPost__content .comments__item-inner {
  display: flex;
}

.blogPost.-comments .blogPost__content .comments__item .children {
  padding: 0;
  margin: 0;
  padding-left: 5%;
  padding-top: 32px;
}

.blogPost.-comments .blogPost__content .comments__body {
  width: 100%;
}

.blogPost.-comments .blogPost__content .comments__img {
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  max-width: 60px;
}

@media (max-width: 575px) {
  .blogPost.-comments .blogPost__content .comments__img {
    width: 48px;
    height: 48px;
    max-width: 48px;
  }
}

.blogPost.-comments .blogPost__content .comments__text a {
  text-decoration: underline;
}

.blogPost.-comments .blogPost__content .comments__text ul,
.blogPost.-comments .blogPost__content .comments__text ul li {
  list-style: disc inside !important;
}

.blogPost.-comments .blogPost__content .comments__text ol,
.blogPost.-comments .blogPost__content .comments__text ol li {
  list-style: decimal inside !important;
}

.blogPost.-comments .blogPost__content .comments__helpful {
  display: flex;
  align-items: center;
}

.blogPost.-comments .blogPost__content .comments__helpful > * + * {
  margin-left: 10px;
}

.blogPost.-comments .blogPost__content .comments__helpful button {
  width: 50px;
  height: 30px;
  padding: 8px 12px;
}

.bg-white {
  background-color: var(--color-white);
}

.bg-black {
  background-color: var(--color-black);
}

.bg-dark-1 {
  /* background-color: var(--color-dark-1); */
  background-color: #5d5c62;
}

.bg-dark-2 {
  background-color: var(--color-dark-2);
}

.bg-dark-3 {
  background-color: var(--color-dark-3);
}

.bg-dark-4 {
  background-color: var(--color-dark-4);
}

.bg-dark-5 {
  background-color: var(--color-dark-5);
}

.bg-dark-6 {
  background-color: var(--color-dark-6);
}

.bg-dark-7 {
  background-color: var(--color-dark-7);
}

.bg-dark-8 {
  background-color: var(--color-dark-8);
}

.bg-light-1 {
  background-color: var(--color-light-1);
}

.bg-light-2 {
  background-color: var(--color-light-2);
}

.bg-light-3 {
  background-color: var(--color-light-3);
}

.bg-light-4 {
  background-color: var(--color-light-4);
}

.bg-light-5 {
  background-color: var(--color-light-5);
}

.bg-light-6 {
  background-color: var(--color-light-6);
}

.bg-light-7 {
  background-color: var(--color-light-7);
}

.bg-light-8 {
  background-color: var(--color-light-8);
}

.bg-light-9 {
  background-color: var(--color-light-9);
}

.bg-light-10 {
  background-color: var(--color-light-10);
}

.bg-light-11 {
  background-color: var(--color-light-11);
}

.bg-light-12 {
  background-color: var(--color-light-12);
}

.bg-purple-1 {
  /* background-color: var(--color-purple-1); */
  background-color: #cac7d7;
}

.bg-purple-2 {
  background-color: var(--color-purple-2);
}

.bg-purple-3 {
  background-color: var(--color-purple-3);
}

.bg-purple-4 {
  background-color: var(--color-purple-4);
}

.bg-purple-5 {
  background-color: var(--color-purple-5);
}

.bg-green-1 {
  background-color: var(--color-green-1);
}

.bg-green-2 {
  background-color: var(--color-green-2);
}

.bg-green-3 {
  background-color: var(--color-green-3);
}

.bg-green-4 {
  background-color: var(--color-green-4);
}

.bg-green-5 {
  background-color: var(--color-green-5);
}

.bg-green-6 {
  background-color: var(--color-green-6);
}

.bg-green-7 {
  background-color: var(--color-green-7);
}

.bg-orange-1 {
  background-color: var(--color-orange-1);
}

.bg-orange-2 {
  background-color: var(--color-orange-2);
}

.bg-orange-3 {
  background-color: var(--color-orange-3);
}

.bg-orange-4 {
  background-color: var(--color-orange-4);
}

.bg-orange-5 {
  background-color: var(--color-orange-5);
}

.bg-orange-6 {
  background-color: var(--color-orange-6);
}

.bg-orange-7 {
  background-color: var(--color-orange-7);
}

.bg-red-1 {
  background-color: var(--color-red-1);
}

.bg-red-2 {
  background-color: var(--color-red-2);
}

.bg-red-3 {
  background-color: var(--color-red-3);
}

.bg-beige-1 {
  background-color: var(--color-beige-1);
}

.bg-blue-1 {
  background-color: var(--color-blue-1);
}

.bg-blue-2 {
  background-color: var(--color-blue-2);
}

.bg-blue-3 {
  background-color: var(--color-blue-3);
}

.bg-blue-4 {
  background-color: var(--color-blue-4);
}

.bg-blue-5 {
  background-color: var(--color-blue-5);
}

.bg-blue-6 {
  background-color: var(--color-blue-6);
}

.bg-blue-7 {
  background-color: var(--color-blue-7);
}

.bg-yellow-1 {
  background-color: var(--color-yellow-1);
}

.bg-yellow-2 {
  background-color: var(--color-yellow-2);
}

.bg-yellow-3 {
  background-color: var(--color-yellow-3);
}

.bg-yellow-4 {
  background-color: var(--color-yellow-4);
}

.bg-yellow-5 {
  background-color: var(--color-yellow-5);
}

.bg-info-1 {
  background-color: var(--color-info-1);
}

.bg-info-2 {
  background-color: var(--color-info-2);
}

.bg-warning-1 {
  background-color: var(--color-warning-1);
}

.bg-warning-2 {
  background-color: var(--color-warning-2);
}

.bg-error-1 {
  background-color: var(--color-error-1);
}

.bg-error-2 {
  background-color: var(--color-error-2);
}

.bg-success-1 {
  background-color: var(--color-success-1);
}

.bg-success-2 {
  background-color: var(--color-success-2);
}

.text-white {
  color: var(--color-white) !important;
}

.text-black {
  color: var(--color-black) !important;
}

.text-dark-1 {
  color: var(--color-dark-1) !important;
}

.text-dark-2 {
  color: var(--color-dark-2) !important;
}

.text-dark-3 {
  color: var(--color-dark-3) !important;
}

.text-dark-4 {
  color: var(--color-dark-4) !important;
}

.text-dark-5 {
  color: var(--color-dark-5) !important;
}

.text-dark-6 {
  color: var(--color-dark-6) !important;
}

.text-dark-7 {
  color: var(--color-dark-7) !important;
}

.text-dark-8 {
  color: var(--color-dark-8) !important;
}

.text-light-1 {
  color: var(--color-light-1) !important;
}

.text-light-2 {
  color: var(--color-light-2) !important;
}

.text-light-3 {
  color: var(--color-light-3) !important;
}

.text-light-4 {
  color: var(--color-light-4) !important;
}

.text-light-5 {
  color: var(--color-light-5) !important;
}

.text-light-6 {
  color: var(--color-light-6) !important;
}

.text-light-7 {
  color: var(--color-light-7) !important;
}

.text-light-8 {
  color: var(--color-light-8) !important;
}

.text-light-9 {
  color: var(--color-light-9) !important;
}

.text-light-10 {
  color: var(--color-light-10) !important;
}

.text-light-11 {
  color: var(--color-light-11) !important;
}

.text-light-12 {
  color: var(--color-light-12) !important;
}

.text-purple-1 {
  color: var(--color-purple-1) !important;
}

.text-purple-2 {
  color: var(--color-purple-2) !important;
}

.text-purple-3 {
  color: var(--color-purple-3) !important;
}

.text-purple-4 {
  color: var(--color-purple-4) !important;
}

.text-purple-5 {
  color: var(--color-purple-5) !important;
}

.text-green-1 {
  color: var(--color-green-1) !important;
}

.text-green-2 {
  color: var(--color-green-2) !important;
}

.text-green-3 {
  color: var(--color-green-3) !important;
}

.text-green-4 {
  color: var(--color-green-4) !important;
}

.text-green-5 {
  color: var(--color-green-5) !important;
}

.text-green-6 {
  color: var(--color-green-6) !important;
}

.text-green-7 {
  color: var(--color-green-7) !important;
}

.text-orange-1 {
  color: var(--color-orange-1) !important;
}

.text-orange-2 {
  color: var(--color-orange-2) !important;
}

.text-orange-3 {
  color: var(--color-orange-3) !important;
}

.text-orange-4 {
  color: var(--color-orange-4) !important;
}

.text-orange-5 {
  color: var(--color-orange-5) !important;
}

.text-orange-6 {
  color: var(--color-orange-6) !important;
}

.text-orange-7 {
  color: var(--color-orange-7) !important;
}

.text-red-1 {
  color: var(--color-red-1) !important;
}

.text-red-2 {
  color: var(--color-red-2) !important;
}

.text-red-3 {
  color: var(--color-red-3) !important;
}

.text-beige-1 {
  color: var(--color-beige-1) !important;
}

.text-blue-1 {
  color: var(--color-blue-1) !important;
}

.text-blue-2 {
  color: var(--color-blue-2) !important;
}

.text-blue-3 {
  color: var(--color-blue-3) !important;
}

.text-blue-4 {
  color: var(--color-blue-4) !important;
}

.text-blue-5 {
  color: var(--color-blue-5) !important;
}

.text-blue-6 {
  color: var(--color-blue-6) !important;
}

.text-blue-7 {
  color: var(--color-blue-7) !important;
}

.text-yellow-1 {
  color: var(--color-yellow-1) !important;
}

.text-yellow-2 {
  color: var(--color-yellow-2) !important;
}

.text-yellow-3 {
  color: var(--color-yellow-3) !important;
}

.text-yellow-4 {
  color: var(--color-yellow-4) !important;
}

.text-yellow-5 {
  color: var(--color-yellow-5) !important;
}

.text-info-1 {
  color: var(--color-info-1) !important;
}

.text-info-2 {
  color: var(--color-info-2) !important;
}

.text-warning-1 {
  color: var(--color-warning-1) !important;
}

.text-warning-2 {
  color: var(--color-warning-2) !important;
}

.text-error-1 {
  color: var(--color-error-1) !important;
}

.text-error-2 {
  color: var(--color-error-2) !important;
}

.text-success-1 {
  color: var(--color-success-1) !important;
}

.text-success-2 {
  color: var(--color-success-2) !important;
}

@media (max-width: 767px) {
  .md\:container {
    max-width: 48rem;
    padding-top: 0;
    padding-left: 1.06667rem;
    padding-right: 1.06667rem;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .md\:container {
    max-width: 36rem;
  }
}

@media (max-width: 479px) {
  .md\:container {
    max-width: 100%;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

@media (max-width: 575px) {
  .sm\:container {
    max-width: 36rem;
  }
}

@media (max-width: 479px) {
  .sm\:container {
    max-width: 100%;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

@media (max-width: 479px) {
  .xs\:container {
    max-width: 100%;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.html-overflow-hidden {
  overflow: hidden !important;
}

.html-overflow-hidden body {
  overflow-y: scroll;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.origin-top {
  transform-origin: top !important;
}

.origin-left {
  transform-origin: left !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-normal {
  overflow: initial !important;
}

.overflow-visible {
  overflow: visible !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.img-full {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.img-ratio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.overlay-black-30::after,
.overlay-black-50::after,
.overlay-black-60::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.overlay-black-30::after {
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay-black-50::after {
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-black-60::after {
  background-color: rgba(0, 0, 0, 0.6);
}

.container-1500 {
  max-width: 1530px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .container-1500 {
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .container-1500 {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .container-1500 {
    max-width: 540px;
  }
}

@media (max-width: 575px) {
  .container-1500 {
    max-width: 100%;
  }
}

@media (max-width: 479px) {
  .container-1500 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.container-wide {
  padding-left: 60px;
  padding-right: 60px;
  max-width: 1500px;
  margin: 0 auto;
}

@media (max-width: 575px) {
  .container-wide {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

.rounded-8 {
  border-radius: 8px !important;
}

.rounded-16 {
  border-radius: 16px !important;
}

.rounded-200 {
  border-radius: 200px !important;
}

.rounded-full {
  border-radius: 100% !important;
}

.rounded-top-8 {
  border-radius: 8px 8px 0 0 !important;
}

.z--1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal {
  text-transform: none;
}

.line__item {
  width: 100%;
  height: 1px;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.opac-10 {
  opacity: 0.1;
}

.opac-20 {
  opacity: 0.2;
}

.opac-30 {
  opacity: 0.3;
}

.opac-40 {
  opacity: 0.4;
}

.opac-50 {
  opacity: 0.5;
}

.opac-60 {
  opacity: 0.6;
}

.opac-70 {
  opacity: 0.7;
}

.opac-80 {
  opacity: 0.8;
}

.opac-90 {
  opacity: 0.9;
}

.ul-list {
  padding-left: 1.06667rem;
}

.ul-list li {
  list-style: disc;
}

.ol-list {
  padding-left: 1.06667rem;
}

.ol-list li {
  list-style: decimal;
}

.border-light {
  border: 1px solid var(--color-light-5);
}

.border-dark-1-lg {
  border: 2px solid var(--color-dark-1);
}

.border-bottom-light-2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.border-bottom-light {
  border-bottom: 1px solid var(--color-light-5);
}

.border-right-light {
  border-right: 1px solid var(--color-light-5);
}

.border-top-light {
  border-top: 1px solid var(--color-light-5);
}

.border-top-light-15 {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.svg-shape {
  display: flex;
}

.svg-shape svg {
  width: 100%;
  height: auto;
}

.svg-shape.-r-180 {
  transform: rotate(180deg);
}

.section-bg {
  z-index: 1;
  position: relative;
}

.section-bg__item {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 60px;
  right: 60px;
  height: 100%;
  border-radius: 16px;
}

@media (max-width: 1400px) {
  .section-bg__item {
    left: 20px;
    right: 20px;
  }
}

@media (max-width: 767px) {
  .section-bg__item {
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.section-bg__item.-full {
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 0;
}

.section-bg__item.-height-half {
  height: calc(100% - 150px);
}

.shadow-1 {
  box-shadow: 0px 20px 30px rgba(25, 25, 46, 0.04);
}

.shadow-2 {
  box-shadow: 0px 25px 70px 0px #01213a12;
}

.shadow-3 {
  box-shadow: 0px 20px 30px 0px #19192e0a;
}

.shadow-4 {
  box-shadow: 0px 1px 4px 0px #14034212;
}

.shadow-5 {
  box-shadow: 0px 6px 15px 0px #404f680d;
}

.shadow-6 {
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

.h-100 {
  height: 100% !important;
}

.border-top-dark {
  border-top: 1px solid rgba(20, 3, 66, 0.07) !important;
}

.border-bottom-dark {
  border-bottom: 1px solid rgba(20, 3, 66, 0.07) !important;
}

.absolute-full-center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.gallery__item {
  position: relative;
  display: block;
}

.gallery__item:hover .gallery__button {
  opacity: 1;
}

.gallery__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: black;
  border-radius: 100%;
  width: 3.46667rem;
  height: 3.46667rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.gallery__button .icon {
  color: white;
  stroke-width: 1;
  width: 1.86667rem;
  height: 1.86667rem;
}

.gallery__button.-bottom-right {
  top: unset;
  left: unset;
  transform: unset;
  bottom: 2rem;
  right: 2rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-row {
  flex-direction: row;
}

.bg-transparent {
  background-color: transparent !important;
}

.line.-vertical.-h-120 {
  width: 1px;
  height: 120px;
}

.size-12 {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
}

.size-120 {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
}

.size-100 {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
}

.size-180 {
  flex-shrink: 0;
  width: 180px;
  height: 180px;
}

.size-90 {
  flex-shrink: 0;
  width: 90px;
  height: 90px;
}

.size-70 {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
}

.size-20 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.size-25 {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
}

.size-30 {
  flex-shrink: 0;
  width: 30px !important;
  height: 30px !important;
}

.size-35 {
  flex-shrink: 0;
  width: 35px;
  height: 35px;
}

.size-40 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

.size-45 {
  flex-shrink: 0;
  width: 45px;
  height: 45px;
}

.size-50 {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
}

.size-60 {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
}

.size-16 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.map {
  margin-top: 90px;
  height: 510px;
  z-index: 0;
}

.relative {
  position: relative;
}

.side-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.event-info {
  position: relative;
  z-index: 5;
  margin-top: -130px;
}

@media (max-width: 767px) {
  .event-info {
    margin-top: 0;
  }
}

.contact-form-to-top {
  position: relative;
  z-index: 5;
  margin-top: -360px;
}

@media (max-width: 991px) {
  .contact-form-to-top {
    margin-top: 0;
  }
}

.no-page {
  margin-top: 90px;
}

.no-page__main {
  font-size: 200px;
  line-height: 1.2;
}

@media (max-width: 991px) {
  .no-page__main {
    font-size: 160px;
  }
}

@media (max-width: 767px) {
  .no-page__main {
    font-size: 140px;
  }
}

.courses-single-info {
  position: absolute;
  top: 0;
  right: 0;
  width: 360px;
  z-index: 20;
}

@media (max-width: 1199px) {
  .courses-single-info {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .courses-single-info {
    position: relative;
    width: 100%;
  }
}

.courses-single-info__content {
  height: 57vh;
  overflow-y: scroll;
}

@media (max-width: 991px) {
  .courses-single-info__content {
    height: auto;
    overflow-y: unset;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.lesson-image {
  width: calc(100% - 450px);
}

@media (max-width: 1199px) {
  .lesson-image {
    width: calc(100% - 320px);
  }
}

@media (max-width: 991px) {
  .lesson-image {
    margin-top: 50px;
    width: 100%;
  }
}

.lesson-sidebar {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  height: 100vh;
  width: 450px;
  padding-top: 120px;
}

@media (max-width: 1199px) {
  .lesson-sidebar {
    width: 320px;
  }
}

@media (max-width: 991px) {
  .lesson-sidebar {
    position: relative;
    height: auto;
    width: 100%;
    padding-top: 141px;
    padding-bottom: 50px;
  }
}

.lesson-sidebar.-type-2 {
  left: unset;
  right: 0;
}

@media (max-width: 991px) {
  .lesson-sidebar.-type-2 {
    padding-top: 40px !important;
  }
}

.search-field {
  position: relative;
}

.search-field input {
  padding: 0px 20px 0px 52px;
  border-radius: 8px;
  width: 100%;
  line-height: 1.5;
  height: 100%;
}

.search-field button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 16px;
}

.search-field.-w-340 input {
  width: 340px;
}

@media (max-width: 1199px) {
  .search-field.-w-340 input {
    width: 260px;
  }
}

.search-field.-reverse-button input {
  padding-left: 20px;
  padding-right: 52px;
}

.search-field.-reverse-button button {
  left: unset;
  right: 0;
}

.lesson-sidebar-search {
  position: relative;
}

.lesson-sidebar-search input {
  padding: 17px 20px 17px 52px;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  line-height: 1.5;
}

.lesson-sidebar-search button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 16px;
}

.side-badge {
  position: absolute;
  top: -6px;
  left: -6px;
}

.max-w-250 {
  max-width: 250px;
}

.h-30 {
  height: 30px !important;
}

.h-50 {
  height: 50px !important;
}

.h-60 {
  height: 60px !important;
}

.testimonials-slider-2 .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.py-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.px-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

@media (max-width: 1199px) {
  .xl\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xl\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xl\:pt-0 {
    padding-top: 0px !important;
  }
  .xl\:pb-0 {
    padding-bottom: 0px !important;
  }
  .xl\:pl-0 {
    padding-left: 0px !important;
  }
  .xl\:pr-0 {
    padding-right: 0px !important;
  }
  .xl\:mt-0 {
    margin-top: 0px !important;
  }
  .xl\:mb-0 {
    margin-bottom: 0px !important;
  }
  .xl\:ml-0 {
    margin-left: 0px !important;
  }
  .xl\:mr-0 {
    margin-right: 0px !important;
  }
  .xl\:py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .xl\:px-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .xl\:pt-3 {
    padding-top: 3px !important;
  }
  .xl\:pb-3 {
    padding-bottom: 3px !important;
  }
  .xl\:pl-3 {
    padding-left: 3px !important;
  }
  .xl\:pr-3 {
    padding-right: 3px !important;
  }
  .xl\:mt-3 {
    margin-top: 3px !important;
  }
  .xl\:mb-3 {
    margin-bottom: 3px !important;
  }
  .xl\:ml-3 {
    margin-left: 3px !important;
  }
  .xl\:mr-3 {
    margin-right: 3px !important;
  }
  .xl\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xl\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xl\:pt-5 {
    padding-top: 5px !important;
  }
  .xl\:pb-5 {
    padding-bottom: 5px !important;
  }
  .xl\:pl-5 {
    padding-left: 5px !important;
  }
  .xl\:pr-5 {
    padding-right: 5px !important;
  }
  .xl\:mt-5 {
    margin-top: 5px !important;
  }
  .xl\:mb-5 {
    margin-bottom: 5px !important;
  }
  .xl\:ml-5 {
    margin-left: 5px !important;
  }
  .xl\:mr-5 {
    margin-right: 5px !important;
  }
  .xl\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .xl\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .xl\:pt-8 {
    padding-top: 8px !important;
  }
  .xl\:pb-8 {
    padding-bottom: 8px !important;
  }
  .xl\:pl-8 {
    padding-left: 8px !important;
  }
  .xl\:pr-8 {
    padding-right: 8px !important;
  }
  .xl\:mt-8 {
    margin-top: 8px !important;
  }
  .xl\:mb-8 {
    margin-bottom: 8px !important;
  }
  .xl\:ml-8 {
    margin-left: 8px !important;
  }
  .xl\:mr-8 {
    margin-right: 8px !important;
  }
  .xl\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xl\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xl\:pt-10 {
    padding-top: 10px !important;
  }
  .xl\:pb-10 {
    padding-bottom: 10px !important;
  }
  .xl\:pl-10 {
    padding-left: 10px !important;
  }
  .xl\:pr-10 {
    padding-right: 10px !important;
  }
  .xl\:mt-10 {
    margin-top: 10px !important;
  }
  .xl\:mb-10 {
    margin-bottom: 10px !important;
  }
  .xl\:ml-10 {
    margin-left: 10px !important;
  }
  .xl\:mr-10 {
    margin-right: 10px !important;
  }
  .xl\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .xl\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .xl\:pt-12 {
    padding-top: 12px !important;
  }
  .xl\:pb-12 {
    padding-bottom: 12px !important;
  }
  .xl\:pl-12 {
    padding-left: 12px !important;
  }
  .xl\:pr-12 {
    padding-right: 12px !important;
  }
  .xl\:mt-12 {
    margin-top: 12px !important;
  }
  .xl\:mb-12 {
    margin-bottom: 12px !important;
  }
  .xl\:ml-12 {
    margin-left: 12px !important;
  }
  .xl\:mr-12 {
    margin-right: 12px !important;
  }
  .xl\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xl\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xl\:pt-15 {
    padding-top: 15px !important;
  }
  .xl\:pb-15 {
    padding-bottom: 15px !important;
  }
  .xl\:pl-15 {
    padding-left: 15px !important;
  }
  .xl\:pr-15 {
    padding-right: 15px !important;
  }
  .xl\:mt-15 {
    margin-top: 15px !important;
  }
  .xl\:mb-15 {
    margin-bottom: 15px !important;
  }
  .xl\:ml-15 {
    margin-left: 15px !important;
  }
  .xl\:mr-15 {
    margin-right: 15px !important;
  }
  .xl\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xl\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xl\:pt-20 {
    padding-top: 20px !important;
  }
  .xl\:pb-20 {
    padding-bottom: 20px !important;
  }
  .xl\:pl-20 {
    padding-left: 20px !important;
  }
  .xl\:pr-20 {
    padding-right: 20px !important;
  }
  .xl\:mt-20 {
    margin-top: 20px !important;
  }
  .xl\:mb-20 {
    margin-bottom: 20px !important;
  }
  .xl\:ml-20 {
    margin-left: 20px !important;
  }
  .xl\:mr-20 {
    margin-right: 20px !important;
  }
  .xl\:py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xl\:px-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xl\:pt-25 {
    padding-top: 25px !important;
  }
  .xl\:pb-25 {
    padding-bottom: 25px !important;
  }
  .xl\:pl-25 {
    padding-left: 25px !important;
  }
  .xl\:pr-25 {
    padding-right: 25px !important;
  }
  .xl\:mt-25 {
    margin-top: 25px !important;
  }
  .xl\:mb-25 {
    margin-bottom: 25px !important;
  }
  .xl\:ml-25 {
    margin-left: 25px !important;
  }
  .xl\:mr-25 {
    margin-right: 25px !important;
  }
  .xl\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xl\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xl\:pt-30 {
    padding-top: 30px !important;
  }
  .xl\:pb-30 {
    padding-bottom: 30px !important;
  }
  .xl\:pl-30 {
    padding-left: 30px !important;
  }
  .xl\:pr-30 {
    padding-right: 30px !important;
  }
  .xl\:mt-30 {
    margin-top: 30px !important;
  }
  .xl\:mb-30 {
    margin-bottom: 30px !important;
  }
  .xl\:ml-30 {
    margin-left: 30px !important;
  }
  .xl\:mr-30 {
    margin-right: 30px !important;
  }
  .xl\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xl\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xl\:pt-35 {
    padding-top: 35px !important;
  }
  .xl\:pb-35 {
    padding-bottom: 35px !important;
  }
  .xl\:pl-35 {
    padding-left: 35px !important;
  }
  .xl\:pr-35 {
    padding-right: 35px !important;
  }
  .xl\:mt-35 {
    margin-top: 35px !important;
  }
  .xl\:mb-35 {
    margin-bottom: 35px !important;
  }
  .xl\:ml-35 {
    margin-left: 35px !important;
  }
  .xl\:mr-35 {
    margin-right: 35px !important;
  }
  .xl\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xl\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xl\:pt-40 {
    padding-top: 40px !important;
  }
  .xl\:pb-40 {
    padding-bottom: 40px !important;
  }
  .xl\:pl-40 {
    padding-left: 40px !important;
  }
  .xl\:pr-40 {
    padding-right: 40px !important;
  }
  .xl\:mt-40 {
    margin-top: 40px !important;
  }
  .xl\:mb-40 {
    margin-bottom: 40px !important;
  }
  .xl\:ml-40 {
    margin-left: 40px !important;
  }
  .xl\:mr-40 {
    margin-right: 40px !important;
  }
  .xl\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .xl\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xl\:pt-45 {
    padding-top: 45px !important;
  }
  .xl\:pb-45 {
    padding-bottom: 45px !important;
  }
  .xl\:pl-45 {
    padding-left: 45px !important;
  }
  .xl\:pr-45 {
    padding-right: 45px !important;
  }
  .xl\:mt-45 {
    margin-top: 45px !important;
  }
  .xl\:mb-45 {
    margin-bottom: 45px !important;
  }
  .xl\:ml-45 {
    margin-left: 45px !important;
  }
  .xl\:mr-45 {
    margin-right: 45px !important;
  }
  .xl\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .xl\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .xl\:pt-50 {
    padding-top: 50px !important;
  }
  .xl\:pb-50 {
    padding-bottom: 50px !important;
  }
  .xl\:pl-50 {
    padding-left: 50px !important;
  }
  .xl\:pr-50 {
    padding-right: 50px !important;
  }
  .xl\:mt-50 {
    margin-top: 50px !important;
  }
  .xl\:mb-50 {
    margin-bottom: 50px !important;
  }
  .xl\:ml-50 {
    margin-left: 50px !important;
  }
  .xl\:mr-50 {
    margin-right: 50px !important;
  }
  .xl\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .xl\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .xl\:pt-60 {
    padding-top: 60px !important;
  }
  .xl\:pb-60 {
    padding-bottom: 60px !important;
  }
  .xl\:pl-60 {
    padding-left: 60px !important;
  }
  .xl\:pr-60 {
    padding-right: 60px !important;
  }
  .xl\:mt-60 {
    margin-top: 60px !important;
  }
  .xl\:mb-60 {
    margin-bottom: 60px !important;
  }
  .xl\:ml-60 {
    margin-left: 60px !important;
  }
  .xl\:mr-60 {
    margin-right: 60px !important;
  }
  .xl\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .xl\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .xl\:pt-80 {
    padding-top: 80px !important;
  }
  .xl\:pb-80 {
    padding-bottom: 80px !important;
  }
  .xl\:pl-80 {
    padding-left: 80px !important;
  }
  .xl\:pr-80 {
    padding-right: 80px !important;
  }
  .xl\:mt-80 {
    margin-top: 80px !important;
  }
  .xl\:mb-80 {
    margin-bottom: 80px !important;
  }
  .xl\:ml-80 {
    margin-left: 80px !important;
  }
  .xl\:mr-80 {
    margin-right: 80px !important;
  }
  .xl\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .xl\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .xl\:pt-90 {
    padding-top: 90px !important;
  }
  .xl\:pb-90 {
    padding-bottom: 90px !important;
  }
  .xl\:pl-90 {
    padding-left: 90px !important;
  }
  .xl\:pr-90 {
    padding-right: 90px !important;
  }
  .xl\:mt-90 {
    margin-top: 90px !important;
  }
  .xl\:mb-90 {
    margin-bottom: 90px !important;
  }
  .xl\:ml-90 {
    margin-left: 90px !important;
  }
  .xl\:mr-90 {
    margin-right: 90px !important;
  }
}

@media (max-width: 991px) {
  .lg\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .lg\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .lg\:pt-0 {
    padding-top: 0px !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0px !important;
  }
  .lg\:pl-0 {
    padding-left: 0px !important;
  }
  .lg\:pr-0 {
    padding-right: 0px !important;
  }
  .lg\:mt-0 {
    margin-top: 0px !important;
  }
  .lg\:mb-0 {
    margin-bottom: 0px !important;
  }
  .lg\:ml-0 {
    margin-left: 0px !important;
  }
  .lg\:mr-0 {
    margin-right: 0px !important;
  }
  .lg\:py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .lg\:px-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .lg\:pt-3 {
    padding-top: 3px !important;
  }
  .lg\:pb-3 {
    padding-bottom: 3px !important;
  }
  .lg\:pl-3 {
    padding-left: 3px !important;
  }
  .lg\:pr-3 {
    padding-right: 3px !important;
  }
  .lg\:mt-3 {
    margin-top: 3px !important;
  }
  .lg\:mb-3 {
    margin-bottom: 3px !important;
  }
  .lg\:ml-3 {
    margin-left: 3px !important;
  }
  .lg\:mr-3 {
    margin-right: 3px !important;
  }
  .lg\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .lg\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .lg\:pt-5 {
    padding-top: 5px !important;
  }
  .lg\:pb-5 {
    padding-bottom: 5px !important;
  }
  .lg\:pl-5 {
    padding-left: 5px !important;
  }
  .lg\:pr-5 {
    padding-right: 5px !important;
  }
  .lg\:mt-5 {
    margin-top: 5px !important;
  }
  .lg\:mb-5 {
    margin-bottom: 5px !important;
  }
  .lg\:ml-5 {
    margin-left: 5px !important;
  }
  .lg\:mr-5 {
    margin-right: 5px !important;
  }
  .lg\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .lg\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .lg\:pt-8 {
    padding-top: 8px !important;
  }
  .lg\:pb-8 {
    padding-bottom: 8px !important;
  }
  .lg\:pl-8 {
    padding-left: 8px !important;
  }
  .lg\:pr-8 {
    padding-right: 8px !important;
  }
  .lg\:mt-8 {
    margin-top: 8px !important;
  }
  .lg\:mb-8 {
    margin-bottom: 8px !important;
  }
  .lg\:ml-8 {
    margin-left: 8px !important;
  }
  .lg\:mr-8 {
    margin-right: 8px !important;
  }
  .lg\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .lg\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .lg\:pt-10 {
    padding-top: 10px !important;
  }
  .lg\:pb-10 {
    padding-bottom: 10px !important;
  }
  .lg\:pl-10 {
    padding-left: 10px !important;
  }
  .lg\:pr-10 {
    padding-right: 10px !important;
  }
  .lg\:mt-10 {
    margin-top: 10px !important;
  }
  .lg\:mb-10 {
    margin-bottom: 10px !important;
  }
  .lg\:ml-10 {
    margin-left: 10px !important;
  }
  .lg\:mr-10 {
    margin-right: 10px !important;
  }
  .lg\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .lg\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .lg\:pt-12 {
    padding-top: 12px !important;
  }
  .lg\:pb-12 {
    padding-bottom: 12px !important;
  }
  .lg\:pl-12 {
    padding-left: 12px !important;
  }
  .lg\:pr-12 {
    padding-right: 12px !important;
  }
  .lg\:mt-12 {
    margin-top: 12px !important;
  }
  .lg\:mb-12 {
    margin-bottom: 12px !important;
  }
  .lg\:ml-12 {
    margin-left: 12px !important;
  }
  .lg\:mr-12 {
    margin-right: 12px !important;
  }
  .lg\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .lg\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .lg\:pt-15 {
    padding-top: 15px !important;
  }
  .lg\:pb-15 {
    padding-bottom: 15px !important;
  }
  .lg\:pl-15 {
    padding-left: 15px !important;
  }
  .lg\:pr-15 {
    padding-right: 15px !important;
  }
  .lg\:mt-15 {
    margin-top: 15px !important;
  }
  .lg\:mb-15 {
    margin-bottom: 15px !important;
  }
  .lg\:ml-15 {
    margin-left: 15px !important;
  }
  .lg\:mr-15 {
    margin-right: 15px !important;
  }
  .lg\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .lg\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .lg\:pt-20 {
    padding-top: 20px !important;
  }
  .lg\:pb-20 {
    padding-bottom: 20px !important;
  }
  .lg\:pl-20 {
    padding-left: 20px !important;
  }
  .lg\:pr-20 {
    padding-right: 20px !important;
  }
  .lg\:mt-20 {
    margin-top: 20px !important;
  }
  .lg\:mb-20 {
    margin-bottom: 20px !important;
  }
  .lg\:ml-20 {
    margin-left: 20px !important;
  }
  .lg\:mr-20 {
    margin-right: 20px !important;
  }
  .lg\:py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .lg\:px-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .lg\:pt-25 {
    padding-top: 25px !important;
  }
  .lg\:pb-25 {
    padding-bottom: 25px !important;
  }
  .lg\:pl-25 {
    padding-left: 25px !important;
  }
  .lg\:pr-25 {
    padding-right: 25px !important;
  }
  .lg\:mt-25 {
    margin-top: 25px !important;
  }
  .lg\:mb-25 {
    margin-bottom: 25px !important;
  }
  .lg\:ml-25 {
    margin-left: 25px !important;
  }
  .lg\:mr-25 {
    margin-right: 25px !important;
  }
  .lg\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .lg\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .lg\:pt-30 {
    padding-top: 30px !important;
  }
  .lg\:pb-30 {
    padding-bottom: 30px !important;
  }
  .lg\:pl-30 {
    padding-left: 30px !important;
  }
  .lg\:pr-30 {
    padding-right: 30px !important;
  }
  .lg\:mt-30 {
    margin-top: 30px !important;
  }
  .lg\:mb-30 {
    margin-bottom: 30px !important;
  }
  .lg\:ml-30 {
    margin-left: 30px !important;
  }
  .lg\:mr-30 {
    margin-right: 30px !important;
  }
  .lg\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .lg\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .lg\:pt-35 {
    padding-top: 35px !important;
  }
  .lg\:pb-35 {
    padding-bottom: 35px !important;
  }
  .lg\:pl-35 {
    padding-left: 35px !important;
  }
  .lg\:pr-35 {
    padding-right: 35px !important;
  }
  .lg\:mt-35 {
    margin-top: 35px !important;
  }
  .lg\:mb-35 {
    margin-bottom: 35px !important;
  }
  .lg\:ml-35 {
    margin-left: 35px !important;
  }
  .lg\:mr-35 {
    margin-right: 35px !important;
  }
  .lg\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .lg\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .lg\:pt-40 {
    padding-top: 40px !important;
  }
  .lg\:pb-40 {
    padding-bottom: 40px !important;
  }
  .lg\:pl-40 {
    padding-left: 40px !important;
  }
  .lg\:pr-40 {
    padding-right: 40px !important;
  }
  .lg\:mt-40 {
    margin-top: 40px !important;
  }
  .lg\:mb-40 {
    margin-bottom: 40px !important;
  }
  .lg\:ml-40 {
    margin-left: 40px !important;
  }
  .lg\:mr-40 {
    margin-right: 40px !important;
  }
  .lg\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .lg\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .lg\:pt-45 {
    padding-top: 45px !important;
  }
  .lg\:pb-45 {
    padding-bottom: 45px !important;
  }
  .lg\:pl-45 {
    padding-left: 45px !important;
  }
  .lg\:pr-45 {
    padding-right: 45px !important;
  }
  .lg\:mt-45 {
    margin-top: 45px !important;
  }
  .lg\:mb-45 {
    margin-bottom: 45px !important;
  }
  .lg\:ml-45 {
    margin-left: 45px !important;
  }
  .lg\:mr-45 {
    margin-right: 45px !important;
  }
  .lg\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .lg\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .lg\:pt-50 {
    padding-top: 50px !important;
  }
  .lg\:pb-50 {
    padding-bottom: 50px !important;
  }
  .lg\:pl-50 {
    padding-left: 50px !important;
  }
  .lg\:pr-50 {
    padding-right: 50px !important;
  }
  .lg\:mt-50 {
    margin-top: 50px !important;
  }
  .lg\:mb-50 {
    margin-bottom: 50px !important;
  }
  .lg\:ml-50 {
    margin-left: 50px !important;
  }
  .lg\:mr-50 {
    margin-right: 50px !important;
  }
  .lg\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .lg\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .lg\:pt-60 {
    padding-top: 60px !important;
  }
  .lg\:pb-60 {
    padding-bottom: 60px !important;
  }
  .lg\:pl-60 {
    padding-left: 60px !important;
  }
  .lg\:pr-60 {
    padding-right: 60px !important;
  }
  .lg\:mt-60 {
    margin-top: 60px !important;
  }
  .lg\:mb-60 {
    margin-bottom: 60px !important;
  }
  .lg\:ml-60 {
    margin-left: 60px !important;
  }
  .lg\:mr-60 {
    margin-right: 60px !important;
  }
  .lg\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .lg\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .lg\:pt-80 {
    padding-top: 80px !important;
  }
  .lg\:pb-80 {
    padding-bottom: 80px !important;
  }
  .lg\:pl-80 {
    padding-left: 80px !important;
  }
  .lg\:pr-80 {
    padding-right: 80px !important;
  }
  .lg\:mt-80 {
    margin-top: 80px !important;
  }
  .lg\:mb-80 {
    margin-bottom: 80px !important;
  }
  .lg\:ml-80 {
    margin-left: 80px !important;
  }
  .lg\:mr-80 {
    margin-right: 80px !important;
  }
  .lg\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .lg\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .lg\:pt-90 {
    padding-top: 90px !important;
  }
  .lg\:pb-90 {
    padding-bottom: 90px !important;
  }
  .lg\:pl-90 {
    padding-left: 90px !important;
  }
  .lg\:pr-90 {
    padding-right: 90px !important;
  }
  .lg\:mt-90 {
    margin-top: 90px !important;
  }
  .lg\:mb-90 {
    margin-bottom: 90px !important;
  }
  .lg\:ml-90 {
    margin-left: 90px !important;
  }
  .lg\:mr-90 {
    margin-right: 90px !important;
  }
}

@media (max-width: 767px) {
  .md\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .md\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md\:pt-0 {
    padding-top: 0px !important;
  }
  .md\:pb-0 {
    padding-bottom: 0px !important;
  }
  .md\:pl-0 {
    padding-left: 0px !important;
  }
  .md\:pr-0 {
    padding-right: 0px !important;
  }
  .md\:mt-0 {
    margin-top: 0px !important;
  }
  .md\:mb-0 {
    margin-bottom: 0px !important;
  }
  .md\:ml-0 {
    margin-left: 0px !important;
  }
  .md\:mr-0 {
    margin-right: 0px !important;
  }
  .md\:py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .md\:px-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .md\:pt-3 {
    padding-top: 3px !important;
  }
  .md\:pb-3 {
    padding-bottom: 3px !important;
  }
  .md\:pl-3 {
    padding-left: 3px !important;
  }
  .md\:pr-3 {
    padding-right: 3px !important;
  }
  .md\:mt-3 {
    margin-top: 3px !important;
  }
  .md\:mb-3 {
    margin-bottom: 3px !important;
  }
  .md\:ml-3 {
    margin-left: 3px !important;
  }
  .md\:mr-3 {
    margin-right: 3px !important;
  }
  .md\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .md\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .md\:pt-5 {
    padding-top: 5px !important;
  }
  .md\:pb-5 {
    padding-bottom: 5px !important;
  }
  .md\:pl-5 {
    padding-left: 5px !important;
  }
  .md\:pr-5 {
    padding-right: 5px !important;
  }
  .md\:mt-5 {
    margin-top: 5px !important;
  }
  .md\:mb-5 {
    margin-bottom: 5px !important;
  }
  .md\:ml-5 {
    margin-left: 5px !important;
  }
  .md\:mr-5 {
    margin-right: 5px !important;
  }
  .md\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .md\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .md\:pt-8 {
    padding-top: 8px !important;
  }
  .md\:pb-8 {
    padding-bottom: 8px !important;
  }
  .md\:pl-8 {
    padding-left: 8px !important;
  }
  .md\:pr-8 {
    padding-right: 8px !important;
  }
  .md\:mt-8 {
    margin-top: 8px !important;
  }
  .md\:mb-8 {
    margin-bottom: 8px !important;
  }
  .md\:ml-8 {
    margin-left: 8px !important;
  }
  .md\:mr-8 {
    margin-right: 8px !important;
  }
  .md\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .md\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .md\:pt-10 {
    padding-top: 10px !important;
  }
  .md\:pb-10 {
    padding-bottom: 10px !important;
  }
  .md\:pl-10 {
    padding-left: 10px !important;
  }
  .md\:pr-10 {
    padding-right: 10px !important;
  }
  .md\:mt-10 {
    margin-top: 10px !important;
  }
  .md\:mb-10 {
    margin-bottom: 10px !important;
  }
  .md\:ml-10 {
    margin-left: 10px !important;
  }
  .md\:mr-10 {
    margin-right: 10px !important;
  }
  .md\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .md\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .md\:pt-12 {
    padding-top: 12px !important;
  }
  .md\:pb-12 {
    padding-bottom: 12px !important;
  }
  .md\:pl-12 {
    padding-left: 12px !important;
  }
  .md\:pr-12 {
    padding-right: 12px !important;
  }
  .md\:mt-12 {
    margin-top: 12px !important;
  }
  .md\:mb-12 {
    margin-bottom: 12px !important;
  }
  .md\:ml-12 {
    margin-left: 12px !important;
  }
  .md\:mr-12 {
    margin-right: 12px !important;
  }
  .md\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .md\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .md\:pt-15 {
    padding-top: 15px !important;
  }
  .md\:pb-15 {
    padding-bottom: 15px !important;
  }
  .md\:pl-15 {
    padding-left: 15px !important;
  }
  .md\:pr-15 {
    padding-right: 15px !important;
  }
  .md\:mt-15 {
    margin-top: 15px !important;
  }
  .md\:mb-15 {
    margin-bottom: 15px !important;
  }
  .md\:ml-15 {
    margin-left: 15px !important;
  }
  .md\:mr-15 {
    margin-right: 15px !important;
  }
  .md\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .md\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .md\:pt-20 {
    padding-top: 20px !important;
  }
  .md\:pb-20 {
    padding-bottom: 20px !important;
  }
  .md\:pl-20 {
    padding-left: 20px !important;
  }
  .md\:pr-20 {
    padding-right: 20px !important;
  }
  .md\:mt-20 {
    margin-top: 20px !important;
  }
  .md\:mb-20 {
    margin-bottom: 20px !important;
  }
  .md\:ml-20 {
    margin-left: 20px !important;
  }
  .md\:mr-20 {
    margin-right: 20px !important;
  }
  .md\:py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .md\:px-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .md\:pt-25 {
    padding-top: 25px !important;
  }
  .md\:pb-25 {
    padding-bottom: 25px !important;
  }
  .md\:pl-25 {
    padding-left: 25px !important;
  }
  .md\:pr-25 {
    padding-right: 25px !important;
  }
  .md\:mt-25 {
    margin-top: 25px !important;
  }
  .md\:mb-25 {
    margin-bottom: 25px !important;
  }
  .md\:ml-25 {
    margin-left: 25px !important;
  }
  .md\:mr-25 {
    margin-right: 25px !important;
  }
  .md\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md\:pt-30 {
    padding-top: 30px !important;
  }
  .md\:pb-30 {
    padding-bottom: 30px !important;
  }
  .md\:pl-30 {
    padding-left: 30px !important;
  }
  .md\:pr-30 {
    padding-right: 30px !important;
  }
  .md\:mt-30 {
    margin-top: 30px !important;
  }
  .md\:mb-30 {
    margin-bottom: 30px !important;
  }
  .md\:ml-30 {
    margin-left: 30px !important;
  }
  .md\:mr-30 {
    margin-right: 30px !important;
  }
  .md\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .md\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .md\:pt-35 {
    padding-top: 35px !important;
  }
  .md\:pb-35 {
    padding-bottom: 35px !important;
  }
  .md\:pl-35 {
    padding-left: 35px !important;
  }
  .md\:pr-35 {
    padding-right: 35px !important;
  }
  .md\:mt-35 {
    margin-top: 35px !important;
  }
  .md\:mb-35 {
    margin-bottom: 35px !important;
  }
  .md\:ml-35 {
    margin-left: 35px !important;
  }
  .md\:mr-35 {
    margin-right: 35px !important;
  }
  .md\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md\:pt-40 {
    padding-top: 40px !important;
  }
  .md\:pb-40 {
    padding-bottom: 40px !important;
  }
  .md\:pl-40 {
    padding-left: 40px !important;
  }
  .md\:pr-40 {
    padding-right: 40px !important;
  }
  .md\:mt-40 {
    margin-top: 40px !important;
  }
  .md\:mb-40 {
    margin-bottom: 40px !important;
  }
  .md\:ml-40 {
    margin-left: 40px !important;
  }
  .md\:mr-40 {
    margin-right: 40px !important;
  }
  .md\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .md\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .md\:pt-45 {
    padding-top: 45px !important;
  }
  .md\:pb-45 {
    padding-bottom: 45px !important;
  }
  .md\:pl-45 {
    padding-left: 45px !important;
  }
  .md\:pr-45 {
    padding-right: 45px !important;
  }
  .md\:mt-45 {
    margin-top: 45px !important;
  }
  .md\:mb-45 {
    margin-bottom: 45px !important;
  }
  .md\:ml-45 {
    margin-left: 45px !important;
  }
  .md\:mr-45 {
    margin-right: 45px !important;
  }
  .md\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .md\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .md\:pt-50 {
    padding-top: 50px !important;
  }
  .md\:pb-50 {
    padding-bottom: 50px !important;
  }
  .md\:pl-50 {
    padding-left: 50px !important;
  }
  .md\:pr-50 {
    padding-right: 50px !important;
  }
  .md\:mt-50 {
    margin-top: 50px !important;
  }
  .md\:mb-50 {
    margin-bottom: 50px !important;
  }
  .md\:ml-50 {
    margin-left: 50px !important;
  }
  .md\:mr-50 {
    margin-right: 50px !important;
  }
  .md\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .md\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .md\:pt-60 {
    padding-top: 60px !important;
  }
  .md\:pb-60 {
    padding-bottom: 60px !important;
  }
  .md\:pl-60 {
    padding-left: 60px !important;
  }
  .md\:pr-60 {
    padding-right: 60px !important;
  }
  .md\:mt-60 {
    margin-top: 60px !important;
  }
  .md\:mb-60 {
    margin-bottom: 60px !important;
  }
  .md\:ml-60 {
    margin-left: 60px !important;
  }
  .md\:mr-60 {
    margin-right: 60px !important;
  }
  .md\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .md\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .md\:pt-80 {
    padding-top: 80px !important;
  }
  .md\:pb-80 {
    padding-bottom: 80px !important;
  }
  .md\:pl-80 {
    padding-left: 80px !important;
  }
  .md\:pr-80 {
    padding-right: 80px !important;
  }
  .md\:mt-80 {
    margin-top: 80px !important;
  }
  .md\:mb-80 {
    margin-bottom: 80px !important;
  }
  .md\:ml-80 {
    margin-left: 80px !important;
  }
  .md\:mr-80 {
    margin-right: 80px !important;
  }
  .md\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .md\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .md\:pt-90 {
    padding-top: 90px !important;
  }
  .md\:pb-90 {
    padding-bottom: 90px !important;
  }
  .md\:pl-90 {
    padding-left: 90px !important;
  }
  .md\:pr-90 {
    padding-right: 90px !important;
  }
  .md\:mt-90 {
    margin-top: 90px !important;
  }
  .md\:mb-90 {
    margin-bottom: 90px !important;
  }
  .md\:ml-90 {
    margin-left: 90px !important;
  }
  .md\:mr-90 {
    margin-right: 90px !important;
  }
}

@media (max-width: 575px) {
  .sm\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sm\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sm\:pt-0 {
    padding-top: 0px !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0px !important;
  }
  .sm\:pl-0 {
    padding-left: 0px !important;
  }
  .sm\:pr-0 {
    padding-right: 0px !important;
  }
  .sm\:mt-0 {
    margin-top: 0px !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0px !important;
  }
  .sm\:ml-0 {
    margin-left: 0px !important;
  }
  .sm\:mr-0 {
    margin-right: 0px !important;
  }
  .sm\:py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .sm\:px-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .sm\:pt-3 {
    padding-top: 3px !important;
  }
  .sm\:pb-3 {
    padding-bottom: 3px !important;
  }
  .sm\:pl-3 {
    padding-left: 3px !important;
  }
  .sm\:pr-3 {
    padding-right: 3px !important;
  }
  .sm\:mt-3 {
    margin-top: 3px !important;
  }
  .sm\:mb-3 {
    margin-bottom: 3px !important;
  }
  .sm\:ml-3 {
    margin-left: 3px !important;
  }
  .sm\:mr-3 {
    margin-right: 3px !important;
  }
  .sm\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .sm\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .sm\:pt-5 {
    padding-top: 5px !important;
  }
  .sm\:pb-5 {
    padding-bottom: 5px !important;
  }
  .sm\:pl-5 {
    padding-left: 5px !important;
  }
  .sm\:pr-5 {
    padding-right: 5px !important;
  }
  .sm\:mt-5 {
    margin-top: 5px !important;
  }
  .sm\:mb-5 {
    margin-bottom: 5px !important;
  }
  .sm\:ml-5 {
    margin-left: 5px !important;
  }
  .sm\:mr-5 {
    margin-right: 5px !important;
  }
  .sm\:py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sm\:px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .sm\:pt-8 {
    padding-top: 8px !important;
  }
  .sm\:pb-8 {
    padding-bottom: 8px !important;
  }
  .sm\:pl-8 {
    padding-left: 8px !important;
  }
  .sm\:pr-8 {
    padding-right: 8px !important;
  }
  .sm\:mt-8 {
    margin-top: 8px !important;
  }
  .sm\:mb-8 {
    margin-bottom: 8px !important;
  }
  .sm\:ml-8 {
    margin-left: 8px !important;
  }
  .sm\:mr-8 {
    margin-right: 8px !important;
  }
  .sm\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sm\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sm\:pt-10 {
    padding-top: 10px !important;
  }
  .sm\:pb-10 {
    padding-bottom: 10px !important;
  }
  .sm\:pl-10 {
    padding-left: 10px !important;
  }
  .sm\:pr-10 {
    padding-right: 10px !important;
  }
  .sm\:mt-10 {
    margin-top: 10px !important;
  }
  .sm\:mb-10 {
    margin-bottom: 10px !important;
  }
  .sm\:ml-10 {
    margin-left: 10px !important;
  }
  .sm\:mr-10 {
    margin-right: 10px !important;
  }
  .sm\:py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .sm\:px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .sm\:pt-12 {
    padding-top: 12px !important;
  }
  .sm\:pb-12 {
    padding-bottom: 12px !important;
  }
  .sm\:pl-12 {
    padding-left: 12px !important;
  }
  .sm\:pr-12 {
    padding-right: 12px !important;
  }
  .sm\:mt-12 {
    margin-top: 12px !important;
  }
  .sm\:mb-12 {
    margin-bottom: 12px !important;
  }
  .sm\:ml-12 {
    margin-left: 12px !important;
  }
  .sm\:mr-12 {
    margin-right: 12px !important;
  }
  .sm\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .sm\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .sm\:pt-15 {
    padding-top: 15px !important;
  }
  .sm\:pb-15 {
    padding-bottom: 15px !important;
  }
  .sm\:pl-15 {
    padding-left: 15px !important;
  }
  .sm\:pr-15 {
    padding-right: 15px !important;
  }
  .sm\:mt-15 {
    margin-top: 15px !important;
  }
  .sm\:mb-15 {
    margin-bottom: 15px !important;
  }
  .sm\:ml-15 {
    margin-left: 15px !important;
  }
  .sm\:mr-15 {
    margin-right: 15px !important;
  }
  .sm\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sm\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sm\:pt-20 {
    padding-top: 20px !important;
  }
  .sm\:pb-20 {
    padding-bottom: 20px !important;
  }
  .sm\:pl-20 {
    padding-left: 20px !important;
  }
  .sm\:pr-20 {
    padding-right: 20px !important;
  }
  .sm\:mt-20 {
    margin-top: 20px !important;
  }
  .sm\:mb-20 {
    margin-bottom: 20px !important;
  }
  .sm\:ml-20 {
    margin-left: 20px !important;
  }
  .sm\:mr-20 {
    margin-right: 20px !important;
  }
  .sm\:py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .sm\:px-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .sm\:pt-25 {
    padding-top: 25px !important;
  }
  .sm\:pb-25 {
    padding-bottom: 25px !important;
  }
  .sm\:pl-25 {
    padding-left: 25px !important;
  }
  .sm\:pr-25 {
    padding-right: 25px !important;
  }
  .sm\:mt-25 {
    margin-top: 25px !important;
  }
  .sm\:mb-25 {
    margin-bottom: 25px !important;
  }
  .sm\:ml-25 {
    margin-left: 25px !important;
  }
  .sm\:mr-25 {
    margin-right: 25px !important;
  }
  .sm\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .sm\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .sm\:pt-30 {
    padding-top: 30px !important;
  }
  .sm\:pb-30 {
    padding-bottom: 30px !important;
  }
  .sm\:pl-30 {
    padding-left: 30px !important;
  }
  .sm\:pr-30 {
    padding-right: 30px !important;
  }
  .sm\:mt-30 {
    margin-top: 30px !important;
  }
  .sm\:mb-30 {
    margin-bottom: 30px !important;
  }
  .sm\:ml-30 {
    margin-left: 30px !important;
  }
  .sm\:mr-30 {
    margin-right: 30px !important;
  }
  .sm\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .sm\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .sm\:pt-35 {
    padding-top: 35px !important;
  }
  .sm\:pb-35 {
    padding-bottom: 35px !important;
  }
  .sm\:pl-35 {
    padding-left: 35px !important;
  }
  .sm\:pr-35 {
    padding-right: 35px !important;
  }
  .sm\:mt-35 {
    margin-top: 35px !important;
  }
  .sm\:mb-35 {
    margin-bottom: 35px !important;
  }
  .sm\:ml-35 {
    margin-left: 35px !important;
  }
  .sm\:mr-35 {
    margin-right: 35px !important;
  }
  .sm\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sm\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sm\:pt-40 {
    padding-top: 40px !important;
  }
  .sm\:pb-40 {
    padding-bottom: 40px !important;
  }
  .sm\:pl-40 {
    padding-left: 40px !important;
  }
  .sm\:pr-40 {
    padding-right: 40px !important;
  }
  .sm\:mt-40 {
    margin-top: 40px !important;
  }
  .sm\:mb-40 {
    margin-bottom: 40px !important;
  }
  .sm\:ml-40 {
    margin-left: 40px !important;
  }
  .sm\:mr-40 {
    margin-right: 40px !important;
  }
  .sm\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .sm\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .sm\:pt-45 {
    padding-top: 45px !important;
  }
  .sm\:pb-45 {
    padding-bottom: 45px !important;
  }
  .sm\:pl-45 {
    padding-left: 45px !important;
  }
  .sm\:pr-45 {
    padding-right: 45px !important;
  }
  .sm\:mt-45 {
    margin-top: 45px !important;
  }
  .sm\:mb-45 {
    margin-bottom: 45px !important;
  }
  .sm\:ml-45 {
    margin-left: 45px !important;
  }
  .sm\:mr-45 {
    margin-right: 45px !important;
  }
  .sm\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sm\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .sm\:pt-50 {
    padding-top: 50px !important;
  }
  .sm\:pb-50 {
    padding-bottom: 50px !important;
  }
  .sm\:pl-50 {
    padding-left: 50px !important;
  }
  .sm\:pr-50 {
    padding-right: 50px !important;
  }
  .sm\:mt-50 {
    margin-top: 50px !important;
  }
  .sm\:mb-50 {
    margin-bottom: 50px !important;
  }
  .sm\:ml-50 {
    margin-left: 50px !important;
  }
  .sm\:mr-50 {
    margin-right: 50px !important;
  }
  .sm\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .sm\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .sm\:pt-60 {
    padding-top: 60px !important;
  }
  .sm\:pb-60 {
    padding-bottom: 60px !important;
  }
  .sm\:pl-60 {
    padding-left: 60px !important;
  }
  .sm\:pr-60 {
    padding-right: 60px !important;
  }
  .sm\:mt-60 {
    margin-top: 60px !important;
  }
  .sm\:mb-60 {
    margin-bottom: 60px !important;
  }
  .sm\:ml-60 {
    margin-left: 60px !important;
  }
  .sm\:mr-60 {
    margin-right: 60px !important;
  }
  .sm\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .sm\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .sm\:pt-80 {
    padding-top: 80px !important;
  }
  .sm\:pb-80 {
    padding-bottom: 80px !important;
  }
  .sm\:pl-80 {
    padding-left: 80px !important;
  }
  .sm\:pr-80 {
    padding-right: 80px !important;
  }
  .sm\:mt-80 {
    margin-top: 80px !important;
  }
  .sm\:mb-80 {
    margin-bottom: 80px !important;
  }
  .sm\:ml-80 {
    margin-left: 80px !important;
  }
  .sm\:mr-80 {
    margin-right: 80px !important;
  }
  .sm\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .sm\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .sm\:pt-90 {
    padding-top: 90px !important;
  }
  .sm\:pb-90 {
    padding-bottom: 90px !important;
  }
  .sm\:pl-90 {
    padding-left: 90px !important;
  }
  .sm\:pr-90 {
    padding-right: 90px !important;
  }
  .sm\:mt-90 {
    margin-top: 90px !important;
  }
  .sm\:mb-90 {
    margin-bottom: 90px !important;
  }
  .sm\:ml-90 {
    margin-left: 90px !important;
  }
  .sm\:mr-90 {
    margin-right: 90px !important;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.xl\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.lg\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.md\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.sm\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.x-gap-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.x-gap-5 > * {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.y-gap-5 {
  margin-top: -2.5px;
  margin-bottom: -2.5px;
}

.y-gap-5 > * {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.x-gap-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.x-gap-10 > * {
  padding-left: 5px;
  padding-right: 5px;
}

.y-gap-10 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.y-gap-10 > * {
  padding-top: 5px;
  padding-bottom: 5px;
}

.x-gap-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.x-gap-15 > * {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.y-gap-15 {
  margin-top: -7.5px;
  margin-bottom: -7.5px;
}

.y-gap-15 > * {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.x-gap-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.x-gap-20 > * {
  padding-left: 10px;
  padding-right: 10px;
}

.y-gap-20 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.y-gap-20 > * {
  padding-top: 10px;
  padding-bottom: 10px;
}

.x-gap-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.x-gap-30 > * {
  padding-left: 15px;
  padding-right: 15px;
}

.y-gap-30 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.y-gap-30 > * {
  padding-top: 15px;
  padding-bottom: 15px;
}

.x-gap-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.x-gap-40 > * {
  padding-left: 20px;
  padding-right: 20px;
}

.y-gap-40 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.y-gap-40 > * {
  padding-top: 20px;
  padding-bottom: 20px;
}

.x-gap-50 {
  margin-left: -25px;
  margin-right: -25px;
}

.x-gap-50 > * {
  padding-left: 25px;
  padding-right: 25px;
}

.y-gap-50 {
  margin-top: -25px;
  margin-bottom: -25px;
}

.y-gap-50 > * {
  padding-top: 25px;
  padding-bottom: 25px;
}

.x-gap-60 {
  margin-left: -30px;
  margin-right: -30px;
}

.x-gap-60 > * {
  padding-left: 30px;
  padding-right: 30px;
}

.y-gap-60 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.y-gap-60 > * {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  .md\:x-gap-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .md\:x-gap-5 > * {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .md\:y-gap-5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .md\:y-gap-5 > * {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .md\:x-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .md\:x-gap-10 > * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .md\:y-gap-10 {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .md\:y-gap-10 > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .md\:x-gap-15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .md\:x-gap-15 > * {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .md\:y-gap-15 {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }
  .md\:y-gap-15 > * {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
  .md\:x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .md\:x-gap-20 > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .md\:y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .md\:y-gap-20 > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .md\:x-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .md\:x-gap-30 > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .md\:y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .md\:y-gap-30 > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .md\:x-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .md\:x-gap-40 > * {
    padding-left: 20px;
    padding-right: 20px;
  }
  .md\:y-gap-40 {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .md\:y-gap-40 > * {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .md\:x-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .md\:x-gap-50 > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .md\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .md\:y-gap-50 > * {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .md\:x-gap-60 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .md\:x-gap-60 > * {
    padding-left: 30px;
    padding-right: 30px;
  }
  .md\:y-gap-60 {
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .md\:y-gap-60 > * {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .sm\:x-gap-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .sm\:x-gap-5 > * {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .sm\:y-gap-5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .sm\:y-gap-5 > * {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .sm\:x-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .sm\:x-gap-10 > * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .sm\:y-gap-10 {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .sm\:y-gap-10 > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sm\:x-gap-15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .sm\:x-gap-15 > * {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .sm\:y-gap-15 {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }
  .sm\:y-gap-15 > * {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
  .sm\:x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .sm\:x-gap-20 > * {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm\:y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .sm\:y-gap-20 > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sm\:x-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sm\:x-gap-30 > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sm\:y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .sm\:y-gap-30 > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sm\:x-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .sm\:x-gap-40 > * {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sm\:y-gap-40 {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .sm\:y-gap-40 > * {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sm\:x-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .sm\:x-gap-50 > * {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sm\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .sm\:y-gap-50 > * {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .sm\:x-gap-60 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .sm\:x-gap-60 > * {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sm\:y-gap-60 {
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .sm\:y-gap-60 > * {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.layout-pt-sm {
  padding-top: 30px;
}

.layout-pb-sm {
  padding-bottom: 30px;
}

.layout-pt-md {
  padding-top: 60px;
}

.layout-pb-md {
  padding-bottom: 60px;
}

.layout-pt-lg {
  padding-top: 20px;
}

.layout-pb-lg {
  padding-bottom: 20px;
}

.layout-pt-xl {
  padding-top: 140px;
}

.layout-pb-xl {
  padding-bottom: 140px;
}

@media (max-width: 767px) {
  .layout-pt-sm {
    padding-top: 30px;
  }
  .layout-pb-sm {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .layout-pt-md {
    padding-top: 60px;
  }
  .layout-pb-md {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .layout-pt-lg {
    padding-top: 100px;
  }
  .layout-pb-lg {
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .layout-pt-xl {
    padding-top: 120px;
  }
  .layout-pb-xl {
    padding-bottom: 120px;
  }
}

@media (max-width: 575px) {
  .layout-pt-sm {
    padding-top: 30px;
  }
  .layout-pb-sm {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .layout-pt-md {
    padding-top: 50px;
  }
  .layout-pb-md {
    padding-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .layout-pt-lg {
    padding-top: 80px;
  }
  .layout-pb-lg {
    padding-bottom: 80px;
  }
}

@media (max-width: 575px) {
  .layout-pt-xl {
    padding-top: 80px;
  }
  .layout-pb-xl {
    padding-bottom: 80px;
  }
}

.w-1\/7 {
  width: 14.285714% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/1 {
  width: 100% !important;
}

@media (max-width: 1199px) {
  .xl\:w-1\/5 {
    width: 20% !important;
  }
  .xl\:w-1\/4 {
    width: 25% !important;
  }
  .xl\:w-1\/3 {
    width: 33.333333% !important;
  }
  .xl\:w-1\/2 {
    width: 50% !important;
  }
  .xl\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .lg\:w-1\/5 {
    width: 20% !important;
  }
  .lg\:w-1\/4 {
    width: 25% !important;
  }
  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }
  .lg\:w-1\/2 {
    width: 50% !important;
  }
  .lg\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .md\:w-1\/5 {
    width: 20% !important;
  }
  .md\:w-1\/4 {
    width: 25% !important;
  }
  .md\:w-1\/3 {
    width: 33.333333% !important;
  }
  .md\:w-1\/2 {
    width: 50% !important;
  }
  .md\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .sm\:w-1\/5 {
    width: 20% !important;
  }
  .sm\:w-1\/4 {
    width: 25% !important;
  }
  .sm\:w-1\/3 {
    width: 33.333333% !important;
  }
  .sm\:w-1\/2 {
    width: 50% !important;
  }
  .sm\:w-1\/1 {
    width: 100% !important;
  }
}

.flex-column {
  flex-direction: column;
}

@media (max-width: 1199px) {
  .xl\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 991px) {
  .lg\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .md\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 575px) {
  .sm\:flex-column {
    flex-direction: column !important;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@media (max-width: 1199px) {
  .xl\:text-center {
    text-align: center !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
  .xl\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 991px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 767px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 575px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

@media (max-width: 1199px) {
  .xl\:justify-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-center {
    justify-content: center !important;
  }
  .xl\:justify-between {
    justify-content: space-between !important;
  }
  .xl\:items-start {
    align-items: flex-start !important;
  }
  .xl\:items-end {
    align-items: flex-end !important;
  }
  .xl\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 991px) {
  .lg\:justify-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-center {
    justify-content: center !important;
  }
  .lg\:justify-between {
    justify-content: space-between !important;
  }
  .lg\:items-start {
    align-items: flex-start !important;
  }
  .lg\:items-end {
    align-items: flex-end !important;
  }
  .lg\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 767px) {
  .md\:justify-start {
    justify-content: flex-start !important;
  }
  .md\:justify-end {
    justify-content: flex-end !important;
  }
  .md\:justify-center {
    justify-content: center !important;
  }
  .md\:justify-between {
    justify-content: space-between !important;
  }
  .md\:items-start {
    align-items: flex-start !important;
  }
  .md\:items-end {
    align-items: flex-end !important;
  }
  .md\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 575px) {
  .sm\:justify-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-center {
    justify-content: center !important;
  }
  .sm\:justify-between {
    justify-content: space-between !important;
  }
  .sm\:items-start {
    align-items: flex-start !important;
  }
  .sm\:items-end {
    align-items: flex-end !important;
  }
  .sm\:items-center {
    align-items: center !important;
  }
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

@media (max-width: 1199px) {
  .xl\:d-none {
    display: none !important;
  }
  .xl\:d-inline-block {
    display: inline-block !important;
  }
  .xl\:d-block {
    display: block !important;
  }
  .xl\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 991px) {
  .lg\:d-none {
    display: none !important;
  }
  .lg\:d-inline-block {
    display: inline-block !important;
  }
  .lg\:d-block {
    display: block !important;
  }
  .lg\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 767px) {
  .md\:d-none {
    display: none !important;
  }
  .md\:d-inline-block {
    display: inline-block !important;
  }
  .md\:d-block {
    display: block !important;
  }
  .md\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 575px) {
  .sm\:d-none {
    display: none !important;
  }
  .sm\:d-inline-block {
    display: inline-block !important;
  }
  .sm\:d-block {
    display: block !important;
  }
  .sm\:d-flex {
    display: flex !important;
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

@media (max-width: 1199px) {
  .xl\:order-1 {
    order: 1;
  }
  .xl\:order-2 {
    order: 2;
  }
  .xl\:order-3 {
    order: 3;
  }
  .xl\:order-4 {
    order: 4;
  }
  .xl\:order-5 {
    order: 5;
  }
  .xl\:order-6 {
    order: 6;
  }
}

@media (max-width: 991px) {
  .lg\:order-1 {
    order: 1;
  }
  .lg\:order-2 {
    order: 2;
  }
  .lg\:order-3 {
    order: 3;
  }
  .lg\:order-4 {
    order: 4;
  }
  .lg\:order-5 {
    order: 5;
  }
  .lg\:order-6 {
    order: 6;
  }
}

@media (max-width: 767px) {
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
  .md\:order-3 {
    order: 3;
  }
  .md\:order-4 {
    order: 4;
  }
  .md\:order-5 {
    order: 5;
  }
  .md\:order-6 {
    order: 6;
  }
}

@media (max-width: 575px) {
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
  .sm\:order-3 {
    order: 3;
  }
  .sm\:order-4 {
    order: 4;
  }
  .sm\:order-5 {
    order: 5;
  }
  .sm\:order-6 {
    order: 6;
  }
}

.svg-waves {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 180px;
}

@media (max-width: 767px) {
  .svg-waves {
    height: 80px;
  }
}

.svg-waves__parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5)
    infinite;
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.svg-waves__parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  fill: rgba(255, 255, 255, 0.7);
}

.svg-waves__parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
  animation-delay: -3s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  fill: rgba(255, 255, 255, 0.5);
}

.svg-waves__parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
  -webkit-animation-duration: 13s;
  animation-duration: 13s;
  fill: rgba(255, 255, 255, 0.3);
}

.svg-waves__parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
  animation-delay: -5s;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  fill: white;
}

@-webkit-keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.side-content {
  position: relative;
}

.side-content__wrap > *:nth-child(3n + 3) .side-content__item {
  left: unset;
  right: 100%;
}

.side-content__item {
  position: absolute;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  width: 360px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .side-content__item {
    display: none;
  }
}

.side-content:hover .side-content__item {
  pointer-events: auto;
  opacity: 1;
}

.toggle-element.-dshb-more {
  position: absolute;
  top: 55px;
  right: 10px;
}

.absolute-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.pointer {
  cursor: pointer;
}

.-sidebar-buttons {
  position: relative;
}

.-sidebar-buttons > * {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.-sidebar-buttons > *.-is-button-active {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  opacity: 1;
}

.-sidebar-buttons > *:nth-child(1n + 2) {
  position: absolute;
  top: 0;
  left: 0;
}

.-sidebar-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
  transform: translateX(30px);
}

.-sidebar-menu.-sidebar-menu-opened {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  transform: none;
  opacity: 1;
}

.-is-el-visible .-sidebar-buttons > *.-is-button-active {
  pointer-events: auto;
}

.-is-el-visible .-sidebar-menu.-sidebar-menu-opened {
  pointer-events: auto;
}

.progress-bar {
  position: relative;
}

.progress-bar__bg {
  width: 100%;
  height: 4px;
  border-radius: 4px;
}

.progress-bar__bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  border-radius: 4px;
}

.progress-bar__bar span {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.preloader {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.preloader__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #04021b;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transform-origin: top;
}

.preloader__bg.origin-bottom {
  transform-origin: bottom !important;
}

.-hover-dshb-header-light {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-hover-dshb-header-light:hover {
  color: var(--color-purple-1) !important;
  background-color: var(--color-light-4) !important;
}

.-base-sidebar-menu-hover {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-base-sidebar-menu-hover:hover {
  background-color: var(--color-light-4) !important;
}

.w-unset {
  width: unset;
}

.scroll-bar-1::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-bar-1::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.-stepCard-hover {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-stepCard-hover .stepCard__icon > *,
.-stepCard-hover .stepCard__title,
.-stepCard-hover .stepCard__text {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-stepCard-hover:hover {
  background-color: white !important;
}

.-stepCard-hover:hover .stepCard__icon > * {
  color: var(--color-dark-1) !important;
}

.-stepCard-hover:hover .stepCard__title {
  color: var(--color-dark-1) !important;
}

.-stepCard-hover:hover .stepCard__text {
  color: var(--color-light-1) !important;
}

.-infoCard-hover {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-infoCard-hover .infoCard__title {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-infoCard-hover:hover {
  background-color: white !important;
}

.-infoCard-hover:hover .infoCard__title {
  color: var(--color-dark-1) !important;
}

.-featureCard-hover .featureCard__content,
.-featureCard-hover .featureCard__title,
.-featureCard-hover .featureCard__text {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-featureCard-hover:hover .featureCard__content {
  background-color: var(--color-dark-1) !important;
}

.-featureCard-hover:hover .featureCard__title,
.-featureCard-hover:hover .featureCard__text {
  color: white !important;
}

.-featureCard-hover-3 .featureCard__content,
.-featureCard-hover-3 .featureCard__title,
.-featureCard-hover-3 .featureCard__text {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-featureCard-hover-3:hover .featureCard__content {
  background-color: var(--color-purple-1) !important;
}

.-featureCard-hover-3:hover .featureCard__title,
.-featureCard-hover-3:hover .featureCard__text {
  color: white !important;
}

.-featureCard-hover-2 .featureCard__icon {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.-featureCard-hover-2:hover .featureCard__icon {
  color: white !important;
  background-color: var(--color-dark-1) !important;
}

.-teamCard-hover .teamCard__image {
  position: relative;
}

.-teamCard-hover .teamCard__socials {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 6, 79, 0.6);
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.-teamCard-hover .teamCard__socials > div {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(15px);
}

.-teamCard-hover:hover .teamCard__socials {
  opacity: 1;
}

.-teamCard-hover:hover .teamCard__socials > div {
  transform: translateY(0);
}

.calendar-top-cell {
  text-align: center;
  width: 120px;
  height: 50px;
}

.calendar-cell {
  height: 150px;
  padding: 6px 10px;
  overflow-y: scroll;
}

.calendar-cell::-webkit-scrollbar {
  width: 4px;
}

.calendar-cell::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.toggle-element {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  pointer-events: none;
}

.toggle-element.-down {
  transform: translateY(20px);
}

.toggle-element.-is-el-visible {
  z-index: 25;
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.toggle-element.-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 26px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 180px;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
}

.footer-bg-color {
  background-color: #311f61;
}

.invoice-wrapper {
  min-height: 100vh;
}

.invoice-wrapper .invoice__content {
  padding: 120px 50px;
  padding-bottom: 100px;
}

.invoice-wrapper .invoice__footer {
  padding: 45px 0;
}

@media (max-width: 575px) {
  .sm\:pos-unset {
    position: unset;
  }
}

.toggle-bottom {
  position: absolute;
  top: 100%;
}

.toggle-bottom.-icons-1 {
  width: 160px;
}

.toggle-bottom.-profile {
  width: 300px;
  right: 0;
}

@media (max-width: 575px) {
  .toggle-bottom.-profile {
    position: fixed;
    top: unset;
    right: unset;
    bottom: 0;
    left: 0;
    width: 100vw;
  }
}

.toggle-bottom.-notifications {
  width: 470px;
  right: -20px;
}

@media (max-width: 575px) {
  .toggle-bottom.-notifications {
    position: fixed;
    right: unset;
    bottom: unset;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
  }
}

.toggle-bottom.-courses {
  width: 380px;
  right: -20px;
}

@media (max-width: 575px) {
  .toggle-bottom.-courses {
    position: fixed;
    right: unset;
    bottom: unset;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
  }
}

.img-el__side {
  position: absolute;
  top: -20px;
  left: -20px;
}

.img-el.-w-260 {
  width: 260px;
}

.sidebar-menu {
  position: fixed;
  z-index: 500 !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
}

.sidebar-menu__content {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 415px;
  height: 100%;
}

.sidebar-menu__bg {
  background-color: rgba(24, 24, 26, 0.7);
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.section-slider-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.section-slider-nav.-absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.section-slider-nav.-absolute.-prev {
  left: -35px;
}

.section-slider-nav.-absolute.-next {
  right: -35px;
}

@media (max-width: 575px) {
  .section-slider-nav.-absolute {
    width: 48px;
    height: 48px;
  }
  .section-slider-nav.-absolute i {
    font-size: 20px !important;
  }
  .section-slider-nav.-absolute.-prev {
    left: -10px;
  }
  .section-slider-nav.-absolute.-next {
    right: -10px;
  }
}

.section-slider-nav.-absolute-out {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.section-slider-nav.-absolute-out.-prev {
  right: calc(100% + 30px);
}

.section-slider-nav.-absolute-out.-next {
  left: calc(100% + 30px);
}

@media (max-width: 1500px) {
  .section-slider-nav.-absolute-out.-prev {
    right: calc(100% + 10px);
  }
  .section-slider-nav.-absolute-out.-next {
    left: calc(100% + 10px);
  }
}

.section-slider-nav.-white {
  background-color: white;
}

.section-slider-nav.-white:hover {
  background-color: var(--color-purple-1) !important;
  color: white !important;
}

.section-slider-nav.-outline-white {
  border: 2px solid white;
}

.section-slider-nav.-outline-white:hover {
  background-color: white !important;
  color: var(--color-dark-1) !important;
}

.section-slider-nav.-outline-dark-1 {
  border: 2px solid var(--color-dark-1);
}

.section-slider-nav.-outline-dark-1:hover {
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.elements-image {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .elements-image {
    flex-direction: column;
  }
}

.elements-image__img1 {
  margin-bottom: 120px;
}

@media (max-width: 767px) {
  .elements-image__img1 {
    padding-left: 100px;
  }
}

@media (max-width: 575px) {
  .elements-image__img1 {
    margin: 0;
    padding: 0;
  }
}

.elements-image__img2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 575px) {
  .elements-image__img2 {
    position: relative;
    margin-top: 20px;
  }
}

.elements-image__el1 {
  position: absolute;
  top: 85px;
  left: 50px;
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

.elements-image__el2 {
  position: absolute;
  bottom: 270px;
  left: -60px;
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

.elements-image__el3 {
  position: absolute;
  bottom: 75px;
  right: 30px;
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

.composition.-type-1 {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  gap: 60px;
}

@media (max-width: 991px) {
  .composition.-type-1 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .composition.-type-1 {
    gap: 20px;
  }
}

.composition.-type-1 .-img-1 {
  display: flex;
  justify-content: flex-end;
}

.composition.-type-1 .-img-2 {
  grid-row: span 2;
  align-self: flex-end;
}

.composition.-type-1 .-img-3 {
  display: flex;
  justify-content: flex-end;
}

.composition.-type-1 .-el-1 {
  position: absolute;
  bottom: 337px;
  right: 170px;
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

@media (max-width: 991px) {
  .composition.-type-1 .-el-1 {
    right: 40px;
  }
}

.composition.-type-1 .-el-2 {
  position: absolute;
  bottom: -40px;
  left: 0;
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

@media (max-width: 991px) {
  .composition.-type-1 .-el-2 {
    left: unset;
  }
}

.composition.-type-1 .-el-3 {
  position: absolute;
  top: 154px;
  left: -20px;
  box-shadow: 0px 40px 30px 0px #19192e0a;
}

@media (max-width: 991px) {
  .composition.-type-1 .-el-3 {
    left: 40px;
  }
}

@media (max-width: 575px) {
  .composition.-type-1 .-el-3 {
    top: unset;
    bottom: -30px;
    left: 20px;
  }
}

.composition.-type-2 {
  position: relative;
  display: flex;
}

@media (max-width: 767px) {
  .composition.-type-2 {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .composition.-type-2 > * {
    width: 100% !important;
  }
}

.composition.-type-2 .-el-1 {
  width: 345px;
}

.composition.-type-2 .-el-2 {
  position: relative;
  left: -55px;
  margin-top: 58px;
  width: 300px;
}

@media (max-width: 767px) {
  .composition.-type-2 .-el-2 {
    left: 0;
  }
}

@media (max-width: 575px) {
  .composition.-type-2 .-el-2 {
    margin-top: 20px;
  }
}

.composition.-type-3 {
  position: relative;
  padding-left: 112px;
}

@media (max-width: 575px) {
  .composition.-type-3 {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }
}

.composition.-type-3 .-el-1 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 300px;
}

@media (max-width: 575px) {
  .composition.-type-3 .-el-1 {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    transform: none;
    top: unset;
    left: unset;
  }
}

.composition.-type-4 {
  position: relative;
  padding-bottom: 72px;
}

@media (max-width: 991px) {
  .composition.-type-4 {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .composition.-type-4 {
    margin-bottom: 20px;
    padding-bottom: 0;
  }
}

.composition.-type-4 .-el-2 {
  position: absolute;
  bottom: 0;
  right: -60px;
  width: 360px;
}

@media (max-width: 1199px) {
  .composition.-type-4 .-el-2 {
    right: 50%;
    transform: translate(50%);
  }
}

@media (max-width: 575px) {
  .composition.-type-4 .-el-2 {
    margin-top: 30px;
    position: relative;
    right: unset;
    width: 100%;
    transform: none;
  }
}

.composition.-type-5 {
  position: relative;
  padding-bottom: 170px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1199px) {
  .composition.-type-5 {
    margin-left: 50px;
  }
}

@media (max-width: 575px) {
  .composition.-type-5 {
    padding-bottom: 0;
    margin-left: 0;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.composition.-type-5 .-el-2 {
  position: absolute;
  bottom: 0;
  left: -40px;
}

@media (max-width: 575px) {
  .composition.-type-5 .-el-2 {
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 30px;
  }
}

.composition.-type-5 .-el-3 {
  position: absolute;
  top: 20px;
  left: 120px;
}

.composition.-type-5 .-el-4 {
  position: absolute;
  bottom: 300px;
  left: -40px;
}

@media (max-width: 575px) {
  .composition.-type-5 .-el-4 {
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 30px;
  }
  .composition.-type-5 .-el-4 .-w-260 {
    width: 100%;
  }
}

.composition.-type-5 .-el-5 {
  position: absolute;
  bottom: 101px;
  left: 250px;
}

.composition.-type-6 {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 30px;
}

@media (max-width: 767px) {
  .composition.-type-6 {
    grid-template-columns: 1fr;
  }
}

.composition.-type-6 > * {
  width: 280px;
}

@media (max-width: 1199px) {
  .composition.-type-6 > * {
    width: 220px;
  }
}

@media (max-width: 991px) {
  .composition.-type-6 > * {
    width: 100%;
  }
}

.composition.-type-6 .-el-2 {
  grid-row: span 2;
}

.composition.-type-7 {
  position: relative;
}

.composition.-type-7 .-el-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.composition.-type-8 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  align-items: center;
}

@media (max-width: 575px) {
  .composition.-type-8 {
    gap: 15px;
  }
}

.composition.-type-8 .-el-1 {
  grid-row: span 2;
}

@media (max-width: 991px) {
  .lg\:h-auto {
    height: auto;
  }
}

.-button-hover-1__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

@media (max-width: 1199px) {
  .-button-hover-1__button {
    opacity: 1;
  }
}

.-button-hover-1:hover .-button-hover-1__button {
  opacity: 1;
}

.show-more__content {
  overflow: hidden;
  position: relative;
  max-height: 200px;
  transition: max-height 0.2s ease-out;
}

.show-more__content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 94.82%
  );
  transition: opacity 0.2s ease-out;
}

.show-more.is-active .show-more__content::after {
  opacity: 0;
}

.page-nav-menu.-line {
  border-bottom: 2px solid var(--color-light-5);
}

.page-nav-menu.-line .page-nav-menu__link {
  position: relative;
  display: block;
}

.page-nav-menu.-line .page-nav-menu__link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-purple-1);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.page-nav-menu.-line .page-nav-menu__link.is-active {
  color: var(--color-purple-1) !important;
}

.page-nav-menu.-line .page-nav-menu__link.is-active::after {
  opacity: 1;
}

table.table thead {
  background-color: var(--color-purple-3);
}

table.table th {
  padding: 25px;
  font-size: 16px;
  line-height: 1;
  color: var(--color-purple-1);
  font-weight: 500;
}

table.table th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table td {
  padding: 25px;
  border-bottom: 1px solid var(--color-light-5);
}

.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #615e5e;
}

.tooltip__content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.2;
  background-color: var(--color-dark-8);
  color: white;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tooltip__content::after {
  content: "";
  position: absolute;
  width: 0.53333rem;
  height: 0.53333rem;
  background-color: black;
  transform: rotate(45deg);
}

.tooltip.-top .tooltip__content {
  bottom: calc(100% + 6px);
  transform: translateY(8px);
}

.tooltip.-top .tooltip__content::after {
  bottom: -0.26667rem;
}

.tooltip.-bottom .tooltip__content {
  top: calc(100% + 6px);
  transform: translateY(-8px);
}

.tooltip.-bottom .tooltip__content::after {
  top: -0.26667rem;
}

.tooltip.-left .tooltip__content {
  right: calc(100% + 6px);
  transform: translateX(8px);
}

.tooltip.-left .tooltip__content::after {
  right: -0.26667rem;
}

.tooltip.-right .tooltip__content {
  left: calc(100% + 6px);
  transform: translateX(-8px);
}

.tooltip.-right .tooltip__content::after {
  left: -0.26667rem;
}

.tooltip:hover .tooltip__content {
  transform: none;
  opacity: 1;
}

.sidebar-calendar__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sidebar-calendar__button:hover {
  background-color: var(--color-light-3);
}

.sidebar-calendar__button.-is-active {
  background-color: var(--color-purple-1);
}

.sidebar-calendar__button.-is-active > div {
  color: white !important;
}

.bg-red-light {
  background-color: rgba(232, 84, 62, 0.15);
}

.bg-orange-light {
  background-color: rgba(231, 142, 52, 0.15);
}

.bg-blue-light {
  background-color: rgba(80, 142, 240, 0.15);
}

.bg-blue-light {
  background-color: rgba(68, 91, 149, 0.15);
}

.bg-purple-light {
  background-color: rgba(117, 69, 240, 0.15);
}

.bg-green-light {
  background-color: rgba(0, 255, 132, 0.15);
}

.dot-left {
  position: relative;
  display: flex;
  align-items: center;
}

.dot-left::before {
  content: "";
  position: absolute;
  right: calc(100% + 5px);
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--color-purple-1);
}

.dot-left.-orange::before {
  background-color: var(--color-orange-1);
}

.table-calendar {
  overflow: scroll;
  table-layout: fixed;
  width: 100%;
}

.table-calendar td {
  text-align: right;
  vertical-align: top;
  width: 120px;
}

.overflow-scroll {
  overflow: scroll;
}

.absolute-bookmark {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: var(--color-purple-1) !important;
  border-radius: 100%;
  background: white;
}
 
.media-btn{
  margin-right: 40px;
}

@media screen and (min-width:200px) and (max-width:950px) {
  .media .page-header__title{
    position: absolute;
    margin-left: 5px;
    margin-top: -65px;

  }
  
}
.media-2{
  background-color: rgb(11, 6, 77);
  padding: 10px 10px 10px 10px;
  color:#ffffff;
  box-shadow: 0 4px 8px 0 #000000;
}
@media screen and (min-width:200px) and (max-width:700px) {
  .media-2{
    display: flex;
    margin-top: 10px;
  } 
  
}

@media screen and (min-width:270px) and (max-width:640px) {
  .media-7{
  display: block;
  position: absolute;
  margin-left: -70px;
  margin-top: -50px;
  }
  
}

@media screen and (min-width:650px) and (max-width:1080px) {
  .media-7{
    position: absolute;
    margin-left: 50px;
  }
  
}

@media screen and (min-width:1000px) and (max-width:1300px) {
  .media-7{
    
    margin-left: 100px;
  }
  
}

@media screen and (min-width:580px) and (max-width:850px) {
  .media-7{
  display: block;
  position: absolute;
  margin-left: 50px;
  }
  
}



@media screen and (min-width:570px) and (max-width: 700px) {
.media-btn{
 margin-left: 10px;
 padding-right: 40px;
width: 75%;
height: 10%;
}  
}
@media screen and (min-width:280px)  and (max-width:580px) {
  .media-btn{
   display: block;
   width: 30%;
margin-top: 50px; 
margin-right: -60px;
  }
  
}

@media screen and (min-width:280px) and (max-width: 580px) {
  .media-img{
    width: 30%;
    height: 30%;
    position: absolute;
    margin-left: 100px;

  }  
  }
  
  
@media screen and (min-width: 280px) and (max-width: 580px) {
  .media-name{
   margin-left: 245px;
   position: absolute;
  }
  
}

@media screen and (min-width: 280px) and (max-width: 580px) {
  .media-name2{
   font-size: 12px;
   display: flex;
   position: absolute;
  padding-left: 100px;
  }
  
}

@media screen and (min-width: 640px) and (max-width: 1080px) {
  .media-8{
    position: relative;
   margin-top: 180px;
  }
  
}

@media screen and (min-width: 280px) and (max-width: 680px) {
  .media-8{
    position: relative;
   margin-top: 18px;
   display: block;
   max-width: 40px;
   margin-left: -142px;
  }
  
}

@media screen and (min-width:280px) and (max-width:450px){
  .text1-wrapper .innerWrap {
   display: none;
  }
  .text3-wrapper .innerWrap1{
    display: none;
  }
}


@media screen and (min-width:950px) and (max-width:1100px){
  .innerWrap{
    margin-right: -120px;
  }
}

@media screen and (max-width:551px) {
  .text1-wrapper div{
    position: relative;
    display: grid;
    flex-wrap: nowrap;
    max-width: 80%;
    max-height: 110px;
    
  }

  .masthead-4img{
    width: 40px;
  }

  .t1-name{
    font-size: 14px;
   padding-top: 0px;
  }

  .t1-b{
    font-size: 15px;
    
  }
  .text1-wrapper {
    padding-right: 0px;
  }

.masthead-image__el2{
  width: 100%;
}  

.innerWrap{
  margin-right: 120px;
  
}
}

@media screen and (max-width:530px) and (min-width:280px) {
  .innerWrap1{
    margin-left: 150px;
  }
  .text3-wrapper{
    max-width: 150px;
    margin-left: -250px;
  }
  .disp{
  display: none;
}
  
}


@media screen and (max-width:400px) {
  .text2-wrapper{
    width: 45%;
  }
}


@media screen and (max-width:991px) {
  .innerWrap1{
    margin-left: 150px;
  }
  
}

.section2-amz{
  margin-left: 170px;
}

@media screen and (max-width:766px) {
  .section2-amz{
    position: relative;
    margin-left: 150px;
  }

}

.section3-amz{
  padding-left: 100px;
}

@media screen and (max-width:766px) {
  .section3-amz{
    position: relative;
    padding-left: 152px;
  }

}


.section4-amz{
  margin-left: 70px;

}

@media screen and (max-width:766px) {
  .section3-amz{
    position: relative;
    padding-left: 152px;
  }

}


.swip2-in{
  height: 327px;
  transition-duration: 0ms;
  transform: translate(0px,0px,0px);
}

.position2{
  display: flex;
}

@media screen and (min-width: 280px) and (max-width: 540px) {
  .position2{
    display: grid;  
    grid-template-columns: 100px 100px 100px;  
    grid-template-rows: 70px 70px; 
  } 
}





.flip-card {
  background-color: transparent;
  height: 300px;
  perspective: 1000px;

}

.flip-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
 
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
  border-radius: 10px;
}
.flip-card-front img{
  margin-top: 50px;
}
.flip-card-back {
  background-color: #fff;
  color: black;
  transform: rotateY(180deg);
}
.flip-card-back .heading{
color:#6440FB;
margin-right:190px;
margin-top: 30px;
font-size:20px;
  padding-right:-30px;
}
.flip-card-back .heading1{
color:black;
  text-align:left;
  margin-left:20px;
  margin-top:20px;
}

.name1{ 
margin-left:150px;
}
.name2{
margin-left:150px;
margin-top:-8px;
}


.name4{
  margin-top:-20px;
  }
  

@media screen and (min-width:280px) and (max-width:850px){
.flip-card{
 background-color: transparent;
  max-width: 260px;
  height: 300px;
  margin-left: 50px;
  display: grid;
  grid-template-columns: 100px 100px 100px;  
  grid-template-rows: 70px 70px; 
}
.crd1{
  margin-left: 80px;
}
.flip-card-back .heading{
font-size:16px;
}
.flip-card-back .heading1{
font-size:13px;
}
.flip-card-back .name1{
font-size:14px;
}
.flip-card-back .name2{
font-size:11px;
}
.flip-card-front img{
position:relative;

max-width:260px;
height:100px;
}

}

@media screen and (max-width:780px) {
.crd4{
  margin-left: 80px;
}  
}

@media screen and (max-width:600px) {
  .flip-card{
    margin-left: 155px;
    
  }  
  .crd1{
    margin-left: 183px;
  }
  .crd4{
    margin-left: 183px;
  }
  }

  @media screen and (min-width:280px) and (max-width:665px) {
    .top1{
      margin-top: 0px;
      display: grid;
      grid-template-columns: 300px 300px 200px;  
      grid-template-rows: 30px 30px; 
      position: absolute;
      margin-left:140px ;
    }
    .topn{
      margin-top: 0px;
    }
  } 

  .accept{
    display: flex;
    
  }
  @media screen and (min-width: 270px) and (max-width: 650px) {
    .accept{
      margin-left: 1px;
      
    }
  }

  .side-nav{
    position: sticky;
  }

  .sect{
    font-size: 22px;
    font-weight: bold;
    margin-top: 110px;
    background-color: rgba(117, 69, 240, 0.15);
  }

  .sect div a:hover{
    color: darkred;
    text-decoration: underline;
  }
  .shad{
    box-shadow: 0 4px 8px 0px #000;
  }

 