
body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar {
  width: 335px;
  position: absolute;
  top: 160px;
  right: 10px;
  padding-top: 40px;
}

@media screen and (min-width:200px) and (max-width:700px) {
  .sidebar {
    position: absolute;
    top:300px;
  }
  
}

.sidebar div {
  /* padding: 8px; */

  font-size: 24px;
  display: block;
}

.custom-sidebar {
  width: 345px;
  position: absolute;
  top: 210px;
  right: 150px;
  padding-top: 2px;
  padding-left: 50px;
  background-color: #fff;
  box-shadow: 0px 2px 7px rgb(66, 65, 65);
}
 
.custom-sidebar div {
  padding: 8px;
  font-size: 24px;
  /* display: block; */
}
.main-content {
  margin-left: -150px;
  font-size: 18px;
}

@media screen and (min-width: 900px) and (max-width:1400px) {
  .custom-sidebar{
    width: 25%;
   margin-right: 10px;
  }
}

@media screen and (min-width: 280px) and (max-width:900px) {
  .custom-sidebar{
 position: relative;
 left: 120px ;   
 max-width: 100%;
 margin-top: -500px;
  }
}


@media screen and (min-width: 320px) and (max-width: 900px) {
  .WordSection1{
    position: relative;
    top: 0px;
    left: -135px;
    width: 350px;
    overflow-y: visible;
   text-align: center;
  display: block;
  margin-bottom: 500px;
    
  }
}
@media screen and (min-width: 270px) and (max-width: 310px) {
  .WordSection1{
    position: relative;
    top: 0px;
    left: -135px;
    width: 250px;
    overflow-y: visible;
   text-align: center;
  display: block;
  margin-bottom: 500px;
    
  }
}
.WordSection1{
  background-color: #ffffff;
  margin-left: 110px;
  margin-top: 40px;
  padding: 10px 50px 0px 30px;
  box-shadow: 0px 2px 7px rgb(66, 65, 65);
}

/* .custom-page{
background-color: #fff;

} */



/* CustomDropdown.css */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-trigger {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.custom-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-option {
  padding: 12px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #ddd;
}
