select,
input[type="text"],
input[type="search"],
textarea,
input[type="email"] {
  border: 0;
}

.-dark-mode form.contact-form {
  select,
  input[type="text"],
  input[type="password"],
  input[type="search"],
  textarea,
  input[type="email"] {
    border-color: rgba(white, 0.2);
    color: white !important;
  }
}

form.contact-form {
  label,
  p {}

  select,
  input[type="text"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  textarea,
  input[type="email"] {
    border: 0;
    outline: none;
    width: 100%;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
    font-size: 15px;
    line-height: 1.5;
    padding: 15px 22px;
    transition: all 0.15s $easeOutQuart;

    &:focus {
      outline: none;
    }

    &.-border-dark {
      border: 1px solid rgba(black, 0.15) !important;
    }
  }

  ::placeholder {
    font-weight: 400;
  }
  
  &.-light {
    label,
    p {
      color: white;
    }
  
    select,
    input[type="text"],
    input[type="search"],
    textarea,
    input[type="email"] {
      color: white;
      border-bottom: 1px solid rgba(white, 0.6);
    }
  
    ::placeholder {
      color: rgba(white, 0.7);
    }
  }
}

.form-checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  label {
    margin: 0;
    margin-left: 9px;
    font-size: 14px;
  }

  &__mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border: 2px solid var(--color-dark-3);
  }

  &__icon {
    display: none;
    position: absolute;
    font-size: 6px;
    color: white;
  }

  input {
    cursor: pointer;
    z-index: 5;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:checked ~ .form-checkbox__mark {
      background-color: var(--color-dark-1);
      border-color: var(--color-dark-1);

      .form-checkbox__icon {
        display: block;
      }
    }
  }

  &:hover input ~ .form-checkbox__mark {
    background-color: var(--color-dark-7);
  }

  &:hover input:checked ~ .form-checkbox__mark {
    background-color: var(--color-dark-1);
  }
}

.form-radio {
  display: flex;
  align-items: center;

  .radio {
    cursor: pointer;
    position: relative;
    display: block;
  
    &__mark {
      height: 15px;
      width: 15px;
      border-radius: 100%;
      border: 2px solid var(--color-dark-3);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__icon {
      display: none;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: var(--color-dark-1);
    }
  
    input {
      z-index: 5;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      
      &:checked ~ .radio__mark {
        border-color: var(--color-dark-1);
        
        .radio__icon {
          display: block;
        }
      }
    }
  
    &:hover input ~ .radio__mark {
      background-color: rgba(black, 0.05);
    }
  }
}

.form-switch .switch {
  position: relative;
  width: 60px;
  height: 30px;
  
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);
    background-color: rgba(white, 0.2);
    transition: .3s;
    border-radius: 30px;
    border: 1px solid #E7E7EC;
    
    &::before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 5px;
      bottom: 4px;
      background-color: var(--color-light-2);
      border-radius: 50%;
      transition: .3s;
    }
  }

  input {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  
    &:checked + .switch__slider {
      background-color: var(--color-purple-1);
    }

    &:checked + .switch__slider::before {
      transform: translateX(29px);
      background-color: #FFFFFF;
    }
  }
}

.form-single-field {
  &.-base {
    position: relative;
    height: 70px;

    > input {
      width: 100%;
      height: 100%;

      &.text-white {
        &::placeholder {
          color: white;
        }
      }

      &.text-dark-1 {
        &::placeholder {
          color: var(--color-dark-1);
        }
      }
    }

    > button {
      position: absolute;
      top: 10px;
      bottom: 10px;
      right: 10px;
      min-width: calc(70px - 20px);
    }
  }

  &.-help {
    position: relative;
    height: 70px;

    > input {
      width: 100%;
      height: 100%;
      border: 1px solid var(--color-light-5);
      padding: 24px;
      border-radius: 8px;
    }

    > button {
      position: absolute;
      top: 10px;
      bottom: 10px;
      right: 10px;
      width: 144px;
    }
  }
}

.form-upload {
  &__wrap {
    position: relative;
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 28px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
