* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.navbar {
  height: 150px;
  width: 100%;
  padding: 20px 30px;
  position: relative;

}

.s-bar {
  position: absolute;
  display: inline-block;
  margin-top: -40px;
  margin-left: 420px;
}

@media screen and (max-width:1024px) {
  .s-bar {
    display: none;
  }
}

.s1 {
  cursor: pointer;
  background-color: white;
  border: 2px solid white;
  font-size: 15px;
  height: 40px;
  padding: 6px 34px;
  border-radius: 8px;
  margin-left: px;
  margin-top: -1px;
}

.s1:hover {
  background-color: #140342;
  color: white;
}

.navbar .nav-header {
  display: inline;
}

.navbar .nav-header .nav-logo {
  margin-left: 0px;
  position: absolute;
  margin-top: -30px;
  /* width: 150px; */
}

.navbar .nav-links {
  display: inline;
  float: right;
  font-size: 16px;
  margin-top: 80px;
  margin-right: -20px;
}


.navbar .nav-links a {
  padding: 10px 13px;
  text-decoration: none;
  font-weight: 550;
  color: #ffffff;

}

/* Hover effects */
.navbar .nav-links a:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;

}

/* responsive navbar toggle button */
.navbar #nav-check,
.navbar .nav-btn {
  display: none;
}

@media (max-width:1024px) {
  .navbar .nav-btn {
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .navbar .nav-btn label {
    display: inline-block;
    width: 80px;
    height: 70px;
    padding: 25px;
  }

  .navbar .nav-btn label span {
    display: block;
    height: 10px;
    width: 25px;
    border-top: 3px solid #eee;
  }

  .navbar .nav-btn label:hover,
  .navbar #nav-check:checked~.nav-btn label {
    background-color: #140342;
    transition: all 0.5s ease;
  }

  .navbar .nav-links {
    position: absolute;
    display: block;
    text-align: center;
    width: 60%;
    background-color: #140342;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 0;
    right: 20px;
  }

  .navbar .nav-links a {
    display: block;
  }

  /* when nav toggle button not checked */
  .navbar #nav-check:not(:checked)~.nav-links {
    height: 0px;
  }

  /* when nav toggle button is checked */
  .navbar #nav-check:checked~.nav-links {
    height: calc(100vh - 70px);
    overflow-y: auto;
  }

  /* .navbar .nav-links .loginBtn {
      padding: 20px 20px ;
      margin: 20px;
      font-size:  18px;
      font-weight: bold;
      color: rgb(9, 14, 90);
    } */
  /* Responsive dropdown code */
  .navbar .nav-links .dropdown,
  .navbar .nav-links .dropdown2 {
    float: none;
    width: 100%;
  }

  .navbar .nav-links .dropdown1 {
    float: none;
    width: 100%;
    position: relative;
    display: block;
    text-align: center;
    right: 200px;
  }

  .navbar .nav-links .drop-content {
    position: relative;
    background-color: rgb(61, 61, 239);
    top: 0px;
    left: 0px;
    display: none;

    min-width: 100px;
    font-size: 14px;

    align-items: center;
    z-index: 1;
  }

  .navbar .nav-links .drop-content2 {
    position: relative;
    background-color: rgb(129, 105, 250);
    top: 0px;
    /* font-size: 11px; */
    /* margin-left: -60px; */
    left: 0px;
    display: none;

    min-width: 100px;
    font-size: 14px;

    align-items: center;
    z-index: 1;
  }

  /* Text color */
  .navbar .nav-links .drop-content a {
    color: rgb(9, 14, 90);
  }

}

/* Dropdown menu CSS code */
.dropdown {
  position: relative;
  display: inline-block;
  color: #ffffff;
}

.drop-content,
.drop-content2 {
  display: none;
  position: absolute;
  background-color: #89cff0;
  min-width: 320px;
  font-size: 14px;
  top: 30px;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgb(255, 255, 255);
}

/* on hover show dropdown */
.dropdown:hover .drop-content,
.dropdown2:hover .drop-content2 {
  display: block;
}

/* drondown links */
.drop-content a {
  display: block;
  transition: all 0.15s ease !important;
}


.dropdown2 .drop-content2 {
  position: absolute;
  left: 250px;
  top: 30px;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  max-height: 530px;
}

.drop-content2::-webkit-scrollbar {
  display: none;
}

.drop-content3 {
  display: none;
  position: absolute;
  background-color: #89cff0;
  min-width: 200px;
  font-size: 14px;
  top: 34px;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgb(255, 255, 255);

}

.dropdown:hover .drop-content,
.dropdown2:hover .drop-content3 {
  display: block;
}

.dropdown2 .drop-content3 {
  position: absolute;
  left: 180px;
  top: 6px;
}

.drop-content4 {
  display: none;
  position: absolute;
  background-color: #89cff0;
  min-width: 180px;
  font-size: 14px;
  top: 34px;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgb(255, 255, 255);
}

.dropdown:hover .drop-content,
.dropdown2:hover .drop-content4 {
  display: block;
}

.dropdown2 .drop-content4 {
  position: absolute;
  left: 180px;
  top: 50px;
}


.drop-content5 {
  display: none;
  position: absolute;
  background-color: #89cff0;
  min-width: 180px;
  font-size: 14px;
  top: 34px;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgb(255, 255, 255);
}

.dropdown:hover .drop-content,
.dropdown2:hover .drop-content5 {
  display: block;
}

.dropdown2 .drop-content5 {
  position: absolute;
  left: 180px;
  top: 86px;
}


.drop-content6 {
  display: none;
  position: absolute;
  background-color: #89cff0;
  min-width: 180px;
  font-size: 14px;
  top: 34px;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgb(255, 255, 255);
}

.dropdown:hover .drop-content,
.dropdown2:hover .drop-content6 {
  display: block;
}

.dropdown2 .drop-content6 {
  position: absolute;
  left: 180px;
  top: 5px;
}


.drop-content7 {
  display: none;
  position: absolute;
  background-color: #89cff0;
  min-width: 180px;
  font-size: 14px;
  top: 34px;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgb(255, 255, 255);
}

.dropdown:hover .drop-content,
.dropdown2:hover .drop-content7 {
  display: block;
}

.dropdown2 .drop-content7 {
  position: absolute;
  left: 180px;
  top: 5px;
}


.drop-content8 {
  display: none;
  position: absolute;
  background-color: #89cff0;
  min-width: 180px;
  font-size: 14px;
  top: 34px;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 2px 7px rgb(255, 255, 255);
}

.dropdown:hover .drop-content,
.dropdown2:hover .drop-content8 {
  display: block;
}

.dropdown2 .drop-content8 {
  position: absolute;
  left: 180px;
  top: 45px;
}


.dropdown1 {
  position: relative;
  display: inline-block;
  margin-left: 200px;
  color: #ffffff;
}

.dropdown1 button {
  background-color: white;
  border: 2px solid white;
  padding: 6px 34px;
  height: 48px;
  font-size: 15px;
  border-radius: 8px;
}

.dropdown1 button:hover {
  background-color: #140342;
  color: white;
}