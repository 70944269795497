
.cont{
    color: black;
    font-size: 14px;
}
.cont1{
    color: black;
    font-size: 14px; 
    margin-left: 1in;
}

.headings{
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: bold;
}
.link{
    color: rgba(7, 83, 234, 0.717);
    font-size: 14px;
}
.MsoListParagraphCxSpMiddle{
    color: rgba(7, 83, 234, 0.717);
        font-size: 16px;
}
.MsoListParagraphCxSpMiddle:hover a{
    text-decoration: underline;
}
.MsoListParagraphCxSpLast{
    color: rgba(7, 83, 234, 0.717);
       font-size: 16px;
       
}
.MsoListParagraphCxSpLast:hover a{
    text-decoration: underline;
}
.MsoListParagraphCxSpFirst{
    color: rgba(7, 83, 234, 0.717);
    font-size: 16px;
}

.MsoListParagraphCxSpFirst:hover a{
    text-decoration: underline;
}

table{
    box-shadow: 0px 2px 7px rgb(45, 44, 44);
}